import { useEffect, useState } from 'react'
import 'assets/styles/chart.css'
import Avatar from 'components/atoms/Avatar'
import { DashboardLayout } from 'containers/Layouts/DashboardLayout'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { useAdminTools } from 'hooks/useAdminTools'
import useIsMobile from 'hooks/useIsMobile'

const Chart = () => {
  const { companyId } = useParams<any>()
  const [myCompany, setMyCompany] = useState<any>()
  const { getEmployeeChart } = useAdminTools()
  const isMobile = useIsMobile()

  const user: any = useSelector((state) => get(state, 'user.user'))

  const getCompanyName = () => {
    user?.employee?.forEach((company) => {
      if (company.companyId === parseInt(companyId)) {
        setMyCompany(company)
      }
    })
  }

  const getChartData = () => {
    const headers = {
      'x-appengine-company-id': companyId,
    }
    const onSuccess = (response: any) => {}
    getEmployeeChart(headers, onSuccess)
  }

  useEffect(() => {
    getChartData()
  }, [])

  useEffect(() => {
    getCompanyName()
  }, [myCompany, getCompanyName])

  const responsiveClass = `sm:max-w-172 lg:max-w-200 xl:max-w-256 ${isMobile ? 'mx-4' : 'ml-12'} max-w-192`

  return (
    <DashboardLayout onlyMainSidebar>
      <div
        className="w-full h-24 fixed top-0"
        style={{ zIndex: 1, background: 'linear-gradient(134deg, rgb(106, 152, 240) 0%, rgb(73, 97, 220) 99%)' }}
      >
        <div
          className={`${responsiveClass} fixed rounded-lg justify-start flex items-center mt-7`}
          style={{ zIndex: 1 }}
        >
          <Avatar
            className=""
            rectangle
            name={myCompany?.companyName || 'Instinct Design Studio'}
            image={myCompany?.companyThumbnail}
          />
          <h1 className={`${isMobile ? 'text-xl' : 'text-3xl'} text-white font-bold ml-4`}>
            {' '}
            {`Welcome to ${
              myCompany?.companyName
              // .split(' ')
              // .map((name) => capitalize(name))
              // .join(' ')
            }`}
          </h1>
        </div>
      </div>

      <main className="transform translate-y-28 w-full h-screen">
        <iframe title="chart frame" src={`/company/${companyId}/chart`} width={'100%'} height={'100%'}></iframe>
      </main>
    </DashboardLayout>
  )
}

export default Chart
