import useUrlState from '@ahooksjs/use-url-state'

/**
 * This is state to mange current url paramters. foreg: pagigation,filters etc...
 * 
 * @param initialState 
 * @param push true for updating the parameters using pushState and false for replaceState
 * @returns 
 */

export default function useParams(initialState = {}, push = true) {
  const [state, setState] = useUrlState(initialState, {
    navigateMode: push ? 'push' : 'replace',
  })

  return [state, setState]
}
