import * as types from 'state/redux/constants'

const initialState = {
  user: {},
  personalInfo: {},
  userProfile: {},
}

const userReducer = (state = initialState, action: { type: any; msg: any; err: any; data: any }) => {
  switch (action.type) {
    case types.SET_USER_DATA:
      return { ...state, user: action.data }
    case types.SET_PERSONAL_INFO:
      return { ...state, personalInfo: action.data }
    case types.SET_USER_PROFILE:
      return { ...state, userProfile: action.data }

    default:
      return state
  }
}

export default userReducer
