import Avatar from 'components/atoms/Avatar'
import { AiFillLock, AiOutlineGlobal } from 'react-icons/ai'
import { IoIosArrowDown, IoMdArrowDropright } from 'react-icons/io'
import parse from 'html-react-parser'
import moment from 'moment'
import { capitalize } from 'lodash'
import useIsMobile from 'hooks/useIsMobile'

type FeedbackCardProps = {
  idx: number
  currentCard: number
  setCurrentCard: React.Dispatch<React.SetStateAction<number>>
  feedback: any
}

export const ReviewFeedbackCard = ({ setCurrentCard, currentCard, idx, feedback }: FeedbackCardProps) => {
  const isMobile = useIsMobile()

  const handleCardClick = () => {
    if (currentCard === idx) {
      setCurrentCard(-2)
    } else {
      setCurrentCard(idx)
    }
  }

  const FeedbackDateFormat = (date) => {
    const now = moment(new Date())
    const newDate = moment(date)
    let returnData = null

    if (now.diff(newDate, 'days') > 7) {
      returnData = newDate.format('DD MMM YYYY')
    } else {
      returnData = newDate.fromNow()
    }

    return returnData
  }

  return (
    <div>
      <div
        onClick={handleCardClick}
        style={{ boxShadow: '0 4px 10px 0 rgba(171, 171, 171, 0.2)' }}
        className={`bg-white py-3 px-3 rounded-md ${'cursor-pointer bg-red-200 text-gray-500'}`}
      >
        <div className="file__close flex justify-between rounded cursor-pointer">
          <div className={`flex ${isMobile ? 'text-xs' : 'text-sm gap-4'}`}>
            <div>
              <span className={`${isMobile ? 'text-xs' : ''} font-bold`}>From: </span>
              <span className={isMobile ? 'text-xs' : ''}>{feedback?.from?.fullName}</span>
            </div>
            <div>
              <IoIosArrowDown
                style={{ transform: currentCard === idx ? 'rotate(180deg)' : '', transition: 'all 0.3s ease-in-out' }}
                className="text-gray-600"
                size={20}
              />
            </div>
          </div>
          <div className="flex">
            <div className="flex space-x-2 font-semibold items-center">
              {feedback?.visibility === 'PUBLIC' && <AiOutlineGlobal className="text-themeGray" />}
              {(feedback?.visibility === 'PRIVATE' ||
                feedback?.visibility === 'PRIVATE_AND_MANAGER' ||
                feedback?.visibility === 'MANAGER_ONLY') && <AiFillLock className="text-themeGray" />}
              <p className={`${isMobile ? 'text-xs' : 'text-sm'} pr-4`}>
                {feedback?.visibility === 'PRIVATE_AND_MANAGER'
                  ? 'Private + Manager'
                  : feedback?.visibility === 'MANAGER_ONLY'
                  ? 'Manager only'
                  : feedback?.visibility === 'PRIVATE'
                  ? feedback?.type === 'PRIVATE_NOTES'
                    ? 'Private note'
                    : capitalize(feedback?.visibility)
                  : capitalize(feedback?.visibility)}
              </p>
            </div>
            <p className={`${isMobile ? 'text-xs' : 'text-sm'} font-semibold italic text-gray-700`}>
              ({FeedbackDateFormat(feedback?.createdAt)})
            </p>
          </div>
        </div>
        <div
          style={{
            transition: 'max-height 0.4s linear',
            maxHeight: currentCard === idx ? '500px' : '0',
            overflow: 'hidden',
          }}
          className={`${currentCard === idx ? 'my-4' : ''}`}
        >
          <div className="flex gap-2 items-center">
            <Avatar
              nameClass={isMobile ? 'text-xs' : 'text-sm'}
              medium
              name={feedback?.from?.fullName}
              image={feedback?.from?.profileImageUrl}
              withName
            />
            <span className="text-gray-600 font-bold text-xl">
              <IoMdArrowDropright size={24} />
            </span>
            {feedback?.to?.map((to) => (
              <Avatar
                nameClass={isMobile ? 'text-xs' : 'text-sm'}
                medium
                name={to?.fullName}
                image={to?.profileImageUrl}
                withName
              />
            ))}
          </div>
          <div className="mt-3 mx-1">
            <span className="font-bold text-sm">Feedback:</span>
            <p className={`mt-2 ${isMobile ? 'text-xs' : 'text-sm'}`}>{parse(feedback?.description)}</p>
          </div>
        </div>
      </div>
    </div>
  )
}
