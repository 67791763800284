/* This example requires Tailwind CSS v2.0+ */
import useIsMobile from 'hooks/useIsMobile'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'

export default function Pagination({
  size = 0,
  pagination,
  onPageChange,
}: {
  size: number
  pagination: { currentPage: number; pageSize: number; totalPage: number; totalCount?: number }
  onPageChange?: (a: string) => void
}) {
  const { currentPage, totalPage, totalCount } = pagination
  const from = size * currentPage - (size - 1)
  const to = size * currentPage

  const isMobile = useIsMobile()

  return (
    size > 0 && (
      <div className={`flex float-right items-center my-4 ${isMobile ? 'pb-10' : ''}`}>
        <p className="text-sm font-medium mx-1">
          {from} – {to > totalCount ? totalCount : to} of {totalCount}
        </p>
        {currentPage !== 1 && (
          <button
            onClick={() => onPageChange('Prev')}
            className="flex border items-center justify-center w-8 h-8 rounded-lg mx-1 focus:shadow-outline hover:border-themeBlue"
          >
            <MdArrowBackIosNew />
          </button>
        )}
        {currentPage !== totalPage && (
          <button
            onClick={() => onPageChange('Next')}
            className="flex border items-center justify-center w-8 h-8 rounded-lg mx-1 focus:shadow-outline hover:border-themeBlue"
          >
            <MdArrowForwardIos />
          </button>
        )}
      </div>
    )
  )
}
