const SubMenu = ({ subnav, show }) => {
  return (
    <div
      style={{ zIndex: 5498, height: 'calc(100vh - 1px)', overflowY: 'scroll', padding: '2rem 0 5rem 0' }}
      className={`${
        show ? 'translate-x-0' : 'translate-x-full'
      } transition-all transform bg-gray-100 absolute top-0 w-full`}
    >
        {subnav()}
    </div>
  )
}

export default SubMenu
