import React from 'react'
import 'assets/styles/chart.css'
import OrgChart from '@abhxnav/react-org-chart'
import avatarSVG from 'assets/logo/avatarPersonal.svg'
import Button from 'components/atoms/Button/button'
import { HttpRequest } from 'helpers/services'
import { Loader } from 'react-hyper-tree'

export default class Chart extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      tree: undefined,
      downloadingChart: false,
      config: {},
      highlightPostNumbers: [1],
      companyId: window.location.pathname.split('/')[2],
      hideButtons: window.parent.location.pathname.includes('org-chart') ? false : true,
    }
  }

  getChild = async (id) => {
    const { companyId } = this.state
    const headers = {
      'x-appengine-company-id': companyId,
      type: 'directReports',
      employeeId: id,
    }
    try {
      const res = await HttpRequest('GET', `/company/employees/organization`, {}, headers)
      if (res?.data?.code === 200) {
        const updatedItem = res.data.data.map((item) => {
          const updatedPerson = {
            ...item.person,
            avatar: item.person.avatar === '' ? avatarSVG : item.person.avatar,
            department: item.person.department || { id: '', name: '' },
          }

          return {
            ...item,
            person: updatedPerson,
          }
        })
        return updatedItem
      }
      return []
    } catch (error) {
      console.error(error)
      return []
    }
  }

  handleDownload = () => {
    this.setState({ downloadingChart: false })
  }

  handleOnChangeConfig = (config) => {
    this.setState({ config: config })
  }

  handleLoadConfig = () => {
    const { config } = this.state
    return config
  }

  getTruncatedString = (str, len) => {
    if (!str || str?.length <= len) {
      return str
    }
    return `${str?.substring(0, len)}...`
  }

  getEmployeeChart = () => {
    const { companyId } = this.state
    const headers = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/company/employees/organization`, {}, headers)
      .then((res) => {
        if (res?.data?.code === 200) {
          const data = res.data.data
          const department = data?.person?.department
          const updatedItem = {
            ...data,
            person: {
              ...data.person,
              department : {
                name: this.getTruncatedString(department?.name, 20)
              },
              title:  this.getTruncatedString(data?.person?.title, 20),
              avatar: data.person.avatar !== '' ? data.person.avatar : avatarSVG,
            },
            children: data.children.map((item) => {
              const itemDepartment = item?.person?.department
              return {
                ...item,
                person: {
                  ...item.person,
                  department : {
                    name: this.getTruncatedString(itemDepartment?.name, 20)
                  },
                  title:  this.getTruncatedString(item?.person?.title, 20),
                  avatar: item.person.avatar !== '' ? item.person.avatar : avatarSVG,
                },
              }
            }),
          }
          this.setState({ tree: updatedItem })
        }
        if (!window.parent.location.pathname.includes('org-chart')) {
          const zoom = document.querySelector('#zoom-in')
          const fit = document.querySelector('#zoom-extent')
          fit.click()
          setTimeout(() => {
            zoom.click()
          }, 300)
        }
      })
      .catch((err) => {
        console.error(err.response)
      })
  }

  loadChildren = async (d) => {
    try {
      const childrenData = await this.getChild(d.id)
      return childrenData
    } catch (error) {
      console.error(error)
      return []
    }
  }

  componentDidMount() {
    this.getEmployeeChart()
  }

  render() {
    const { downloadingChart } = this.state

    const downloadImageId = 'download-image'
    const downloadPdfId = 'download-pdf'

    if (this.state.isLoading) return <div>Loading...</div>

    return (
      <>
        <div className="absolute top-5 left-10">
          <div className={`${this.state.hideButtons ? 'hidden' : 'visible'} flex flex-col gap-3`}>
            <Button
              id="zoom-in"
              size="md"
              btnColor="bg-blue-500"
              hoverColor="bg-blue-500"
              className="font-semibold"
              label={'+'}
              primary
            />
            <Button
              id="zoom-out"
              size="md"
              btnColor="bg-blue-500"
              hoverColor="bg-blue-500"
              className="font-semibold"
              label={'-'} 
              primary
            />
            <Button
              id="zoom-extent"
              size="md"
              btnColor="bg-blue-500"
              hoverColor="bg-blue-500"
              className="font-semibold"
              label={'Fit'}
              primary
            />
          </div>
        </div>
        <div className="absolute top-5 right-10">
          <div className={`${this.state.hideButtons ? 'hidden' : 'visible'} flex gap-3`}>
            <Button
              id="download-image"
              size="md"
              btnColor="bg-blue-500"
              hoverColor="bg-blue-500"
              className="font-semibold"
              label={'Download as Image'}
              primary
              loading={downloadingChart}
            />
            <Button
              id="download-pdf"
              size="md"
              btnColor="bg-blue-500"
              hoverColor="bg-blue-500"
              className="font-semibold"
              label={'Download as PDF'}
              primary
              loading={downloadingChart}
            />
          </div>
        </div>
        {this.state.tree ? (
          <OrgChart
            tree={this.state.tree}
            downloadImageId={downloadImageId}
            downloadPdfId={downloadPdfId}
            onConfigChange={(config) => {
              this.handleOnChangeConfig(config)
            }}
            loadConfig={(d) => {
              let configuration = this.handleLoadConfig(d)
              if (configuration) {
                return configuration
              }
            }}
            downlowdedOrgChart={(d) => {
              this.handleDownload()
            }}
            loadImage={(d) => {
              let avatarPersonnel
              if (d.person.avatar !== null && d.person.avatar !== '') {
                avatarPersonnel = d.person.avatar
              } else {
                avatarPersonnel = avatarSVG
              }
              return Promise.resolve(avatarPersonnel)
            }}
            loadChildren={this.loadChildren}
          />
        ) : (
          <Loader />
        )}
      </>
    )
  }
}
