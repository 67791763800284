/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { get, isEmpty } from 'lodash'
import { useSelector } from 'react-redux'
import ExploreGoalList from 'components/templates/Review/List/ExploreGoalList'

const Explore = ({
  goalsList,
  avgRating,
  onAvgRatingChange,
  onGoalsRatingChange,
  fetching,
  isSelf,
  isManager,
  employeeSubmitted,
  managerSubmitted,
  assessmentType,
  goalsSelfAssessment,
  isDisabled = false,
  setSelectedGoal,
  setViewModal,
  ratingSys,
  valueRatingSys,
  excludedGoals,
  setExcludedGoals,
  ancestor,
}: any) => {
  const user: any = useSelector((state) => get(state, 'user.user'))

  const [selectedCompany, setSelectedCompany]: any = useState()

  useEffect(() => {
    user.employee.map((company) => {
      if (company.companyId === Number(companyId)) {
        setSelectedCompany(company)
      }
    })
  }, [user])

  const { companyId }: any = useParams()

  return (
    <>
      <div className="w-full font-roboto items-center mt-8 px-4 mb-5">
        <main className="">
          <div className="relative font-roboto">
            <ExploreGoalList
              setRefetch={() => {}}
              avgRating={avgRating}
              onAvgRatingChange={onAvgRatingChange}
              onGoalsRatingChange={onGoalsRatingChange}
              data={!isEmpty(goalsList) ? goalsList : []}
              loading={fetching}
              isSelf={isSelf}
              isManager={isManager}
              employeeSubmitted={employeeSubmitted}
              managerSubmitted={managerSubmitted}
              assessmentType={assessmentType}
              goalsSelfAssessment={goalsSelfAssessment}
              isManage
              isDisabled={isDisabled}
              setSelectedGoal={setSelectedGoal}
              setViewModal={setViewModal}
              ratingSys={ratingSys}
              valueRatingSys={valueRatingSys}
              excludedGoals={excludedGoals}
              setExcludedGoals={setExcludedGoals}
              ancestor={ancestor}
            />
            {!fetching && goalsList?.length === 0 && (
              <div className="font-semibold flex justify-center my-14">
                <p>No goals have been created yet</p>
              </div>
            )}
          </div>
        </main>
      </div>
    </>
  )
}

export default Explore
