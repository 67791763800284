import { useEffect, useState } from 'react'

import Dropdown from 'components/atoms/Dropdown/Dropdown'
import Table from 'components/organisms/Table/Table'
import { TABLE } from 'constants/common'
import { useCompanyTools } from 'hooks/useCompanyTools'
import { nanoid } from 'nanoid'
import { useTranslation } from 'react-i18next'

const WorkHistoryList = ({ data, setShowWorkHistory, setSelectedJob, setRefetch, hideEdit = false }: any) => {
  const { t } = useTranslation()
  const [localCopy, setLocalCopy] = useState(data)
  const { deleteWorkHistory } = useCompanyTools()

  useEffect(() => {
    setLocalCopy(data)
  }, [data])

  const onDelete = async (idx: number) => {
    const onSuccess = () => {
      setRefetch(true)
    }
    await deleteWorkHistory(idx, onSuccess)
  }

  const dataList = localCopy.map((a) => {
    let manager
    if (a.manager) {
      manager = a.manager.fullName
    }

    const returnData = {
      jobTitle: a.jobTitle,
      startDate: new Date(a.startDate).toLocaleString('en-gb', { day: '2-digit', month: 'short', year: 'numeric' }),
      endDate: a.endDate
        ? new Date(a.endDate).toLocaleString('en-gb', { day: '2-digit', month: 'short', year: 'numeric' })
        : 'Present',
      jobLevel: a.jobLevel,
      salary: a.annualSalary,
      department: a.department,
      manager,
    }
    return returnData
  })

  const headers = [
    t(TABLE.EMPLOYEE_WORKHISTORY_HEADER.JOB_TITLE),
    t(TABLE.EMPLOYEE_WORKHISTORY_HEADER.JOB_LEVEL),
    t(TABLE.EMPLOYEE_WORKHISTORY_HEADER.DEPARTMENT),
    t(TABLE.EMPLOYEE_WORKHISTORY_HEADER.MANAGER),
    t(TABLE.EMPLOYEE_WORKHISTORY_HEADER.SALARY),
    t(TABLE.EMPLOYEE_WORKHISTORY_HEADER.START_DATE),
    t(TABLE.EMPLOYEE_WORKHISTORY_HEADER.END_DATE),
    !hideEdit && 'dropdown', // Conditionally add 'dropdown'
  ].filter(Boolean)

  const WorkHistoryTableConfig = {
    headers,
    dataList,
    config: {
      dark: false,
      headers: {
        textColor: '',
        textSize: 'text-sm',
        textCase: 'capitalize',
        textWeight: 'font-normal',
      },
      dataList: {
        items: localCopy,
        textSize: 'text-sm',
        dropdown: (user) =>
          !hideEdit ? (
            <Dropdown
              secondary
              items={[
                {
                  id: nanoid(6),
                  name: 'Edit',
                  onClick: () => {
                    setSelectedJob(user)
                    setShowWorkHistory(true)
                  },
                },
                {
                  id: nanoid(6),
                  name: 'Delete',
                  className: 'text-red-500',
                  onClick: () => {
                    onDelete(user.id)
                  },
                },
              ]}
              showDots
            />
          ) : (
            ''
          ),
        onItemClick: (item: any) => {},
      },
    },
  }

  return (
    <>
      <Table {...WorkHistoryTableConfig} ancestor="admin-employees" />
    </>
  )
}

export default WorkHistoryList
