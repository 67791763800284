/* eslint-disable @typescript-eslint/no-unused-vars */

import { useEffect, useState } from 'react'
import Button from 'components/atoms/Button/button'
import { Selector2, Selector3 } from 'components/atoms/formcompomnents/Selector'
import DatePickerInput from 'components/atoms/formcompomnents/DatePickerinput'
import NestedDropdown from 'components/atoms/Dropdown/NestedDropdown'
import { MultiSelctor } from 'components/atoms/formcompomnents/MultipleSelector'
import { measureList, priorityList } from 'pages/Goal/GoalCreate/DefaultList'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import { HttpRequest } from 'helpers/services'
import { capitalize, isEmpty, upperCase } from 'lodash'
import { useParams } from 'react-router-dom'
import { FaInfoCircle, FaLock } from 'react-icons/fa'
import { BsGlobe } from 'react-icons/bs'
import { XIcon } from '@heroicons/react/solid'
import { AiOutlineCloseCircle } from 'react-icons/ai'
import { useDispatch } from 'react-redux'
import { setErrorMessages } from 'state/redux/actions/settings.actions'
import SearchBar2 from 'components/atoms/SearchBar/SearchBar2'
import { AssignDropdown } from 'components/templates/Meeting/OneOne/Dialogs/AssignDropdown'
import { Toggle2 } from 'components/atoms/Toggle'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
const EditGoal = ({
  setEditGoal,
  selectedGoal,
  setRefetch,
  setViewModal,
  setSelectedGoal,
}: {
  editGoal: boolean
  setEditGoal: any
  selectedGoal: any
  setRefetch?: any
  setViewModal?: any
  setSelectedGoal?: any
}) => {
  const { companyId }: any = useParams()
  const dispatch: any = useDispatch()
  const [parentGoalsList, setParentGoalsList] = useState([])
  const [parentGoal, setParentGoal] = useState<any>([])
  const [initialized, setInitialized] = useState(false)
  const [goal, setGoal] = useState({
    title: selectedGoal?.name,
    description: selectedGoal?.description === '' ? '' : selectedGoal?.description,
    start: selectedGoal?.progressStartValue === null ? null : selectedGoal?.progressStartValue,
    target: selectedGoal?.progressTargetValue === null ? null : selectedGoal?.progressTargetValue,
    assessmentCycle: selectedGoal?.reviewCycle,
  })
  const [formError, setFormError]: any = useState({})
  const [dueDate, setDueDate] = useState(new Date(selectedGoal?.dueDate))
  const [saveLoading, setSaveLoading] = useState(false)

  const [departmentList, setDepartmentList] = useState([])
  const [teamList, setTeamList] = useState([])
  const [goalList, setGoalList] = useState([])
  const [ownerList, setOwnerList] = useState([])
  const [assessmentCycleList, setAssessmentCycleList] = useState([])

  const [supporter, setSupporter] = useState(null)
  const [supporters, setSupporters] = useState([])
  const [supportersList, setSupportersList] = useState([])

  const [visibleDepartmentList, setVisibleDepartmentList] = useState(selectedGoal?.departmentVisibility)
  const [visibleDepartment, setVisibleDepartment] = useState()

  const [visibleTeamList, setVisibleTeamList] = useState(selectedGoal?.teamVisibility)
  const [visibleTeam, setVisibleTeam] = useState()

  const [tags, setTags] = useState(selectedGoal?.goalTag)
  const [tagList, setTagList] = useState([])

  const [parentSearchInput, setParentSearchInput] = useState('')
  const [parentSuggestionFetching, setParentSuggestionFetching] = useState(false)
  const [showParentSuggestions, setShowParentSuggestions] = useState(false)

  const [selectedCycle, setSelectedCycle]: any = useState()
  const [currentCycle, setCurrentCycle]: any = useState()
  const [cycleSearchInput, setCycleSearchInput] = useState('')
  const [cycleSearchInputLoader, setCycleSearchInputLoader] = useState(false)
  const [addUpProgress, setAddUpProgress] = useState(selectedGoal?.rollupChildCounts)

  const fetchDepartmentList = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/company/department', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setInitialized(true)
          const departments = data?.data?.departments
          const tempList = departments.map((d) => ({
            name: d?.name,
            id: d?.id,
          }))
          setDepartmentList(tempList)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
      })
  }

  const fetchTeamList = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-my-team': 'false',
    }
    HttpRequest('GET', '/team/get-all', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setInitialized(true)
          const teams = data?.data?.team
          const tempList = teams.map((d) => ({
            name: d?.name,
            id: d?.id,
          }))
          setTeamList(tempList)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
      })
  }

  const fetchGoalCycleList = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      goalCreation: true,
    }
    HttpRequest('GET', '/goal/cycle', {}, commonHeader)
      .then(({ data }: any) => {
        setInitialized(true)
        if (data.code === 200) {
          const goals = data?.data?.goalCycle
          if (goals) {
            const tempList = goals.map((g) => ({
              name: g?.name,
              id: g?.id,
            }))
            setGoalList(tempList)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
      })
  }

  const fetchAssessmentCycleList = () => {
    setCycleSearchInputLoader(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    if (cycleSearchInput) {
      commonHeader['search'] = cycleSearchInput
    }
    HttpRequest('GET', '/review/cycle/list', {}, commonHeader)
      .then(({ data }: any) => {
        setInitialized(true)
        if (data.code === 200) {
          const assessments = data?.data?.reviewCycle
          assessments?.map((assessment) => {
            if (assessment?.iscurrent && !selectedCycle) {
              setCurrentCycle(assessment)
            }
          })
          setAssessmentCycleList(assessments)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
        setCycleSearchInputLoader(false)
      })
  }

  const fetchOwnerList = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/company/employees/list', {}, commonHeader)
      .then(({ data }: any) => {
        setInitialized(true)
        if (data.code === 200) {
          const employees = data?.data
          if (employees) {
            setSupportersList(employees)
            setOwnerList(employees)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
      })
  }

  const fetchTagList = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/goal/tags', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setTagList(data.data.sort((a, b) => a?.id - b?.id))
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    if (!initialized) {
      fetchDepartmentList()
      fetchTeamList()
      fetchGoalCycleList()
      fetchAssessmentCycleList()
      fetchOwnerList()
      fetchTagList()
    }
  }, [])

  useEffect(() => {
    fetchAssessmentCycleList()
  }, [cycleSearchInput])

  const typeList = [
    {
      id: 'a',
      name: 'Individual',
    },
    {
      id: 'b',
      name: 'Department',
      children: departmentList,
    },
    {
      id: 'd',
      name: 'Team',
      children: teamList,
    },
    {
      id: 'c',
      name: 'Company',
    },
  ]

  let tempType = {
    item: {},
    subItem: {},
  }
  const subItemDResult = typeList[1]?.children?.find((i) => i?.id === selectedGoal?.department?.id)
  const subItemTResult = typeList[1]?.children?.find((i) => i?.id === selectedGoal?.team?.id)

  typeList.forEach((item) => {
    if (item?.id === 'b' && capitalize(selectedGoal?.type) === 'Department') {
      tempType = {
        item: item,
        subItem: subItemDResult,
      }
    } else if (item?.id === 'd' && capitalize(selectedGoal?.type) === 'Team') {
      tempType = {
        item: item,
        subItem: subItemTResult,
      }
    } else if (item?.id === 'a' && capitalize(selectedGoal?.type) === 'Individual') {
      tempType = { item: item, subItem: {} }
    } else if (item?.id === 'c' && capitalize(selectedGoal?.type) === 'Company') {
      tempType = { item: item, subItem: {} }
    }
  })

  const [type, setType]: any = useState(null)

  useEffect(() => {
    if (subItemDResult !== undefined && (type === null || type?.subItem === undefined)) {
      setType(tempType)
    } else if (subItemDResult === undefined && type === null) {
      setType(tempType)
    }

    if (subItemTResult !== undefined && (type === null || type?.subItem === undefined)) {
      setType(tempType)
    } else if (subItemTResult === undefined && type === null) {
      setType(tempType)
    }
  }, [])

  let visibilityList = [
    {
      id: 'a',
      name: 'Public',
      icon: <BsGlobe />,
    },
    {
      id: 'b',
      name: 'Private',
      icon: <FaLock />,
    },
    {
      id: 'c',
      name: 'Private to selected department',
      icon: <FaLock />,
    },
    {
      id: 'd',
      name: 'Private to selected team',
      icon: <FaLock />,
    },
  ]

  if (!isEmpty(type?.subItem) && type?.item?.name === 'Department') {
    visibilityList = [
      {
        id: 'a',
        name: 'Public',
        icon: <BsGlobe />,
      },
      {
        id: 'c',
        name: 'Private to selected department',
        icon: <FaLock />,
      },
    ]
  }

  if (!isEmpty(type?.subItem) && type?.item?.name === 'Team') {
    visibilityList = [
      {
        id: 'a',
        name: 'Public',
        icon: <BsGlobe />,
      },
      {
        id: 'd',
        name: 'Private to selected Team',
        icon: <FaLock />,
      },
    ]
  }

  const tempOwners = selectedGoal.owner.map((o) => ({
    fullName: o?.owner?.fullName,
    id: o?.owner?.id,
    email: o?.owner?.email,
    jobTitle: o?.owner?.jobTitle,
  }))

  const [owner, setOwner] = useState(null)
  const [owners, setOwners] = useState([])

  useEffect(() => {
    if (owners.length === 0 && !isEmpty(tempOwners)) {
      setOwners(tempOwners)
    }
  }, [initialized])

  const goalCycleList = goalList

  let tempMeasure = {}
  if (selectedGoal?.progressType === 'BINARY') {
    tempMeasure = measureList.find((item) => item?.name === 'Complete/Incomplete')
  } else {
    tempMeasure = measureList.find((item) => item?.name === capitalize(selectedGoal?.progressType))
  }
  const tempGoalCycle = goalCycleList?.find((item) => item?.id === selectedGoal?.goalCycle?.id)
  const tempAssessmentCycle = goalCycleList?.find((item) => item?.id === selectedGoal?.reviewCycle?.id)

  const [measure, setMeasure]: any = useState(tempMeasure)
  const [goalCycle, setGoalCycle] = useState(null)
  const [assessmentCycle, setAssessmentCycle] = useState(null)

  useEffect(() => {
    if (goalCycle === null && !isEmpty(tempGoalCycle)) {
      setGoalCycle(tempGoalCycle)
    }
    if (assessmentCycle === null && !isEmpty(tempAssessmentCycle)) {
      setAssessmentCycle(tempAssessmentCycle)
    }
  }, [])

  const tempVisibility = visibilityList.find((item) => {
    if (item?.name === capitalize(selectedGoal?.visibility)) {
      return item
    } else if (item?.id === 'c' && capitalize(selectedGoal?.visibility).includes('department')) {
      return item
    } else if (item?.id === 'd' && capitalize(selectedGoal?.visibility).includes('team')) {
      return item
    }
  })

  const [visibility, setVisibility] = useState(tempVisibility)

  const [priority, setPriority] = useState(capitalize(selectedGoal?.priority))

  const onMeasureSelect = (item) => {
    setMeasure(item)
  }

  const onPrioritySelect = (item) => {
    setPriority(item?.name)
  }

  const onTagSelect = (item) => {
    setTags([...tags, item])
    const temp = tagList.filter((tag) => tag?.id !== item?.id)
    setTagList(temp)
  }

  const onTagChipCancel = (item) => {
    const newTags = tags.filter((tag) => tag?.id !== item?.id)
    setTags(newTags)
    const temp = [...tagList, item].sort((a, b) => a?.id - b?.id)
    setTagList(temp)
  }

  const onVisibilitySelect = (item) => {
    setVisibility(item)
  }

  const onOwnerSelect = (item) => {
    setOwner(item?.name)
    setOwners((prev) => {
      const isExist = prev.filter((o) => o?.id === item?.id)
      if (!isEmpty(isExist)) return [...prev]
      return [item]
    })
  }

  const onVisibleDepartmentSelect = (item) => {
    setVisibleDepartment(item?.name)
    setVisibleDepartmentList([...visibleDepartmentList, item])
  }

  const onVisibleTeamSelect = (item) => {
    setVisibleTeam(item?.name)
    setVisibleTeamList([...visibleTeamList, item])
  }

  const onOwnerChipCancel = (item) => {
    const newOwner = owners.filter((owner) => owner?.id !== item?.id)
    setOwners(newOwner)
  }

  const onDepartmentChipCancel = (item) => {
    const newList = visibleDepartmentList.filter((d) => d?.id !== item?.id)
    setVisibleDepartmentList(newList)
  }

  const onTeamChipCancel = (item) => {
    const newList = visibleTeamList.filter((d) => d?.id !== item?.id)
    setVisibleTeamList(newList)
  }

  const onGoalCycleSelect = (item) => {
    setGoalCycle(item)
  }

  const onAssessmentCycleSelect = (item) => {
    setAssessmentCycle(item)
  }

  const validateForm = () => {
    let isValid = true
    let messages = {
      title: '',
      owner: '',
      goalCycle: '',
      type: '',
      description: '',
      start: null,
      target: null,
      visibility: '',
      dueDate: '',
    }
    if (goal.title === '') {
      messages.title = `Title can’t be blank`
      isValid = false
    }
    if (owners.length === 0) {
      messages.owner = `At least one owner is required`
      isValid = false
    }
    if (isEmpty(type)) {
      messages.type = `Type is required`
      isValid = false
    }
    if ((goal.start === null || goal.start === '') && (measure?.id === 'b' || measure?.id === 'c')) {
      messages.start = `Numeric key results require a starting amount`
      isValid = false
    }
    if ((goal.target === null || goal.target === '') && (measure?.id === 'b' || measure?.id === 'c')) {
      messages.target = `Numeric key results require a target amount`
      isValid = false
    }
    if (visibility?.id === 'c' && visibleDepartmentList.length === 0) {
      messages.visibility = `Please select department`
      isValid = false
    }

    if (visibility?.id === 'd' && visibleTeamList.length === 0) {
      messages.visibility = `Please select team`
      isValid = false
    }
    setFormError({
      ...formError,
      ...messages,
    })
    return isValid
  }

  const onSave = () => {
    if (validateForm()) {
      let ownersId = []

      if (owners.length > 0) {
        owners.forEach((item) => {
          ownersId.push(item?.id)
        })
      }

      let supportersId = []

      if (supporters.length > 0) {
        supporters.forEach((item) => {
          supportersId.push(item?.id)
        })
      }

      let data = {
        name: goal.title,
        description: goal.description,
        progressType: measure?.name === 'Complete/Incomplete' ? 'BINARY' : upperCase(measure?.name),
        goalCycleId: goalCycle?.id,
        reviewCycleId: selectedCycle
          ? selectedCycle?.id
          : goal?.assessmentCycle?.id !== '' && goal?.assessmentCycle !== null
          ? goal?.assessmentCycle?.id
          : currentCycle?.id,
        dueDate: dueDate !== null ? dueDate.toISOString().split('T')[0] : null,
        owners: ownersId,
        supporters: supportersId,
        type: upperCase(type?.item?.name),
        visibility: upperCase(visibility?.name).split(' ').join('_'),
        priority: priority,
        parent: parentGoal ? parentGoal[0]?.id : null,
        rollupChildCounts: addUpProgress,
      }

      if (goal.start > -1 && goal.start !== null) {
        data['progressStartValue'] = goal.start
      }

      if (goal.target) {
        data['progressTargetValue'] = goal.target
      }

      if (type?.item?.name === 'Department') {
        data['departmentId'] = type?.subItem?.id
      }

      if (visibility?.id === 'c') {
        const temp = visibleDepartmentList.map((item) => item?.id)
        data['departmentVisibility'] = temp
      }

      if (type?.item?.name === 'Team') {
        data['teamId'] = type?.subItem?.id
      }

      if (visibility?.id === 'd') {
        const temp = visibleTeamList.map((item) => item?.id)
        data['teamVisibility'] = temp
      }

      if (tags.length > 0) {
        const temp = tags.map((item) => item?.id)
        data['tags'] = temp
      }

      setSaveLoading(true)
      HttpRequest('PUT', `/goal/${selectedGoal?.id}`, data, { 'x-appengine-company-id': companyId })
        .then((res: any) => {
          if (res?.data?.code === 200) {
            setRefetch(true)
            setEditGoal(false)
            setViewModal(false)
            setSaveLoading(false)
            setSelectedGoal({})
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          console.error(err)
          setSaveLoading(false)
        })
    }
  }

  const fetchGoals = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-size': 0,
      'x-appengine-skip': true,
    }

    if (parentSearchInput) {
      commonHeader['search'] = parentSearchInput
    }

    HttpRequest('POST', '/goal/list', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          const goals = data?.data?.goals
          if (goals) {
            const filteredGoals = goals.filter((goal) => goal?.id !== selectedGoal?.id)
            setParentGoalsList(filteredGoals)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
        setTimeout(() => {}, 1000)
      })
  }

  useEffect(() => {
    fetchGoals()
  }, [parentSearchInput])

  useEffect(() => {
    setParentGoal(selectedGoal?.parent?.length !== 0 ? [selectedGoal?.parent] : null)
    setGoalCycle(selectedGoal?.goalCycle ? selectedGoal?.goalCycle : {})
    setAssessmentCycle(selectedGoal?.reviewCycle ? selectedGoal?.reviewCycle : {})
    setSupporters((prev) => {
      let supp = []
      selectedGoal?.supporters?.forEach((item) => {
        supp.push(item.supporter)
      })
      return selectedGoal?.supporters ? supp : []
    })
    setType(
      selectedGoal?.type
        ? {
            item: { id: selectedGoal?.type === 'INDIVIDUAL' ? 'a' : 'd', name: capitalize(selectedGoal?.type) },
            subItem: selectedGoal?.department
              ? { id: selectedGoal?.department?.id, name: selectedGoal?.department?.name }
              : selectedGoal?.team
              ? { id: selectedGoal?.team?.id, name: selectedGoal?.team?.name }
              : {},
          }
        : {}
    )
  }, [selectedGoal])

  const onParentChipCancel = (item: any) => {
    const newParent = parentGoal.filter((parent) => parent?.id !== item?.id)
    setParentGoal(newParent)
  }

  const removeParentSearchAction = () => {
    setParentSearchInput('')
  }

  useEffect(() => {
    ownerList.map((emp) => {
      return owners.filter((o) => o.fullName !== emp.fullName)
    })
  }, [ownerList])

  const onSupportersChipCancel = (item) => {
    const newSupporter = supporters.filter((supporter) => supporter?.id !== item?.id)
    setSupporters(newSupporter)
  }

  const onSupportersSelect = (item) => {
    setSupporter(item?.name)
    setSupporters((prev) => {
      const isExist = prev.filter((o) => o?.id === item?.id)
      if (!isEmpty(isExist)) return [...prev]
      return [...prev, item]
    })
  }

  return (
    <>
      <div className="overflow-y-scroll flex space-y-4 flex-col flex-1 p-6 justify-between">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">Edit goal</h2>
          <XIcon
            className="h-5 w-5 text-themeGray cursor-pointer"
            aria-hidden="true"
            onClick={() => setEditGoal(false)}
          />
        </div>
        <NormalFormInput
          value={goal.title}
          error={formError.title}
          placeholder="Enter your goal here..."
          label="Title"
          onChange={(e) => setGoal({ ...goal, title: e.target.value })}
        />
        <NormalFormInput
          value={goal.description}
          error={formError.description}
          placeholder=""
          textarea
          label="Description"
          optional
          onChange={(e) => setGoal({ ...goal, description: e.target.value })}
        />
        <MultiSelctor
          width=""
          label="Owner"
          selectedItem={owner}
          list={ownerList}
          placeholder="Owner"
          onSelect={onOwnerSelect}
          spanClass="py-0.5"
          error={formError.owner}
          chipList={owners}
          onChipCancel={onOwnerChipCancel}
          withAvatar
          zIndex={5002}
        />
        <Selector2
          width=""
          label="How will you measure progress?"
          selectedItem={measure}
          list={measureList}
          placeholder="Select"
          onSelect={onMeasureSelect}
          spanClass="py-0.5"
        />
        {measure?.name !== 'Complete/Incomplete' && (
          <div className="flex space-x-4">
            <NormalFormInput
              label="Start"
              id="start"
              name="start"
              type="number"
              value={goal.start}
              onChange={(e) => setGoal({ ...goal, start: e.target.value })}
              fullWidth
              error={formError.start}
            />
            <NormalFormInput
              label="Target"
              id="target"
              name="target"
              type="number"
              className="placeholder-themeGray"
              value={goal.target}
              onChange={(e) => setGoal({ ...goal, target: e.target.value })}
              fullWidth
              error={formError.target}
            />
          </div>
        )}
        {selectedGoal?.progressType === 'NUMBER' && (
          <div className="flex items-center gap-2 py-4">
            <Toggle2
              text=""
              enabled={addUpProgress}
              setEnabled={() => {
                setAddUpProgress(!addUpProgress)
              }}
            />
            <span className="text-sm">Add up progress from sub-goals?</span>
            <Tooltip
              text="If you turn this toggle on, the sub-goals progress will be added to the main goal"
              position="top"
            >
              <FaInfoCircle size={14} />
            </Tooltip>
          </div>
        )}
        <DatePickerInput
          minDate={new Date(selectedGoal?.createdAt)}
          setDate={setDueDate}
          date={dueDate}
          label="Due Date"
          zIndex={100}
        />
        <div className="flex flex-col">
          <span className="text-sm font-semibold flex gap-x-2 my-2">Assessment Cycle</span>
          <div className="flex gap-2 items-center">
            <div className="border border-gray-400 rounded-md w-full">
              <AssignDropdown
                header={
                  <p className="text-sm font-normal pt-2">
                    {selectedCycle
                      ? selectedCycle?.name
                      : goal?.assessmentCycle?.name !== '' && goal?.assessmentCycle !== null
                      ? goal?.assessmentCycle?.name
                      : currentCycle?.name}
                  </p>
                }
                ownerSearchInput={cycleSearchInput}
                setOwnerSearchInput={setCycleSearchInput}
                ownerSuggestedList={assessmentCycleList.map((cycle) => ({
                  name: cycle.name,
                  id: cycle.id,
                  isCurrent: cycle.iscurrent,
                  onClick: () => {
                    setCycleSearchInput('')
                    setSelectedCycle(cycle)
                  },
                }))}
                suggestionFetching={cycleSearchInputLoader}
                type="assessment-cycle-select"
              />
            </div>
            {(!isEmpty(selectedCycle) || !isEmpty(goal?.assessmentCycle) || !isEmpty(currentCycle)) && (
              <AiOutlineCloseCircle
                size={20}
                className="transition-all cursor-pointer text-gray-700"
                onClick={() => {
                  setSelectedCycle({})
                  setCurrentCycle({})
                  goal['assessmentCycle'] = null
                }}
              />
            )}
          </div>
        </div>
        <NestedDropdown
          items={typeList}
          label="Type"
          placeholder="Select"
          type={type}
          setType={setType}
          error={formError.type}
        />
        <div className="relative flex items-center gap-2 w-full">
          <div className="w-full">
            <SearchBar2
              onFocus={() => setShowParentSuggestions(true)}
              onBlur={() => setShowParentSuggestions(false)}
              onCancel={removeParentSearchAction}
              onChange={setParentSearchInput}
              value={parentSearchInput}
              placeholder="Select a parent goal..."
              withChip
              onChipCancel={onParentChipCancel}
              chipItem={parentGoal.length !== 0 ? parentGoal : null}
              label="Add a Parent Goal"
              noBadge
            />
            {showParentSuggestions && (
              <div
                className="bg-white absolute mt-1 cursor-pointer overflow-auto shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5"
                style={{ zIndex: 1000, maxWidth: '100%', minWidth: '366px', top: '110%' }}
              >
                {parentGoalsList.map((item) => (
                  <div
                    className="flex items-center bg-white hover:bg-themeBlue2 max-h-32"
                    onMouseDown={() => {
                      setParentGoal([item])
                      setParentSearchInput('')
                    }}
                    onMouseUp={() => setShowParentSuggestions(false)}
                  >
                    <div className="px-4 py-2">
                      <p className="text-sm">{item?.name}</p>
                    </div>
                  </div>
                ))}
                {!parentSuggestionFetching && parentSearchInput.length > 0 && parentGoalsList.length === 0 && (
                  <p className="p-4 text-sm text-gray-900">No Parent Goals found</p>
                )}
              </div>
            )}
          </div>
          <AiOutlineCloseCircle
            size={20}
            className={`${parentGoal ? 'cursor-pointer text-gray-700' : 'cursor-not-allowed text-gray-400'} mt-10`}
            onClick={() => setParentGoal([])}
          />
        </div>

        <MultiSelctor
          width=""
          label="Supporters"
          selectedItem={supporter}
          list={supportersList}
          placeholder="Supporters"
          onSelect={onSupportersSelect}
          spanClass="py-0.5"
          error={formError.supporters}
          chipList={supporters}
          onChipCancel={onSupportersChipCancel}
          withAvatar
        />

        {visibility?.id === 'c' && (
          <>
            <MultiSelctor
              width=""
              selectedItem={visibleDepartment}
              list={departmentList}
              placeholder="Select Department"
              onSelect={onVisibleDepartmentSelect}
              spanClass="py-0.5"
              chipList={visibleDepartmentList}
              onChipCancel={onDepartmentChipCancel}
            />
            <span className="text-xs text-themeRed">{formError.visibility}</span>
          </>
        )}
        {visibility?.id === 'd' && (
          <>
            <MultiSelctor
              width=""
              selectedItem={visibleTeam}
              list={teamList}
              placeholder="Select Team"
              onSelect={onVisibleTeamSelect}
              spanClass="py-0.5"
              chipList={visibleTeamList}
              onChipCancel={onTeamChipCancel}
            />
            <span className="text-xs text-themeRed">{formError.visibility}</span>
          </>
        )}
        <Selector3
          width=""
          label="Priority"
          selectedItem={priority}
          list={priorityList}
          placeholder="Select"
          onSelect={onPrioritySelect}
          spanClass="py-0.5"
          optional
        />
        <Selector2
          width=""
          label="Visibility"
          selectedItem={visibility}
          list={visibilityList}
          placeholder="Select"
          onSelect={onVisibilitySelect}
          spanClass="py-0.5"
        />
        {/* <MultiSelctor
          width=""
          label="Tags"
          list={tagList}
          placeholder="Select.."
          onSelect={onTagSelect}
          optional
          spanClass="py-0.5"
          chipList={tags}
          onChipCancel={onTagChipCancel}
        /> */}
      </div>

      <div className="space-y-4 p-5 border-t border-themeGray2">
        <div className="flex space-x-2 ">
          <Button size="md" hoverColor="bg-themeBlue" label={'Save'} primary onClick={onSave} loading={saveLoading} />
          <Button
            textColor="text-black"
            size="md"
            hoverColor="bg-themeRed"
            label={'Cancel'}
            secondary
            onClick={() => setEditGoal(false)}
          />
        </div>
      </div>
    </>
  )
}

export default EditGoal
