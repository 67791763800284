import { avatarInitials } from 'lib/utils'
import { classNames } from 'lib/utils/classNames'

const Avatar = ({
  name,
  withName,
  image,
  hw = '10',
  title,
  nameClass = '',
  className,
  small = false,
  medium = false,
  mediumLarge = false,
  large = false,
  rectangle = false,
  extralarge = false,
  xxl = false,
  rounded = false,
  outline = false,
  noTitleMargin = false,
}: {
  hw?: string
  withName?: boolean
  name: string
  title?: string
  image?: string | null
  nameClass?: string
  className?: string
  small?: boolean
  medium?: boolean
  mediumLarge?: boolean
  large?: boolean
  extralarge?: boolean
  xxl?: boolean
  rectangle?: boolean
  rounded?: boolean
  outline?: boolean
  noTitleMargin?: boolean
}) => {
  let height
  let width
  let h
  let w
  if (small) {
    height = `h-6`
    width = `w-6`
  } else if (medium) {
    height = `h-8`
    width = `w-8`
  } else if (mediumLarge) {
    height = `h-13`
    width = `w-13`
  } else if (large) {
    height = `h-36`
    width = `w-36`
  } else if (extralarge) {
    height = `h-40`
    width = `w-40`
  } else if (xxl) {
    height = `h-48`
    width = `w-48`
  } else {
    height = `h-10`
    width = `w-10`
  }
  return (
    <div className={`flex items-center ${className}`}>
      <div className={`select-none flex-shrink-0  ${height} ${width}`} style={{ height: h, width: w }}>
        {image ? (
          <div className={outline ? `bg-blue-500 p-0.5 w-full h-full rounded-full` : ''}>
            <img
              className={`${outline ? 'border-2 border-white' : ''} h-full w-full ${
                !rectangle ? 'rounded-full object-cover object-top' : 'rounded-lg'
              }`}
              src={image}
              alt=""
            />
          </div>
        ) : (
          <div className={outline ? `bg-blue-500 p-0.5 w-full h-full rounded-full relative` : 'relative w-full h-full'}>
            <div
              style={{ backgroundColor: avatarInitials(name || '').color }}
              className={`${small && 'text-xs'} ${mediumLarge && 'text-2xl'} ${medium && 'text-sm'} 
            ${large && 'text-6xl'} ${extralarge && 'text-5xl'} ${xxl && 'text-8xl'} text-white h-full w-full ${
                !rectangle ? 'rounded-full' : 'rounded-lg'
              } ${outline && 'border-2 border-white'} flex items-center  justify-center py-2`}
            >
              {avatarInitials(name || '').initials}
            </div>
          </div>
        )}
      </div>
      {withName && (
        <div className="ml-4">
          <div className={classNames(nameClass ? nameClass : 'text-sm', 'font-medium text-black')}>{name}</div>
          {title && (
            <div className={`${!noTitleMargin && ''} text-xs font-medium font-roboto text-gray-600`}>{title}</div>
          )}
        </div>
      )}
    </div>
  )
}

export default Avatar
