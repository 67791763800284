import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import Avatar from 'components/atoms/Avatar'
import { PLACEHOLDERS } from 'constants/common'
import { useTranslation } from 'react-i18next'
import { AiOutlineSearch } from 'react-icons/ai'
import { CgClose } from 'react-icons/cg'

const SearchBar = ({
  onChange,
  value,
  onCancel,
  withChip,
  onChipCancel,
  chipItem,
  placeholder,
  label,
  required,
  inputClass,
  Icon = false,
  autoCompleteOff,
  disabled = false,
  className = '',
  marginTopForChip = '',
  noAvatar,
  ancestor,
}: {
  value?: string
  onCancel?: () => void
  onChipCancel?: (value?: any) => any
  onChange?: (e: any) => void
  placeholder?: string
  Icon?: boolean
  label?: string
  required?: string
  inputClass?: string
  withChip?: boolean
  chipItem?: any
  autoCompleteOff?: boolean
  disabled?: boolean
  className?: string
  noAvatar?: boolean
  ancestor?: string
  marginTopForChip?: string
}) => {
  const { t } = useTranslation()
  const onTempChipCancel = () => {
    onChipCancel()
    chipItem = undefined
  }
  const renderPlaceholder = () => {
    if (chipItem) {
      return ''
    } else {
      return placeholder ? placeholder : t(PLACEHOLDERS.SEARCH_PLACEHOLDER)
    }
  }

  return (
    <>
      {label && (
        <div className="flex gap-x-2 my-2">
          <label className="block dark:text-white text-sm font-semibold">
            {label} {required && <span className="text-red-500 dark:text-red-400 font-medium text-base">*</span>}
          </label>
        </div>
      )}
      <div className={`${className} w-full relative flex `}>
        {Icon && (
          <div className="absolute inset-y-0 left-0 pl-5 flex items-center pointer-events-none ">
            <AiOutlineSearch className="h-5 w-5 text-gray-500 " aria-hidden="true" />
          </div>
        )}
        <input
          type="text"
          name="search"
          autoComplete="off"
          value={value ? value : ''}
          id="search"
          disabled={disabled}
          onChange={(e: any) => onChange(e.target.value)}
          className={`${Icon ? 'pl-12' : ''} ${
            disabled && 'cursor-not-allowed bg-gray-100'
          } ${inputClass} focus:outline-none transition-all  px-6 py-3 focus:border-blue-500 block w-full sm:text-sm border border-gray-400 rounded-lg`}
          placeholder={renderPlaceholder()}
        />
        {withChip &&
          chipItem !== undefined &&
          chipItem !== null &&
          chipItem !== '' &&
          (ancestor === 'department' ? (
            chipItem?.map((item) => (
              <span className="absolute top-1 left-2 px-1.5 py-1 rounded-full bg-blue-100 border border-blue-300 text-gray-700 text-sm flex items-center align-center w-max">
                <span className="flex items-center gap-2 text-black mr-2">
                  {!noAvatar && <Avatar name={item?.name} nameClass="font-semibold" small />}
                  <p className="text-xs text-gray-700 font-semibold">{item?.name}</p>
                </span>
                {!disabled && (
                  <CgClose
                    className="transition-all hover:text-blue-800 h-5 w-5 ml-1 font-extrabold cursor-pointer"
                    onClick={() => onTempChipCancel}
                  />
                )}
              </span>
            ))
          ) : (
            <span
              className={`absolute top-1 ${marginTopForChip} left-2 px-1.5 py-1 rounded-full bg-blue-100 border border-blue-300 text-gray-700 text-sm flex items-center align-center w-max`}
            >
              <span className="flex items-center gap-2 text-black mr-2">
                {!noAvatar && <Avatar name={chipItem?.fullName} nameClass="font-semibold" small />}
                <p className="text-xs text-gray-700 font-semibold">{chipItem?.fullName}</p>
              </span>
              {!disabled && (
                <CgClose
                  className="transition-all hover:text-blue-800 h-5 w-5 ml-1 font-extrabold cursor-pointer"
                  onClick={() => {
                    onTempChipCancel()
                  }}
                />
              )}
            </span>
          ))}
        <Transition
          show={value.length > 0}
          enter="transition origin-right transform ease-out duration-150"
          enterFrom=" opacity-90 scale-0"
          enterTo=" opacity-100 scale-100"
          leave="transition transform ease-in duration-150"
          leaveFrom="opacity-100 scale-100"
          leaveTo=" opacity-90 scale-0"
        >
          <div className="absolute  inset-y-0 right-0 ml-auto p-4 pr-5">
            <div className="-mx-1.5 -my-1.5">
              <button
                onClick={onCancel}
                type="button"
                className="inline-flex bg-secondary rounded-md p-0.5 text-themeBlue hover:text-themeGreen focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-green-50 focus:ring-themeBlue"
              >
                <span className="sr-only">Dismiss</span>
                <XIcon className="h-5 w-5" aria-hidden="true" />
              </button>
            </div>
          </div>
        </Transition>
      </div>
    </>
  )
}

export default SearchBar
