import { AiOutlineCheck } from 'react-icons/ai'
import { RiHashtag, RiPercentLine } from 'react-icons/ri'

export const priorityList = [
  {
    id: 'c1',
    name: 'P1',
  },
  {
    id: 'c2',
    name: 'P2',
  },
  {
    id: 'c3',
    name: 'P3',
  },
  {
    id: 'c4',
    name: 'P4',
  },
  {
    id: 'c5',
    name: 'P5',
  },
  {
    id: 'c6',
    name: 'P6',
  },
  {
    id: 'c7',
    name: 'P7',
  },
  {
    id: 'c8',
    name: 'P8',
  },
  {
    id: 'c9',
    name: 'P9',
  },
  {
    id: 'c10',
    name: 'P10',
  },
]

export const status = [
  {
    id: 'd1',
    name: 'Any',
    title: 'ACTIVE',
    isActive: true,
  },
  {
    id: 'd2',
    name: 'On track',
  },
  {
    id: 'd3',
    name: 'Processing',
  },
  {
    id: 'd4',
    name: 'Off track',
  },
  {
    id: 'd5',
    break: true,
    name: 'No update',
  },
  {
    id: 'd6',
    title: 'ENDED',
    name: 'Completed',
  },
  {
    id: 'd7',
    name: 'Incomplete',
    break: true,
  },
  {
    id: 'd8',
    title: 'DRAFT',
    name: 'Draft',
  },
]

export const measureList = [
  {
    id: 'c',
    name: 'Percent',
    icon: <RiPercentLine />,
  },
  {
    id: 'b',
    name: 'Number',
    icon: <RiHashtag />,
  },
  {
    id: 'a',
    name: 'Complete/Incomplete',
    icon: <AiOutlineCheck />,
  },
]
