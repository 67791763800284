import Button from 'components/atoms/Button/button'
import FeedbackEditor from 'components/atoms/Editor/FeedbackEditor'
import moment from 'moment'
import Explore from 'components/templates/Review/ReviewGoals'
import Rating from 'components/templates/Review/Rating/Rating'
import { AssessmentBox, ValuesLoader } from 'components/templates/Review/ReviewSegment'
import RequestedFeedbackList from 'components/templates/Review/List/RequestedFeedbacksList'
import { ReviewFeedbackCard } from 'components/templates/Review/Card/ReviewFeedbackCard'
import { isEmpty, get } from 'lodash'
import { useEffect, useState } from 'react'
import SharedNotesAccordion from 'components/atoms/SharedNotesAccordion'
import useIsMobile from 'hooks/useIsMobile'
import ConditionalWrapper from 'pages/Common/ConditionalWrapper'
import { useSelector } from 'react-redux'

export const initial_employee_metrics = {
  feedbacks: {},
  goals: {},
  meetings: {},
  overview: {},
  reviews: {},
  todos: {},
}

const SelfAppraisal = (props) => {
  const {
    isSelf,
    isManager,
    managerSubmitted,
    saveReview,
    isFetching,
    goalsSelfAssessment,
    setGoalsSelfAssessment,
    feedbackSelfAssessment,
    setFeedbackSelfAssessment,
    avgRating,
    onAvgRatingChange,
    company,
    selfValues,
    onSelfValuesRatingChange,
    employeeSubmitted,
    setEmployeeSubmitted,
    currentFeedbackCard,
    setCurrentFeedbackCard,
    isFeedbackFetching,
    feedback,
    savingReview,
    companyId,
    user,
    companyUserInfo,
    setFeedbackModalShow,
    refetch,
    setRefetch,
    reviews,
    selfGoalsList,
    onSelfGoalsRatingChange,
    userId,
    checkBoxEditable,
    setCheckBoxEditable,
    setSelectedGoal,
    setViewModal,
    onSelfValueAssessmentChange,
    sharedNotes,
    accomplishments,
    setAccomplishments,
    careerAspirations,
    setCareerAspirations,
    ratingSys,
    valueRatingSys,
    comments,
    setComments,
    setRequestType,
  } = props
  const isMobile = useIsMobile()

  const assessmentType = 'self'
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(true)
  const [isEditableCheckbox, setIsEditableCheckbox] = useState(true)
  const personalInfo: any = useSelector((state) => get(state, 'user.personalInfo'))

  const userEmpId = personalInfo?.companyEmails

  const employeeWithEmail = userEmpId.find((email) => {
    return email.companyId === parseInt(companyId)
  })

  const employeeId = employeeWithEmail?.employeeId

  const [activeIndices, setActiveIndices] = useState([])

  const toggleAccordion = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((i) => i !== index))
    } else {
      setActiveIndices([...activeIndices, index])
    }
  }
  const expandAll = () => {
    const allIndices = Array.from({ length: selfValues.length }, (_, index) => index)
    setActiveIndices(allIndices)
  }

  const collapseAll = () => {
    setActiveIndices([])
  }
  useEffect(() => {
    if (reviews?.skipLevelSigner?.id === parseInt(employeeId)) {
      setCheckBoxDisabled(false)
    }
  }, [userId])

  useEffect(() => {
    if (isSelf) {
      setIsEditableCheckbox(true)
    } else if (isManager && employeeSubmitted !== undefined && !employeeSubmitted) {
      setIsEditableCheckbox(false)
    }
  }, [isSelf, isManager, employeeSubmitted])

  return (
    <>
      <ConditionalWrapper
        condition={!checkBoxDisabled || reviews.targetEmployee.id !== parseInt(employeeId)}
        wrapper={(children) => <div className="pointer-events-none cursor-not-allowed">{children}</div>}
      >
        {/* Cards */}
        <div style={{ gap: '3rem' }} className={`${isMobile ? 'flex-col-reverse' : ''} h-auto rounded-lg bg-white`}>
          <div className="space-y-2 gap-4">
            {/* Company Values Card*/}
            <div
              style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
              className={`h-auto rounded-lg bg-white p-2`}
            >
              <div className="flex items-center justify-between pt-4 px-4 gap-3">
                <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold`}>{company?.companyName} Values</h1>
                <div className="flex pointer-events-none">
                  {avgRating.values_self > 0 && (
                    <p className="flex items-center justify-center text-xs py-1 px-2 mr-4 rounded-md bg-gray-100 shadow text-blue-800 font-bold">
                      {avgRating.values_self}
                    </p>
                  )}
                  <Rating
                    defaultValue={avgRating.values_self}
                    onClick={() => {}}
                    fillColor="#1F51FF"
                    ratingSys={ratingSys}
                    valueRatingSys={valueRatingSys}
                  />
                </div>
              </div>

              <div style={{ width: '95%' }} className="bg-gray-200 h-0.5 mx-auto mt-3" />

              <div className={isMobile ? 'space-y-2' : 'p-4 space-y-2'}>
                {!isFetching ? (
                  selfValues?.length > 0 ? (
                    selfValues.map((item, index) => (
                      <div key={index} className="flex flex-col">
                        <div className="flex justify-between">
                          <button
                            className={`flex items-center w-full ${isMobile ? '' : 'gap-2'} outline-none`}
                            onClick={() => toggleAccordion(index)}
                          >
                            <p
                              className={`${
                                isMobile ? 'text-xs' : 'border-l-2 border-themeGreen pl-2 text-sm'
                              } text-left truncate m-2 text-gray-900 font-semibold`}
                            >
                              {item?.companyValue?.value}
                            </p>
                            <span
                              className={`mr-5 transform transition-transform ${
                                activeIndices.includes(index) ? 'rotate-180' : 'rotate-0'
                              }`}
                            >
                              <svg
                                width="13"
                                height="9"
                                viewBox="0 0 15 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.11133 1.07812L7.11133 7.07812L13.1113 1.07812"
                                  stroke="black"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                          </button>
                          <div
                            className={`${!isSelf ? 'pointer-events-none cursor-not-allowed' : ''} flex items-center`}
                          >
                            <Rating
                              defaultValue={item?.rating}
                              onClick={(value) => onSelfValuesRatingChange(value, index)}
                              fillColor="#ffd700"
                              tooltip={true}
                              isSelf={isSelf}
                              isManager={isManager}
                              employeeSubmitted={employeeSubmitted}
                              managerSubmitted={managerSubmitted}
                              assessmentType={assessmentType}
                              ratingSys={ratingSys}
                              valueRatingSys={valueRatingSys}
                            />
                          </div>
                        </div>
                        <div>
                          {activeIndices.includes(index) && (
                            <div className="mx-2 border-l-2 border-themeGreen">
                              <div className="px-2 pt-2">
                                <div>
                                  <div className="mb-2">
                                    <p className={isMobile ? 'text-xs' : 'text-sm'}>
                                      {item?.companyValue?.description}
                                    </p>
                                  </div>
                                  <textarea
                                    name="value-assessment"
                                    id="value-assessment"
                                    rows={4}
                                    placeholder="Describe in brief how you demonstrated this value"
                                    value={item.assessment}
                                    onChange={(value) => onSelfValueAssessmentChange(value, index)}
                                    readOnly={employeeSubmitted || isManager}
                                    className="w-full resize-none rounded-md text-sm focus:outline-none focus:border-gray"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-between border-l-2 border-themeGreen">
                      <p className="m-2 text-dark text-sm font-bold">No Company Values.</p>
                      <p className="m-2 text-black text-sm font-bold">-</p>
                    </div>
                  )
                ) : (
                  <ValuesLoader />
                )}
              </div>
              <div className="ml-6 mb-5 mr-5 flex justify-between">
                <div className="flex gap-4 items-end">
                  <button
                    onClick={() => expandAll()}
                    className={`no-underline text-blue-500 outline-none ${isMobile ? 'text-xs' : 'text-sm'}`}
                  >
                    Expand all
                  </button>
                  <button
                    onClick={() => collapseAll()}
                    className={`no-underline text-blue-500 outline-none ${isMobile ? 'text-xs' : 'text-sm'}`}
                  >
                    Collapse all
                  </button>
                </div>
                <div>
                  <Button
                    disabled={isFetching || savingReview}
                    size={isMobile ? 'sm' : 'md'}
                    btnColor="bg-blue-500"
                    hoverColor="bg-blue-500"
                    className={`${isMobile ? 'mt-2' : ''} font-semibold w-20`}
                    label={'Save'}
                    primary
                    onClick={() => saveReview()}
                  />
                </div>
              </div>
            </div>
            {/* 360 Feedback Card*/}
            <div
              style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
              className={`h-auto rounded-lg bg-white p-2`}
            >
              <div className="flex items-center justify-between pt-4 px-4">
                <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold`}>360 Feedback</h1>
                <div className={isManager ? 'pointer-events-none cursor-not-allowed' : 'flex cursor-pointer'}>
                  {avgRating.feedback_self > 0 && (
                    <p className="flex items-center justify-center text-xs py-1 px-2 mr-4 rounded-md bg-gray-100 shadow text-blue-800 font-bold">
                      {avgRating.feedback_self}
                    </p>
                  )}
                </div>
              </div>

              <div style={{ width: '95%' }} className="bg-gray-200 h-0.5 mx-auto mt-3" />

              {isSelf && (
                <RequestedFeedbackList
                  companyId={companyId}
                  user={user}
                  companyUserInfo={companyUserInfo}
                  isInfoFetching={isFetching}
                  setFeedbackModalShow={setFeedbackModalShow}
                  refetch={refetch}
                  setRefetch={setRefetch}
                  type="self"
                  setRequestType={setRequestType}
                />
              )}

              <div className={isMobile ? 'mb-5' : 'px-4 mb-5'}>
                {isFeedbackFetching ? (
                  <div className="my-4">
                    <ValuesLoader />
                  </div>
                ) : feedback.length > 0 ? (
                  <div className="bg-gray-100 text-gray-800 rounded py-4 px-2 mt-4 mb-4 space-y-3">
                    {feedback.map((f, idx) => (
                      <ReviewFeedbackCard
                        feedback={f}
                        idx={idx}
                        setCurrentCard={setCurrentFeedbackCard}
                        currentCard={currentFeedbackCard}
                        key={f.id + idx}
                      />
                    ))}
                  </div>
                ) : (
                  <></>
                )}
                {!isFetching && (
                  <div>
                    {isSelf && !employeeSubmitted ? (
                      <>
                        <p className={`block dark:text-white py-2 ${isMobile ? 'text-xs' : 'text-sm'} font-semibold`}>
                          Self Assessment for feedback
                        </p>
                        <FeedbackEditor
                          bgColor="#fff"
                          placeholder="Self Assessment for Feedback"
                          value={feedbackSelfAssessment}
                          setValue={setFeedbackSelfAssessment}
                          label={''}
                        />
                      </>
                    ) : feedbackSelfAssessment ? (
                      <AssessmentBox type="self" description={feedbackSelfAssessment} field="Feedbacks" />
                    ) : (
                      <div>
                        <p className="block dark:text-white py-2 text-sm font-semibold">Self Assessment for feedback</p>
                        <div className="h-16 w-full border-2 border-gray-300 rounded-lg mt-1"></div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div className="mb-5 mr-5 flex items-center justify-end">
                <Button
                  disabled={isFetching || savingReview}
                  size={isMobile ? 'sm' : 'md'}
                  btnColor="bg-blue-500"
                  hoverColor="bg-blue-500"
                  className="font-semibold w-20"
                  label={'Save'}
                  primary
                  onClick={() => saveReview()}
                />
              </div>
            </div>

            {/* Shared Notes Container */}
            <div
              style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
              className={`h-auto rounded-lg bg-white px-2`}
            >
              <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold pt-4 px-4`}>Shared Notes</h1>

              <div style={{ width: '95%' }} className="bg-gray-200 h-0.5 mx-auto mt-3" />

              {!isEmpty(sharedNotes) ? (
                <SharedNotesAccordion sharedNotes={sharedNotes} />
              ) : (
                <div className={`p-4 ${isMobile ? 'text-xs' : 'text-sm'}`}>
                  There are no shared meeting notes during this cycle
                </div>
              )}
            </div>
          </div>
        </div>

        {/* Goals Card*/}
        <div
          style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
          className={`h-auto rounded-lg bg-white px-2`}
        >
          <Explore
            isSelf={isSelf}
            isManager={isManager}
            employeeSubmitted={employeeSubmitted}
            managerSubmitted={managerSubmitted}
            assessmentType={assessmentType}
            goalsSelfAssessment={goalsSelfAssessment}
            fetching={isFetching}
            goalsList={selfGoalsList}
            avgRating={avgRating.avgGoal_self}
            onAvgRatingChange={onAvgRatingChange}
            onGoalsRatingChange={onSelfGoalsRatingChange}
            userId={userId}
            isDisabled={isManager}
            setSelectedGoal={setSelectedGoal}
            setViewModal={setViewModal}
            ratingSys={ratingSys}
            valueRatingSys={valueRatingSys}
            ancestor="self-appraisal"
          />

          <div className="px-2 pb-6">
            {!isFetching && (
              <div>
                <div className="py-1" />
                {isSelf && !employeeSubmitted ? (
                  <FeedbackEditor
                    bgColor="#ffff"
                    placeholder="Self Assessment for Goals"
                    value={goalsSelfAssessment}
                    setValue={setGoalsSelfAssessment}
                    label={''}
                  />
                ) : (
                  <AssessmentBox type="self" description={goalsSelfAssessment} field="Goals" />
                )}
                <div className="py-1" />
              </div>
            )}
          </div>
          <div className="pb-5 mr-5 flex items-center justify-end">
            <Button
              disabled={isFetching || savingReview}
              size={isMobile ? 'sm' : 'md'}
              btnColor="bg-blue-500"
              hoverColor="bg-blue-500"
              className="font-semibold w-20"
              label={'Save'}
              primary
              onClick={() => saveReview()}
            />
          </div>
        </div>

        {/* Accomplishment Card */}
        <div
          style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
          className={`h-auto rounded-lg bg-white p-4 mt-4`}
        >
          <div className={`${isMobile ? 'text-sm' : ''} font-bold mb-2`}>Accomplishments</div>
          {isSelf && !employeeSubmitted ? (
            <div>
              <p className="text-xs mb-3">Please share your accomplishments in the space provided below</p>
              <FeedbackEditor bgColor="#fff" value={accomplishments} setValue={setAccomplishments} label={''} />
              <div className="pt-4 flex items-center justify-end">
                <Button
                  disabled={isFetching || savingReview}
                  size={isMobile ? 'sm' : 'md'}
                  btnColor="bg-blue-500"
                  hoverColor="bg-blue-500"
                  className="font-semibold w-20"
                  label={'Save'}
                  primary
                  onClick={() => saveReview()}
                />
              </div>
            </div>
          ) : accomplishments ? (
            <AssessmentBox type="" description={accomplishments} field="" />
          ) : (
            <div className="h-16 w-full border-2 border-gray-300 rounded-lg mt-1"></div>
          )}
        </div>

        {/* Career Aspiration Card */}
        <div
          style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
          className={`h-auto rounded-lg bg-white p-4 mt-4`}
        >
          <div className={`${isMobile ? 'text-sm' : ''} font-bold mb-2`}>Career Aspirations</div>
          {isSelf && !employeeSubmitted ? (
            <div>
              <p className="text-xs mb-3">Please share your career aspirations in the space provided below</p>
              <FeedbackEditor bgColor="#fff" value={careerAspirations} setValue={setCareerAspirations} label={''} />
              <div className="pt-4 flex items-center justify-end">
                <Button
                  disabled={isFetching || savingReview}
                  size={isMobile ? 'sm' : 'md'}
                  btnColor="bg-blue-500"
                  hoverColor="bg-blue-500"
                  className="font-semibold w-20"
                  label={'Save'}
                  primary
                  onClick={() => saveReview()}
                />
              </div>
            </div>
          ) : careerAspirations ? (
            <AssessmentBox type="" description={careerAspirations} field="" />
          ) : (
            <div className="h-16 w-full border-2 border-gray-300 rounded-lg mt-1"></div>
          )}
        </div>

        {/* Employee Comments Card */}
        <div
          style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
          className={`h-auto rounded-lg bg-white p-4 mt-4`}
        >
          <div>
            <div className="w-full flex">
              <div className="flex flex-col w-full h-full">
                <div>
                  <div className={`${isMobile ? 'text-sm' : ''} font-bold mb-2`}>Comments</div>
                  {isSelf && !employeeSubmitted ? (
                    <div>
                      <FeedbackEditor
                        bgColor="#fff"
                        placeholder="Write your comments here..."
                        value={comments}
                        setValue={setComments}
                        label={''}
                        required={comments?.length > 50 ? false : true}
                      />
                      <div className="flex items-center justify-end mt-4">
                        <Button
                          disabled={isFetching || savingReview}
                          size={isMobile ? 'sm' : 'md'}
                          btnColor="bg-blue-500"
                          hoverColor="bg-blue-500"
                          className="font-semibold w-20"
                          label={'Save'}
                          primary
                          onClick={() => saveReview()}
                        />
                      </div>
                    </div>
                  ) : comments ? (
                    <AssessmentBox type="" description={comments} field="" />
                  ) : (
                    <div className="h-16 w-full border-2 border-gray-300 rounded-lg mt-1"></div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Overall Rating Card*/}
        <div className={`${isMobile ? 'pb-10' : ''} w-full flex justify-start items-center mt-4`}>
          <div
            style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
            className="w-full rounded-lg bg-white mb-2 py-4 px-4"
          >
            <div className="flex justify-between items-center w-full">
              <h1 className={`${isMobile ? 'text-sm' : ''} font-bold`}>Overall Rating</h1>
              <div>
                <Rating
                  defaultValue={Number(avgRating.overall_self)}
                  onClick={(value) => onAvgRatingChange(value, 'overall_self', saveReview)}
                  fillColor="#1F51FF"
                  isSelf={isSelf}
                  isManager={isManager}
                  employeeSubmitted={employeeSubmitted}
                  managerSubmitted={managerSubmitted}
                  assessmentType={assessmentType}
                  ratingSys={ratingSys}
                  valueRatingSys={valueRatingSys}
                />
              </div>
            </div>
          </div>
        </div>
      </ConditionalWrapper>
      {/* Confirmation Card*/}
      <div
        style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
        className={`h-auto rounded-lg bg-white px-2 mt-4`}
      >
        <div className="flex flex-col items-start justify-center space-y-4 pt-4 px-4">
          <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold`}>
            Submit <span className="text-red-500 text-sm -mt-2">*</span>
          </h1>
          <div className="flex flex-col space-y-4 pb-5">
            <p className={`${isMobile ? 'text-xs' : 'text-sm'} border-l-2 border-red-400 pl-3`}>
              Submit your self-assessment to your manager. Once you submit your self-assessment only your manager can
              unlock it.
            </p>
            <div className="ml-4 space-y-3">
              <div className="flex items-center justify-start">
                <input
                  style={{
                    opacity: (isSelf || isManager) && isEditableCheckbox ? '1' : '0.6',
                    cursor: (isSelf || isManager) && isEditableCheckbox ? 'pointer' : 'not-allowed',
                  }}
                  onChange={(e) => {
                    setEmployeeSubmitted(e.target.checked)
                    saveReview({ employee: e.target.checked, manager: managerSubmitted })
                    if ((isSelf && e.target.checked) || (isManager && !e.target.checked)) {
                      setIsEditableCheckbox(false)
                      setCheckBoxEditable(false)
                    }
                  }}
                  className="disabled:opacity-50 form-check-input appearance-none h-4 w-4 border border-gray-500 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id={'employeeSubmitted'}
                  checked={employeeSubmitted}
                  value={'employeeSubmitted'}
                  disabled={!(isSelf || isManager) || !isEditableCheckbox}
                />
                <p className={`${isMobile ? 'text-xs' : 'text-sm'} ${checkBoxEditable ? '' : 'text-gray-500'}`}>
                  {reviews?.targetEmployee?.fullName}{' '}
                  {employeeSubmitted && moment(reviews?.employeeSubmittedTime).isValid() && (
                    <span className={`${isMobile ? 'text-xs' : ''} italic font-bold`}>
                      ({moment(reviews?.employeeSubmittedTime).format('l')})
                    </span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default SelfAppraisal
