import { XCircleIcon } from '@heroicons/react/solid'

const SuccessBlock = ({ message, closeSuccessMessage }: { message: string; closeSuccessMessage?: () => void }) => {
  return (
    <div
      hidden={message && message.length > 0 ? false : true}
      className={`rounded-md bg-green-100 dark:bg-gray-800 border dark:border-gray-700 border-transparent p-4`}
    >
      <div className="flex">
        <div className="flex-shrink-0 cursor-pointer" onClick={closeSuccessMessage}>
          <XCircleIcon className={`h-5 w-5 dark:text-green-300 text-green-400`} aria-hidden="true" />
        </div>
        <div className="ml-3">
          <h3 className={`text-sm font-medium text-green-800 dark:text-green-500 `}>{message}</h3>
        </div>
      </div>
    </div>
  )
}

export default SuccessBlock
