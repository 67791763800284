/* This example requires Tailwind CSS v2.0+ */
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import Avatar from 'components/atoms/Avatar'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import useIsMobile from 'hooks/useIsMobile'
import { classNames } from 'lib/utils/classNames'
import { isEmpty } from 'lodash'
import moment from 'moment'
import { Fragment } from 'react'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import { CgClose } from 'react-icons/cg'

const Selector = ({
  list,
  placeholder = '',
  label = '',
  required = false,
  selectedItem = '',
  onSelect = (_) => {},
  border = true,
  error = '',
  keyName = 'name',
  disableFocus = false,
}) => {
  return (
    <div className="w-full">
      <Listbox value={selectedItem} onChange={onSelect}>
        {({ open }) => (
          <div>
            {label && (
              <Listbox.Label className="block dark:text-white text-sm font-medium text-gray-700">
                {label} {required && <span className="text-red-500 font-medium text-base">*</span>}
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button
                className={`bg-white dark:bg-gray-800 relative w-full ${
                  border ? 'border' : ''
                } dark:border-gray-700 border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none ${
                  disableFocus ? '' : 'focus:ring-1 focus:ring-yellow-500 focus:border-yellow-500'
                }  sm:text-sm`}
              >
                <span className={`block truncate  dark:text-white ${!selectedItem ? 'text-gray-600' : ''}`}>
                  {selectedItem || placeholder}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              {error && <p className="mt-2 transition-all duration-200 text-sm text-red-600">{error}</p>}

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="pl-0 scroll-dark-md min-w-48 absolute z-10 mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {list.map((item, idx) => (
                    <Listbox.Option
                      disabled={item.hasOwnProperty('disabled')}
                      key={item.name + idx}
                      className={({ active }) =>
                        classNames(
                          active ? 'text-white bg-gradient-to-r from-pink-500 to-yellow-500' : 'text-gray-900',
                          'cursor-default select-none relative py-2 pl-3 pr-9'
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold ' : 'font-normal',
                              'block truncate dark:text-white text-left'
                            )}
                          >
                            {item[keyName]}
                          </span>

                          {selected ? (
                            <CheckIcon
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4 h-5 w-5'
                              )}
                              aria-hidden="true"
                            />
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </div>
  )
}
export default Selector

export const Selector2 = ({
  list,
  placeholder = '',
  label = '',
  required = false,
  selectedItem = {},
  onSelect = (item) => {},
  border = true,
  error = '',
  alignLeft = false,
  keyName = 'name',
  disableFocus = false,
  width = '',
  noOptionText = '',
  spanClass = '',
  disabled = false,
  optional = false,
  tooltip = '',
  buttonBoxClass = '',
  tooltipPosition = 'top',
  zIndex = 1010,
  showSubText = false,
}) => {
  return (
    <div className={`${!isEmpty(width) ? width : 'w-full'}`}>
      <Listbox value={selectedItem} disabled={disabled} onChange={onSelect}>
        {({ open }) => (
          <div>
            {label && (
              <div className="flex items-center">
                <Listbox.Label className="block dark:text-white my-2 text-sm font-semibold">
                  {label} {required && <span className="text-red-500 font-medium text-base">*</span>}
                  {optional && <span className="text-gray-400 font-normal">(optional)</span>}
                </Listbox.Label>
                {tooltip.length > 0 && (
                  <Tooltip text={tooltip} position={tooltipPosition}>
                    <AiOutlineExclamationCircle className="text-gray-400 mx-1" />
                  </Tooltip>
                )}
              </div>
            )}
            <div className="relative">
              <Listbox.Button
                className={`${buttonBoxClass} bg-white dark:bg-gray-800 relative w-full ${
                  border ? 'border' : ''
                } dark:border-gray-700 border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none ${
                  disableFocus ? '' : ' focus:border-blue-500 focus:outline-none transition-all'
                } ${disabled && 'opacity-50 cursor-not-allowed bg-gray-100'} sm:text-sm`}
              >
                <span
                  className={`${spanClass} flex items-center truncate ${alignLeft ? 'mx-4' : ''} dark:text-white ${
                    !selectedItem?.name ? 'text-themeGray' : ''
                  }`}
                >
                  <span className="mr-1">{selectedItem?.icon}</span>
                  {selectedItem?.name || placeholder}
                  {showSubText && selectedItem && (
                    <span className="italic ml-2">
                      ({moment(selectedItem?.startdate).format('Do MMM YYYY')} -{' '}
                      {moment(selectedItem?.enddate).format('Do MMM YYYY')})
                    </span>
                  )}
                </span>
                <span
                  className={`absolute inset-y-0 ${
                    alignLeft ? 'left-1' : 'right-0'
                  } flex items-center pr-2 pointer-events-none`}
                >
                  <SelectorIcon className="h-5 w-5 text-themeGray" aria-hidden="true" />
                </span>
              </Listbox.Button>
              {selectedItem?.suggestion && <p className="text-xs mt-2 text-gray-400">{selectedItem?.suggestion}</p>}
              {error && <p className="mt-2 transition-all duration-200 text-sm text-red-600">{error}</p>}

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  style={{ zIndex: zIndex }}
                  static
                  className="pl-0 scroll-dark-md min-w-48 absolute z-50 mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {list.map((item, idx) => (
                    <Listbox.Option
                      disabled={item.hasOwnProperty('disabled')}
                      key={item.name + idx}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-blue-500 text-white' : '',
                          item?.id === selectedItem?.id ? ' bg-blue-500 text-white' : '',
                          'cursor-default relative py-2 pl-3 pr-9'
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-medium' : 'font-normal',
                              'flex items-center truncate dark:text-white text-left'
                            )}
                          >
                            <span className="mr-1">{item?.icon}</span> {item[keyName]}
                            {showSubText && (
                              <span className="italic ml-2">
                                ({moment(item?.startdate).format('Do MMM YYYY')} -{' '}
                                {moment(item?.enddate).format('Do MMM YYYY')})
                              </span>
                            )}
                          </span>

                          {selected ? (
                            <CheckIcon
                              className={classNames(
                                active ? 'text-white' : 'text-themeBlue',
                                'absolute inset-y-0 right-0 flex items-center pr-4 h-5 w-5'
                              )}
                              aria-hidden="true"
                            />
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                  {list.length === 0 && (
                    <span className={classNames('block truncate text-themeGray dark:text-white p-2 text-left')}>
                      {noOptionText}
                    </span>
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </div>
  )
}

export const Selector3 = ({
  list,
  placeholder = '',
  label = '',
  inLabel = '',
  required = false,
  selectedItem = '',
  onSelect = (item) => {},
  border = true,
  error = '',
  keyName = 'name',
  disableFocus = false,
  width = '',
  spanClass = '',
  buttonBoxClass = '',
  suggestion = '',
  noOptionText = '',
  optional = false,
  disabled = false,
  tooltip = '',
  noMinWidth = false,
  errorClass = '',
  sublabel = undefined,
  employeeHelper = false,
  truncateText = false // New prop for conditional text truncation
}) => {

  const isMobile = useIsMobile()

  return (
    <div className={`${!isEmpty(width) ? width : 'w-full'}`}>
      <Listbox value={selectedItem} onChange={onSelect} disabled={disabled}>
        {({ open }) => (
          <div>
            {label && (
              <div className="flex items-center">
                <Listbox.Label
                  className={`${isMobile ? 'text-xs' : 'text-sm'} block dark:text-white my-2 font-semibold`}
                >
                  {label}{' '}
                  {required && (
                    <span className={`${isMobile ? 'text-sm' : 'text-base'} text-red-500 font-medium`}>*</span>
                  )}{' '}
                  {optional && <span className="text-themeGray font-normal">(optional)</span>}
                  {sublabel && (
                    <div className={`${isMobile ? 'text-xs' : 'text-sm'} text-gray-600 italic`}>{sublabel}</div>
                  )}
                </Listbox.Label>
                {tooltip.length > 0 && (
                  <Tooltip text={tooltip}>
                    <AiOutlineExclamationCircle className="text-themeGray mx-1" />
                  </Tooltip>
                )}
              </div>
            )}
            <div className="relative">
              <Listbox.Button
                className={`${buttonBoxClass} cursor-pointer bg-white dark:bg-gray-800 relative w-full ${
                  border ? 'border' : ''
                } dark:border-gray-700 border-gray-400 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none ${
                  disableFocus ? '' : 'focus:border-blue-500 focus:outline-none transition-al'
                } ${disabled && 'opacity-50 cursor-not-allowed bg-gray-100'} text-xs sm:text-sm`}
              >
                <span
                  className={`${spanClass} block truncate ${!inLabel.length === 0 && 'mx-4'} dark:text-white ${
                    !selectedItem ? 'text-themeGray' : 'text-dark'
                  }`}
                >
                {inLabel}  {truncateText && selectedItem && selectedItem.length > 17 ? `${selectedItem.slice(0, 16)}...` : selectedItem || placeholder}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              {suggestion && <p className="text-xs mt-2 text-gray-400">{suggestion}</p>}
              {error && (
                <p className={`${errorClass ? errorClass : 'text-sm'} mt-2 transition-all duration-200 text-red-600`}>
                  {error}
                </p>
              )}

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  style={{ zIndex: 1010 }}
                  static
                  className={`${
                    noMinWidth ? '' : 'min-w-48'
                  } pl-0 scroll-dark-md absolute z-10 mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-xs sm:text-sm`}
                >
                  {list.map((item, idx) => (
                    <Listbox.Option
                      disabled={item.hasOwnProperty('disabled')}
                      key={item[keyName] + idx}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-themeBlue2' : 'text-gray-900',
                          item[keyName] === selectedItem ? ' bg-themeBlue2' : 'text-black',  employeeHelper ? 'pr-2' : 'pr-9',
                          'cursor-default relative py-2 pl-3 '
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              selected ? 'font-semibold ' : 'font-normal',
                              'block truncate dark:text-white text-left text-wrap'
                            )}
                          >
                            {item[keyName]}
                          </span>

                          {selected ? (
                            <CheckIcon
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4 h-5 w-5'
                              )}
                              aria-hidden="true"
                            />
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                  {list.length === 0 && (
                    <span className={classNames('block truncate text-themeGray dark:text-white p-2 text-left')}>
                      {noOptionText}
                    </span>
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </div>
  )
}

//DROPDOWN FOR ONLY ONE SELECTED USER
export const Selector4 = ({
  list,
  placeholder = '',
  label = '',
  inLabel = '',
  required = false,
  selectedItem,
  onSelect = (item) => {},
  onChipCancel = () => {},
  border = true,
  chipList = [],
  error = '',
  keyName = 'name',
  disableFocus = false,
  width = '',
  spanClass = '',
  buttonBoxClass = '',
  suggestion = '',
  noOptionText = '',
  withAvatar = false,
  tooltip = '',
  optional = false,
}) => {
  return (
    <div className={`${!isEmpty(width) ? width : 'w-full'}`}>
      <Listbox value={selectedItem} onChange={onSelect}>
        {({ open }) => (
          <div className={!label && ''}>
            {label && (
              <div className="flex items-center">
                <Listbox.Label className="block dark:text-white my-2 text-sm font-semibold">
                  {label} {required && <span className="text-red-500 font-medium text-base">*</span>}{' '}
                  {optional && <span className="text-themeGray font-medium">(optional)</span>}
                </Listbox.Label>
                {tooltip.length > 0 && (
                  <Tooltip text={tooltip}>
                    <AiOutlineExclamationCircle className="text-themeGray mx-1" />
                  </Tooltip>
                )}
              </div>
            )}
            <div className="relative">
              <Listbox.Button
                className={`${buttonBoxClass} bg-white dark:bg-gray-800 relative w-full ${
                  border ? 'border' : ''
                } dark:border-gray-700 border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none ${
                  disableFocus
                    ? ''
                    : 'focus:ring-themeBlue focus:border-themeBlue focus:outline-none focus:ring-2 focus:ring-offset-2 transition-al'
                } sm:text-sm`}
              >
                <span
                  className={`${spanClass} block truncate  text-themeGray dark:text-white ${
                    !selectedItem ? 'text-themeGray' : ''
                  }`}
                >
                  {!isEmpty(selectedItem) ? (
                    withAvatar ? (
                      <span className="px-1.5 py-1 rounded-full text-themeGray bg-gray-200 text-sm flex items-center align-center w-max">
                        {withAvatar ? (
                          <span className="text-black mr-2">
                            <Avatar name={selectedItem?.fullName} nameClass="font-semibold" small withName />
                          </span>
                        ) : (
                          <span className="mx-2">{selectedItem?.name}</span>
                        )}
                        <CgClose
                          className="h-5 w-5 ml-1 font-extrabold cursor-pointer"
                          onClick={() => onChipCancel()}
                        />
                      </span>
                    ) : (
                      selectedItem?.name
                    )
                  ) : (
                    placeholder
                  )}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              {suggestion && <p className="text-xs mt-2 text-gray-400">{suggestion}</p>}
              {error && <p className="mt-2 transition-all duration-200 text-sm text-red-600">{error}</p>}

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  style={{ zIndex: 1010 }}
                  static
                  className="pl-0 scroll-dark-md min-w-48 absolute z-10 mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {list.map((item, idx) => (
                    <Listbox.Option
                      key={idx}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-themeBlue2' : 'text-gray-900',
                          item?.name === selectedItem ? ' bg-themeBlue2' : 'text-black',
                          item?.borderTop ? ' border-t-2 border-themeGray2' : 'text-black',
                          'cursor-default relative py-2 pl-3 pr-9'
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <span
                            className={classNames(
                              item?.name === selectedItem ? ' bg-themeBlue2' : 'text-black',
                              selected ? 'font-semibold ' : 'font-normal',
                              'block truncate dark:text-white text-left'
                            )}
                            key={idx}
                          >
                            {withAvatar ? (
                              <Avatar name={item?.fullName} withName title={item?.jobTitle} medium />
                            ) : (
                              <>{item?.name}</>
                            )}
                          </span>

                          {selected ? (
                            <CheckIcon
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4 h-5 w-5'
                              )}
                              aria-hidden="true"
                            />
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                  {list.length === 0 && (
                    <span className={classNames('block truncate text-themeGray dark:text-white p-2 text-left')}>
                      {noOptionText}
                    </span>
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </div>
  )
}

export const CheckboxSelector = ({
  list,
  placeholder = '',
  label = '',
  inLabel = '',
  required = false,
  selectedItem = '',
  onSelect = (_) => {},
  border = true,
  error = '',
  keyName = 'name',
  disableFocus = false,
  width = '',
  spanClass = '',
  buttonBoxClass = '',
  suggestion = '',
}) => {
  const checkboxClass =
    'text-themeBlue border-themeGray2 rounded-sm focus:outline-none focus:ring-0 focus:ring-themeBlue mt-0.5'

  return (
    <div className={`${!isEmpty(width) ? width : 'w-full'}`}>
      <Listbox value={selectedItem} onChange={onSelect}>
        {({ open }) => (
          <div>
            {label && (
              <Listbox.Label className="block dark:text-white my-2 text-sm font-semibold">
                {label} {required && <span className="text-red-500 font-medium text-base">*</span>}
              </Listbox.Label>
            )}
            <div className="relative">
              <Listbox.Button
                className={`${buttonBoxClass} bg-white dark:bg-gray-800 relative w-full ${
                  border ? 'border' : ''
                } dark:border-gray-700 border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none ${
                  disableFocus
                    ? ''
                    : 'focus:ring-themeBlue focus:border-themeBlue focus:outline-none focus:ring-2 focus:ring-offset-2 transition-al'
                } sm:text-sm`}
              >
                <span
                  className={`${spanClass} block truncate ${
                    !inLabel.length === 0 && 'mx-4'
                  } text-themeGray dark:text-white ${!selectedItem ? 'text-themeGray' : ''}`}
                >
                  {inLabel} {selectedItem || placeholder}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              {suggestion && <p className="text-xs mt-2 text-gray-400">{suggestion}</p>}
              {error && <p className="mt-2 transition-all duration-200 text-sm text-red-600">{error}</p>}

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  static
                  className="pl-0 scroll-dark-md min-w-48 absolute z-10 mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 text-base ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none sm:text-sm"
                >
                  {list.map((item, idx) => (
                    <Listbox.Option
                      disabled={item.hasOwnProperty('disabled')}
                      key={item.name + idx}
                      className={({ active }) =>
                        classNames(
                          active ? 'bg-themeBlue2' : 'text-gray-900',
                          item.name === selectedItem ? ' bg-themeBlue2' : 'text-black',
                          'cursor-default relative py-2 pl-3 pr-9'
                        )
                      }
                      value={item}
                    >
                      {({ selected, active }) => (
                        <>
                          <label className={`flex cursor-pointer`}>
                            <input type="checkbox" className={checkboxClass} />
                            <div className={`ml-2`}>
                              <p className="">{item[keyName]}</p>
                            </div>
                          </label>

                          {selected ? (
                            <CheckIcon
                              className={classNames(
                                active ? 'text-white' : 'text-indigo-600',
                                'absolute inset-y-0 right-0 flex items-center pr-4 h-5 w-5'
                              )}
                              aria-hidden="true"
                            />
                          ) : null}
                        </>
                      )}
                    </Listbox.Option>
                  ))}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </div>
  )
}
