import Modal from 'components/atoms/Modal2'
import { truncate } from 'lodash'
import { AiFillFile, AiOutlineFileAdd } from 'react-icons/ai'
import { useEffect, useState } from 'react'
import DocView from 'components/organisms/Meeting/OneOne/DocView'
import AddAttachmentModal from 'components/organisms/Meeting/OneOne/AddAttachmentModal'
import moment from 'moment'
import { Oval as Loader } from 'react-loader-spinner'
import NoAttachment from 'assets/icons/no-document.png'
import { getFileExtension, getFileType } from 'lib/utils'
import {
  BsFillFileEarmarkExcelFill,
  BsFillFileEarmarkImageFill,
  BsFillFileEarmarkPdfFill,
  BsFillFileEarmarkPptFill,
  BsFillFileEarmarkWordFill,
} from 'react-icons/bs'
import { FaFileCsv, FaFileVideo } from 'react-icons/fa'
import Button from 'components/atoms/Button/button'

const FileIcon = ({ fileType }) => {
  switch (fileType) {
    case 'pdf':
      return <BsFillFileEarmarkPdfFill className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />
    case 'doc':
      return <BsFillFileEarmarkWordFill className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />
    case 'xls':
      return <BsFillFileEarmarkExcelFill className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />
    case 'csv':
      return <FaFileCsv className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-2" />
    case 'ppt':
      return <BsFillFileEarmarkPptFill className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />
    case 'zip':
      return <AiFillFile className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />
    case 'txt':
      return <AiFillFile className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />
    case 'img':
      return <BsFillFileEarmarkImageFill className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />
    case 'video':
      return <FaFileVideo className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />

    default:
      return <AiFillFile className="w-12 h-12 text-gray-800 rounded-md bg-gray-500 p-1" />
  }
}

const FileViewerModal = ({
  showModal,
  setShowModal,
  item,
  updateAttachments,
  deleteAttachments,
  header,
  getAttachments,
  isTalkingPoint = false,
}: {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  updateAttachments: (attachment: any, id: number) => void
  deleteAttachments: (attachment: any, id: number) => void
  item: any
  header: string
  getAttachments: any
  isTalkingPoint?: boolean
}) => {
  const [refetch, setRefetch] = useState(false)
  const [docs, setDocs] = useState([])
  const [showAddModal, setShowAddModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [docStep, setDocStep] = useState(0)

  const onCancel = () => {
    setShowModal(false)
  }

  useEffect(() => {
    if (item?.id) {
      getAttachments(setLoading, setDocs, setRefetch)
    }
  }, [item])

  useEffect(() => {
    if (refetch) {
      getAttachments(setLoading, setDocs, setRefetch)
    }
  }, [refetch])

  useEffect(() => {
    if (showModal) {
      setDocStep(0)
    }
  }, [showModal])

  return (
    <Modal zIndex={5000} header={truncate(header, { length: 130 })} open={showModal} setOpen={onCancel}>
      <AddAttachmentModal
        header={header}
        updateAttachments={updateAttachments}
        setRefetch={setRefetch}
        showModal={showAddModal}
        setShowModal={setShowAddModal}
        item={item}
        isTalkingPoint={isTalkingPoint}
      />
      <div className="flex items-start">
        <div className=" ml-5 mr-10 flex flex-col justify-center items-start">
          <h2 className="my-2 mt-4 ml-2 font-semibold">Attachments</h2>
          <div
            style={{
              background: 'rgba(255,255,255,0.2)',
              backdropFilter: 'blur(4px)',
              height: docs.length >= 4 ? '500px' : 'max-content',
              overflowY: docs.length >= 4 ? 'scroll' : 'unset',
            }}
            className="w-80 space-y-3 py-8 px-4 rounded-lg"
          >
            <div
              onClick={() => setShowAddModal(true)}
              className="file__list cursor-pointer hover:bg-gray-800 px-4 py-2 rounded-lg flex justify-between items-center"
            >
              <div className="flex gap-4 items-center">
                <AiOutlineFileAdd className="w-10 h-10 text-gray-800 rounded-md bg-gray-500 p-1" />
                <div>
                  <p className="text-sm font-semibold">Add Attachment</p>
                  <p className="mt-2 text-xs text-gray-400">10 Mb Max. File Size</p>
                </div>
              </div>
            </div>

            {loading ? (
              <Loader
                wrapperClass="flex justify-center"
                secondaryColor="#7d7d7d"
                color={'white'}
                height={50}
                width={50}
              />
            ) : docs.length > 0 ? (
              docs.map((doc, idx) => (
                <div
                  onClick={() => setDocStep(idx)}
                  className={`${
                    docStep === idx ? 'border border-dashed' : ''
                  } file__list transition-all cursor-pointer hover:bg-gray-800 px-4 py-2 rounded-lg flex justify-between items-center`}
                >
                  <div className="flex gap-4 items-center">
                    <FileIcon fileType={getFileType(getFileExtension(doc.fileName))} />
                    <div>
                      <p className="text-sm font-semibold">{truncate(doc.fileName, { length: 24 })}</p>
                      <p className="mt-2 text-xs text-gray-400">
                        Shared By {doc.uploadedBy.fullName} on {moment(doc.createdAt).format('l')}
                      </p>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p className="text-sm text-center mt-2 text-white">No Attachments</p>
            )}
          </div>
        </div>
        {docs.length > 0 ? (
          <DocView
            deleteAttachments={deleteAttachments}
            setRefetch={setRefetch}
            step={docStep}
            setStep={setDocStep}
            item={item}
            docs={docs}
            isTalkingPoint={isTalkingPoint}
          />
        ) : (
          <div className="w-full h-96 flex flex-col items-center justify-center">
            <img className="w-40 h-40" src={NoAttachment} alt="no-attachment" />
            No Attachments
            <Button
              size="md"
              btnColor="bg-blue-500"
              hoverColor="bg-blue-500"
              className="mt-3 font-semibold w-30"
              label={'Upload'}
              primary
              onClick={() => setShowAddModal(true)}
            />
          </div>
        )}
      </div>
    </Modal>
  )
}

export default FileViewerModal
