import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import Button from 'components/atoms/Button/button'
import { memo, useEffect, useRef, useState } from 'react'
import { BsArrowLeft, BsArrowRight } from 'react-icons/bs'
import { Oval as Loader } from 'react-loader-spinner'
import NoAttachment from 'assets/icons/no-document.png'
import { MdDelete, MdOutlineScreenShare } from 'react-icons/md'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { AiOutlineDownload } from 'react-icons/ai'
import { FaRegCopy } from 'react-icons/fa'
import DeleteAttachmentDialog from 'components/organisms/Meeting/OneOne/DeleteAttachmentDialog'
import { useParams } from 'react-router-dom'

const CustomHeader = ({
  state,
  prevDocument,
  nextDocument,
  item,
  deleteAttachments,
  isTalkingPoint,
  setRefetch,
  setStep,
}) => {
  const { companyId }: any = useParams()
  const downloadRef = useRef<HTMLAnchorElement>(null)
  const [isCopied, setIsCopied] = useState(false)
  const [showDeleteModal, setShowDeleteModal] = useState(false)

  useEffect(() => {
    if (isCopied) {
      const timer = setTimeout(() => {
        setIsCopied(false)
      }, 2000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [isCopied])

  const TOOLTIP_POSITION = 'bottom'

  return (
    <div>
      <DeleteAttachmentDialog
        companyId={companyId}
        deleteAttachments={deleteAttachments}
        setRefetch={setRefetch}
        deleteModal={showDeleteModal}
        setDeleteModal={setShowDeleteModal}
        itemId={item?.id}
        attachmentId={state?.currentDocument?.id}
        fileName={state?.currentDocument?.fileName}
        isTalkingPoint={isTalkingPoint}
      />
      <div className="my-6 pr-4 flex justify-end">
        <div className="text-sm flex gap-8">
          <Tooltip position={TOOLTIP_POSITION} text="Download">
            <div
              onClick={() => downloadRef.current.click()}
              className="text-gray-50 hover:text-blue-500 cursor-pointer flex gap-3"
            >
              <AiOutlineDownload size={20} />
              <a ref={downloadRef} hidden href={state?.currentDocument?.uri} download>
                .
              </a>
              Download
            </div>
          </Tooltip>
          <Tooltip position={TOOLTIP_POSITION} text="Open in new tab">
            <div
              onClick={() => window.open(state?.currentDocument?.uri, '_blank')}
              className="text-gray-50 hover:text-blue-500 cursor-pointer flex gap-3"
            >
              <MdOutlineScreenShare size={22} /> Open in new tab
            </div>
          </Tooltip>
          <div
            onClick={() => {
              navigator.clipboard.writeText(state?.currentDocument?.uri)
              setIsCopied(true)
            }}
            className="relative text-gray-50 hover:text-blue-500 cursor-pointer flex gap-3"
          >
            <span
              className={`${
                isCopied ? 'opacity-1' : 'opacity-0'
              } transition-all absolute font-bold bg-green-100 text-green-800 px-3 py-1 rounded-md -bottom-10 left-4`}
            >
              Copied
            </span>
            <FaRegCopy size={20} /> Copy Url
          </div>
          <Tooltip position={TOOLTIP_POSITION} text="Delete">
            <div
              onClick={() => setShowDeleteModal(true)}
              className="text-gray-50 hover:text-red-500 cursor-pointer flex gap-3"
            >
              <MdDelete size={20} /> Delete
            </div>
          </Tooltip>
        </div>
      </div>

      <div style={{ minWidth: '150px', margin: '0 10px' }} id="doc-nav" className="flex justify-between items-center">
        <div className="text-sm text-white">{state.currentDocument.fileName}</div>
        <div className="flex justify-center items-center mb-6">
          <p id="doc-nav-info" className="text-sm">
            Document {state.currentFileNo + 1} of {state.documents.length}
          </p>

          <button
            id="doc-nav-prev"
            style={{ width: '30px', height: '30px', margin: '0 5px 0 10px' }}
            className="rounded-full shadow"
            onClick={() => {
              setStep((prev) => prev - 1)
              prevDocument()
            }}
            disabled={state.currentFileNo === 0}
          >
            <BsArrowLeft color="#fff" size="60%" />
          </button>

          <button
            style={{ width: '30px', height: '30px', margin: '0 5px 0 10px' }}
            className="rounded-full shadow"
            id="doc-nav-next"
            onClick={() => {
              setStep((prev) => prev + 1)
              nextDocument()
            }}
            disabled={state.currentFileNo >= state.documents.length - 1}
          >
            <BsArrowRight color="#fff" size="60%" />
          </button>
        </div>
      </div>
    </div>
  )
}

const DocView = ({ docs, item, deleteAttachments, isTalkingPoint, setRefetch, step, setStep }) => {
  return (
    <DocViewer
      requestHeaders={{ prefetchMethod: 'GET' }}
      documents={docs}
      initialActiveDocument={docs[step]}
      pluginRenderers={DocViewerRenderers}
      config={{
        header: {
          overrideComponent: (state, prevDocument, nextDocument) => {
            return (
              <CustomHeader
                item={item}
                deleteAttachments={deleteAttachments}
                setRefetch={setRefetch}
                state={state}
                prevDocument={prevDocument}
                nextDocument={nextDocument}
                setStep={setStep}
                isTalkingPoint={isTalkingPoint}
              />
            )
          },
        },
        noRenderer: {
          overrideComponent: ({ document, fileName }) => {
            return (
              <div className="w-full flex flex-col gap-4 items-center justify-center h-96">
                <img className="w-40 h-40" src={NoAttachment} alt="no-attachment" />
                Sorry, unable to open this file
                <Button
                  size="md"
                  btnColor="bg-blue-500"
                  hoverColor="bg-blue-500"
                  className="font-semibold w-30"
                  label={'Download'}
                  primary
                  onClick={() => alert('Download File')}
                />
              </div>
            )
          },
        },
        loadingRenderer: {
          overrideComponent: () => {
            return (
              <div className="w-full flex flex-col gap-4 items-center justify-center h-96 overflow-hidden">
                <Loader color={'white'} secondaryColor="#7d7d7d" height={50} width={50} />
              </div>
            )
          },
        },
        csvDelimiter: ',',
        pdfZoom: {
          defaultZoom: 1.1,
          zoomJump: 0.2,
        },
      }}
      language="en"
    />
  )
}

export default memo(DocView)
