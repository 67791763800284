import { ReactElement } from 'react'

interface Props {
  label: any
  className?: string
  bgColor?: string
  labelColor?: string
}

export default function Label({
  label,
  className,
  bgColor = 'bg-themeGray2',
  labelColor = 'text-white',
}: Props): ReactElement {
  return (
    <div className={`${bgColor} font-roboto rounded-md p-1 px-2 select-none ${className}`}>
      <p className={`${labelColor} text-xs font-medium`}>{label}</p>
    </div>
  )
}
