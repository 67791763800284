import Rating from 'components/templates/Review/Rating/Rating'
import { AiOutlineEdit } from 'react-icons/ai'
import parse from 'html-react-parser'
import useIsMobile from 'hooks/useIsMobile'

const RatingSystemItem = ({
  title,
  subtitle,
  desc,
  rating,
  setActiveIndex,
  idx,
  ancestor,
}: {
  title?: any
  subtitle?: any
  desc?: any
  rating?: any
  setActiveIndex?: any
  idx?: any
  ancestor?: any
}) => {
  const isMobile = useIsMobile()
  return (
    <div>
      <div className="flex items-center gap-4 mb-3 file__close">
        <div className={`${isMobile ? 'text-xs flex-col' : 'flex-col text-base'} font-bold flex gap-3 text-center`}>
          <div className="leading-loose text-left">{title}</div>
          <div className="flex items-center gap-1">
            <div className="mr-1">[</div>
            <div className="pointer-events-none cursor-not-allowed">
              <Rating defaultValue={rating} onClick={() => {}} fillColor="#ffd700" />
            </div>{' '}
            <div>]</div>
          </div>
        </div>
        {ancestor === 'admin' && (
          <AiOutlineEdit className="close__icon cursor-pointer ml-2" size={24} onClick={() => setActiveIndex(idx)} />
        )}
      </div>
      <div className="font-bold text-base mb-1">{subtitle}</div>
      <p className="text-sm">{typeof desc == 'string' ? parse(desc) : desc}</p>
    </div>
  )
}

export default RatingSystemItem
