import React, { useEffect, useState } from 'react'
import Button from 'components/atoms/Button/button'
import Modal from 'components/atoms/Modal'
import { HttpRequest } from 'helpers/services'
import { isEmpty } from 'lodash'
import { nanoid } from 'nanoid'
import { Toggle2 } from 'components/atoms/Toggle'
import { useDispatch } from 'react-redux'
import { setErrorMessages } from 'state/redux/actions/settings.actions'
import SearchBar2 from 'components/atoms/SearchBar/SearchBar2'
import Avatar from 'components/atoms/Avatar'
import { useMeetings } from 'components/templates/Meeting/ContextApi/MeetingContext'

const CreateOneOneModal = ({
  showModal,
  setShowModal,
  selectedOneOnOne,
  fetchList,
  setMeetingRefetch,
  hideOld = false,
  header,
}: {
  showModal: boolean
  hideOld?: boolean
  selectedOneOnOne?: any
  header?: string
  fetchList?: () => void
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setMeetingRefetch?: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const isGoalPage = window.location.pathname.includes('goals')
  const companyId = parseInt(window.location.pathname.split('/')[isGoalPage ? 3 : 2])
  const [template, setTemplate]: any = useState({ id: -1, name: 'Custom', borderTop: true })
  const [prevAgendaMeetingToggle, setPrevAgendaMeetingToggle] = useState(true)
  const [prevActionMeetingToggle, setPrevActionMeetingToggle] = useState(true)
  const [hideOldToggle, setHideOldToggle] = useState(true)
  const [showSuggestions, setShowSuggestions] = useState(false)

  const [ownerSearchInput, setOwnerSearchInput] = useState('')
  const [ownerSuggestedList, setOwnerSuggestedList] = useState([])
  const [owners, setOwners] = useState([])
  const [suggestionFetching, setSuggestionFetching] = useState(false)

  const { refetchData } = useMeetings()

  const [talkingPoints, setTalkingPoints]: any = useState<any>([
    {
      point: '',
    },
  ])
  const [formError, setFormError]: any = useState({})
  const dispatch: any = useDispatch()

  useEffect(() => {
    if (companyId) {
      fetchEmployeeSuggestions()
    }
  }, [])

  useEffect(() => {
    companyId && fetchEmployeeSuggestions()
  }, [ownerSearchInput])

  const fetchEmployeeSuggestions = () => {
    setSuggestionFetching(true)
    const commonHeader = {
      search: ownerSearchInput,
      'x-appengine-company-id': companyId,
      isNew: true,
    }
    HttpRequest('GET', '/company/employees/employees/OneOnOne', {}, commonHeader)
      .then(({ data }: any) => {
        const employees = data?.data
        setOwnerSuggestedList(employees)
        setSuggestionFetching(false)
      })
      .catch((err) => {
        console.error(err)
        setSuggestionFetching(false)
      })
  }

  const onCancel = () => {
    setShowModal(false)
    setTalkingPoints([
      {
        point: '',
      },
    ])
    setFormError({})
    setOwners([])
    setTemplate({ id: -1, name: 'Custom', borderTop: true })
  }

  const validateForm = () => {
    let isValid = true
    let messages = {
      talkingPoint: '',
      employees: '',
    }

    setFormError({
      ...formError,
      ...messages,
    })
    return isValid
  }

  const onSave = () => {
    if (validateForm()) {
      const commonHeader = {
        'x-appengine-company-id': companyId,
      }

      let data = {
        oneOnOneTo: owners[0]?.id,
        meetingFrequency: 'once',
        allowPreviousMeetingPoints: true,
        allowPreviousActionItems: true,
        shouldHideOld: false,
        isActionItem: true,
        isCustom: false,
        templateId: null,
      }

      if (template.id === -2) {
        data['templateId'] = null
      } else if (template.id === -1) {
        data['isCustom'] = true
        data['templateId'] = null
        const talkingPoint = talkingPoints
          .filter((item, index) => index !== talkingPoints.length - 1)
          .map((item) => item.point)
        data['templatePoints'] = talkingPoint
      } else {
        data['templateId'] = template?.id
      }

      HttpRequest('POST', '/meeting-room', data, commonHeader)
        .then(({ data }: any) => {
          if (data.code === 200) {
            onCancel()
            fetchList()
            refetchData()
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages(['Error creating 1:1']))
        })
    }
  }

  const onEdit = () => {
    if (validateForm()) {
      const commonHeader = {
        'x-appengine-company-id': companyId,
      }
      let data = {
        frequency: 'once',
        allowPreviousMeetingPoints: prevAgendaMeetingToggle,
        allowPreviousActionItems: prevActionMeetingToggle,
        shouldHideOld: hideOldToggle,
        isActionItem: true,
        isCustom: false,
        templateId: null,
      }
      if (template.id === -2) {
        data['templateId'] = null
      } else if (template.id === -1) {
        data['isCustom'] = true
        data['templateId'] = null
        const talkingPoint = talkingPoints
          .filter((item, index) => index !== talkingPoints.length - 1)
          .map((item) => item.point)
        data['templatePoints'] = talkingPoint
      } else {
        data['templateId'] = template?.id
      }
      HttpRequest('PATCH', `/meeting-room/${selectedOneOnOne?.id}`, data, commonHeader)
        .then(({ data }: any) => {
          if (data.code === 200) {
            onCancel()
            setMeetingRefetch(true)
            refetchData()
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages(['Error creating 1:1']))
          console.error(err)
        })
    }
  }

  const x = 15
  const timeRangeList = []
  const newDate = new Date()
  const format: any = {
    hour: 'numeric',
    minute: 'numeric',
  }

  for (let minutes = 0; minutes < 12 * 60; minutes = minutes + x) {
    newDate.setHours(0)
    newDate.setMinutes(minutes)
    timeRangeList.push({
      id: nanoid(6),
      name: newDate.toLocaleTimeString('en', format).replace('AM', ''),
    })
  }

  useEffect(() => {
    if (selectedOneOnOne) {
      setTemplate(selectedOneOnOne?.template ? selectedOneOnOne.template : { id: -2, name: 'No agenda template' })
      setPrevAgendaMeetingToggle(selectedOneOnOne?.allowPreviousMeetingPoints)
      setPrevActionMeetingToggle(selectedOneOnOne?.allowPreviousActionItems)
      setHideOldToggle(selectedOneOnOne?.shouldHideOld)
    }
  }, [selectedOneOnOne])

  const removeSearchAction = () => {
    setOwnerSearchInput('')
  }

  const onChipCancel = (item) => {
    const newOwner = owners.filter((owner) => owner?.id !== item?.id)
    setOwners(newOwner)
  }

  return (
    <Modal
      fixedOverflow
      bodyPaddingZero
      max={{ w: 160 }}
      hwClassName={'max-w-160 p-0 sm:p-0'}
      header={
        selectedOneOnOne ? `${header ? header : '1:1'} Setting` : `Set up ${header ? header : '1:1'} relationship`
      }
      open={showModal}
      setOpen={onCancel}
    >
      <div className="overflow-y-scroll max-h-104 px-6 py-4">
        <div className="" style={{ zIndex: 1000 }}>
          {!selectedOneOnOne && (
            <div className="py-2 mb-5">
              <SearchBar2
                onFocus={() => setShowSuggestions(true)}
                onBlur={() => setShowSuggestions(false)}
                disabled={owners.length > 0}
                onCancel={removeSearchAction}
                onChange={setOwnerSearchInput}
                value={ownerSearchInput}
                placeholder="Select a user..."
                withChip
                onChipCancel={onChipCancel}
                chipItem={owners}
                label="Who is your 1:1 with?"
                error={formError.employees}
              />
              {showSuggestions && (
                <div
                  className="bg-white absolute mt-1 cursor-pointer overflow-auto shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5"
                  style={{ zIndex: 1000, width: '90%' }}
                >
                  {ownerSuggestedList.map((item) => (
                    <div
                      className="flex items-center bg-white hover:bg-themeBlue2 max-h-32"
                      onMouseDown={() => {
                        setOwners([...owners, item])
                        setOwnerSearchInput('')
                      }}
                      onMouseUp={() => setShowSuggestions(false)}
                    >
                      <div className="px-4 py-2">
                        <Avatar name={item?.fullName} withName title={item?.jobTitle} medium />
                      </div>
                    </div>
                  ))}
                  {!suggestionFetching && ownerSearchInput.length > 0 && ownerSuggestedList.length === 0 && (
                    <p className="p-4 text-sm text-gray-900">No employees found</p>
                  )}
                </div>
              )}
            </div>
          )}

          {selectedOneOnOne && (
            <div className="py-4 space-y-2">
              <div className="flex items-center justify-between">
                <h4 className="text-xl text-black font-semibold pb-1">Agenda Items from previous meeting</h4>
                <Toggle2 enabled={prevAgendaMeetingToggle} setEnabled={setPrevAgendaMeetingToggle} text="" />
              </div>
              <p className="text-sm text-themeGray w-120">
                Ensure that you never miss an important conversation. Unchecked agenda items from the previous meeting
                will show up in the next meeting.
              </p>
            </div>
          )}

          {selectedOneOnOne && (
            <div className="py-4 space-y-2">
              <div className="flex items-center justify-between">
                <h4 className="text-xl text-black font-semibold pb-1">Action Items from previous meeting</h4>
                <Toggle2 enabled={prevActionMeetingToggle} setEnabled={setPrevActionMeetingToggle} text="" />
              </div>
              <p className="text-sm text-themeGray w-120">
                Ensure that you never miss an important conversation. Unchecked action items from the previous meeting
                will show up in the next meeting.
              </p>
            </div>
          )}
        </div>
      </div>

      <div className="flex justify-between items-center p-6 space-x-2 border-t border-gray-200">
        {selectedOneOnOne ? (
          <Button
            size="md"
            hoverColor="bg-themeBlue"
            className="font-semibold"
            label={'Save'}
            primary
            onClick={onEdit}
          />
        ) : (
          <Button
            size="md"
            disabled={isEmpty(owners)}
            hoverColor="bg-themeBlue"
            className="font-semibold"
            label={'Save'}
            primary
            onClick={onSave}
          />
        )}
      </div>
    </Modal>
  )
}

export default CreateOneOneModal
