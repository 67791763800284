// Add Names and variables in internally alphabetical orders in this file.

// A

// B
export const BUTTONS = {
  TASK: 'Add Another Task',
  ADD_AGENDA: 'Save Agenda',
  BACK_TO_LOGIN: 'Go back to login',
  CREATE_ACCOUNT: 'Create account',
  FORGOT_PASSWORD: 'Forget your Password?',
  LOGIN: 'Login',
  NEXT: 'Next',
  RESET_PASSWORD: 'Reset Password',
  SAVE: 'Save',
  ENABLE: 'Enable',
  CANCEL: 'Cancel',
  SEND_EMAIL: 'Send Email',
  SIGN_IN: 'Sign In',
  SIGN_UP: 'Sign Up',
  UPDATE: 'Update',
  SYNC_ACTIVE_DIRECTORY: 'Sync User Directory',
  SYNC_SAML: 'Sync Saml Info',
  ADD_EMPLOYEES: 'Add employees',
  LOGOUT: 'Log out',
  MANAGE_SETTINGS: 'Manage Settings',
  VIEW_PROFILE: 'View profile',
  HELP: 'Help',
  DOWNLOAD_TEMPLATE: 'Download Template',
  EXPORT_CSV: 'Export CSV',
  ADD_ANOTHER: 'Add Another',
  SAVE_PERMISSIONS: 'Save Permissions',
  SAVE_PROFILE: 'Save profile',
  SAVE_VALUE: 'Save values',
  SAVE_TASK: 'Save Tasks',
  SAVE_CHANGES: 'Save changes',
  REACTIVATE: 'Reactivate',
  DEACTIVATE_ACCOUNT: 'Yes, deactivate account',
  DELETE_ACCOUNT: 'Yes, delete account',
  CREATE_DEPARTMENT: 'Create Department',
  CREATE_DEPARTMENT_FROM_AD: 'Sync Departments From Active Directory',
  CREATE_TEAM: 'Create Team',
  ADD_MEMBER: 'Add member',
  CUSTOM_ATTRIBUTE: 'Create custom attribute',
  ADD_VALUE: 'Add another value',
  ADD_EDUCATION: 'Add education',
  DELETE_GOAL_CYCLE: 'Yes, delete cycle',
  MAKE_CURRENT: 'Yes, make current',
  CREATE_NEW_SHEET: 'Create New Sheet',
  CLOSE: 'Close',
}

// L
export const LABELS = {
  EMPLOYEES: 'Employees',
  USER_ATTRIBUTES: 'User Attributes',
  GOAL_CYCLES: 'Goal Cycles',
  REPORTING: 'Reporting',
  DOMAIN_NAME: 'Domain name',
  PERMISSIONS: 'Permissions',
  TAGS: 'Tags',
  SETTINGS: 'Goals settings',
}

// M
export const MESSAGES = {
  CODE: {
    TOKEN_INVALID: 'TOKEN_INVALID',
  },
  ERROR_MESSAGE: {
    COMPANY_DOMAIN_ERROR: 'Error while fetching company ID',
    CREATE_ACCOUNT_ERROR: 'Error while creating account, please try after some time.',
    INVALID_TOKEN: 'Invalid token, Please try after sometime.',
    RESET_PASSWORD_ERROR: 'Error in password reset, Please try after some time.',
  },
  SUCCESS_MESSAGES: {
    CHECK_EMAIL: 'Please check your email to set new password',
  },
  VALIDATION_MESSAGES: {
    EMAIL: 'Email is required',
    INCORRECT_EMAIL: 'Email is incorrect',
    FIRST_NAME: 'First name is required',
    LAST_NAME: 'Last name is required',
    PASSWORD: 'Password is required',
    CONFIRM_PASSWORD: `Confirm password is required`,
    STRONG_PASSWORD: `Your password must have atleast 6 Characters 1 Lowercase, 1 Uppercase, 1 Number, 1 SpecialCharacter`,
    EQUAL_PASSWORD: `Passwords don't match`,
    DEPARTMENT_NAME: `Department name is required`,
  },
}

// P

export const PLACEHOLDERS = {
  SEARCH_PLACEHOLDER: 'Start typing to search',
}

// T

export const TABLE = {
  HEADER: {
    NAME: 'Name',
    STATUS: 'Status',
    TITLE: 'Title',
    PERMISSIONS: 'Permissions',
    DEPARTMENT: 'Department',
    MANAGER: 'Manager',
    EMAIL: 'Email',
  },
  DEPARTMENT_HEADER: {
    NAME: 'Name',
    MEMBERS: 'Members',
    DEPARTMENT_HEADS: 'Head',
    DEPARTMENT_POOL: 'Pool Amount',
    DEPARTMENT_POOL_NAME: 'Department Name',
  },
  USER_ATTRIBUTES: {
    ATTRIBUTE: 'Attribute',
    VISIBILITY: 'Visibility',
    ASSIGNED: 'Assigned (people)',
    ARCHIVED: 'Archived',
  },
  PERMISSIONS_CUSTOM: {
    CUSTOME_ROLE: 'Custom Role',
    MEMBERS: 'Members',
  },
  GOAL_CYCLES_HEADER: {
    GOAL_CYCLE: 'Goal Cycle',
    DURATION: 'Duration',
    GOALS: 'Goals',
    NOTIFICATIONS: 'Notifications',
  },
  EMPLOYEE_BONUS_HEADER: {
    SALARY: 'Salary',
    MANAGER: 'Manager',
    DEPARTMENT: 'Department',
    AGE: 'Age',
    GENDER: 'Gender',
    ETHNICITY: 'Ethnicity',
    JOB_TITLE: 'Job Title',
    JOB_LEVEL: 'Job Level',
    HIRE_DATE: 'Hire date',
    BONUS: 'Bonus',
    RATINGS: 'Overall Assessment',
  },
  EMPLOYEE_WORKHISTORY_HEADER: {
    JOB_TITLE: 'Job Title',
    JOB_LEVEL: 'Job Level',
    DEPARTMENT: 'Department',
    MANAGER: 'Manager',
    SALARY: 'Salary',
    START_DATE: 'Start Date',
    END_DATE: 'End Date',
  },
}

export const TABS = {
  ALL: 'All',
  ACTIVE: 'Active',
  CANCELLERD: 'Cancelled',
  CREATED: 'Created',
  DEACTIVE: 'Deactive',
  PENDING: 'Pending',
  LOADED: 'Loaded',
  SYNC: 'Sync',
}

// U

export const USER = {
  ROLE: {
    ADMIN: 'Admin',
  },
  STATUS: {
    ACTIVE: 'active',
    INACTIVE: 'inactive',
    INVITED: 'invited',
  },
}
