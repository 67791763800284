import { combineReducers } from 'redux'
import userReducer from 'state/redux/reducers/user.reducers'
import settingsReducer from 'state/redux/reducers/settings.reducer'
import companyReducer from 'state/redux/reducers/company.reducers'

export default combineReducers({
  user: userReducer,
  settings: settingsReducer,
  company: companyReducer,
})
