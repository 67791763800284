/* eslint-disable @typescript-eslint/no-unused-vars */
//SORTING FOR PARTICULAR FIELDS

import Tooltip from 'components/atoms/Tooltip/Tooltip'
import 'components/organisms/Table/_table.scss'
import useIsMobile from 'hooks/useIsMobile'
import { avatarInitials } from 'lib/utils/avatarInitials'
import { isEmpty } from 'lodash'
import camelCase from 'lodash/camelCase'
import map from 'lodash/map'
import { IoMdArrowDropright } from 'react-icons/io'

interface TableConfig {
  loading?: boolean
  onSort?: () => void
  sortMethod?: string
  setOnMembers?: any
  tableWidth?: any
  changeColWidth?: boolean
  config?: {
    headers?: { textColor?: string; bgColor?: string; textSize?: string; textCase?: string; textWeight?: string }
    dataList?: {
      link?: boolean
      items?: { [key: string]: any }[]
      withImage?: React.ReactNode
      showTags?: boolean
      tagTitle?: string
      withTitle?: React.ReactNode
      onItemClick?: (...args: any[]) => void
      textColor?: string
      dropdown?: (item?: any) => void | React.ReactNode
      button?: (item?: any) => void | React.ReactNode
      bgColor?: string
      pattern?: string
      textSize?: string
      patternConfig?: { firstColor?: string; secondColor?: string }
    }
  }
  headers: string[]
  dataList: { [key: string]: any }[]
  ancestor?: string
}

const Table = (props: TableConfig) => {
  const {
    dataList,
    headers,
    config,
    loading,
    onSort,
    sortMethod,
    setOnMembers,
    tableWidth,
    changeColWidth = false,
    ancestor,
  } = props

  const loadingList = [
    { id: '1', fullName: '', title: '', permissions: '' },
    { id: '2', fullName: '', title: '', permissions: '' },
  ]
  const {
    textColor: hTextColor,
    bgColor: hBgColor,
    textSize: hTextSize,
    textCase: hTextCase,
    textWeight: hTextWeight,
  } = config?.headers || {} // h = header
  const {
    textColor: bTextColor,
    pattern,
    patternConfig,
    dropdown,
    button,
    onItemClick,
    withImage,
    showTags = false,
    tagTitle = 'Head',
    withTitle = false,
    textSize: bTextSize,
    link = false,
  } = config?.dataList || {} // b = body
  let colWidth = `${100 / headers.length}%`

  if (changeColWidth) {
    colWidth = `${40 / (headers.length - 2)}%`
  }

  const isMobile = useIsMobile()

  return (
    <div className="flex flex-col">
      <div
        style={{
          width: isMobile ? 'calc(100% - 1px)' : 'unset',
          overflowX: isMobile ? 'scroll' : 'unset',
          zoom: isMobile ? '0.8' : '0',
        }}
        className="py-2 align-middle inline-block min-w-full"
      >
        <div className={`sm:rounded-lg`}>
          <table
            className={`${!isEmpty(tableWidth) ? tableWidth : 'min-w-full'} divide-y-0 divide-gray-700 font-roboto`}
          >
            <thead className={`${hBgColor} border-b `}>
              <tr>
                {map(headers, (header, idx) => (
                  <th
                    key={header}
                    scope="col"
                    style={header === 'Goal Cycle' || header === 'Duration' ? { width: '30%' } : { width: colWidth }}
                    className={`${hTextColor || 'text-gray-500'} px-2 py-4 ${
                      header === 'dropdown' || header === 'Members' || header === 'count'
                        ? 'text-right pr-4'
                        : 'text-left'
                    } ${hTextSize || 'text-base'} ${hTextCase || 'uppercase'} ${
                      hTextWeight || 'font-bold'
                    } tracking-wider`}
                  >
                    {header === 'dropdown' || header === 'button' ? (
                      ''
                    ) : header === 'Name' ? (
                      <div
                        className="flex items-center cursor-pointer gap-x-1"
                        onClick={() => {
                          onSort()
                          if (setOnMembers) {
                            setOnMembers(false)
                          }
                          onSort()
                        }}
                      >
                        {header}{' '}
                        <IoMdArrowDropright
                          className={sortMethod === 'ASC' ? 'rotate-90 transform' : '-rotate-90 transform'}
                        />
                      </div>
                    ) : header === 'Members' ? (
                      <div
                        className="flex flex-row justify-center items-center cursor-pointer gap-x-1"
                        onClick={() => {
                          setOnMembers(true)
                          onSort()
                        }}
                      >
                        {header}{' '}
                        <IoMdArrowDropright
                          className={sortMethod === 'ASC' ? 'rotate-90 transform' : '-rotate-90 transform'}
                        />
                      </div>
                    ) : (
                      header
                    )}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody className="">
              {map(loading ? loadingList : dataList, (item, idx) =>
                loading ? (
                  <tr
                    role="button"
                    onClick={() => {
                      const item = config?.dataList?.items[idx]
                      onItemClick(item)
                    }}
                    className={` ${
                      bTextColor
                        ? bTextColor
                        : pattern === 'striped'
                        ? idx % 2 === 0
                          ? `${patternConfig?.firstColor}`
                          : `${patternConfig?.secondColor}`
                        : 'bg-transparent  hover:bg-gray-100 cursor-pointer  border-b border-gray-200 '
                    }`}
                    key={idx}
                  >
                    {map(headers, (header, _idx) => {
                      return (
                        <td
                          key={item.id + '-' + header}
                          style={
                            header === 'Goal Cycle' || header === 'Duration' ? { width: '30%' } : { width: colWidth }
                          }
                          className={`${bTextColor || 'text-gray-500'}  px-4 py-4  ${
                            header === 'dropdown' || header === 'button' ? 'text-right pr-4' : 'text-left'
                          } whitespace-nowrap ${bTextSize || 'text-base'}`}
                        >
                          <div className="h-4 bg-gray-200 animate-pulse duration-700 rounded"></div>
                        </td>
                      )
                    })}
                  </tr>
                ) : link ? (
                  <tr
                    role="button"
                    onClick={() => {
                      const item = config?.dataList?.items[idx]
                      onItemClick(item)
                    }}
                    key={idx}
                  >
                    <a
                      href="www.google.com"
                      className={` ${
                        bTextColor
                          ? bTextColor
                          : pattern === 'striped'
                          ? idx % 2 === 0
                            ? `${patternConfig?.firstColor}`
                            : `${patternConfig?.secondColor}`
                          : 'bg-transparent flex items-center hover:bg-gray-100 cursor-pointer  border-b border-gray-200 '
                      }`}
                    >
                      {map(headers, (header, _idx) => {
                        const value = item[camelCase(header.toLowerCase())]

                        return (
                          <td
                            key={item.id + '-' + header}
                            style={
                              header === 'Goal Cycle' || header === 'Duration' ? { width: '30%' } : { width: colWidth }
                            }
                            className={`${bTextColor || 'text-gray-500'}  px-2 py-4  ${
                              header === 'dropdown' || header === 'button' ? 'text-right pr-4' : 'text-left'
                            } whitespace-nowrap ${bTextSize || 'text-base'}`}
                          >
                            {header === 'dropdown' ? (
                              dropdown(config?.dataList?.items[idx])
                            ) : header === 'Name' && withImage ? (
                              <div className="flex items-center">
                                <div className="flex-shrink-0 h-10 w-10">
                                  {value.image ? (
                                    <img className="h-10 w-10 rounded-full" src={value.image} alt="" />
                                  ) : (
                                    <div
                                      style={{
                                        backgroundColor: avatarInitials(value.fullName || '').color,
                                      }}
                                      className="text-white rounded-full flex items-center  justify-center py-2"
                                    >
                                      {avatarInitials(value.fullName || '').initials}
                                    </div>
                                  )}
                                </div>
                                <div title={value.fullName} className="ml-4">
                                  <div className="text-sm font-medium text-gray-900 capitalize">{value.fullName}</div>
                                </div>
                              </div>
                            ) : (
                              value
                            )}
                          </td>
                        )
                      })}
                    </a>
                  </tr>
                ) : (
                  <tr
                    role="button"
                    onClick={() => {
                      const item = config?.dataList?.items[idx]
                      onItemClick(item)
                    }}
                    className={` ${
                      bTextColor
                        ? bTextColor
                        : pattern === 'striped'
                        ? idx % 2 === 0
                          ? `${patternConfig?.firstColor}`
                          : `${patternConfig?.secondColor}`
                        : 'bg-transparent  font-roboto hover:bg-gray-100  border-b border-gray-200 '
                    } ${onItemClick ? 'cursor-default' : 'cursor-pointer'}`}
                    key={idx}
                  >
                    {map(headers, (header, _idx) => {
                      const value = item[camelCase(header.toLowerCase())]
                      return (
                        <td
                          key={item.id + '-' + header}
                          style={
                            header === 'Goal Cycle' || header === 'Duration' ? { width: '30%' } : { width: colWidth }
                          }
                          className={`${bTextColor || 'text-black'}  px-2 py-4  ${
                            header === 'dropdown' || header === 'button' || header === 'Members' || header === 'count'
                              ? 'text-right pr-4'
                              : 'text-left'
                          } whitespace-nowrap ${bTextSize || 'text-base'}`}
                        >
                          {loading ? (
                            <div className="h-5 bg-gray-200 animate-pulse  rounded"></div>
                          ) : header === 'dropdown' ? (
                            dropdown(config?.dataList?.items[idx])
                          ) : header === 'Department' && ancestor === 'admin-employees' ? (
                            <>
                              {value?.length >= 2 ? (
                                <div>
                                  <div>{value[0]?.name}</div>
                                  <Tooltip
                                    text={value
                                      ?.slice(1 - value?.length)
                                      ?.map((dept) => dept?.name)
                                      .join(', ')}
                                    position="top"
                                  >
                                    <span className="bg-blue-300 py-1 px-2 rounded-full text-xs">
                                      + {value?.length - 1} more
                                    </span>
                                  </Tooltip>
                                </div>
                              ) : (
                                value?.map((dept) => <>{dept?.name}</>)
                              )}
                            </>
                          ) : header === 'button' ? (
                            button(config?.dataList?.items[idx])
                          ) : header === 'Name' && withImage ? (
                            <div className="flex items-center">
                              <div className="flex-shrink-0 h-10 w-10 text-base">
                                {value.image ? (
                                  <img className="h-10 w-10 rounded-full" src={value.image} alt="" />
                                ) : (
                                  <div
                                    style={{
                                      backgroundColor: avatarInitials(value.fullName || '').color,
                                    }}
                                    className="text-white rounded-full flex items-center  justify-center py-2"
                                  >
                                    {avatarInitials(value.fullName || '').initials}
                                  </div>
                                )}
                              </div>
                              <div title={value.fullName} className="ml-4">
                                <div className="font-semibold text-black capitalize">{value.fullName}</div>
                                {withTitle && (
                                  <div className="text-xs font-medium font-roboto text-gray-500 capitalize">
                                    {value.title}
                                  </div>
                                )}
                              </div>
                              {showTags
                                ? item.isHead && (
                                    <div className="ml-4 text-gray-700 bg-gray-200 rounded-md py-0.5 px-2">
                                      {tagTitle}
                                    </div>
                                  )
                                : ''}
                            </div>
                          ) : (
                            value
                          )}
                        </td>
                      )
                    })}
                  </tr>
                )
              )}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  )
}
export default Table
