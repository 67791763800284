/* eslint-disable @typescript-eslint/no-unused-vars */
import CreateOneOneModal from 'components/templates/Meeting/ContextApi/CreateOneOnOnModal'
import { useContext, useState, createContext } from 'react'

type defaultModalProps = {
  openModal: () => void
  closeModal: () => void
}

const defaultModal: defaultModalProps = {
  openModal: () => {},
  closeModal: () => {},
}
const CreateOneOnOneContext = createContext(defaultModal)

export function CreateOneOnOneContextProvider({ children }) {
  const [showModal, setShowModal] = useState(false)

  const openModal = () => {
    setShowModal(true)
  }

  const closeModal = () => {
    setShowModal(false)
  }

  return (
    <CreateOneOnOneContext.Provider
      value={{
        openModal,
        closeModal,
      }}
    >
      <CreateOneOneModal fetchList={() => {}} showModal={showModal} setShowModal={setShowModal} />
      {children}
    </CreateOneOnOneContext.Provider>
  )
}

export function useCreateOneOnOne() {
  const context = useContext(CreateOneOnOneContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
