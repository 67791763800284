import Button from 'components/atoms/Button/button'
import FeedbackEditor from 'components/atoms/Editor/FeedbackEditor'
import SharedNotesAccordion from 'components/atoms/SharedNotesAccordion'
import { AssignDropdown } from 'components/templates/Meeting/OneOne/Dialogs/AssignDropdown'
import { ReviewFeedbackCard } from 'components/templates/Review/Card/ReviewFeedbackCard'
import RequestedFeedbackList from 'components/templates/Review/List/RequestedFeedbacksList'
import Rating from 'components/templates/Review/Rating/Rating'
import Explore from 'components/templates/Review/ReviewGoals'
import { AssessmentBox, ValuesLoader } from 'components/templates/Review/ReviewSegment'
import { HttpRequest } from 'helpers/services'
import { useCompanyTools } from 'hooks/useCompanyTools'
import useIsMobile from 'hooks/useIsMobile'
import { get, isEmpty } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'

export const initial_employee_metrics = {
  feedbacks: {},
  goals: {},
  meetings: {},
  overview: {},
  reviews: {},
  todos: {},
}

const ManagerAppraisal = (props) => {
  const {
    isSelf,
    isManager,
    managerSubmitted,
    setManagerSubmitted,
    saveReview,
    isFetching,
    goalsManagerAssessment,
    setGoalsManagerAssessment,
    goalsSelfAssessment,
    hideGoalsAssessment,
    feedbackManagerAssessment,
    setFeedbackManagerAssessment,
    overallManagerAssessment,
    setOverallManagerAssessment,
    avgRating,
    onAvgRatingChange,
    company,
    managerValues,
    onManagerValuesRatingChange,
    employeeSubmitted,
    currentFeedbackCard,
    setCurrentFeedbackCard,
    isFeedbackFetching,
    feedback,
    savingReview,
    companyId,
    user,
    companyUserInfo,
    setFeedbackModalShow,
    refetch,
    setRefetch,
    hideFeedbackAssessment,
    reviews,
    managerGoalsList,
    onManagerGoalsRatingChange,
    userId,
    hideAssessment,
    setHideAssessment,
    setSelectedGoal,
    setViewModal,
    sharedNotes,
    onManagerValueAssessmentChange,
    ratingSys,
    valueRatingSys,
    excludedGoals,
    setExcludedGoals,
    setRequestType,
    skipLevelUserId,
    setSkipLevelUserId,
    isSkipLevelSignDone,
    setIsSkipLevelSignDone,
    setRefetchReview,
  } = props

  const [supporterEmp, setSupporterEmp]: any = useState('')
  const [managerValue, setManagerValue] = useState()
  const [ownerSearchQuery, setOwnerSearchQuery] = useState('')
  const [dropdownLoader, setDropdownLoader] = useState(false)
  const [employeeList, setEmployeeList]: any = useState()
  const personalInfo: any = useSelector((state) => get(state, 'user.personalInfo'))
  const [isInTeam, setIsInTeam] = useState(false)
  const [checkBoxDisabled, setCheckBoxDisabled] = useState(true)
  const [initialized, setInitialized] = useState(false)

  const userEmpId = personalInfo?.companyEmails

  const employeeWithEmail = userEmpId.find((email) => {
    return email.companyId === parseInt(companyId)
  })

  const employeeId = employeeWithEmail?.employeeId

  const { getEmployeeUserInfo } = useCompanyTools()
  const [empTeam, setEmpTeam] = useState<any>()

  const fetchEmployeeUserInfo = async () => {
    try {
      const data = await getEmployeeUserInfo(employeeId)
      setEmpTeam(data)
    } catch (e) {}
  }

  useEffect(() => {
    if (employeeId) {
      fetchEmployeeUserInfo()
    }
  }, [employeeId, company])

  const assessmentType = 'manager'

  const dispatch: any = useDispatch()
  const isMobile = useIsMobile()

  const location = useLocation()
  const pathname = location.pathname
  useEffect(() => {
    if (empTeam) {
      const inTeam = empTeam?.team.some((member) => member.employeeId === parseInt(userId))
      setIsInTeam(inTeam)
    }
  }, [empTeam, pathname])

  const [areasOfImprovement, setAreasOfImprovement] = useState('')

  const fetchAcknowledgement = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    const data = {}

    HttpRequest('GET', `/review/${reviews.id}`, data, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setAreasOfImprovement(data?.data?.improvementAreas)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    fetchAcknowledgement()
    if (reviews?.skipLevelSigner?.id === parseInt(employeeId)) {
      setCheckBoxDisabled(false)
    }
  }, [pathname])

  const acknowledge = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    const data = {
      improvementAreas: areasOfImprovement,
    }
    HttpRequest('POST', `/review/${reviews.id}`, data, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          dispatch(setSuccessMessage('Review Saved Successfully'))
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(setErrorMessages(err))
      })
  }

  const fetchEmployeeList = () => {
    setDropdownLoader(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-size': -1,
    }
    if (ownerSearchQuery) {
      commonHeader['search'] = ownerSearchQuery
    }
    HttpRequest('GET', '/company/employees/list', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          setDropdownLoader(false)
          const employees = data?.data
          if (employees) {
            setEmployeeList([
              {
                id: 'none',
                fullName: 'None',
                title: '',
                displayName: 'None',
                type: `assignedBy-none`,
              },
              ...employees,
            ])
            setSupporterEmp(employees.find((emp) => emp.id === skipLevelUserId) || '')
          }
        }
      })
      .catch((err) => {
        setDropdownLoader(false)
        console.error(err)
      })
  }

  useEffect(() => {
    fetchEmployeeList()
  }, [ownerSearchQuery])

  const [activeIndices, setActiveIndices] = useState([])

  const toggleAccordion = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((i) => i !== index))
    } else {
      setActiveIndices([...activeIndices, index])
    }
  }
  const expandAll = () => {
    const allIndices = Array.from({ length: managerValues.length }, (_, index) => index)
    setActiveIndices(allIndices)
  }

  const collapseAll = () => {
    setActiveIndices([])
  }

  useEffect(() => {
    if (initialized) {
      saveReview()
    }
  }, [isSkipLevelSignDone, initialized, skipLevelUserId, hideAssessment])

  return !isSelf ||
    (isSelf &&
      ((reviews?.skipLevelSigner && reviews?.isSkipLevelSignDone) || !reviews?.skipLevelSigner) &&
      managerSubmitted && !hideAssessment) ? (
    <>
      {/* Cards */}
      <div style={{ gap: '3rem' }} className={`${isMobile ? 'flex-col-reverse' : ''} h-auto rounded-lg bg-white`}>
        <div className="space-y-2 gap-4">
          {/* Company Values Card*/}
          <div
            style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
            className={`h-auto rounded-lg bg-white p-2 `}
          >
            <div className="flex items-center justify-between pt-4 px-4 gap-3">
              <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold`}>{company?.companyName} Values</h1>
              <div className="flex pointer-events-none cursor-not-allowed">
                {avgRating.values_manager > 0 && (
                  <p className="flex items-center justify-center text-xs py-1 px-2 mr-4 rounded-md bg-gray-100 shadow text-blue-800 font-bold">
                    {avgRating.values_manager}
                  </p>
                )}
                <Rating
                  defaultValue={avgRating.values_manager}
                  onClick={() => {}}
                  fillColor="#1F51FF"
                  ratingSys={ratingSys}
                  valueRatingSys={valueRatingSys}
                />
              </div>
            </div>

            <div style={{ width: '95%' }} className="bg-gray-200 h-0.5 mx-auto mt-3" />

            <div className={isMobile ? 'space-y-2' : 'p-4 space-y-2'}>
              {!isFetching ? (
                managerValues?.length > 0 ? (
                  managerValues.map((item, index) => (
                    <div key={index} className="flex flex-col">
                      <div className="flex justify-between">
                        <button
                          className={`flex items-center w-full ${isMobile ? '' : 'gap-2'} outline-none`}
                          onClick={() => toggleAccordion(index)}
                        >
                          <p
                            className={`${
                              isMobile ? 'text-xs' : 'border-l-2 border-themeGreen pl-2 text-sm'
                            } text-left truncate m-2 text-gray-900 font-semibold`}
                          >
                            {item?.companyValue?.value}
                          </p>
                          <span
                            className={`mr-5 transform transition-transform ${
                              activeIndices.includes(index) ? 'rotate-180' : 'rotate-0'
                            }`}
                          >
                            <svg
                              width="13"
                              height="9"
                              viewBox="0 0 15 9"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.11133 1.07812L7.11133 7.07812L13.1113 1.07812"
                                stroke="black"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              />
                            </svg>
                          </span>
                        </button>
                        <div
                          className={`${
                            isSelf || !checkBoxDisabled ? 'pointer-events-none cursor-not-allowed' : ''
                          } flex items-center`}
                        >
                          <Rating
                            defaultValue={item?.rating}
                            onClick={(value) => onManagerValuesRatingChange(value, index)}
                            fillColor="#ffd700"
                            isSelf={isSelf}
                            isManager={isManager}
                            employeeSubmitted={employeeSubmitted}
                            managerSubmitted={managerSubmitted}
                            assessmentType={assessmentType}
                            ratingSys={ratingSys}
                            valueRatingSys={valueRatingSys}
                          />
                        </div>
                      </div>

                      <div>
                        {activeIndices.includes(index) && (
                          <div className="mx-2 border-l-2 border-themeGreen">
                            <div className="px-2 pt-2">
                              <div>
                                <div className="mb-2">
                                  <p className={isMobile ? 'text-xs' : 'text-sm'}>{item?.companyValue?.description}</p>
                                </div>
                                <textarea
                                  name="value-assessment"
                                  id="value-assessment"
                                  rows={4}
                                  placeholder="Describe in brief how you demonstrated this value"
                                  value={item.assessment}
                                  onChange={(value) => onManagerValueAssessmentChange(value, index)}
                                  className="w-full resize-none rounded-md text-sm focus:outline-none focus:border-gray"
                                  readOnly={managerSubmitted || isSelf}
                                ></textarea>
                              </div>
                            </div>
                          </div>
                        )}
                      </div>
                    </div>
                  ))
                ) : (
                  <div className="flex justify-between border-l-2 border-themeGreen">
                    <p className="m-2 text-dark text-sm font-bold">No Company Values.</p>
                    <p className="m-2 text-black text-sm font-bold">-</p>
                  </div>
                )
              ) : (
                <ValuesLoader />
              )}
            </div>
            <div className="ml-6 mb-5 mr-5 flex justify-between">
              <div className="flex gap-4 items-end">
                <button
                  onClick={() => expandAll()}
                  className={`no-underline text-blue-500 outline-none ${isMobile ? 'text-xs' : 'text-sm'}`}
                >
                  Expand all
                </button>
                <button
                  onClick={() => collapseAll()}
                  className={`no-underline text-blue-500 outline-none ${isMobile ? 'text-xs' : 'text-sm'}`}
                >
                  Collapse all
                </button>
              </div>
              <div>
                <Button
                  disabled={isFetching || savingReview || !checkBoxDisabled}
                  size={isMobile ? 'sm' : 'md'}
                  btnColor="bg-blue-500"
                  hoverColor="bg-blue-500"
                  className="font-semibold w-20"
                  label={'Save'}
                  primary
                  onClick={() => saveReview()}
                />
              </div>
            </div>
          </div>
          {/* 360 Feedback Card*/}
          <div
            style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
            className={`h-auto rounded-lg bg-white p-2`}
          >
            <div className="flex items-center justify-between pt-4 px-4">
              <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold`}>360 Feedback</h1>
              {/* <div className={isSelf ? 'pointer-events-none cursor-not-allowed' : 'flex cursor-pointer'}> */}
              {avgRating.feedback_manager > 0 && (
                <p className="flex items-center justify-center text-xs py-1 px-2 mr-4 rounded-md bg-gray-100 shadow text-blue-800 font-bold">
                  {avgRating.feedback_manager}
                </p>
              )}
              {/* </div> */}
            </div>

            <div style={{ width: '95%' }} className="bg-gray-200 h-0.5 mx-auto mt-3" />

            {isManager && (
              <RequestedFeedbackList
                companyId={companyId}
                user={user}
                companyUserInfo={companyUserInfo}
                isInfoFetching={isFetching}
                setFeedbackModalShow={setFeedbackModalShow}
                refetch={refetch}
                setRefetch={setRefetch}
                type="manager"
                setRequestType={setRequestType}
              />
            )}

            <div className={isMobile ? 'mb-5' : 'px-4 mb-5'}>
              {isFeedbackFetching ? (
                <div className="my-4">
                  <ValuesLoader />
                </div>
              ) : feedback.length > 0 ? (
                <div className="bg-gray-100 text-gray-800 rounded py-4 px-2 mt-4 mb-4 space-y-3">
                  {feedback.map((f, idx) => (
                    <ReviewFeedbackCard
                      feedback={f}
                      idx={idx}
                      setCurrentCard={setCurrentFeedbackCard}
                      currentCard={currentFeedbackCard}
                      key={f.id + idx}
                    />
                  ))}
                </div>
              ) : (
                <></>
              )}
              {!isFetching && (
                <div>
                  <div className="py-2" />
                  <div className="py-2" />
                  {isManager && checkBoxDisabled && !managerSubmitted ? (
                    <>
                      <FeedbackEditor
                        bgColor={hideFeedbackAssessment ? 'rgb(255, 248, 235)' : '#fff'}
                        placeholder="Manager Assessment for Feedback"
                        value={feedbackManagerAssessment}
                        setValue={setFeedbackManagerAssessment}
                        label={''}
                        readonly={!checkBoxDisabled}
                      />
                    </>
                  ) : feedbackManagerAssessment ? (
                    <AssessmentBox type="manager" description={feedbackManagerAssessment} field="Feedbacks" />
                  ) : (
                    <div>
                      <p className={`block dark:text-white py-2 ${isMobile ? 'text-xs' : 'text-sm'} font-semibold`}>
                        Manager Assessment for feedback
                      </p>
                      <div className="h-16 w-full border-2 border-gray-400 rounded-md mt-1"></div>
                    </div>
                  )}
                </div>
              )}
            </div>
            <div className="mb-5 mr-5 flex items-center justify-end">
              <Button
                disabled={isFetching || savingReview || !checkBoxDisabled}
                size={isMobile ? 'sm' : 'md'}
                btnColor="bg-blue-500"
                hoverColor="bg-blue-500"
                className="font-semibold w-20"
                label={'Save'}
                primary
                onClick={() => saveReview()}
              />
            </div>
          </div>

          {/* Shared Notes Container */}
          <div
            style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
            className={`h-auto rounded-lg bg-white px-2`}
          >
            <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold pt-4 px-4`}>Shared Notes</h1>

            <div style={{ width: '95%' }} className="bg-gray-200 h-0.5 mx-auto mt-3" />

            {!isEmpty(sharedNotes) ? (
              <SharedNotesAccordion sharedNotes={sharedNotes} />
            ) : (
              <div className={`p-4 ${isMobile ? 'text-xs' : 'text-sm'}`}>
                There are no shared meeting notes during this cycle
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Goals Card*/}
      <div
        style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
        className={`${
          !checkBoxDisabled ? 'pointer-events-none cursor-not-allowed' : ''
        } h-auto rounded-lg bg-white px-2`}
      >
        <Explore
          isSelf={isSelf}
          isManager={isManager}
          employeeSubmitted={employeeSubmitted}
          managerSubmitted={managerSubmitted}
          assessmentType={assessmentType}
          goalsSelfAssessment={goalsSelfAssessment}
          fetching={isFetching}
          goalsList={managerGoalsList}
          avgRating={avgRating.avgGoal_manager}
          onAvgRatingChange={onAvgRatingChange}
          onGoalsRatingChange={onManagerGoalsRatingChange}
          userId={userId}
          isDisabled={isSelf}
          setSelectedGoal={setSelectedGoal}
          setViewModal={setViewModal}
          ratingSys={ratingSys}
          valueRatingSys={valueRatingSys}
          excludedGoals={excludedGoals}
          setExcludedGoals={setExcludedGoals}
          ancestor="manager-appraisal"
        />

        <div className="px-2 pb-6">
          {!isFetching && (
            <div>
              <div className="py-1" />
              <div className="py-1" />
              {isManager && !managerSubmitted ? (
                <>
                  <FeedbackEditor
                    bgColor={hideGoalsAssessment ? 'rgb(255, 248, 235)' : '#fff'}
                    placeholder="Manager Assessment for Goals"
                    value={goalsManagerAssessment}
                    setValue={setGoalsManagerAssessment}
                    label={''}
                    readonly={!checkBoxDisabled}
                  />
                </>
              ) : (
                <AssessmentBox type="manager" description={goalsManagerAssessment} field="Goals" />
              )}
            </div>
          )}
        </div>
        <div className="pb-5 mr-5 flex items-center justify-end">
          <Button
            disabled={isFetching || savingReview || !checkBoxDisabled}
            size={isMobile ? 'sm' : 'md'}
            btnColor="bg-blue-500"
            hoverColor="bg-blue-500"
            className="font-semibold w-20"
            label={'Save'}
            primary
            onClick={() => saveReview()}
          />
        </div>
      </div>

      {/* Areas of Improvement Card*/}
      <div className={` ${isMobile ? 'mt-5' : ''} w-full flex justify-start items-center mt-2`}>
        <div
          style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
          className="w-full rounded-lg bg-white mb-2 py-4 px-4"
        >
          <div className="flex justify-between items-center w-full">
            <h1 className={`${isMobile ? 'text-sm' : ''} font-bold mb-2`}>Areas of Improvement</h1>
          </div>
          <div className="w-full">
            <div className="mt-3">
              {!isSelf ? (
                <div>
                  <FeedbackEditor
                    bgColor="#fff"
                    placeholder="Reflect on this period and identify areas where the employee could potentially improve."
                    value={areasOfImprovement}
                    setValue={setAreasOfImprovement}
                    label={''}
                    readonly={!checkBoxDisabled}
                  />
                  <div className="pt-3 flex items-center justify-end">
                    <Button
                      disabled={isFetching || savingReview || !checkBoxDisabled}
                      size={isMobile ? 'sm' : 'md'}
                      btnColor="bg-blue-500"
                      hoverColor="bg-blue-500"
                      className="font-semibold w-20"
                      label={'Save'}
                      primary
                      onClick={() => acknowledge()}
                    />
                  </div>
                </div>
              ) : (
                <AssessmentBox type="" description={areasOfImprovement} field="" />
              )}
            </div>
          </div>
        </div>
      </div>

      {/* Overall Rating Card*/}
      <div className="w-full flex justify-start items-center">
        <div
          style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
          className="w-full rounded-lg bg-white mb-2 py-4 px-4"
        >
          <div className="flex justify-between items-center w-full mb-3">
            <h1 className={`${isMobile ? 'text-sm' : ''} font-bold`}>Overall Rating</h1>
            <div className={isSelf || !checkBoxDisabled ? 'pointer-events-none cursor-not-allowed -mr-3' : '-mr-3'}>
              <Rating
                defaultValue={Number(avgRating.overallRating)}
                onClick={(value) => setManagerValue(value)}
                fillColor="#1F51FF"
                isSelf={isSelf}
                isManager={isManager}
                employeeSubmitted={employeeSubmitted}
                managerSubmitted={managerSubmitted}
                assessmentType={assessmentType}
                ratingSys={ratingSys}
                valueRatingSys={valueRatingSys}
              />
            </div>
          </div>
          {isManager && checkBoxDisabled ? (
            <>
              <FeedbackEditor
                bgColor="#fff"
                value={overallManagerAssessment}
                setValue={setOverallManagerAssessment}
                label={''}
              />
              <div className="pt-3 flex items-center justify-end">
                <Button
                  disabled={isFetching || savingReview || !checkBoxDisabled}
                  size={isMobile ? 'sm' : 'md'}
                  btnColor="bg-blue-500"
                  hoverColor="bg-blue-500"
                  className="font-semibold w-20"
                  label={'Save'}
                  primary
                  onClick={() => {
                    saveReview('', '', managerValue)
                    setRefetchReview(true)
                  }}
                />
              </div>
            </>
          ) : (
            <AssessmentBox description={overallManagerAssessment} field="Overall" />
          )}
        </div>
      </div>

      {/* Confirmation Card*/}
      <div
        style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
        className={`h-full rounded-lg bg-white px-2 mt-4`}
      >
        <div
          className={`${
            isSelf || !checkBoxDisabled ? 'pointer-events-none cursor-not-allowed' : ''
          } flex items-center pt-4 px-4`}
        >
          <input
            onChange={(e) => {
              setInitialized(true)

              setHideAssessment(e.target.checked)
            }}
            className="focus:ring-0 form-check-input appearance-none h-4 w-4 border border-gray-500 rounded-sm bg-white checked:bg-blue-500 checked:border-blue-500 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
            type="checkbox"
            id={'hideAssessment'}
            checked={hideAssessment}
            value={'hideAssessment'}
          />
          <p className={`${isMobile ? 'text-xs' : 'font-semibold text-sm'}`}>Hide Assessment</p>
        </div>

        <div className="flex flex-col items-start justify-center space-y-4 pt-4 px-4">
          <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold`}>Manager Signoff</h1>
          <div className="flex flex-col space-y-4 pb-5">
            <p className={`${isMobile ? 'text-xs' : 'text-sm'} border-l-2 border-red-400 pl-3`}>
              The following checkbox indicates that the manager has completed the assessment.
            </p>
            <div className="ml-4 space-y-3">
              <div className="flex items-center justify-start">
                <input
                  style={{
                    opacity: isSelf ? '0.6' : '1',
                    cursor: isSelf ? 'not-allowed' : 'pointer',
                  }}
                  onChange={(e) => {
                    setManagerSubmitted(e.target.checked)
                    saveReview({ employee: employeeSubmitted, manager: e.target.checked })
                  }}
                  className="disabled:opacity-50 form-check-input appearance-none h-4 w-4 border border-gray-500 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                  type="checkbox"
                  id={'managerSubmitted'}
                  checked={managerSubmitted}
                  value={'managerSubmitted'}
                  disabled={isSelf || !checkBoxDisabled}
                ></input>
                <p className={isMobile ? 'text-xs' : 'text-sm'}>
                  {reviews?.manager?.fullName}{' '}
                  {managerSubmitted && moment(reviews?.managerSubmittedTime).isValid() && (
                    <span className="italic font-bold">({moment(reviews?.managerSubmittedTime).format('l')})</span>
                  )}
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="flex flex-col items-start justify-center space-y-2 pt-2 px-4">
          <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold`}>Skip Level Signoff</h1>
          <div className="flex items-center justify-start gap-1" id="skip-level-block">
            {isInTeam && (
              <AssignDropdown
                header={
                  <div className="w-full">
                    {supporterEmp?.fullName ? (
                      <div className="flex">
                        <input
                          style={{
                            opacity: checkBoxDisabled ? '0.6' : '1',
                            cursor: checkBoxDisabled ? 'not-allowed' : 'pointer',
                          }}
                          onChange={(e) => {
                            setIsSkipLevelSignDone(e.target.checked)
                            setInitialized(true)
                          }}
                          className="disabled:opacity-50 h-4 grid form-check-input appearance-none w-4 border border-gray-500 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center  mr-2  bg-contain float-left cursor-pointer"
                          type="checkbox"
                          id={'isSkipLevelSignDone'}
                          checked={isSkipLevelSignDone}
                          disabled={checkBoxDisabled}
                          onClick={(e) => e.stopPropagation()}
                        />
                        <p className={isMobile ? 'text-xs' : 'text-sm'}>{supporterEmp?.fullName}</p>
                      </div>
                    ) : (
                      <p style={{ cursor: !checkBoxDisabled ? 'not-allowed' : 'pointer' }} className="text-xs w-full">
                        Select Employee..
                      </p>
                    )}
                  </div>
                }
                ownerSuggestedList={employeeList?.map((employee) => ({
                  name: employee.fullName,
                  id: employee.id,
                  onClick: () => {
                    setSupporterEmp(employee.id === 'none' ? null : employee)
                    setSkipLevelUserId(employee.id === 'none' ? null : employee?.id)
                    setInitialized(true)
                  },
                }))}
                ownerSearchInput={ownerSearchQuery}
                setOwnerSearchInput={setOwnerSearchQuery}
                suggestionFetching={dropdownLoader}
                type="assessment-filter"
              />
            )}
            {!isInTeam && (
              <div className="flex w-full mt-2 ml-4 pb-4">
                <input
                  style={{
                    opacity: !reviews?.managerSubmitted || checkBoxDisabled ? '0.6' : '1',
                    cursor: !reviews?.managerSubmitted || checkBoxDisabled ? 'not-allowed' : 'default',
                  }}
                  onChange={(e) => {
                    setIsSkipLevelSignDone(e.target.checked)
                    setInitialized(true)
                  }}
                  className="disabled:opacity-50 h-4 grid form-check-input appearance-none w-4 border border-gray-500 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center  mr-2  bg-contain float-left cursor-pointer"
                  type="checkbox"
                  checked={isSkipLevelSignDone}
                  disabled={!reviews?.managerSubmitted || checkBoxDisabled}
                />
                <p style={{ cursor: 'not-allowed' }} className={isMobile ? 'text-xs' : 'text-sm'}>
                  {reviews?.skipLevelSigner ? reviews?.skipLevelSigner?.fullName : 'Select an employee..'}
                </p>
              </div>
            )}

            {reviews?.skipLevelSignedAt && (
              <span className="italic text-sm font-bold h-10 mt-3 px-auto">
                ({moment(reviews?.skipLevelSignedAt).format('l')})
              </span>
            )}
          </div>
        </div>
      </div>
    </>
  ) : (
    <>
      <div className="w-full flex justify-center font-bold">{hideAssessment ? 'Assessment is set as hidden.' : `Hidden until signoff(s) are done.`}</div>
    </>
  )
}

export default ManagerAppraisal
