import 'components/atoms/Tooltip/_tooltip.scss'
const Tooltip = ({ children, text, position = 'right', zIndex = 900, margin = '' }: any) => {
  return (
    <div
      style={{ zIndex: zIndex }}
      className="tooltip-btn"
      data-tooltip-margin={margin}
      data-tooltip={text}
      data-tooltip-location={position}
    >
      {children}
    </div>
  )
}

export default Tooltip
