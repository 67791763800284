import { isEmpty } from 'lodash'
import { useState } from 'react'

const useTabs = (tabs: { name: string; count?: number | string }[], setTab?: any) => {
  const [curTab, setCurTab] = useState(isEmpty(setTab) ? tabs[0]?.name : setTab)
  const helpers = tabs.map((tab) => tab?.name === curTab)
  const goTo = [...tabs.map((tab) => tab?.name)]

  return { curTab, setCurTab, helpers, goTo }
}

export default useTabs
