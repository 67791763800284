import * as types from 'state/redux/constants'

const initialState = {
  drawer: false,
  error: [],
  successMessage: '',
  loading: false,
}

const settingsReducer = (state = initialState, action: { type: any; msg: any; err: any; data: any }) => {
  switch (action.type) {
    case types.SET_DRAWER_TOGGLE:
      return {
        ...state,
        drawer: action.data,
      }
    case types.SET_ERROR_MESSAGE:
      return {
        ...state,
        error: [...action.data],
      }
    case types.SET_SUCCESS_MESSAGE:
      return {
        ...state,
        successMessage: action.data,
      }
    case types.SET_LOADING:
      return {
        ...state,
        loading: action.data,
      }

    default:
      return state
  }
}

export default settingsReducer
