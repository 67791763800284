import { useState } from 'react'
import { ExclamationCircleIcon } from '@heroicons/react/solid'
import { AiOutlineExclamationCircle, AiOutlineEye, AiOutlineEyeInvisible } from 'react-icons/ai'
import { isEmpty } from 'lodash'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { BsSearch } from 'react-icons/bs'
import useIsMobile from 'hooks/useIsMobile'

interface INormalFormInput {
  label?: string
  id?: string
  name?: string
  required?: boolean
  disabled?: boolean
  optional?: boolean
  type?: string
  suggestion?: string
  placeholder?: string
  error?: string
  className?: string
  value: string | undefined | number
  min?: number
  max?: number
  onChange?: (e: any) => void
  onKeyPress?: (e: any) => void
  gridClass?: string
  props?: any
  showPasswordButton?: boolean
  fullWidth?: boolean
  textarea?: boolean
  rows?: number
  cols?: number
  emailIcon?: boolean
  searchIcon?: boolean
  suggestionClass?: string
  tooltip?: string
  paddingRight?: string
  visibleIcon?: boolean
  onBlur?: () => void
  step?: number
  ancestor?: string
  widthClass?: string
}

const NormalFormInput = ({
  label,
  id,
  name,
  type = 'text',
  placeholder,
  error,
  value,
  onChange,
  onKeyPress,
  gridClass,
  required,
  min,
  max,
  suggestion = '',
  showPasswordButton = false,
  optional = false,
  fullWidth = false,
  textarea = false,
  className = '',
  disabled = false,
  rows = 4,
  cols = 100,
  emailIcon = false,
  searchIcon,
  suggestionClass = 'text-themeGray',
  tooltip = '',
  paddingRight = 'pr-10',
  visibleIcon = false,
  widthClass = '',
  onBlur,
  step,
  ancestor,
  ...props
}: INormalFormInput) => {
  const isMobile = useIsMobile()
  const errorClass = `border-red-300 text-red-900 placeholder-red-400 focus:outline-none focus:border-red-500`
  const [showPass, setShowPass] = useState(false)
  return (
    <div className={`${gridClass} ${fullWidth ? 'w-full' : ''}`}>
      <div className={`flex gap-x-2 ${isEmpty(label) ? 'my-0' : 'my-2'}`}>
        <label htmlFor={name} className={`${isMobile ? 'text-xs' : 'text-sm '} block dark:text-white font-semibold`}>
          {label} {required && <span className="text-red-500 dark:text-red-400 font-medium text-base">*</span>}
        </label>
        {tooltip.length > 0 && (
          <Tooltip text={tooltip} position="top">
            <AiOutlineExclamationCircle className="text-themeGray mx-1" />
          </Tooltip>
        )}
        {optional && (
          <span className="text-sm text-themeGray" id="email-optional">
            (Optional)
          </span>
        )}
      </div>

      <div className={`${widthClass}`}>
        <div className="file__close mt-1 relative rounded-md shadow-sm">
          {textarea ? (
            <textarea
              {...props}
              onBlur={onBlur}
              spellCheck={false}
              id={id}
              value={value}
              name={name}
              onChange={onChange}
              placeholder={placeholder}
              disabled={disabled}
              rows={rows}
              cols={cols}
              className={`${disabled && 'cursor-not-allowed opacity-50 bg-gray-100'}  block border w-full pr-10 ${
                error ? errorClass : 'border-gray-400'
              } sm:text-sm p-2 focus:ring-blue-500 focus:border-blue-500 focus:outline-none rounded-md dark:placeholder-gray-600 dark:bg-gray-800 dark:border-gray-700 dark:text-white ${className}`}
            />
          ) : (
            <>
              {emailIcon && (
                <div className="absolute inset-y-0 left-0 px-3 flex items-center rounded-l-md bg-themeBlue2 pointer-events-none">
                  <span className="text-themeGray">@</span>
                </div>
              )}
              <input
                {...props}
                onBlur={onBlur}
                spellCheck={false}
                id={id}
                value={value}
                name={name}
                min={min}
                max={max}
                onChange={onChange}
                onKeyPress={onKeyPress}
                disabled={disabled}
                placeholder={placeholder}
                type={showPasswordButton ? (showPass ? 'text' : 'password') : type}
                className={` ${disabled && 'cursor-not-allowed bg-gray-100 opacity-50'} ${
                  emailIcon && 'pl-12'
                } ${paddingRight} block w-full ${
                  error ? errorClass : 'border-gray-400'
                } text-xs sm:text-sm p-2 border border-gray-400 hover:border-blue-500 focus:border-blue-500 focus:outline-none transition-all rounded-md  dark:text-white ${className}`}
                step={type === 'number' ? step : null}
              />
              {searchIcon && (
                <div className="absolute inset-y-0 right-0 px-3 flex items-center pointer-events-none">
                  <BsSearch />
                </div>
              )}
            </>
          )}

          {ancestor !== 'counter' && (
            <div className="flex items-center absolute transition-all duration-200 inset-y-0 right-0 pr-3 ">
              {error && (
                <div className=" pointer-events-none">
                  <ExclamationCircleIcon className="h-5 w-5 text-red-500 dark:text-red-400" aria-hidden="true" />
                </div>
              )}
              {showPasswordButton && value && (
                <div className="close__icon ml-2" onClick={() => setShowPass(!showPass)}>
                  {showPass ? (
                    <AiOutlineEyeInvisible
                      className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-600"
                      aria-hidden="true"
                    />
                  ) : (
                    <AiOutlineEye
                      className="h-6 w-6 text-gray-500 cursor-pointer hover:text-gray-600"
                      aria-hidden="true"
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </div>
        {suggestion && <p className={`${suggestionClass} mt-2 text-xs`}>{suggestion}</p>}
        {error && (
          <p
            className="text-left mt-2 transition-all duration-200 text-sm text-red-600 dark:text-red-500"
            id={`${name || id}-error`}
          >
            {error}
          </p>
        )}
      </div>
    </div>
  )
}

export default NormalFormInput
