import React, { useCallback, useEffect, useState } from 'react'
import Button from 'components/atoms/Button/button'
import Modal from 'components/atoms/Modal'
import { Selector2, Selector3 } from 'components/atoms/formcompomnents/Selector'
import DatePickerInput from 'components/atoms/formcompomnents/DatePickerinput'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import { Toggle2 } from 'components/atoms/Toggle'
import { useDispatch, useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { HttpRequest } from 'helpers/services'
import { useParams } from 'react-router-dom'
import { validTimezones } from 'helpers/dateconvertors'
import moment from 'moment'
import { setUser } from 'state/redux/actions/user.actions'
import { durationFormattedList, formatDurationToMinutes, timeRangeFormattedList } from 'lib/utils'
import RecurringModal from 'components/organisms/People/OneOne/RecurringModal'
import { useOneOnOneTools } from 'hooks/useOneOnOneTools'
import { setSuccessMessage } from 'state/redux/actions/settings.actions'
import Avatar from 'components/atoms/Avatar'
import SearchBar2 from 'components/atoms/SearchBar/SearchBar2'
import MeetLogo from 'assets/logo/meet.png'
import TeamsLogo from 'assets/logo/teams.png'

const Days = [
  { id: 0, label: 'S', selected: false },
  { id: 1, label: 'M', selected: false },
  { id: 2, label: 'T', selected: false },
  { id: 3, label: 'W', selected: false },
  { id: 4, label: 'T', selected: false },
  { id: 5, label: 'F', selected: false },
  { id: 6, label: 'S', selected: false },
]

const CreateMeetingWithGoalModal = ({
  showModal,
  setShowModal,
  goal,
}: {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  goal: any
}) => {
  const { companyId, userId }: any = useParams()
  const dispatch: any = useDispatch()
  const [timeZone, setTimeZone] = useState(null)
  const [formError, setFormError]: any = useState({})
  const user: any = useSelector((state) => get(state, 'user.user'))
  const [isGoogleCalendarIntegrated, setIsGoogleCalendarIntegrated] = useState(false)
  const [isOutlookCalendarIntegrated, setIsOutlookCalendarIntegrated] = useState(false)
  const [googleCalendarUrl, setGoogleCalendarUrl] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [outlookEmail, setOutlookEmail] = useState(null)
  const [showRecurringModal, setShowRecurringModal] = useState(false)
  const [link, setLink] = useState(-1)
  const [isSaving, setIsSaving] = useState(false)

  const [generateLink, setGenerateLink] = useState('')
  const [manualLink, setManualLink] = useState('')
  const [isInstant, setIsInstant] = useState(false)
  const [isRecurring, setIsRecurring] = useState(false)
  const [location, setLocation] = useState('')
  const [duration, setDuration] = useState('')
  const [title, setTitle] = useState('')
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState('')
  const [days, setDays] = useState(Days)
  const [weeks, setWeeks] = useState('1')
  const [occurrences, setOccurrences] = useState('12')
  const [template, setTemplate] = useState<any>({
    id: 'a',
    name: '1:1 Meeting Agenda',
    value: 'default',
  })

  const [guestsSuggestionFetching, setGuestsSuggestionFetching] = useState(false)
  const [showGuestsSuggestions, setShowGuestsSuggestions] = useState(false)
  const [guestsSuggestedList, setGuestsSuggestedList] = useState([])
  const [guestsSearchInput, setGuestsSearchInput] = useState('')
  const [guests, setGuests] = useState([])

  const { createMeetingWithGoal } = useOneOnOneTools()

  const onCancel = () => {
    setShowModal(false)
  }

  const timeRangeList = timeRangeFormattedList()
  const durationList = durationFormattedList()

  const templateList = [
    {
      id: 'a',
      name: '1:1 Meeting Agenda',
      value: 'default',
    },
    {
      id: 'b',
      name: 'Default Template without Agenda',
      value: 'default_no_agenda',
    },
    {
      id: 'c',
      name: 'Team Meeting Agenda',
      value: 'team',
    },
    {
      id: 'd',
      name: 'Department Meeting Agenda',
      value: 'department',
    },
  ]

  const timeZoneList = [
    {
      id: 'a',
      name: moment().tz(validTimezones['EST']).isDST() ? 'EDT' : 'EST',
    },
    {
      id: 'b',
      name: moment().tz(validTimezones['CST']).isDST() ? 'CDT' : 'CST',
    },
    {
      id: 'c',
      name: moment().tz(validTimezones['MST']).isDST() ? 'MDT' : 'MST',
    },
    {
      id: 'd',
      name: moment().tz(validTimezones['PST']).isDST() ? 'PDT' : 'PST',
    },
    {
      id: 'e',
      name: 'IST',
    },
  ]

  const onTimeSelect = (item) => {
    setTime(item?.name)
  }

  const onDurationSelect = (item) => {
    setDuration(item?.name)
  }

  const onTimeZoneSelect = (item) => {
    setTimeZone(item?.name)
  }

  const onTemplateSelect = (item) => {
    setTemplate(item)
  }

  const validateForm = () => {
    let isValid = true
    let messages = {
      date: '',
      time: '',
      location: '',
      template: '',
    }

    if (date === null) {
      messages.date = 'Please enter a date'
      isValid = false
    }

    if (time === '') {
      messages.time = 'Please select time'
      isValid = false
    }

    if (template === '') {
      messages.location = 'Please select a template'
      isValid = false
    }

    setFormError({
      ...formError,
      ...messages,
    })
    return isValid
  }

  const onSave = () => {
    setIsSaving(true)

    const onError = () => {
      setIsSaving(false)
    }

    const onSuccess = () => {
      setShowModal(false)
      setDate(null)
      setTime('')
      setLocation('')
      setGenerateLink('zoom')
      setIsInstant(false)
      dispatch(setSuccessMessage('Meeting created successfully'))
      setIsSaving(false)
    }
    let timeAndDate: any = moment(date).format('YYYY-MM-DD') + 'T' + moment(time, ['h:mm A']).format('HH:mm')

    const recurrenceDays = days.filter((day) => day.selected).map((day) => day.id)

    const data = {
      meetingTime: timeAndDate,
      duration: formatDurationToMinutes(duration.trim()),
      location: location,
      isInstant: isInstant,
      template: template?.value,
      isRecurring: isRecurring,
      goalId: goal?.id,
      goalOwnerId: goal?.owner?.[0]?.owner?.id,
      title,
    }

    if (!isEmpty(guests)) {
      data['guests'] = guests.map((guest) => guest.id)
    }

    if (generateLink !== '' || manualLink !== '') {
      data['generateLink'] = manualLink ? manualLink : generateLink
    }

    if (isRecurring) {
      data['recurrence'] = {}
      data['recurrence']['occurences'] = occurrences
      data['recurrence']['weeks'] = weeks
      data['recurrence']['recurrenceDays'] = recurrenceDays
    }

    createMeetingWithGoal(data, companyId, onSuccess, onError)
  }

  const onSaveMeeting = () => {
    if (validateForm()) {
      onSave()
    }
  }

  const getMeetingTime = (d?) => {
    const date = d ? new Date(d) : new Date()
    return date.toLocaleTimeString([], {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  const fetchGoogleUrl = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-user-id': userId,
    }

    HttpRequest('GET', `/user/google-calendar-url`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const url = data?.data?.url
          setGoogleCalendarUrl(url)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }



  useEffect(() => {
    const token = user.googleIDToken
    setIsOutlookCalendarIntegrated(user.isOutlookCalendarSynced)
    setIsGoogleCalendarIntegrated(user.isGoogleCalendarSynced)
    setOutlookEmail(user.outlookEmail)
    if (token) {
      const userIntegratedEmail = JSON.parse(atob(token?.split('.')[1])).email
      setUserEmail(userIntegratedEmail)
    }
    setTimeZone(user.timeZone)
  }, [user])

  useEffect(() => {
    fetchGoogleUrl()
  }, [])

  const onGuestsChipCancel = (item) => {
    const newGuests = guests.filter((guest) => guest?.id !== item?.id)
    setGuests(newGuests)
  }

  const removeGuestsSearchAction = () => {
    setGuestsSearchInput('')
  }

  const fetchGuestsSuggestions = () => {
    setGuestsSuggestionFetching(true)
    const commonHeader = {
      search: guestsSearchInput,
      'x-appengine-company-id': companyId,
      'x-appengine-size': -1,
    }
    HttpRequest('GET', '/company/employees/list', {}, commonHeader)
      .then(({ data }: any) => {
        const employees = data?.data
        setGuestsSuggestedList(employees)
        setGuestsSuggestionFetching(false)
      })
      .catch((err) => {
        console.error(err)
        setGuestsSuggestionFetching(false)
      })
  }

  useEffect(() => {
    fetchGuestsSuggestions()
  }, [guestsSearchInput])

  useEffect(() => {
    if (showModal) {
      setTemplate({
        id: 'a',
        name: '1:1 Meeting Agenda',
        value: 'default',
      })
    }
  }, [showModal])

  useEffect(() => {
    setTime(getMeetingTime(new Date()).toUpperCase())
    setDuration('30 minutes')
    setDate(new Date())
  }, [showModal])

  useEffect(() => {
    if (isInstant) {
      setTime(getMeetingTime(new Date()).toUpperCase())
      setDate(new Date())
    }
  }, [isInstant])

  useEffect(() => {
    if (isRecurring) {
      setShowRecurringModal(true)
    }
  }, [isRecurring])

  return (
    <Modal
      max={{ w: 200 }}
      hwClassName={'p-0'}
      header={
        <div>
          <input
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Enter meeting title"
            className="block px-0 py-0 w-4/5 text-md bg-transparent border-0 appearance-none border-b border-transparent focus:outline-none focus:ring-0 focus:border-blue-500 peer"
          />
        </div>
      }
      open={showModal}
      setOpen={onCancel}
      zIndex={5001}
    >
      <RecurringModal
        days={days}
        setDays={setDays}
        setOccurrences={setOccurrences}
        setWeeks={setWeeks}
        occurrences={occurrences}
        weeks={weeks}
        showModal={showRecurringModal}
        setShowModal={setShowRecurringModal}
      />
      <div className="flex">
        <div className="w-8/12 h-full mr-8">
          <div className="flex space-x-3">
            <div className="">
              <DatePickerInput
                className="w-30"
                error={formError.date}
                required
                date={date}
                minDate={new Date()}
                setDate={setDate}
                label="Next meeting"
                disabled={isInstant}
              />
            </div>
            <div className="flex space-x-2">
              <Selector3
                required
                selectedItem={time}
                list={timeRangeList}
                label="Start Time"
                placeholder="Ex. 10:00"
                onSelect={onTimeSelect}
                buttonBoxClass="py-2.5 "
                error={formError.time}
                disabled={isInstant}
              />
              <Selector3
                width="flex-1"
                selectedItem={duration}
                list={durationList}
                label="Duration"
                placeholder="Ex. 30 minutes"
                onSelect={onDurationSelect}
                buttonBoxClass="mt-1 py-2.5"
                disabled={isInstant}
              />
              <div className="flex items-center">
                <Selector3
                  required
                  selectedItem={timeZone}
                  list={timeZoneList}
                  label=""
                  placeholder=""
                  onSelect={onTimeZoneSelect}
                  buttonBoxClass="mt-1 py-2.5 "
                  width="w-full pt-9 flex-1"
                  noMinWidth
                  disabled
                />
              </div>
            </div>
          </div>
          <div className="space-y-4 mt-4" style={{ zIndex: 1000 }}>
            <NormalFormInput
              className="h-10"
              placeholder={`Enter Location...`}
              value={location}
              label={'Location'}
              onChange={(e) => setLocation(e.target.value)}
              error={formError.location}
            />
            <div className="w-full relative">
              <SearchBar2
                onFocus={() => setShowGuestsSuggestions(true)}
                onBlur={() => setShowGuestsSuggestions(false)}
                onCancel={removeGuestsSearchAction}
                onChange={(value) => {
                  setGuestsSearchInput(value)
                }}
                value={guestsSearchInput}
                placeholder="Guests"
                withChip
                onChipCancel={onGuestsChipCancel}
                chipItem={guests}
                label="Guests"
              />
              {showGuestsSuggestions && (
                <div
                  className="w-full bg-white absolute mt-1 cursor-pointer overflow-auto shadow-lg max-h-60 rounded-md ring-1 ring-black ring-opacity-5"
                  style={{ zIndex: 1000 }}
                >
                  {guestsSuggestedList.map((item) => (
                    <div
                      className="flex items-center bg-white hover:bg-themeBlue2 max-h-32"
                      onMouseDown={() => {
                        setGuests((prev) => {
                          const isExist = prev.filter((o) => o.id === item.id)
                          if (!isEmpty(isExist)) return [...prev]
                          return [...prev, item]
                        })
                        setGuestsSearchInput('')
                      }}
                    >
                      <div className="px-4 py-2">
                        <Avatar
                          name={item?.fullName}
                          image={item?.profileImageThumbnailUrl}
                          withName
                          title={item?.jobTitle}
                          medium
                        />
                      </div>
                    </div>
                  ))}
                  {!guestsSuggestionFetching && guestsSearchInput.length > 0 && guestsSuggestedList.length === 0 && (
                    <p className="p-4 text-sm text-gray-900">No Guests found</p>
                  )}
                </div>
              )}
            </div>
            <div>
              <Selector2
                required
                selectedItem={template}
                list={templateList}
                label="Template"
                placeholder="Select Template"
                onSelect={onTemplateSelect}
                buttonBoxClass="mt-1 py-2.5 "
                width="w-full flex-1"
                error={formError.template}
              />
            </div>
          </div>
        </div>
        <div className="w-0.5 bg-gray-200"></div>
        <div className="w-4/12 h-full pl-4">
          <div className="py-1">
            <div className="flex items-center justify-between">
              <h4 className="text-sm text-black font-semibold">Instant Meeting</h4>
              <Toggle2 enabled={isInstant} setEnabled={setIsInstant} text="" />
            </div>
          </div>
          <div className="pt-2">
            <div className="flex items-center justify-between">
              <h4 className="text-sm text-black font-semibold">Recurring Meeting</h4>
              <Toggle2 enabled={isRecurring} setEnabled={setIsRecurring} text="" />
            </div>
            {isRecurring && (
              <span
                onClick={() => setShowRecurringModal(true)}
                className="hover:text-blue-600 cursor-pointer text-sm text-blue-500 underline"
              >
                Edit Recurrence
              </span>
            )}
          </div>
          <div className="mt-6 mb-4">
            <h4 className="mb-3 text-sm text-black font-semibold">Generate Meeting Link</h4>
            <div className="flex items-center space-x-8">
              <label className="inline-flex items-center">
                <input
                  disabled={!isGoogleCalendarIntegrated}
                  type="checkbox"
                  value="activedirectory"
                  checked={link === 1}
                  onChange={() => {
                    setLink((prev) => (prev === 1 ? -1 : 1))
                    setGenerateLink((prev) => (prev === 'googleMeet' ? '' : 'googleMeet'))
                  }}
                  className={`${
                    !isGoogleCalendarIntegrated ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } cursor-pointer text-blue-500 focus:outline-none focus:ring-0 focus:ring-blue-500`}
                />
                <span
                  className={`${
                    !isGoogleCalendarIntegrated ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } ml-2 text-sm`}
                >
                  <img className="w-8 h-6" alt="google meet" src={MeetLogo} />
                </span>
              </label>
              <label className="inline-flex items-center">
                <input
                  disabled={!isOutlookCalendarIntegrated}
                  type="checkbox"
                  value="activedirectory"
                  checked={link === 2}
                  onChange={() => {
                    setLink((prev) => (prev === 2 ? -1 : 2))
                    setGenerateLink((prev) => (prev === 'microsoftTeams' ? '' : 'microsoftTeams'))
                  }}
                  className={`${
                    !isOutlookCalendarIntegrated ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } text-blue-500 focus:outline-none focus:ring-0 focus:ring-blue-500`}
                />
                <span
                  className={`${
                    !isOutlookCalendarIntegrated ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } ml-2 text-sm`}
                >
                  <img className="w-9 h-8" alt="microsoft teams" src={TeamsLogo} />
                </span>
              </label>
            </div>
            <div className="pt-2">
              <NormalFormInput
                className="h-10"
                placeholder={`Enter Manual Link...`}
                value={manualLink}
                label={'Custom Meeting Link'}
                onChange={(e) => setManualLink(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="pt-8 mb-2  flex items-center justify-between w-full border-gray-200">
        {isGoogleCalendarIntegrated || isOutlookCalendarIntegrated ? (
          <p className="text-sm text-gray-700">
            * Calendar synced with{' '}
            <span className="font-semibold">
              {userEmail}
              {isGoogleCalendarIntegrated && isOutlookCalendarIntegrated && ','} {outlookEmail}
            </span>
          </p>
        ) : (
          <p className="text-sm text-gray-700">
            * No Calendar Integrated,{' '}
            <span onClick={() => (window.location.href = googleCalendarUrl)} className="text-blue-500 cursor-pointer">
              Click here
            </span>{' '}
            to sync
          </p>
        )}
        <Button
          size="md"
          hoverColor="bg-themeBlue"
          className="w-20 font-semibold"
          label={isSaving ? 'Saving' : 'Save'}
          primary
          disabled={isSaving || title === ''}
          onClick={onSaveMeeting}
        />
      </div>
    </Modal>
  )
}

export default CreateMeetingWithGoalModal
