/* eslint-disable @typescript-eslint/no-unused-vars */
import Button from 'components/atoms/Button/button'
import Dropdown2 from 'components/atoms/Dropdown/Dropdown2'
import DatePickerInput from 'components/atoms/formcompomnents/DatePickerinput'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import { Selector3 } from 'components/atoms/formcompomnents/Selector'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { HttpRequest } from 'helpers/services'
import { useEffect, useState } from 'react'
import { AiFillStar, AiOutlineCalendar, AiOutlineCloseCircle } from 'react-icons/ai'
import { IoLocationSharp } from 'react-icons/io5'
import { useDispatch, useSelector } from 'react-redux'
import CreateReviewModal from 'components/templates/Review/Modal/CreateReviewModal'
import { NavLink, useLocation, useNavigate } from 'react-router-dom'
import { get, isEmpty, merge, truncate } from 'lodash'
import { useCompanyTools } from 'hooks/useCompanyTools'
import { useTeams } from 'components/templates/People/ContextApi/TeamContext'
import RequestFeedbackModal from 'components/templates/Review/Modal/RequestFeedbackModal'
import { useReviewTools } from 'hooks/useReviewTools'
import moment from 'moment-timezone'
import parse from 'html-react-parser'
import { setSuccessMessage } from 'state/redux/actions/settings.actions'
import {
  durationFormattedList,
  formatDurationToMinutes,
  formatDurationToString,
  timeRangeFormattedList,
} from 'lib/utils'
import { CgTimelapse } from 'react-icons/cg'
import { validTimezones } from 'helpers/dateconvertors'
import { roundToHalf } from 'helpers/formatters'
import { FiExternalLink } from 'react-icons/fi'
import useTabs from 'components/organisms/Tabs/useTabs'
import SelfAppraisal from 'components/templates/Review/SelfAppraisal'
import AnimatedContainer from 'containers/AnimatedContainer'
import ManagerAppraisal from 'components/templates/Review/ManagerAppraisal'
import StepperTabs from 'components/atoms/StepperTabs'
import ReminderModal from 'components/organisms/ReminderModal'
import AppraisalAcknowledgement from 'components/organisms/AppraisalAcknowledgement'
import useIsMobile from 'hooks/useIsMobile'

export const ValuesLoader = () => {
  return (
    <div className="flex justify-between">
      <div className="space-y-4">
        <div className="w-40 h-6 bg-gray-200 animate-pulse rounded-md" />
        <div className="w-40 h-6 bg-gray-200 animate-pulse rounded-md" />
        <div className="w-40 h-6 bg-gray-200 animate-pulse rounded-md" />
      </div>
      <div>
        {[1, 2, 3].map((_) => (
          <div className="flex pb-2">
            <AiFillStar size={26} className="text-gray-200 animate-pulse" />
            <AiFillStar size={26} className="text-gray-200 animate-pulse" />
            <AiFillStar size={26} className="text-gray-200 animate-pulse" />
            <AiFillStar size={26} className="text-gray-200 animate-pulse" />
            <AiFillStar size={26} className="text-gray-200 animate-pulse" />
          </div>
        ))}
      </div>
    </div>
  )
}

export const InfoLoader = () => {
  return (
    <div className="py-5 flex flex-col justify-between">
      <div className="space-y-4">
        {[1, 2, 3, 4].map((_) => (
          <div className="space-y-2">
            <div className="w-60 h-7 bg-gray-300 animate-pulse rounded-md" />
            <div className="w-40 h-4 bg-gray-200 animate-pulse rounded-md" />
          </div>
        ))}
      </div>
    </div>
  )
}

export const formattedDate2 = (date: any, timeZone: any) => {
  const d = new Date(date)
  const time = moment.tz(moment(d.toUTCString()).toISOString(), validTimezones[timeZone]).format('LT')
  const day = moment.tz(moment(d.toUTCString()).toISOString(), validTimezones[timeZone]).format('ll')
  return `${day} @ ${time}`
}

export const AssessmentBox = ({ type = undefined, description, field }) => {
  let label =
    type === 'self'
      ? field === 'Feedbacks'
        ? 'Self Assessment for Feedback'
        : 'Self Assessment for Goals'
      : field === 'Feedbacks'
      ? 'Manager Assessment for Feedback'
      : 'Manager Assessment for Goals'

  if (field === 'Overall') {
    label = ''
  }

  return (
    <div>
      {type && <p className="block dark:text-white py-2 text-sm font-semibold">{label}</p>}
      <p className="border-2 border-gray-300 rounded-lg block dark:text-white my-2 px-2 py-2 text-xs">
        {parse(description ? description : type ? `No ${label}` : 'No comments yet')}
      </p>
    </div>
  )
}

export const initial_avg_rating = {
  overall_self: 0,
  values_self: 0,
  feedback_self: 0,
  avgGoal_self: 0,
  overall_manager: 0,
  values_manager: 0,
  feedback_manager: 0,
  avgGoal_manager: 0,
}

export const initial_employee_metrics = [
  { metric: 'meetings', value: '0' },
  { metric: 'tasks', value: '0' },
  { metric: 'assessments', value: '0' },
  { metric: 'feedback', value: '0' },
  { metric: 'goals', value: '0' },
]

export const ReviewSegment = ({
  companyId,
  onDataFromReviewSegment,
  showCreateReviewModal,
  setShowCreateReviewModal,
  selectedEmployee,
  setSelectedGoal,
  setViewModal,
  viewSidebar,
  setViewSidebar,
}: any) => {
  const loc = useLocation()
  const pathname = loc.pathname
  const userId = pathname.split('/')[4]

  const [isFetching, setIsFetching] = useState(false)
  const [isFeedbackFetching, setIsFeedbackFetching] = useState(false)
  const [isInfoFetching, setIsInfoFetching] = useState(false)
  const [refetch, setRefetch] = useState(false)
  const [selfValues, setSelfValues] = useState([])
  const [managerValues, setManagerValues] = useState([])
  const [feedbackModalShow, setFeedbackModalShow] = useState(false)
  const [managerSubmitted, setManagerSubmitted] = useState(false)
  const [employeeSubmitted, setEmployeeSubmitted] = useState(false)
  const [savingReview, setSavingReview] = useState(false)

  const [hideAssessment, setHideAssessment] = useState(true)

  const dispatch: any = useDispatch()
  const isMobile = useIsMobile()
  const [selfGoalsList, setSelfGoalsList] = useState([])
  const [managerGoalsList, setManagerGoalsList] = useState([])
  const [editDate, setEditDate] = useState(false)
  const [location, setLocation] = useState('')
  const [date, setDate] = useState(new Date())
  const [time, setTime] = useState('')
  const [duration, setDuration] = useState('')
  const [timeFormat, setTimeFormat] = useState('PM')
  const [createReviewLocation, setCreateReviewLocation] = useState('')
  const [createReviewDate, setCreateReviewDate] = useState(new Date())
  const [createReviewTime, setCreateReviewTime] = useState('')
  const [createReviewDuration, setCreateReviewDuration] = useState('')
  const [createReviewTimeFormat, setCreateReviewTimeFormat] = useState('PM')
  const [isInstant, setIsInstant] = useState(false)
  const [currentFeedbackCard, setCurrentFeedbackCard] = useState(-1)
  const [feedback, setFeedback] = useState([])
  const [reviewList, setReviewList] = useState<any>({})
  const [reviewCycle, setReviewCycle] = useState<any>({})
  const [reviews, setReviews] = useState<any>({})
  const [companyValuesSelfAssessment, setCompanyValuesSelfAssessment] = useState<any>('')
  const [companyValuesManagerAssessment, setCompanyValuesManagerAssessment] = useState<any>('')
  const [selfValueAssessment, setSelfValueAssessment] = useState('')
  const [managerValueAssessment, setManagerValueAssessment] = useState('')
  const [feedbackSelfAssessment, setFeedbackSelfAssessment] = useState<any>('')
  const [feedbackManagerAssessment, setFeedbackManagerAssessment] = useState<any>('')
  const [overallManagerAssessment, setOverallManagerAssessment] = useState('')
  const [goalsManagerAssessment, setGoalsManagerAssessment] = useState<any>('')
  const [goalsSelfAssessment, setGoalsSelfAssessment] = useState<any>('')
  const [avgRating, setAvgRating] = useState<any>(initial_avg_rating)
  const [timeZone, setTimeZone] = useState(null)
  const [navigateReviews, setNavigateReviews] = useState({
    prev: null,
    next: null,
  })
  const [error, setError] = useState('')
  const [participants, setParticipants] = useState([])
  const [title, setTitle] = useState('')
  const [dataToReview, setDataToReview] = useState({})
  const [isDataLoaded, setIsDataLoaded] = useState(false)
  const [checkBoxEditable, setCheckBoxEditable] = useState(true)
  const [employeeMetrics, setEmployeeMetrics] = useState(initial_employee_metrics)
  const [accomplishments, setAccomplishments] = useState('')
  const [careerAspirations, setCareerAspirations] = useState('')
  const [comments, setComments] = useState('')
  const [excludedGoals, setExcludedGoals] = useState([])
  const [ratingSys, setRatingSys] = useState([])
  const [valueRatingSys, setValueRatingSys] = useState([])
  const [manualLink, setManualLink] = useState('')
  const [generateLink, setGenerateLink] = useState('')
  const [requestType, setRequestType] = useState('')
  const [skipLevelSigned, setSkipLevelSigned] = useState()
  const [skipLevelUserId, setSkipLevelUserId] = useState('')
  const [isSkipLevelSignDone, setIsSkipLevelSignDone] = useState()
  const [refetchReview, setRefetchReview] = useState(false)

  const tabs = [
    { name: 'Self Assessment', status: employeeSubmitted ? 'complete' : 'pending' },
    { name: 'Manager Assessment', status: managerSubmitted ? 'complete' : 'pending' },
    { name: 'Acknowledgement', status: 'pending' },
  ]
  const { curTab, setCurTab, helpers, goTo } = useTabs(tabs)
  const [onSelf, onManager, onAcknowledgement, onComplete] = helpers

  const { fetchTeams, fetchTeamListContext } = useTeams()
  const { getReviews, getReview, postReviews, patchReviewDate, postReview } = useReviewTools()

  const [companyUserInfo, setCompanyUserInfo]: any = useState()
  const [company, setCompany]: any = useState()
  const [showReminderModal, setShowReminderModal] = useState(false)
  const [sharedNotes, setSharedNotes] = useState([])
  const user: any = useSelector((state) => get(state, 'user.user'))
  const { getCompanyUserInfo } = useCompanyTools()

  const isSelf = company?.employeeId === companyUserInfo?.myProfile?.employeeId
  const isManager = company?.employeeId !== companyUserInfo?.myProfile?.employeeId

  const fetchCompanyUserInfo = async () => {
    setIsInfoFetching(true)
    try {
      const data = await getCompanyUserInfo(userId)
      setCompanyUserInfo(data)
      setIsInfoFetching(false)
    } catch (e) {
      setIsInfoFetching(false)
    }
  }

  const getCompanyName = () => {
    user?.employee?.forEach((company) => {
      if (company.companyId === parseInt(companyId)) {
        setCompany(company)
      }
    })
  }

  useEffect(() => {
    fetchTeamListContext(true)
    fetchCompanyUserInfo()
  }, [userId])

  useEffect(() => {
    getCompanyName()
  }, [company, getCompanyName])

  const fetchReviews = (id?: string | number) => {
    setIsFetching(true)
    const onSuccess = (response) => {
      setReviewList(response)
      if (!isEmpty(response?.past) || !isEmpty(response?.future) || !isEmpty(response?.todays)) {
        const onReviewSuccess = (response) => {
          setReviews(response)
          setIsSkipLevelSignDone(response?.isSkipLevelSignDone)
          setSkipLevelUserId(response?.skipLevelSigner?.id)
          setIsFetching(false)
        }
        const currReviewId = response.future[0]
          ? response.future[0].id
          : response.todays[0]
          ? response.todays[0].id
          : response.past[0].id
        getReview(id ? id : currReviewId, companyId, onReviewSuccess)
      } else {
        setIsFetching(false)
      }
      setRefetchReview(false)
    }
    getReviews(userId, companyId, onSuccess)
  }

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => setError(''), 3000)
      return () => clearTimeout(timer)
    }
  }, [error])

  const createReviews = () => {
    const onSuccess = () => {
      setShowCreateReviewModal(false)
      fetchReviews()
    }
    let timeAndDate: any =
      moment(createReviewDate).format('YYYY-MM-DD') + 'T' + moment(createReviewTime, ['h:mm A']).format('HH:mm')

    const data = {
      isInstant,
      reviewTime: timeAndDate,
      duration: formatDurationToMinutes(createReviewDuration),
      reviewCycleId: reviewCycle?.id,
      location: createReviewLocation,
      targetEmployee: userId,
      meetingLink: manualLink ? manualLink : generateLink,
    }
    const onError = (message) => {
      setError(message)
    }
    postReviews(data, companyId, onSuccess, onError)
  }

  const onSave = () => {
    createReviews()
  }

  const formatReviews = (reviews: any, timeZone: any) => {
    if (reviews?.length) {
      return reviews.map((m) => {
        return {
          id: m?.id,
          name: `${m?.reviewCycle?.name} - ${formattedDate2(m?.reviewTime, timeZone)}`,
          isActive: true,
          onClick: () => fetchReviews(m?.id),
        }
      })
    }
  }

  const onUpdateReviewDate = () => {
    const onSuccess = () => {
      fetchReviews()
      setEditDate(false)
    }
    let timeAndDate: any = moment(date).format('YYYY-MM-DD') + 'T' + moment(time, ['h:mm A']).format('HH:mm')

    const data = {
      reviewTime: timeAndDate,
      duration: formatDurationToMinutes(duration.trim()),
      location,
    }
    patchReviewDate(reviews?.id, data, companyId, onSuccess)
  }

  const fetchFeedback = () => {
    setIsFeedbackFetching(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-size': 100,
    }

    const data = {
      isTeam: false,
      type: 'RECEIVED',
      isPending: false,
    }

    HttpRequest('POST', `/feedback/list/${userId}`, data, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const feedback = data?.data?.feedback
          if (feedback) {
            setFeedback(feedback)
          }
        }
        setIsFeedbackFetching(false)
      })
      .catch((err) => {
        setIsFeedbackFetching(false)
      })
  }

  const saveReview = (checkBox?, selfRating?, managerRating?) => {
    setSavingReview(true)
    const onSuccess = () => {
      dispatch(setSuccessMessage('Review Saved Successfully'))
      setSavingReview(false)
    }

    const data = {}

    data['excludedGoals'] = excludedGoals

    if (isSelf) {
      data['goalsSelfAssessment'] = goalsSelfAssessment
      data['companyValuesSelfAssessment'] = companyValuesSelfAssessment
      data['feedbackSelfAssessment'] = feedbackSelfAssessment
      data['accomplishments'] = accomplishments
      data['employeeCareerAspirations'] = careerAspirations
      data['employeeComments'] = comments
    }

    if (isManager) {
      data['goalsManagerAssessment'] = goalsManagerAssessment
      data['companyValuesManagerAssessment'] = companyValuesManagerAssessment
      data['feedbackManagerAssessment'] = feedbackManagerAssessment
      data['overallManagerAssessment'] = overallManagerAssessment
    }

    const updatedSelfValues = selfValues.map((value) => ({
      valueId: value.companyValue.id,
      rating: value.rating,
      assessment: value.assessment,
    }))
    const updatedManagerValues = managerValues.map((value) => ({
      valueId: value.companyValue.id,
      rating: value.rating,
      assessment: value.assessment,
    }))

    const updatedSelfGoals = selfGoalsList.map((goal) => ({ goalId: goal.id, rating: goal.rating }))
    const updatedManagerGoals = managerGoalsList.map((goal) => ({
      goalId: goal.id,
      rating: goal.rating,
      isExcluded: excludedGoals.includes(goal.id),
    }))

    if (selfValues) {
      data['selfValues'] = updatedSelfValues
    }
    if (managerValues) {
      data['values'] = updatedManagerValues
    }
    if (avgRating.avgGoal_self > 0) {
      data['selfGoals'] = updatedSelfGoals
    }
    if (avgRating.avgGoal_manager > 0) {
      data['goals'] = updatedManagerGoals
    }
    data['overallRating'] = managerRating || avgRating.overallRating
    if (selfRating) {
      data['overallSelfRating'] = selfRating
    }

    data['managerSubmitted'] = !isEmpty(checkBox) ? checkBox.manager : managerSubmitted
    data['employeeSubmitted'] = !isEmpty(checkBox) ? checkBox.employee : employeeSubmitted
    data['skipLevelSigner'] = skipLevelUserId
    data['isSkipLevelSignDone'] = isSkipLevelSignDone
    data['hideAssessment'] = hideAssessment

    const onError = (err) => {
      setSavingReview(false)
    }

    postReview(reviews?.id, data, companyId, onSuccess, onError)
  }

  const timeRangeList = timeRangeFormattedList()
  const durationList = durationFormattedList()
  const timeZoneList = [
    {
      id: 'a',
      name: moment().tz(validTimezones['EST']).isDST() ? 'EDT' : 'EST',
    },
    {
      id: 'b',
      name: moment().tz(validTimezones['CST']).isDST() ? 'CDT' : 'CST',
    },
    {
      id: 'c',
      name: moment().tz(validTimezones['MST']).isDST() ? 'MDT' : 'MST',
    },
    {
      id: 'd',
      name: moment().tz(validTimezones['PST']).isDST() ? 'PDT' : 'PST',
    },
    {
      id: 'e',
      name: 'IST',
    },
  ]

  const onTimeSelect = (item) => {
    setTime(item?.name)
  }

  const onTimeZoneSelect = (item) => {
    setTimeZone(item?.name)
  }

  const onDurationSelect = (item) => {
    setDuration(item?.name)
  }

  useEffect(() => {
    fetchReviews()
    fetchFeedback()
  }, [pathname])

  useEffect(() => {
    if (refetchReview) {
      fetchReviews()
    }
  }, [refetchReview])

  const formattedDate = (d: Date) => {
    const date = new Date(d)
    const newDate = moment.tz(moment(date.toUTCString()).toISOString(), validTimezones[user?.timeZone]).format('ll')
    const time = moment.tz(moment(date.toUTCString()).toISOString(), validTimezones[user?.timeZone]).format('LT')
    const day = moment.tz(moment(date.toUTCString()).toISOString(), validTimezones[user?.timeZone]).format('ddd')
    return (
      <>
        <strong>{day}</strong> {newDate} @ {time.split(' ')[0]} {time.split(' ')[1].toUpperCase()} {user?.timeZone}
      </>
    )
  }

  const onAvgRatingChange = (value, type, cb?) => {
    const newAvgRating = { ...avgRating }
    newAvgRating[type] = value
    setAvgRating(newAvgRating)
    saveReview('', newAvgRating.overall_self, newAvgRating.overallRating)
  }

  const onSelfValuesRatingChange = (value, index) => {
    const newValues = [...selfValues]
    newValues[index].rating = value
    setSelfValues(newValues)

    const avgValue = parseFloat((newValues.reduce((acc, cur) => acc + cur.rating, 0) / newValues.length).toFixed(1))
    const overallRating = parseFloat(
      (Number(avgValue + avgRating.avgGoal_self + avgRating.feedback_self) / 3).toFixed(1)
    )
    setAvgRating({ ...avgRating, values_self: roundToHalf(avgValue), overall_self: roundToHalf(overallRating) })
  }

  const onManagerValuesRatingChange = (value, index) => {
    const newValues = [...managerValues]
    newValues[index].rating = value
    setManagerValues(newValues)

    const avgValue = parseFloat((newValues.reduce((acc, cur) => acc + cur.rating, 0) / newValues.length).toFixed(1))
    const overallRating = parseFloat(
      (Number(avgValue + avgRating.avgGoal_manager + avgRating.feedback_manager) / 3).toFixed(1)
    )
    setAvgRating({ ...avgRating, values_manager: roundToHalf(avgValue), overall_manager: roundToHalf(overallRating) })
  }

  const onSelfGoalsRatingChange = (value, index) => {
    const newGoals = [...selfGoalsList]
    newGoals[index].rating = value
    setSelfGoalsList(newGoals)

    const avgGoal = parseFloat((newGoals.reduce((acc, cur) => acc + cur.rating, 0) / newGoals.length).toFixed(1))
    const overallRating = parseFloat((Number(avgRating.values_self + avgGoal + avgRating.feedback_self) / 3).toFixed(1))
    setAvgRating({ ...avgRating, avgGoal_self: roundToHalf(avgGoal), overall_self: roundToHalf(overallRating) })
  }

  const onManagerGoalsRatingChange = (value, index) => {
    const newGoals = [...managerGoalsList]
    newGoals[index].rating = value

    setManagerGoalsList(newGoals)

    const includedGoals = managerGoalsList?.filter((goal) => !goal.isExcluded)

    const avgGoal = parseFloat(
      (includedGoals.reduce((acc, cur) => acc + cur.rating, 0) / includedGoals.length).toFixed(1)
    )
    const overallRating = parseFloat(
      (Number(avgRating.values_manager + avgGoal + avgRating.feedback_manager) / 3).toFixed(1)
    )
    setAvgRating({ ...avgRating, avgGoal_manager: roundToHalf(avgGoal), overall_manager: roundToHalf(overallRating) })
  }

  const onSelfValueAssessmentChange = (value, index) => {
    const assessment = value.target.value
    setSelfValueAssessment(assessment)
    const newValues = [...selfValues]
    newValues[index].assessment = assessment
    setSelfValues(newValues)
  }

  const onManagerValueAssessmentChange = (value, index) => {
    const assessment = value.target.value
    setManagerValueAssessment(assessment)
    const newValues = [...managerValues]
    newValues[index].assessment = assessment
    setManagerValues(newValues)
  }

  const onCloseFeedbackModal = () => {
    setFeedbackModalShow(false)
  }

  const NavigateReviews = (type) => {
    if (type === 'dec') {
      fetchReviews(navigateReviews?.prev?.id)
    } else {
      fetchReviews(navigateReviews?.next?.id)
    }
  }

  const getLocation = (location: string) => {
    if (location.includes('https://') || location.includes('http://')) {
      if (location.includes('zoom.us')) {
        return (
          <NavLink
            to={{ pathname: location }}
            target="_blank"
            className={`${
              isMobile ? 'text-xs' : 'text-sm'
            } flex space-x-2 gap-2 items-center justify-center cursor-pointer`}
          >
            <FiExternalLink className="" /> Zoom
          </NavLink>
        )
      } else if (location.includes('teams.microsoft')) {
        return (
          <NavLink
            to={{ pathname: location }}
            target="_blank"
            className={`${
              isMobile ? 'text-xs' : 'text-sm'
            } flex space-x-2 gap-2 items-center justify-center cursor-pointer`}
          >
            <FiExternalLink className="" /> Microsoft Teams
          </NavLink>
        )
      } else if (location.includes('meet.google')) {
        return (
          <NavLink
            to={{ pathname: location }}
            target="_blank"
            className={`${
              isMobile ? 'text-xs' : 'text-sm'
            } flex space-x-2 gap-2 items-center justify-center cursor-pointer`}
          >
            <FiExternalLink className="" /> Google Meet
          </NavLink>
        )
      } else {
        return (
          <NavLink
            to={{ pathname: location }}
            target="_blank"
            className={`${
              isMobile ? 'text-xs' : 'text-sm'
            } flex space-x-2 gap-2 items-center justify-center cursor-pointer`}
          >
            <FiExternalLink className="" /> External Link
          </NavLink>
        )
      }
    } else {
      return (
        <span
          onClick={() => setEditDate(true)}
          className={`${
            isMobile ? 'text-xs' : 'text-sm'
          } flex space-x-2 gap-2 items-center justify-center cursor-pointer`}
        >
          <IoLocationSharp className="text-red-400" /> {truncate(location, { length: 12 })}
        </span>
      )
    }
  }

  const getTime = (time) => {
    const date = new Date(time)
    return moment.tz(moment(date.toUTCString()).toISOString(), validTimezones[user?.timeZone]).format('LT')
  }

  useEffect(() => {
    setDate(reviews?.reviewTime ? new Date(reviews?.reviewTime) : new Date())
    setTime(getTime(reviews?.reviewTime ? reviews?.reviewTime : new Date()).toUpperCase())
    setDuration(reviews?.duration ? formatDurationToString(`${reviews?.duration} minutes`) : '30 minutes')
    setTimeFormat(getTime(reviews?.reviewTime ? reviews?.reviewTime : new Date()).toUpperCase())
    setLocation(reviews?.location)
    setManagerSubmitted(reviews?.managerSubmitted)
    setSkipLevelSigned(reviews?.isSkipLevelSignDone)
    setEmployeeSubmitted(reviews?.employeeSubmitted)
    setCheckBoxEditable(reviews?.employeeSubmitted ? false : true)
    setSelfValues(reviews?.selfCompanyValues)
    setManagerValues(reviews?.companyValues)
    setCompanyValuesSelfAssessment(reviews?.companyValuesSelfAssessment ? reviews?.companyValuesSelfAssessment : '')
    setCompanyValuesManagerAssessment(
      reviews?.companyValuesManagerAssessment ? reviews?.companyValuesManagerAssessment : ''
    )
    setFeedbackSelfAssessment(reviews?.feedbackSelfAssessment ? reviews?.feedbackSelfAssessment : '')
    setFeedbackManagerAssessment(reviews?.feedbackManagerAssessment ? reviews?.feedbackManagerAssessment : '')
    setHideAssessment(reviews?.hideFeedbackAssessment)
    setGoalsSelfAssessment(reviews?.goalsSelfAssessment ? reviews?.goalsSelfAssessment : '')
    setGoalsManagerAssessment(reviews?.goalsManagerAssessment ? reviews?.goalsManagerAssessment : '')
    setOverallManagerAssessment(reviews?.overallManagerAssessment ? reviews?.overallManagerAssessment : '')
    setAccomplishments(reviews?.accomplishments)
    setCareerAspirations(reviews?.employeeCareerAspirations)
    setComments(reviews?.employeeComments)
    setExcludedGoals(reviews?.excludedGoals)
    const updatedManagerGoals = reviews?.goals?.map((item) => {
      return {
        ...item.goal,
        rating: item.rating,
        isExcluded: excludedGoals?.includes(item.goal.id),
      }
    })
    const updatedSelfGoals = reviews?.selfGoals?.map((item) => {
      return {
        ...item.goal,
        rating: item.rating,
      }
    })
    setSelfGoalsList(updatedSelfGoals)
    setManagerGoalsList(updatedManagerGoals)
    const avgGoal_self = updatedSelfGoals?.reduce((acc, cur) => acc + cur.rating, 0) / updatedSelfGoals?.length
    const avgGoal_manager = updatedManagerGoals?.reduce((acc, cur) => acc + cur.rating, 0) / updatedManagerGoals?.length
    const avgValue_self =
      reviews?.selfCompanyValues?.reduce((acc, cur) => acc + cur.rating, 0) / reviews?.selfCompanyValues?.length
    const avgValue_manager =
      reviews?.companyValues?.reduce((acc, cur) => acc + cur.rating, 0) / reviews?.companyValues?.length

    setAvgRating({
      values_self: isNaN(avgValue_self) ? 0 : roundToHalf(avgValue_self),
      values_manager: isNaN(avgValue_manager) ? 0 : roundToHalf(avgValue_manager),
      avgGoal_self: isNaN(avgGoal_self) ? 0 : roundToHalf(avgGoal_self),
      avgGoal_manager: isNaN(avgGoal_manager) ? 0 : roundToHalf(avgGoal_manager),
      feedback_self: reviews?.feedbackSelfRating ? roundToHalf(reviews?.feedbackSelfRating) : 0,
      feedback_manager: reviews?.feedbackRating ? roundToHalf(reviews?.feedbackRating) : 0,
      overall_self: isNaN(reviews?.overallSelfRating) ? 0 : roundToHalf(reviews?.overallSelfRating),
      overallRating: isNaN(reviews?.overallRating) ? 0 : roundToHalf(reviews?.overallRating),
    })

    const newData = { avgRating, company, selfValues, isManager, isSelf, feedbackSelfAssessment }
    setDataToReview(newData)
    onDataFromReviewSegment(newData)
  }, [reviews])

  useEffect(() => {
    const updatedManagerGoals = reviews?.goals?.map((item) => {
      return {
        ...item.goal,
        rating: item.rating,
        isExcluded: excludedGoals?.includes(item.goal.id),
      }
    })
    setManagerGoalsList(updatedManagerGoals)

    const includedGoals = updatedManagerGoals?.filter((goal) => !goal.isExcluded)

    const avgGoal = parseFloat(
      (includedGoals?.reduce((acc, cur) => acc + cur.rating, 0) / includedGoals?.length).toFixed(1)
    )
    const overallRating = parseFloat(
      (Number(avgRating.values_manager + avgGoal + avgRating.feedback_manager) / 3).toFixed(1)
    )
    setAvgRating({ ...avgRating, avgGoal_manager: roundToHalf(avgGoal), overall_manager: roundToHalf(overallRating) })
  }, [excludedGoals])

  useEffect(() => {
    setTimeZone(user?.timeZone ? user?.timeZone : 'No Timezone')
  }, [user])

  useEffect(() => {
    setCurTab('Self Assessment')
  }, [pathname])

  const fetchSharedNotes = () => {
    setIsFeedbackFetching(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
      size: 5,
      page: 1,
    }

    const data = {
      date: {
        start: new Date(reviews?.reviewCycle?.startDate),
        end: new Date(reviews?.reviewCycle?.endDate),
      },
    }

    HttpRequest('POST', `/company/employees/shared-notes/${userId}`, data, commonHeader)
      .then((res: any) => {
        setSharedNotes(res?.data?.data?.meetings)
        setIsFeedbackFetching(false)
      })
      .catch((err) => {
        console.error(err)
        setIsFeedbackFetching(false)
      })
  }

  useEffect(() => {
    fetchSharedNotes()
  }, [userId, reviews])

  const fetchRatingSysData = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    const data = {}

    HttpRequest('GET', `/review/ratings/list`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.data?.length > 0) {
          const sortedData = res?.data?.data?.sort((a, b) => a.id - b.id)
          setRatingSys(sortedData)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    fetchRatingSysData()
  }, [])

  const appraisalProps = {
    isSelf,
    isManager,
    managerSubmitted,
    setManagerSubmitted,
    saveReview,
    isFetching,
    goalsManagerAssessment,
    setGoalsManagerAssessment,
    goalsSelfAssessment,
    setGoalsSelfAssessment,
    feedbackManagerAssessment,
    setFeedbackManagerAssessment,
    feedbackSelfAssessment,
    setFeedbackSelfAssessment,
    overallManagerAssessment,
    setOverallManagerAssessment,
    avgRating,
    onAvgRatingChange,
    company,
    selfValues,
    managerValues,
    onSelfValuesRatingChange,
    onManagerValuesRatingChange,
    employeeSubmitted,
    setEmployeeSubmitted,
    companyValuesSelfAssessment,
    setCompanyValuesSelfAssessment,
    companyValuesManagerAssessment,
    setCompanyValuesManagerAssessment,
    currentFeedbackCard,
    setCurrentFeedbackCard,
    isFeedbackFetching,
    feedback,
    savingReview,
    companyId,
    user,
    companyUserInfo,
    setFeedbackModalShow,
    refetch,
    setRefetch,
    reviews,
    selfGoalsList,
    onSelfGoalsRatingChange,
    managerGoalsList,
    onManagerGoalsRatingChange,
    userId,
    hideAssessment,
    setHideAssessment,
    checkBoxEditable,
    setCheckBoxEditable,
    setSelectedGoal,
    setViewModal,
    selectedEmployee,
    selfValueAssessment,
    setSelfValueAssessment,
    managerValueAssessment,
    setManagerValueAssessment,
    onSelfValueAssessmentChange,
    onManagerValueAssessmentChange,
    sharedNotes,
    accomplishments,
    setAccomplishments,
    careerAspirations,
    setCareerAspirations,
    ratingSys,
    valueRatingSys,
    comments,
    setComments,
    excludedGoals,
    setExcludedGoals,
    setRequestType,
    setSkipLevelUserId,
    skipLevelUserId,
    skipLevelSigned,
    isSkipLevelSignDone,
    setIsSkipLevelSignDone,
    setRefetchReview,
  }

  useEffect(() => {
    if (!isEmpty(companyUserInfo)) {
      setParticipants([companyUserInfo?.managers, selectedEmployee])
    }
  }, [companyUserInfo])

  useEffect(() => {
    const loadData = () => {
      const newData = { avgRating, company, selfValues }
      setDataToReview(newData)
      setIsDataLoaded(true)
      onDataFromReviewSegment(newData)
    }

    loadData()
  }, [])

  useEffect(() => {
    if (isDataLoaded) {
      onDataFromReviewSegment(dataToReview)
    }
  }, [dataToReview, isDataLoaded, onDataFromReviewSegment])

  const handleTabChange = async (val) => {
    await setCurTab(val)
    if (isManager && val === 'Manager Assessment') {
      setViewSidebar(true)
    } else {
      setViewSidebar(false)
    }
  }

  useEffect(() => {
    setViewSidebar(false)
  }, [pathname])

  useEffect(() => {
    if (!isEmpty(companyUserInfo)) {
      setParticipants([companyUserInfo?.managers, companyUserInfo?.myProfile])
    }
  }, [companyUserInfo])

  const appraisalCompleteProps = {
    isSelf,
    managerSubmitted,
    employeeSubmitted,
    saveReview,
    setManagerSubmitted,
    setEmployeeSubmitted,
    setCheckBoxEditable,
    reviews,
    companyId,
  }

  return (
    <div className="flex flex-col space-y-10 pb-20">
      <RequestFeedbackModal
        manager={user?.fullName}
        employee={companyUserInfo}
        setOpen={setFeedbackModalShow}
        open={feedbackModalShow}
        onCloseModal={onCloseFeedbackModal}
        setRefetch={setRefetch}
        requestType={requestType}
      />
      <CreateReviewModal
        header={
          <div>
            <input
              value={title}
              onChange={(e) => {
                e.target.value === '' || e.target.value === undefined
                  ? setTitle(reviewCycle.name)
                  : setTitle(e.target.value)
              }}
              placeholder="Enter assessment title"
              className="block px-0 py-0 w-4/5 text-md bg-transparent border-0 appearance-none border-b border-transparent focus:outline-none focus:ring-0 focus:border-blue-500 peer"
            />
          </div>
        }
        time={createReviewTime}
        setTime={setCreateReviewTime}
        duration={createReviewDuration}
        setDuration={setCreateReviewDuration}
        date={createReviewDate}
        setDate={setCreateReviewDate}
        timeFormat={createReviewTimeFormat}
        setTimeFormat={setCreateReviewTimeFormat}
        isInstant={isInstant}
        setIsInstant={setIsInstant}
        showModal={showCreateReviewModal}
        setShowModal={setShowCreateReviewModal}
        location={createReviewLocation}
        setLocation={setCreateReviewLocation}
        reviewCycle={reviewCycle}
        setReviewCycle={setReviewCycle}
        error={error}
        onSave={onSave}
        participants={participants}
        manualLink={manualLink}
        setManualLink={setManualLink}
        generateLink={generateLink}
        setGenerateLink={setGenerateLink}
      />

      {isFetching ? (
        <div className="space-y-5">
          <div className="flex gap-x-5">
            <div className="h-10 w-full bg-gray-100 animate-pulse duration-700 rounded-md"></div>
            <div className="h-10 w-32 bg-gray-100 animate-pulse duration-700 rounded-md"></div>
            <div className="h-10 w-32 bg-gray-100 animate-pulse duration-700 rounded-md"></div>
          </div>
          <div className="h-8 w-40 bg-gray-100 animate-pulse rounded-md"></div>
          <div className="h-10 w-full bg-gray-100 animate-pulse rounded-md"></div>
        </div>
      ) : !isEmpty(reviewList?.past) || !isEmpty(reviewList?.future) || !isEmpty(reviewList?.todays) ? (
        <div>
          <div className="mb-4 pb-2 flex items-center justify-between gap-1">
            <div className="flex flex-row pt-2">
              <div className="flex items-center mr-3">
                <Dropdown2
                  boxWidth="w-90"
                  right
                  notFormatDate
                  zIndex={1001}
                  border
                  secondary
                  items={[
                    {
                      id: 'upcoming',
                      name: 'Upcoming',
                      children: formatReviews(reviewList?.future, user?.timeZone),
                    },
                    {
                      id: 'today',
                      name: 'Today',
                      children: formatReviews(reviewList?.todays, user?.timeZone)?.reverse(),
                    },
                    {
                      id: 'past',
                      name: 'Past',
                      children: formatReviews(reviewList?.past, user?.timeZone),
                    },
                  ]}
                  Icon={AiOutlineCalendar}
                />
              </div>
              <h1 className={`font-semibold ${isMobile ? 'text-sm' : 'text-md'}`}>Assessment Cycle : </h1>
              <div className="flex flex-col">
                <span className={`font-semibold ${isMobile ? 'text-sm' : 'text-md'} italic ml-1`}>
                  {reviews?.reviewCycle?.name}
                </span>
                <p className="text-xs">
                  <span className="italic ml-1">
                    {moment(reviews?.reviewCycle?.startDate).format('l')} -{' '}
                    {moment(reviews?.reviewCycle?.endDate).format('l')}
                  </span>
                </p>
              </div>
            </div>

            <div className="flex justify-between">
              <div className="flex items-center">
                <div className="mr-8 ml-5">
                  {isFetching ? (
                    <div className="flex items-center gap-4">
                      <div className="flex space-x-3">
                        <div className="max-w-24">
                          <div className="w-60 bg-gray-200 animate-pulse h-6 rounded-lg"></div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="flex items-center gap-2">
                      <Tooltip position="top" text="Change Details">
                        <h3
                          onClick={() => setEditDate(true)}
                          style={{
                            textDecoration: 'underline',
                            textDecorationColor: 'rgb(220, 225, 230)',
                            textDecorationStyle: 'dashed',
                            textUnderlineOffset: '6px',
                            lineHeight: isMobile ? '24px' : 'unset',
                          }}
                          className={`${isMobile ? 'text-xs' : 'text-base'} font-medium cursor-pointer`}
                        >
                          {formattedDate(date)}
                        </h3>
                      </Tooltip>
                      <Tooltip position="top" text="Review duration">
                        <span
                          onClick={() => setEditDate(true)}
                          className={`${isMobile ? 'text-xs' : 'text-sm'} cursor-pointer flex items-center gap-2`}
                        >
                          <CgTimelapse className="text-gray-600" /> {duration}
                        </span>
                      </Tooltip>
                      {location ? (
                        <Tooltip position="bottom" text={location}>
                          {getLocation(location)}
                        </Tooltip>
                      ) : (
                        ''
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          {editDate && (
            <div className="ml-4 mb-8 flex items-center gap-4">
              <div className="flex space-x-3">
                <div className="w-30">
                  <DatePickerInput
                    className="w-full text-sm py-2.5"
                    date={date}
                    minDate={new Date()}
                    setDate={setDate}
                  />
                </div>
                <div className="flex space-x-2">
                  <Selector3
                    width="w-30"
                    selectedItem={time}
                    list={timeRangeList}
                    placeholder="Start Time"
                    onSelect={onTimeSelect}
                    buttonBoxClass="mt-1 py-2.5"
                  />
                  <Selector3
                    width="w-40"
                    selectedItem={duration}
                    list={durationList}
                    placeholder="Duration"
                    onSelect={onDurationSelect}
                    buttonBoxClass="mt-1 py-2.5"
                  />
                  <Selector3
                    selectedItem={timeZone}
                    list={timeZoneList}
                    label=""
                    placeholder=""
                    onSelect={onTimeZoneSelect}
                    buttonBoxClass="mt-1 py-2.5 "
                    width="w-36"
                    noMinWidth
                    disabled
                  />
                </div>
                <div style={{ width: '9rem' }}>
                  <NormalFormInput
                    paddingRight="pr-2"
                    className="mt-1 py-2.5"
                    placeholder={`Enter Location...`}
                    value={location}
                    onChange={(e) => setLocation(e.target.value)}
                  />
                </div>
              </div>
              <Button
                className="h-10 font-bold ml-4 w-22"
                label="Reschedule"
                onClick={onUpdateReviewDate}
                btnColor="bg-blue-500"
                hoverColor="bg-blue-600"
                textColor="text-white"
                size="md"
              />
              <AiOutlineCloseCircle
                size={25}
                className="transition-all hover:text-blue-500 cursor-pointer ml-2 text-gray-700"
                onClick={() => {
                  setEditDate(false)
                  setDate(new Date(reviews?.reviewTime))
                  setLocation(reviews?.location)
                }}
              />
            </div>
          )}

          <div className="-mt-5 mb-5">
            <StepperTabs tabs={tabs} curTab={curTab} setCurTab={(val) => handleTabChange(val)} />
          </div>
          <AnimatedContainer show={onSelf}>{onSelf && <SelfAppraisal {...appraisalProps} />}</AnimatedContainer>
          <AnimatedContainer show={onManager}>
            {onManager && <ManagerAppraisal {...appraisalProps} />}
          </AnimatedContainer>
          <AnimatedContainer show={onAcknowledgement}>
            {onAcknowledgement && (
              <AppraisalAcknowledgement {...appraisalCompleteProps} avgRating={avgRating} ratingSys={ratingSys} />
            )}
          </AnimatedContainer>
        </div>
      ) : (
        <>
          <div className="flex justify-between">
            <div className="flex items-center">
              <p className="font-bold">
                {' '}
                {isSelf
                  ? ' Your manager has not scheduled your assessment yet.'
                  : 'No assessment scheduled with your direct report.'}
              </p>
            </div>
          </div>
        </>
      )}
      {showReminderModal && (
        <ReminderModal
          setShowReminderModal={setShowReminderModal}
          companyId={companyId}
          selectedEmployee={selectedEmployee}
          reviewId={reviews?.id}
        />
      )}
    </div>
  )
}
