import { upperCase } from 'lodash'
import moment from 'moment'

export const formateDate = () => {}

export const notificationDateFormat = (date) => {
  return `${(date.getMonth() < 9 ? '0' : '') + Number(date.getMonth() + 1)}/${
    (date.getDate() < 9 ? '0' : '') + date.getDate()
  }`
}

export const FeedbackDateFormat = (date) => {
  const now = moment(new Date())
  const newDate = moment(date)
  let returnData = null

  if (now.diff(newDate, 'days') > 7) {
    returnData = newDate.format('DD MMM YYYY')
  } else {
    returnData = newDate.fromNow()
  }

  return returnData
}

export const AuditingDateFormat = (date) => {
  return `${date.toLocaleString('en-gb', {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  })} - ${date.toLocaleTimeString([], {
    hour12: true,
    hour: '2-digit',
    minute: '2-digit',
  })}`
}

export const getTimeFormat = (time) => {
  return upperCase(
    new Date(time)
      .toLocaleTimeString([], {
        hour12: true,
        hour: '2-digit',
        minute: '2-digit',
      })
      .split(' ')[1]
  )
}

export const validTimezones = {
  EST: 'America/New_York',
  IST: 'Asia/Kolkata',
  MST: 'America/Denver',
  PST: 'America/Los_Angeles',
  CST: 'America/Chicago',

  EDT: 'America/New_York',
  MDT: 'America/Denver',
  PDT: 'America/Los_Angeles',
  CDT: 'America/Chicago',
}
