import { HttpRequest } from 'helpers/services'
import { useDispatch } from 'react-redux'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'

export const useOneOnOneTools = () => {
  const dispatch: any = useDispatch()

  const fetchMeetings = (id, sortType, sortMethod, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/meeting-room/meeting/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        const errMgs = err?.response?.data?.message
        dispatch(setErrorMessages([errMgs]))
        onError([errMgs])
      })
  }

  const fetchIndividualMeeting = (id, companyId, onSuccess, onError, sortType, sortMethod) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    if (sortType !== '') {
      commonHeader['x-appengine-sort-type'] = sortType
      commonHeader['x-appengine-sort-method'] = sortMethod
    }
    HttpRequest('GET', `/meeting-room/meeting/meeting/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
        onError()
      })
  }

  const checkActionItem = (itemId, isChecked, progress, priority, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest(
      'PATCH',
      `/meeting-room/action-item/is-checked/${itemId}`,
      { isChecked, progress, priority },
      commonHeader
    )
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const checkActionItem2 = (itemId, isChecked, progress, priority, privacy, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest(
      'PATCH',
      `/meeting-room/action-item/is-checked/${itemId}`,
      { isChecked, progress, priority, isPrivate: privacy },
      commonHeader
    )
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const checkTalkingPoint = (itemId, isChecked, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/meeting-point/is-checked/${itemId}`, { isChecked }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addTalkingPoint = (data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/meeting-point`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addActionItems = (data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/action-item`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deleteActionItem = (id, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('DELETE', `/meeting-room/action-item/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Action item deleted succesfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deleteTalkingPoint = (id, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('DELETE', `/meeting-room/meeting-point/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Agenda item deleted succesfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const dismissTalkingPoint = (id, point, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/meeting-point/${id}`, { point, isDismissed: true }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Previous Agenda item dismissed successfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addComments = (data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/meeting-point/comment`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const createMeetings = (data, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/meeting`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const createMeetingWithGoal = (data, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/meeting/goal`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deleteComment = (id, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('DELETE', `/meeting-room/meeting-point/comment/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const changeMeetingPointPosition = (itemId, pointsLength, position, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest(
      'PATCH',
      `/meeting-room/meeting-point/update-position/${itemId}`,
      { position, pointsLength },
      commonHeader
    )
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Succesfully reordered agenda item'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const updateMeetingPoint = (itemId, point, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/meeting-point/${itemId}`, { point }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const changeActionItemsPosition = (itemId, pointsLength, position, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest(
      'PATCH',
      `/meeting-room/action-item/update-position/${itemId}`,
      { position, pointsLength },
      commonHeader
    )
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Succesfully reordered action item'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const updateActionItem = (itemId, item, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/action-item/${itemId}`, { item }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const updateComment = (itemId, point, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/meeting-point/comment/${itemId}`, { point }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const actionItemAssignTo = (assignToId, dueDate, goal, actionItemId, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest(
      'PUT',
      `/meeting-room/action-item/assign-to`,
      { actionItemId, assignToId, dueDate, goalId: goal },
      commonHeader
    )
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Updated successfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addPrivateNotes = (meetingId, data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PUT', `/meeting-room/meeting/${meetingId}/private-notes`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addUpdateSharedNotes = (data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/meeting/shared-note`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const updateMeetingTime = (
    meetingId,
    type,
    meetingTime,
    duration,
    location,
    generateLink,
    title,
    companyId,
    onSuccess
  ) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    if (type.all) {
      commonHeader['all'] = true
    }

    HttpRequest(
      'PATCH',
      `/meeting-room/meeting/meeting-time/${meetingId}`,
      { meetingTime, duration, location, generateLink, title },
      commonHeader
    )
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const updateMeetingTitle = (
    meetingId,
    type,
    meetingTime,
    duration,
    location,
    generateLink,
    title,
    companyId,
    onSuccess
  ) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    if (type.all) {
      commonHeader['all'] = true
    }

    HttpRequest(
      'PATCH',
      `/meeting-room/meeting/meeting-time/${meetingId}`,
      { meetingTime, duration, location, generateLink, title },
      commonHeader
    )
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const cancelActivateMeeting = (meetingId, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/meeting/cancel-reactivate/${meetingId}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addToAgendaTalkingPoint = (pointId, meetingId, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/meeting-point/add-to-agenda/${pointId}`, { meetingId }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Added to Agenda Items successfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addToAgendaActionItems = (actionItemIds, meetingId, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/action-item/add-to-action/`, { meetingId, actionItemIds }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Added to Action Items successfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const fetchOneOnOne = (id, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/meeting-room/byId/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data?.meetingRoom
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
        onError()
      })
  }

  const getSyncedCalendarEvents = (companyId, onSuccess, currentDate, userId) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      duration: 'month',
      'current-date': currentDate,
      'user-id': userId,
    }
    HttpRequest('GET', `/meeting-room/meeting/synced-calendar-meetings`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deleteMeeting = (id, type, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    if (type.all) {
      commonHeader['all'] = true
    }

    HttpRequest('DELETE', `/meeting-room/meeting/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const fetchAssignedItems = (id, data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('POST', `/meeting-room/action-item/assigned-to-user/${id}`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const actionItems = res?.data?.data?.response?.actionItems
          const pagination = res?.data?.data?.response?.pagination
          onSuccess(actionItems, pagination)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const hideOldOneOnOne = (meetingRoomId, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/toggle-hide/${meetingRoomId}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const updateFeedback = (id, data, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/feedback/${id}`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const uploadAttachment = (id, data, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('POST', `/meeting-room/action-item/${id}/attachment`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
          dispatch(setSuccessMessage('File uploaded successfully'))
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const uploadPointAttachment = (id, data, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('POST', `/meeting-room/meeting-point/attachment/${id}`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
          dispatch(setSuccessMessage('File uploaded successfully'))
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const listAttachment = (id, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('GET', `/meeting-room/action-item/${id}/attachment`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const listPointAttachment = (id, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('GET', `/meeting-room/meeting-point/attachment/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deleteAttachment = (id, attachmentId, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('DELETE', `/meeting-room/action-item/${id}/attachment/${attachmentId}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deletePointAttachment = (id, attachmentId, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('DELETE', `/meeting-room/meeting-point/attachment/${id}/${attachmentId}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const uploadGoalsAttachment = (id, data, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('POST', `/goal/${id}/attachment`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const listGoalsAttachment = (id, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('GET', `/goal/${id}/attachment`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deleteGoalsAttachment = (id, attachmentId, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('DELETE', `/goal/${id}/attachment/${attachmentId}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const response = res?.data?.data
          onSuccess(response)
        }
      })
      .catch((err) => {
        onError(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const updateMeetingModerator = (meetingId, moderatorId, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/meeting/meeting-mod/${meetingId}`, { moderatorId }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Moderator updated successfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const fetchCustomTemplates = (companyId, onSuccess, onError, setLoading) => {
    setLoading(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/meeting-room/template', {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          setLoading(false)
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        onError()
      })
      .finally(() => setLoading(false))
  }

  const fetchMeetingGuidance = (companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/meeting-room/guidance', {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
        onError()
      })
  }

  const updateGuidance = (data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', '/meeting-room/guidance', data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Guidance updated successfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addItemComments = (itemId, data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/action-item/${itemId}/comment`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deleteItemComment = (itemId, id, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('DELETE', `/meeting-room/action-item/${itemId}/comment/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const updateItemComment = (itemId, commentId, comment, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/action-item/${itemId}/comment/${commentId}`, { comment }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addGoals = (data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/meeting-room/meeting-goal`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deleteGoals = (meetingId, id, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('DELETE', `/meeting-room/meeting-goal/${id}`, { meetingId }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const deletePreviousGoals = (previousMeetingId, id, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/meeting-room/meeting-goal/update-goal/${id}`, { previousMeetingId }, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const addToGoals = (goalId, meetingId, previousMeetingId, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest(
      'POST',
      `/meeting-room/meeting-goal/add-to-goal/${goalId}`,
      { meetingId, previousMeetingId },
      commonHeader
    )
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
          dispatch(setSuccessMessage('Added to Current Goals successfully'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  return {
    fetchMeetings,
    fetchIndividualMeeting,
    checkActionItem,
    checkActionItem2,
    checkTalkingPoint,
    addTalkingPoint,
    addActionItems,
    deleteActionItem,
    deleteTalkingPoint,
    addComments,
    deleteComment,
    createMeetings,
    changeMeetingPointPosition,
    changeActionItemsPosition,
    updateActionItem,
    updateMeetingPoint,
    updateComment,
    actionItemAssignTo,
    addPrivateNotes,
    addUpdateSharedNotes,
    updateMeetingTime,
    cancelActivateMeeting,
    addToAgendaTalkingPoint,
    addToAgendaActionItems,
    fetchOneOnOne,
    deleteMeeting,
    fetchAssignedItems,
    dismissTalkingPoint,
    hideOldOneOnOne,
    updateFeedback,
    uploadAttachment,
    listAttachment,
    deleteAttachment,
    uploadGoalsAttachment,
    listGoalsAttachment,
    deleteGoalsAttachment,
    updateMeetingModerator,
    fetchMeetingGuidance,
    fetchCustomTemplates,
    updateGuidance,
    addItemComments,
    deleteItemComment,
    updateItemComment,
    addGoals,
    deleteGoals,
    addToGoals,
    listPointAttachment,
    deletePointAttachment,
    uploadPointAttachment,
    deletePreviousGoals,
    createMeetingWithGoal,
    getSyncedCalendarEvents,
    updateMeetingTitle,
  }
}
