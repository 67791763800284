export const SET_USER_DATA = 'SET_USER_DATA'
export const SET_PERSONAL_INFO = 'SET_PERSONAL_INFO'
export const SET_COMPANY_INFO = 'SET_COMPANY_INFO'
export const SET_USER_PROFILE = 'SET_USER_PROFILE'
export const SET_VALUE = 'SET_VALUE'

export const SET_DRAWER_TOGGLE = 'SET_DRAWER_TOGGLE'
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE'
export const SET_SUCCESS_MESSAGE = 'SET_SUCCESS_MESSAGE'

export const SET_LOADING = 'SET_LOADING'
