import { XIcon } from '@heroicons/react/solid'
import { HttpRequest } from 'helpers/services'
import { useEffect, useState } from 'react'
import { TbDeviceAnalytics } from 'react-icons/tb'
import { metrics } from 'components/atoms/MetricCard'
import { useLocation } from 'react-router-dom'
import useIsMobile from 'hooks/useIsMobile'

export const initial_employee_metrics = {
  feedbacks: {},
  goals: {},
  meetings: {},
  overview: {},
  reviews: {},
  todos: {},
}

const AppraisalsMetricsSidebar = ({ viewMetricsSidebar, setViewMetricsSidebar, companyId, selectedEmployee }) => {
  const [employeeMetrics, setEmployeeMetrics] = useState(initial_employee_metrics)

  const location = useLocation()
  const isMobile = useIsMobile()
  const pathname = location.pathname
  const userId = pathname.split('/')[4]

  const metricsDataToArray = (objData) => {
    const res = []
    for (const key in objData) {
      if (objData.hasOwnProperty(key)) {
        const obj = {
          metric: key,
          value: objData[key],
        }
        res.push(obj)
      }
    }
    return res
  }

  const fetchMetrics = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    const data = {}

    HttpRequest('POST', `/company/employees/analytics/${userId}`, data, commonHeader)
      .then((res: any) => {
        setEmployeeMetrics(res.data.data)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    selectedEmployee && fetchMetrics()
  }, [userId])

  return (
    <div
      className={`fixed flex flex-col top-0 right-0 h-screen bg-white overflow-hidden transform ${
        viewMetricsSidebar ? 'translate-x-0 opacity-100 visible' : 'translate-x-10 opacity-0 invisible'
      } duration-150 transition-all`}
      style={{
        boxShadow: '-4px 2px 6px 0 rgba(212, 212, 212, 0.44)',
        width: isMobile ? '100%' : '400px',
        zIndex: 5000,
        zoom: isMobile ? '0.8' : 'unset',
        height: isMobile ? 'calc(100vh + 200px)' : '',
      }}
    >
      <div className="overflow-y-scroll flex space-y-6 flex-col flex-1 p-6">
        <div className="flex items-center justify-between">
          <div className="flex items-center content-center gap-3">
            <div>
              <TbDeviceAnalytics size={22} className=" text-xl" />
            </div>
            <div className="font-semibold text-xl">Analytics</div>
          </div>
          <XIcon
            className="h-6 w-6 text-gray-600 cursor-pointer"
            aria-hidden="true"
            onClick={() => setViewMetricsSidebar(false)}
          />
        </div>
        <div className="flex flex-col">
          <div className="mb-5">
            <p style={{ fontSize: '0.9rem' }} className="text-justify">
              These metrics indicate the number of meetings, feedback sessions, assessments, feedback received, and
              goals set during this assessment cycle.
            </p>
          </div>

          <div className="mb-4 w-full border border-gray-300 rounded-md">
            <div className="py-2 px-4 font-semibold text-sm">OVERVIEW</div>
            <div className="px-4 pb-2">
              {metricsDataToArray(employeeMetrics?.overview).map((metric) => (
                <div className="flex justify-between">
                  <div style={{ fontSize: '0.9rem' }}>
                    {metrics[metric.metric].toLowerCase().charAt(0).toUpperCase() + metrics[metric.metric].slice(1)}
                  </div>
                  <div className="font-semibold" style={{ fontSize: '0.9rem' }}>
                    {metric.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4 w-full border border-gray-300 rounded-md">
            <div className="py-2 px-4 font-semibold text-sm">MEETINGS</div>
            <div className="px-4 pb-2">
              {metricsDataToArray(employeeMetrics?.meetings).map((metric) => (
                <div className="flex justify-between">
                  <div style={{ fontSize: '0.9rem' }}>
                    {metrics[metric.metric].toLowerCase().charAt(0).toUpperCase() + metrics[metric.metric].slice(1)}
                  </div>
                  <div className="font-semibold" style={{ fontSize: '0.9rem' }}>
                    {metric.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4 w-full border border-gray-300 rounded-md">
            <div className="py-2 px-4 font-semibold text-sm">GOALS</div>
            <div className="px-4 pb-2">
              {metricsDataToArray(employeeMetrics?.goals).map((metric) => (
                <div className="flex justify-between">
                  <div style={{ fontSize: '0.9rem' }}>
                    {metrics[metric.metric].toLowerCase().charAt(0).toUpperCase() + metrics[metric.metric].slice(1)}
                  </div>
                  <div className="font-semibold" style={{ fontSize: '0.9rem' }}>
                    {metric.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4 w-full border border-gray-300 rounded-md">
            <div className="py-2 px-4 font-semibold text-sm">FEEDBACK</div>
            <div className="px-4 pb-2">
              {metricsDataToArray(employeeMetrics?.feedbacks).map((metric) => (
                <div className="flex justify-between">
                  <div style={{ fontSize: '0.9rem' }}>
                    {metrics[metric.metric].toLowerCase().charAt(0).toUpperCase() + metrics[metric.metric].slice(1)}
                  </div>
                  <div className="font-semibold" style={{ fontSize: '0.9rem' }}>
                    {metric.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4 w-full border border-gray-300 rounded-md">
            <div className="py-2 px-4 font-semibold text-sm">ASSESSMENTS</div>
            <div className="px-4 pb-2">
              {metricsDataToArray(employeeMetrics?.reviews).map((metric) => (
                <div className="flex justify-between">
                  <div style={{ fontSize: '0.9rem' }}>
                    {metrics[metric.metric].toLowerCase().charAt(0).toUpperCase() + metrics[metric.metric].slice(1)}
                  </div>
                  <div className="font-semibold" style={{ fontSize: '0.9rem' }}>
                    {metric.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="mb-4 w-full border border-gray-300 rounded-md">
            <div className="py-2 px-4 font-semibold text-sm">TASKS</div>
            <div className="px-4 pb-2">
              {metricsDataToArray(employeeMetrics?.todos).map((metric) => (
                <div className="flex justify-between">
                  <div style={{ fontSize: '0.9rem' }}>
                    {metrics[metric.metric].toLowerCase().charAt(0).toUpperCase() + metrics[metric.metric].slice(1)}
                  </div>
                  <div className="font-semibold" style={{ fontSize: '0.9rem' }}>
                    {metric.value}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AppraisalsMetricsSidebar
