import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { noop } from 'lodash'

const Modal = ({
  open = false,
  setOpen,
  children,
  header,
  onClose,
  disableBackdropClose = true,
  hideCloseBtn = false,
  hwClassName,
  bodyPaddingZero = false,
  zIndex = 5500,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
  header?: string | React.ReactNode
  disableBackdropClose?: boolean
  hideCloseBtn?: boolean
  onClose?: () => void
  hwClassName?
  fixedOverflow?: boolean
  bodyPaddingZero?: boolean
  zIndex?: number
}) => {
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        style={{ zIndex: zIndex }}
        security=""
        as="div"
        static
        className={`fixed inset-0 overflow-hidden`}
        open={open}
        onClose={!disableBackdropClose ? (onClose ? onClose : setOpen) : noop}
      >
        <div className={`font-roboto flex items-end justify-center min-h-screen text-center sm:block sm:p-0`}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              style={{ backdropFilter: 'blur(2px)' }}
              className="fixed inset-0 bg-black bg-opacity-40 transition-opacity"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out transformduration-300"
            enterFrom="opacity-0 translate-y-4 sm:scale-50"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in transformduration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:scale-50"
          >
            <div
              style={{ background: 'rgba(0,0,0,0.8)', backdropFilter: 'blur(2px)' }}
              className={`inline-block align-bottom px-4 pt-5  text-left overflow shadow-xl transform transition-all w-full h-screen sm:align-middle pb-4`}
            >
              {!hideCloseBtn && (
                <div style={{ top: '22px' }} className="hidden sm:block absolute right-0 pt-6 pr-6">
                  <button
                    type="button"
                    className="rounded-md text-gray-50 hover:text-blue-500 focus:outline-none "
                    onClick={() => (onClose ? onClose() : setOpen(false))}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              <div className={``}>
                {header && (
                  <div className={`modal-header border-b border-gray-600 pb-2 py-6`}>
                    <h3 className="text-white text-xl font-semibold">{header}</h3>
                  </div>
                )}
                <div className={'text-white pt-4'}>{children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default Modal
