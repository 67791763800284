/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useState, useEffect, createContext } from 'react'
import { HttpRequest } from 'helpers/services'

const difTeam = { fetchTeams: [], fetchTeamListContext: (isMyTeam?: boolean) => {}, refetchData: () => {} }
const TeamContext = createContext(difTeam)

export function TeamContextProvider({ children }) {
  const [fetchTeams, setFetchTeams] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [refetch, setRefetch] = useState(false)

  const companyId = parseInt(window.location.pathname.split('/')[2])

  const fetchTeamListContext = (isMyTeam?: boolean) => {
    setIsFetching(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-my-team': isMyTeam ? 'true' : 'false',
    }
    if (companyId) {
      HttpRequest('GET', '/team/get-all', {}, commonHeader)
        .then(({ data }: any) => {
          if (data.success) {
            const teams = data?.data?.team
            setFetchTeams(teams)
          }
          setIsFetching(false)
          setRefetch(false)
        })
        .catch((err) => {
          setIsFetching(false)
          setRefetch(false)
          console.error(err)
        })
    }
  }

  const refetchData = () => {
    setRefetch(true)
  }

  useEffect(() => {
    if (refetch) {
      fetchTeamListContext(true)
    }
  }, [refetch])

  return (
    <TeamContext.Provider
      value={{
        fetchTeams,
        fetchTeamListContext,
        refetchData,
      }}
    >
      {children}
    </TeamContext.Provider>
  )
}

export function useTeams() {
  const context = useContext(TeamContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
