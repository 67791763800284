import { lazy } from 'react'
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom'
import NotFound from 'pages/Common/NotFound'
import VerifyEmail from 'pages/authpages/VerifyEmail'
import AddToCalendar from 'pages/Meeting/AddToCalendar'
import GoogleCalendar from 'pages/Meeting/GoogleCalendar'
import OutlookCalendar from 'pages/Meeting/OutlookCalendar'
import Review from 'pages/Review/Review'
import Privacy from 'pages/Terms/Privacy'
import Terms from 'pages/Terms/Terms'
import CompanySettings from 'pages/CompanySettings/CompanySettings'
import CompanyChart from 'pages/Chart'
import Chart from 'pages/Chart/CompanyChart.jsx'

// Page components
const SetPassword = lazy(() => import('pages/authpages/SetPassword'))
const ForgotPassword = lazy(() => import('pages/authpages/ForgotPassword'))
const Profile = lazy(() => import('pages/Profile'))
const ProfileSettings = lazy(() => import('pages/Settings/Profile'))
const AccountSettings = lazy(() => import('pages/Settings/Account'))
const EducationSettings = lazy(() => import('pages/Settings/Education'))
const SkillSettings = lazy(() => import('pages/Settings/Skill'))
const NotificationsSettings = lazy(() => import('pages/Settings/Notifications'))
const Admin = lazy(() => import('pages/Admin/Admin'))
const MasterHomePage = lazy(() => import('pages/Home'))
const NewLoginPage = lazy(() => import('pages/authpages/NewLogin'))
const NewRegisterPage = lazy(() => import('pages/authpages/NewRegister'))
const GoalExplore = lazy(() => import('pages/Goal/Explore'))
const GoalCreate = lazy(() => import('pages/Goal/GoalCreate/GoalCreate'))
const GoalDetails = lazy(() => import('pages/Goal/GoalDetails/GoalDetails'))
const People = lazy(() => import('pages/People/People'))
const Meeting = lazy(() => import('pages/Meeting/Meeting'))
const Company = lazy(() => import('pages/Company/Company'))
const Analytics = lazy(() => import('pages/Analytics/Analytics'))
const Surveys = lazy(() => import('pages/Surveys/Surveys'))
const IndividualSurvey = lazy(() => import('pages/Surveys/IndividualSurvey'))
const ChatWindow = lazy(() => import('components/templates/Chat/ChatWindow'))

interface IProps {
  loading?: boolean
  isUser?: boolean
}

const LayoutRouter = ({ isUser }: IProps) => {
  return (
    <Router>
      <Routes>
        {isUser ? (
          <>
            {/* Test Pages */}
            <Route element={<MasterHomePage />} path="/home" />

            <Route element={<MasterHomePage />} path="/home/:companyId" />
            <Route element={<Navigate to="/home" replace />} path="/login" />
            <Route element={<Navigate to="/home" replace />} path="/register" />

            <Route element={<GoalExplore />} path="/goals/explore/:companyId" />
            <Route element={<GoalExplore />} path="/goals/explore/:companyId/user/:userId" />
            <Route element={<GoalExplore />} path="/goals/explore/:companyId/department/:departmentId" />
            <Route element={<GoalExplore />} path="/goals/explore/:companyId/team/:teamId" />
            <Route element={<GoalExplore />} path="/goals/explore/:companyId/subgoal/:subGoalId" />

            <Route element={<GoalCreate />} path="/goals/explore/:companyId/create" />
            <Route element={<GoalCreate />} path="/goals/explore/:companyId/edit/:goalId" />
            <Route element={<GoalDetails />} path="/goals/explore/:companyId/detail/:goalId" />

            <Route element={<Company />} path="/company/:companyId" />
            <Route element={<CompanyChart />} path="/company/:companyId/org-chart" />
            <Route element={<Chart />} path="/company/:companyId/chart" />
            <Route element={<Company />} path="/company/:companyId/departments" />
            <Route element={<Company />} path="/company/:companyId/teams" />
            <Route element={<Company />} path="/company/:companyId/departments/:id/members" />
            <Route element={<Company />} path="/company/:companyId/teams/:id/members" />
            <Route element={<CompanySettings />} path="/company/:companyId/employee-settings" />

            <Route element={<Analytics />} path="/analytics/:companyId/overview" />
            <Route element={<Analytics />} path="/analytics/:companyId/meetings" />
            <Route element={<Analytics />} path="/analytics/:companyId/feedback" />
            <Route element={<Analytics />} path="/analytics/:companyId/goals" />
            <Route element={<Analytics />} path="/analytics/:companyId/assessments" />
            <Route element={<Analytics />} path="/analytics/:companyId/tasks" />

            <Route element={<Surveys />} path="/active-surveys/:companyId" />
            <Route element={<IndividualSurvey />} path="/active-surveys/:companyId/survey/:surveyId" />

            <Route element={<Profile />} path="/profile/:companyId" />

            <Route element={<People />} path="/people/:companyId/*">
              <Route index element={<People />} />
              <Route element={<People />} path=":path" />
              <Route element={<People />} path=":path/:subpath" />
              <Route element={<People />} path=":path/:subpath/:subpath1" />
              <Route element={<People />} path=":path/:subpath/:subpath1/:subpath2" />
            </Route>

            <Route element={<Review />} path="/review/:companyId/*">
              <Route element={<Review />} index />
              <Route element={<Review />} path=":path" index />
              <Route element={<Review />} path=":path/:subpath/*" />
              <Route element={<Review />} path=":path/:subpath/:subpath1" />
              <Route element={<Review />} path=":path/:subpath/:subpath1/:subpath2" />
            </Route>

            <Route element={<Meeting />} path="/meeting/:companyId/*">
              <Route element={<Meeting />} index />
              <Route element={<Meeting />} path=":path" index />
              <Route element={<Meeting />} path=":path/:subpath/*" />
              <Route element={<Meeting />} path=":path/:subpath/:subpath1" />
              <Route element={<Meeting />} path=":path/:subpath/:subpath1/:subpath2" />
              <Route element={<Meeting />} path=":path/:subpath/:subpath1/:subpath2/:subpath3/:id" />
            </Route>

            <Route element={<Admin />} path="/admin/:companyId/*">
              <Route element={<Admin />} index />
              <Route element={<Admin />} path=":path" index />
              <Route element={<Admin />} path=":path/:subpath/*" />
              <Route element={<Admin />} path=":path/:subpath/:subpath1" />
              <Route element={<Admin />} path=":path/:subpath/:subpath1/:subpath2" />
            </Route>

            <Route element={<ChatWindow />} path="/chat/:companyId" />
            <Route element={<ProfileSettings />} path="/settings/profile" />
            <Route element={<AccountSettings />} path="/settings/account" />
            <Route element={<EducationSettings />} path="/settings/education" />
            <Route element={<SkillSettings />} path="/settings/skill" />
            <Route element={<NotificationsSettings />} path="/settings/notifications" />
            <Route element={<VerifyEmail />} path="/verifyEmail" />
            <Route element={<AddToCalendar />} path="/add-meeting-to-calendar/:id" />
            <Route element={<GoogleCalendar />} path="/google-calendar" />
            <Route element={<OutlookCalendar />} path="/outlook" />
            <Route element={<Privacy />} path="/privacy-policy" />
            <Route element={<Terms />} path="/terms-of-service" />
            <Route element={<Navigate to="/home/" replace />} path="/" />
          </>
        ) : (
          <>
            <Route element={<Privacy />} path="/privacy-policy" />
            <Route element={<Terms />} path="/terms-of-service" />
            <Route element={<NewLoginPage />} path="/login" />
            <Route element={<NewRegisterPage />} path="/register" />
            <Route element={<SetPassword />} path="/set-password" />
            <Route element={<ForgotPassword />} path="/forgot-password" />
            <Route element={<VerifyEmail />} path="/verifyEmail" />
            <Route element={<NewLoginPage />} path="/:companyName" />
            <Route element={<Navigate to="/login" replace />} path="/" />
          </>
        )}
        {/* Error Page */}
        <Route element={<NotFound />} path="*" />
      </Routes>
    </Router>
  )
}

export default LayoutRouter
