import { FiLogOut, FiSettings } from 'react-icons/fi'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { logout } from 'state/redux/actions/user.actions'

const Profile = ({ show, setState }) => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()
  const onLogout = () => {
    dispatch(logout())
    navigate('/login')
    localStorage.removeItem('company')
  }

  const handleClick = (opt: string) => {
    switch (opt) {
      case 'logout':
        onLogout()
        break
      case 'settings':
        navigate('/settings/profile')
        break
      default:
        break
    }
  }

  return (
    <div
      style={{ zIndex: 5498 }}
      className={`${
        show ? 'translate-x-0' : 'translate-x-full'
      } transition-all transform bg-gray-100 absolute top-0 h-screen w-full`}
    >
      <div className="pt-8 pb-12 px-8">
        <div
          onClick={() => handleClick('settings')}
          className="cursor-pointer text-md text-gray-700 flex items-center gap-3 pb-4 border-b-2 border-gray-300"
        >
          <FiSettings size={22} />
          <span>Manage Settings</span>
        </div>
        <div
          onClick={() => handleClick('logout')}
          className="cursor-pointer pt-4 text-md text-red-600 flex items-center gap-5 pb-4 border-b-2 border-gray-300"
        >
          <FiLogOut size={22} />
          <span>Logout</span>
        </div>
      </div>
    </div>
  )
}

export default Profile
