import ReactDOM from 'react-dom'

const TooltipPortal = ({ children }) => {
  return ReactDOM.createPortal(
    children,
    document.body // Render the tooltip in the body element
  )
}

export default TooltipPortal
