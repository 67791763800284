import { IButtonProps } from 'interfaces/atoms/button.types'
import Dropdown from 'react-multilevel-dropdown'
import { IoCaretForwardOutline } from 'react-icons/io5'
import { isEmpty } from 'lodash'
interface IDropdown extends IButtonProps {
  items: {
    name: string
    className?: string
    id: string
    onClick?: () => void
    children?: any
    items?: any
    checkBox?: boolean
    count?: number
    active?: boolean
    search?: boolean
    title?: boolean
    description?: string
    searchInput?: string
    onChangeSearch?: (e: any) => void
  }[]
  showDots?: boolean
  right?: boolean
  border?: boolean
  noIcon?: boolean
  secondaryWithBorder?: boolean
  withoutTitle?: boolean
  onCheckboxChange?: any
  chipList?: any
  closeBoxOnClick?: boolean
}

export default function NestedDropdown2({
  chipList,
  label,
  items,
  Icon,
  onCheckboxChange,
  withoutTitle,
  closeBoxOnClick = false,
}: IDropdown) {
  const checkboxClass =
    'text-blue-500 border-gray-400 rounded-sm focus:outline-none focus:ring-0 focus:ring-themeBlue mt-0.5'

  const onChange = (c, i, e) => {
    const checked = e.target.checked
    c = {
      ...c,
      isActive: checked,
    }
    onCheckboxChange(c, i, checked)
  }

  const onChangeClick = (c, parentItem, e) => {
    const arr = []

    items.map((item) => {
      if (item.id === parentItem.id) {
        item.items.forEach((c2) => {
          if (c2.isActive === true) {
            arr.push(c2)
          }
        })
      }
    })

    if (arr) {
      onCheckboxChange({ ...arr[0], isActive: false }, parentItem, false)
    }

    const updatedParentItem = items.find((item) => item.id === parentItem.id)
    const updatedChildItem = updatedParentItem.items.find((child) => child.id === c.id)
    if (updatedChildItem) {
      updatedChildItem.isActive = e
    }

    onCheckboxChange(updatedChildItem, updatedParentItem, e)
  }

  const title = withoutTitle ? (
    <></>
  ) : (
    <p className="flex flex-1 items-center justify-center truncate text-xs font-semibold text-gray-500 bg-white relative">
      {!isEmpty(chipList) ? chipList[0].text : label}{' '}
    </p>
  )

  return (
    <div className="">
      <Dropdown
        title={title}
        position="right"
        menuClassName="w-60 m-0"
        wrapperClassName="w-60 text-gray-700 hover:text-blue-500 hover:border-blue-500 focus:border-blue-500 border border-gray-400 rounded-lg"
        buttonClassName="focus:ring-2 focus:ring-themeBlue w-full"
        buttonVariant="tertiary"
      >
        {items.map((item) => (
          <Dropdown.Item
            onClick={() => {
              item?.onClick && item?.onClick()
              if (closeBoxOnClick) {
                const mousedownEvent = new MouseEvent('mousedown')
                document.dispatchEvent(mousedownEvent)
              }
            }}
            key={item.id}
            className={item?.active ? 'bg-blue-200' : ''}
          >
            <span className="flex items-center py-0.5 justify-between w-full">
              {item.name}
              <p className="flex items-center">
                {item?.count > 0 && <span className="bg-themeBlue2 rounded-full text-sm px-2 ">{item?.count}</span>}
                {item?.items && <IoCaretForwardOutline className="" />}
              </p>
            </span>
            {item?.checkBox && (
              <Dropdown.Submenu position="right" key={item?.children?.id} className={`w-60 overflow-auto max-h-72`}>
                {item?.title && <p className="px-3 py-1 uppercase text-gray-500 text-xs font-semibold">{item?.name}</p>}
                {item?.description && item?.description.length > 0 && (
                  <p className="px-3 w-52 whitespace-pre-line py-1 text-gray-900 text-xs">{item?.description}</p>
                )}
                {item?.search && (
                  <div className="px-3 py-1">
                    <input
                      type="text"
                      name="search"
                      autoComplete={'off'}
                      value={item?.searchInput}
                      id="search"
                      onChange={(e: any) => item?.onChangeSearch(e.target.value)}
                      className={`focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themeBlue transition-all focus:border-themeBlue block w-full border border-themeGray2 rounded-lg text-sm text-black`}
                      placeholder={'Search..'}
                      onClick={(e: any) => e.stopPropagation()}
                    />
                  </div>
                )}
                {item?.items.map((i) => (
                  <>
                    <Dropdown.Item
                      key={i.id}
                      onClick={() => {
                        onChangeClick(i, item, i?.isActive ? false : true)
                        if (closeBoxOnClick) {
                          const mousedownEvent = new MouseEvent('mousedown')
                          document.dispatchEvent(mousedownEvent)
                        }
                      }}
                      className={i?.isActive ? 'bg-themeBlue text-white' : ''}
                    >
                      <label className={`flex cursor-pointer w-full`}>
                        <div className={`ml-2`}>
                          <p className="">{i.name}</p>
                        </div>
                      </label>
                    </Dropdown.Item>
                    {i?.break && <div className="h-0.5 w-full my-2 bg-themeGray2"></div>}
                  </>
                ))}
              </Dropdown.Submenu>
            )}
            {item?.items && !item?.checkBox && (
              <Dropdown.Submenu position="right" key={item?.children?.id} className="w-60">
                {item?.items.map((i) => (
                  <>
                    <Dropdown.Item
                      key={i.id}
                      onClick={i.onClick}
                      className={i?.active ? 'bg-themeBlue text-white' : ''}
                    >
                      <span className="flex items-center py-0.5 justify-between w-full">
                        {i.name}
                        <p className="flex items-center">
                          {i?.count > 0 && <span className="bg-themeBlue2 rounded-full text-sm px-2">{i?.count}</span>}
                          {i?.items && <IoCaretForwardOutline className="" />}
                        </p>
                      </span>
                      {i?.items && (
                        <Dropdown.Submenu position="right" key={i?.id} className="w-60 overflow-auto max-h-68">
                          {i?.title && (
                            <p className="px-3 py-1 uppercase text-gray-500 text-xs font-semibold">{i?.name}</p>
                          )}
                          {i?.description && i?.description.length > 0 && (
                            <p className="px-3 w-52 whitespace-pre-line py-1 text-gray-900 text-xs">{i?.description}</p>
                          )}
                          {i?.search && (
                            <div className="px-3 py-1">
                              <input
                                type="text"
                                name="search"
                                autoComplete={'off'}
                                value={i?.searchInput}
                                id="search"
                                onChange={(e: any) => i?.onChangeSearch(e.target.value)}
                                className={`focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themeBlue transition-all focus:border-themeBlue block w-full border border-themeGray2 rounded-lg text-sm text-black`}
                                placeholder={'Search..'}
                              />
                            </div>
                          )}
                          {i?.items.map((cItem, idx) => (
                            <>
                              {cItem?.isDisplay && (
                                <Dropdown.Item onClick={() => {}}>
                                  <label key={idx} className={`flex cursor-pointer w-full`}>
                                    <input
                                      id={cItem.id}
                                      type="checkbox"
                                      className={checkboxClass}
                                      value={cItem.name}
                                      checked={cItem.isActive}
                                      onChange={(e) => onChange(cItem, i, e)}
                                    />
                                    <div className={`ml-2`}>
                                      <p className="">{cItem.name}</p>
                                    </div>
                                  </label>
                                </Dropdown.Item>
                              )}
                            </>
                          ))}
                          {i?.listNotFound && (
                            <p className="px-3 w-52 whitespace-pre-line py-1 text-gray-900 text-xs">No match found</p>
                          )}
                        </Dropdown.Submenu>
                      )}
                    </Dropdown.Item>
                    {i?.break && <div className="h-0.5 w-full bg-themeGray2"></div>}
                  </>
                ))}
              </Dropdown.Submenu>
            )}
          </Dropdown.Item>
        ))}
      </Dropdown>
    </div>
  )
}
