import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import useGetParams from 'hooks/useGetParams'
import { HttpRequest } from 'helpers/services'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'
import { useDispatch } from 'react-redux'

const GoogleCalendar = () => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()
  const [params]: any[] = useGetParams()
  const { code, state } = params

  useEffect(() => {
    document.title = 'Integrating Calendar | Coplae'
  }, [])

  const integrateGooglCalendar = () => {
    let data = {
      googleCode: code,
    }

    HttpRequest('POST', `/user/integrate-google-calendar`, data, {})
      .then(({ data }: any) => {
        if (data.code === 200) {
          dispatch(setSuccessMessage('Calendar Integrated Successfully'))
          if (state) {
            window.location.href = state
          } else {
            navigate('/home')
          }
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages(['Error Integrating Calendar']))
        console.error(err)
      })
  }

  useEffect(() => {
    integrateGooglCalendar()
  }, [code])

  return (
    <>
      <div className="w-max mt-40 h-max mx-auto flex flex-col items-center justify-center border rounded-md bg-gray-100 shadow-sm px-40">
        <div className="my-10" style={{ filter: 'invert(1)' }}>
          <svg width="200" height="100" viewBox="0 0 99 19" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M19.091 16.325C17.3441 14.5598 16.4524 12.4307 16.4524 9.91953C16.4524 7.35373 17.3441 5.18826 19.091 3.44133C20.8561 1.6944 23.0034 0.802734 25.4964 0.802734C27.9894 0.802734 30.1185 1.6944 31.8654 3.45953C33.6306 5.20646 34.5222 7.35373 34.5222 9.84674C34.5222 12.4126 33.6306 14.578 31.8654 16.3431C30.1185 18.0901 27.9712 18.9817 25.4964 18.9817C23.0034 18.9635 20.8561 18.0901 19.091 16.325ZM30.0639 14.3051C31.3195 13.0495 31.9382 11.5573 31.9382 9.81035C31.9382 8.06342 31.3195 6.55305 30.0639 5.31564C28.8083 4.06003 27.3343 3.44133 25.5692 3.44133C23.8041 3.44133 22.3119 4.06003 21.0563 5.31564C19.8189 6.55305 19.2002 8.06342 19.2002 9.81035C19.2002 11.5573 19.8189 13.0495 21.0563 14.3051C22.3119 15.5425 23.8041 16.1612 25.5692 16.1612C27.3343 16.1612 28.8265 15.5425 30.0639 14.3051Z"
              fill="white"
            />
            <path
              d="M36.9793 0.802734H46.2781C47.7338 0.802734 48.9894 1.31226 50.0449 2.36769C51.1003 3.42313 51.6098 4.66054 51.6098 6.15271C51.6098 7.60849 51.1003 8.8641 50.0449 9.91953C49.0076 10.975 47.752 11.4845 46.2781 11.4845H41.3102C41.3102 10.7748 41.565 10.1743 42.0563 9.68297C42.5476 9.19165 43.1481 8.93688 43.8578 8.93688H46.2781C47.0423 8.93688 47.6974 8.66393 48.2434 8.13621C48.7893 7.59029 49.0622 6.93519 49.0622 6.17091C49.0622 5.40663 48.7893 4.75153 48.2434 4.20561C47.6974 3.6597 47.0605 3.38674 46.2781 3.38674H39.5451V16.4523C39.5451 17.1438 39.2903 17.7443 38.7808 18.2539C38.2895 18.7452 37.689 18.9999 36.9793 18.9999V0.802734Z"
              fill="white"
            />
            <path
              d="M53.5203 18.9629V0.820312C54.23 0.820312 54.8305 1.07507 55.3218 1.5664C55.8132 2.05772 56.0679 2.65823 56.0679 3.36792V16.4153H62.9283C63.638 16.4153 64.2385 16.6701 64.7298 17.1614C65.2211 17.6527 65.4759 18.2532 65.4759 18.9629H53.5203Z"
              fill="white"
            />
            <path
              d="M84.6731 18.9825H83.9088C82.8352 18.9825 82.0345 18.4183 81.4886 17.3265L80.2694 14.1966H72.6448C72.6448 13.4869 72.8995 12.8864 73.3908 12.3951C73.8822 11.9037 74.4827 11.649 75.1742 11.649H79.2685L75.993 3.16909L70.5157 17.3265C69.9698 18.4183 69.1873 18.9825 68.1136 18.9825H67.3494L73.7366 2.49579C74.0277 1.7861 74.5191 1.29477 75.1924 1.02182L75.8839 0.839844L76.0294 0.858041L76.175 0.839844L76.8665 1.02182C77.558 1.29477 78.0311 1.7861 78.3223 2.49579L84.6731 18.9825Z"
              fill="white"
            />
            <path
              d="M86.6215 18.9629H86.5305V0.820312H98.6134C98.6134 1.51181 98.3587 2.11231 97.8673 2.62184C97.376 3.11316 96.7755 3.36792 96.084 3.36792H89.0781V16.4335H96.0658C96.7573 16.4335 97.376 16.6883 97.8491 17.1796C98.3405 17.6709 98.5952 18.2714 98.5952 18.9811L86.6215 18.9629ZM89.6422 9.73694C89.6422 9.02725 89.897 8.42674 90.3883 7.93542C90.8796 7.44409 91.4801 7.18933 92.1716 7.18933H97.0485C97.0485 7.89902 96.7937 8.49953 96.3024 8.99086C95.8111 9.48218 95.2106 9.73694 94.5191 9.73694H89.6422Z"
              fill="white"
            />
            <path
              d="M5.15238 4.38786C5.06139 4.33327 4.95221 4.29688 4.84302 4.29688C4.80663 4.29688 4.77023 4.29687 4.73384 4.31507C4.58826 4.35147 4.46088 4.42426 4.3699 4.55164C4.27891 4.67902 4.26071 4.82459 4.29711 4.97017C4.3517 5.27952 4.66105 5.47969 4.95221 5.4069C5.24336 5.33412 5.46173 5.04296 5.38894 4.75181C5.37074 4.60623 5.27976 4.47885 5.15238 4.38786Z"
              fill="white"
            />
            <path
              d="M0.711792 12.0315C1.16672 11.3582 1.47607 10.6303 1.62165 9.866C1.85822 8.5922 1.60345 7.33659 1.18492 6.22656C0.711792 7.35479 0.457031 8.574 0.457031 9.8842C0.457031 10.6485 0.548017 11.3582 0.711792 12.0315Z"
              fill="white"
            />
            <path
              d="M13.1767 14.7059H9.73748C8.40908 14.7059 7.29905 14.251 6.3346 13.2866C5.6977 12.6497 5.27916 11.9218 5.07899 11.1211C4.87882 11.485 4.69685 11.8854 4.58767 12.3039C4.07815 14.0872 4.51488 16.0525 5.84327 18.1998C6.9715 18.7093 8.20891 18.9641 9.53731 18.9641H16.4522V17.9632C16.4522 17.0898 16.1247 16.2891 15.4878 15.6522C14.8509 15.0335 14.0502 14.7059 13.1767 14.7059Z"
              fill="white"
            />
            <path
              d="M9.53732 0.820932C7.0989 0.820932 4.96983 1.6762 3.22289 3.36854C2.65878 3.91446 2.20385 4.49677 1.80351 5.09727C2.45861 6.48026 2.94994 8.22719 2.60419 10.0469C2.38582 11.1933 1.8581 12.2488 1.05743 13.2132C1.49416 14.3415 2.16746 15.3787 3.09551 16.3068C3.42306 16.6525 3.78701 16.9437 4.15095 17.2166C3.33208 15.3787 3.13191 13.65 3.60503 12.0304C3.87799 11.066 4.36932 10.2471 4.93343 9.53739C5.00622 8.37277 5.46115 7.37192 6.33462 6.51666C7.28087 5.5704 8.3909 5.09727 9.73749 5.09727H13.1768C14.0684 5.09727 14.8691 4.76972 15.4878 4.13282C16.1065 3.49592 16.4523 2.71344 16.4523 1.82178V0.802734L9.53732 0.820932ZM5.1518 6.40747C5.04261 6.42567 4.95163 6.44387 4.84245 6.44387C4.11456 6.44387 3.45946 5.93435 3.31388 5.18826C3.22289 4.78792 3.31388 4.36939 3.55044 4.00544C3.78701 3.6597 4.13275 3.42313 4.55129 3.33215C4.95163 3.24116 5.38836 3.33215 5.73411 3.56871C6.07985 3.80527 6.31642 4.15102 6.4074 4.56956C6.55298 5.40663 6.00707 6.2255 5.1518 6.40747Z"
              fill="white"
            />
          </svg>
        </div>
        <div className="flex gap-4 items-center mb-10">
          <svg className="mx-2" width={30} height={30} viewBox="0 0 80 80" focusable="false" role="img">
            <title>Google Calendar Icon</title>
            <g>
              <path d="M56.7505 22.4385H22.4398V56.7492H56.7505V22.4385Z" fill="white"></path>
              <path d="M56.7504 72.1898L72.1903 56.75H56.7504V72.1898Z" fill="#EA4335"></path>
              <path d="M72.1903 22.4385H56.7504V56.7492H72.1903V22.4385Z" fill="#FBBC04"></path>
              <path d="M56.7505 56.75H22.4398V72.1898H56.7505V56.75Z" fill="#34A853"></path>
              <path
                d="M7 56.75V67.0432C7 69.8867 9.30311 72.1898 12.1466 72.1898H22.4398V56.75H7Z"
                fill="#188038"
              ></path>
              <path
                d="M72.1903 22.4398V12.1466C72.1903 9.3031 69.8872 7 67.0437 7H56.7504V22.4398H72.1903Z"
                fill="#1967D2"
              ></path>
              <path
                d="M56.7505 7H12.1466C9.3031 7 7 9.3031 7 12.1466V56.7505H22.4398V22.4398H56.7505V7Z"
                fill="#4285F4"
              ></path>
              <path
                d="M29.4778 49.0554C28.1954 48.1891 27.3076 46.9239 26.823 45.2513L29.7994 44.0247C30.0696 45.054 30.5414 45.8517 31.2147 46.4178C31.8838 46.9839 32.6987 47.2627 33.6508 47.2627C34.6244 47.2627 35.4607 46.9668 36.1598 46.3749C36.8588 45.7831 37.2105 45.0282 37.2105 44.1147C37.2105 43.1798 36.8417 42.4163 36.104 41.8245C35.3663 41.2326 34.4399 40.9367 33.3334 40.9367H31.6136V37.9903H33.1576C34.1097 37.9903 34.9117 37.7329 35.5636 37.2183C36.2155 36.7036 36.5415 36.0003 36.5415 35.1039C36.5415 34.3062 36.2498 33.6714 35.6665 33.1954C35.0833 32.7193 34.3456 32.4791 33.4492 32.4791C32.5743 32.4791 31.8795 32.7107 31.3648 33.1782C30.8505 33.6469 30.4638 34.2387 30.2412 34.898L27.2947 33.6714C27.685 32.5649 28.4013 31.587 29.452 30.7421C30.5028 29.8972 31.8452 29.4727 33.475 29.4727C34.6801 29.4727 35.7652 29.7043 36.7259 30.1717C37.6866 30.6392 38.4414 31.2868 38.9861 32.1103C39.5308 32.938 39.801 33.8644 39.801 34.8937C39.801 35.9445 39.5479 36.8323 39.0419 37.5614C38.5358 38.2905 37.9139 38.848 37.1762 39.2383V39.4142C38.1287 39.8069 38.9561 40.4519 39.5694 41.2798C40.1913 42.1161 40.5044 43.1154 40.5044 44.282C40.5044 45.4485 40.2084 46.4907 39.6166 47.4042C39.0247 48.3178 38.2055 49.0383 37.1676 49.5615C36.1255 50.0848 34.9546 50.3507 33.6551 50.3507C32.1497 50.355 30.7601 49.9218 29.4778 49.0554ZM47.7611 34.2847L44.493 36.6479L42.8589 34.1689L48.7218 29.9401H50.9691V49.8875H47.7611V34.2847Z"
                fill="#4285F4"
              ></path>
            </g>
          </svg>
          <h1 className="font-bold text-2xl text-gray-700">Integrating Google calendar...</h1>
        </div>
        <div>
          <div
            style={{ borderTopColor: 'transparent' }}
            className="mb-10 w-16 h-16 border-4 border-blue-400 border-solid rounded-full animate-spin"
          ></div>
        </div>
      </div>
    </>
  )
}

export default GoogleCalendar
