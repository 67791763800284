import { XIcon } from '@heroicons/react/outline'
import RatingSystemItem from 'components/atoms/RatingSystemItem'
import { HttpRequest } from 'helpers/services'
import useIsMobile from 'hooks/useIsMobile'
import { useEffect, useState } from 'react'
import { FaInfoCircle } from 'react-icons/fa'
import { useParams } from 'react-router-dom'

const placeholder = {
  title: '',
  subtitle: '',
  description: '',
}

const AppraisalsInfoSidebar = ({ viewInfoSidebar, setViewInfoSidebar }) => {
  const [fiveStarContent, setFiveStarContent] = useState(placeholder)
  const [fourStarContent, setFourStarContent] = useState(placeholder)
  const [threeStarContent, setThreeStarContent] = useState(placeholder)
  const [twoStarContent, setTwoStarContent] = useState(placeholder)
  const [oneStarContent, setOneStarContent] = useState(placeholder)
  const [dataAvailable, setDataAvailable] = useState(false)

  const { companyId }: any = useParams()
  const isMobile = useIsMobile()

  const fetchRatingSysData = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    const data = {}

    HttpRequest('GET', `/review/ratings/list`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.data?.length > 0) {
          const sortedData = res?.data?.data?.sort((a, b) => a.id - b.id)
          setFiveStarContent(sortedData[0])
          setFourStarContent(sortedData[1])
          setThreeStarContent(sortedData[2])
          setTwoStarContent(sortedData[3])
          setOneStarContent(sortedData[4])
          setDataAvailable(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  useEffect(() => {
    fetchRatingSysData()
  }, [])

  return (
    <div
      className={`fixed flex flex-col top-0 right-0 h-screen bg-white overflow-hidden transform ${
        viewInfoSidebar ? 'translate-x-0 opacity-100 visible' : 'translate-x-10 opacity-0 invisible'
      } duration-150 transition-all`}
      style={{
        boxShadow: '-4px 2px 6px 0 rgba(212, 212, 212, 0.44)',
        width: isMobile ? '100%' : '530px',
        zIndex: 5000,
        zoom: isMobile ? '0.8' : 'unset',
        height: isMobile ? 'calc(100vh + 200px)' : '',
      }}
    >
      <div className="overflow-y-scroll flex space-y-6 flex-col flex-1 p-6 -mr-4">
        <div className="flex items-center justify-between">
          <div className="flex items-center content-center gap-3">
            <div>
              <FaInfoCircle className="h-5 w-5" />
            </div>
            <div className="font-semibold text-xl">FIVE STAR RATING</div>
          </div>
          <XIcon
            className="h-6 w-6 text-gray-600 cursor-pointer"
            aria-hidden="true"
            onClick={() => setViewInfoSidebar(false)}
          />
        </div>
        {dataAvailable ? (
          <div className="pt-2">
            <div className="flex flex-col">
              <div className="flex flex-col gap-y-6">
                <>
                  <RatingSystemItem
                    title={fiveStarContent?.title}
                    subtitle={fiveStarContent?.subtitle}
                    desc={fiveStarContent?.description}
                    rating={5}
                    idx={0}
                  />
                  <RatingSystemItem
                    title={fourStarContent?.title}
                    subtitle={fourStarContent?.subtitle}
                    desc={fourStarContent?.description}
                    rating={4}
                    idx={1}
                  />
                  <RatingSystemItem
                    title={threeStarContent?.title}
                    subtitle={threeStarContent?.subtitle}
                    desc={threeStarContent?.description}
                    rating={3}
                    idx={2}
                  />
                  <RatingSystemItem
                    title={twoStarContent?.title}
                    subtitle={twoStarContent?.subtitle}
                    desc={twoStarContent?.description}
                    rating={2}
                    idx={3}
                  />
                  <RatingSystemItem
                    title={oneStarContent?.title}
                    subtitle={oneStarContent?.subtitle}
                    desc={oneStarContent?.description}
                    rating={1}
                    idx={4}
                  />
                </>
              </div>
            </div>
          </div>
        ) : (
          <div style={{ margin: 'auto' }}>Rating System not available</div>
        )}
      </div>
    </div>
  )
}

export default AppraisalsInfoSidebar
