import React, { useState, useCallback } from 'react'

import Button from 'components/atoms/Button/button'
import Divider from 'components/atoms/Divider'
import Modal from 'components/atoms/Modal'
import Cropper from 'react-easy-crop'
import { Point, Area } from 'react-easy-crop/types'
import { HttpRequest } from 'helpers/services'
import Avatar from 'components/atoms/Avatar'
import { generateCropImage } from 'components/organisms/Settings/generateCropImage'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { FaTrash } from 'react-icons/fa'
import { BsImage } from 'react-icons/bs'

const EditProfilePicDialog = ({
  open,
  setOpen,
  image,
  setImage,
  existingImageURL,
  name,
  selectedUser,
  saveInfo,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  image: string
  setImage: React.Dispatch<React.SetStateAction<string>>
  existingImageURL?: string
  name?: string
  selectedUser?: any
  saveInfo?: any
}) => {
  const [localImage, setLocalImage] = useState<any>(null)
  const [selectedImage, setSelectedImage] = useState<any>(null)
  const [croppedImage, setCroppedImage] = useState<any>(null)
  const [uploading, setUploading] = useState(false)
  const [isExploreOpen, setIsExploreOpen] = useState(false)
  const [croppedArea, setCroppedArea] = useState(null)

  const [crop, setCrop] = useState<Point>({ x: 0, y: 0 })
  const [zoom, setZoom] = useState<any>(1)
  const onCropComplete = useCallback((croppedArea: Area, croppedAreaPixels: Area) => {
    setCroppedArea(croppedAreaPixels)
  }, [])

  const onImageChange = (event) => {
    setIsExploreOpen(true)
    if (event.target.files && event.target.files[0]) {
      setLocalImage(URL.createObjectURL(event.target.files[0]))
      if (event.target.files && event.target.files.length > 0) {
        const reader = new FileReader()
        reader.readAsDataURL(event.target.files[0])
        reader.addEventListener('load', () => {
          setCroppedImage(reader.result)
        })
      }
      setSelectedImage(event.target.files[0])
    }
  }

  const onImageSave = async () => {
    setUploading(true)
    const croppedImg: any = await generateCropImage(croppedImage, croppedArea, selectedImage)
    if (croppedImg) {
      let formData = new FormData()
      formData.append('image', croppedImg)
      await HttpRequest('POST', '/user/image-upload', formData)
        .then(({ data }: any) => {
          if (data.success) {
            setImage(data.data.data.imageUrl)
            saveInfo()
            setIsExploreOpen(false)
            setUploading(false)
            setLocalImage(null)
            setSelectedImage(null)
            setOpen(false)
          }
        })
        .catch((err) => {
          setUploading(false)
          console.error(err)
        })
    }
  }

  const onRemovePic = () => {
    setLocalImage(null)
  }

  const onExistingRemovePic = async () => {
    setUploading(true)
    let formData = new FormData()
    formData.append('image', null)
    await HttpRequest('POST', '/user/image-upload', formData)
      .then(({ data }: any) => {
        if (data.success) {
          setImage(data.data.data.imageUrl)
          saveInfo()
          setIsExploreOpen(false)
          setUploading(false)
          setLocalImage(null)
          setSelectedImage(null)
          setOpen(false)
        }
      })
      .catch((err) => {
        setUploading(false)
        console.error(err)
      })
  }

  const isImageExist = localImage !== null ? true : existingImageURL !== '' && existingImageURL !== null ? true : false
  return (
    <Modal max={{ w: 180 }} hwClassName={'max-w-180'} header={'Profile photo'} open={open} setOpen={setOpen}>
      <div className="w-full flex justify-center">
        <div className="w-52 h-52 relative">
          {uploading ? (
            <div className="bg-gray-200 w-52 h-52 rounded-full animate-pulse"></div>
          ) : (
            <>
              {localImage && isExploreOpen ? (
                <Cropper
                  image={localImage}
                  crop={crop}
                  zoom={zoom}
                  aspect={1}
                  cropShape="round"
                  onCropChange={setCrop}
                  onCropComplete={onCropComplete}
                  onZoomChange={setZoom}
                />
              ) : (
                localImage === null &&
                existingImageURL && <Avatar name={`${selectedUser?.fullName || 'VM'}`} image={existingImageURL} xxl />
              )}

              {!isImageExist && (
                <div className="w-52 h-52 flex items-center justify-center">
                  <Avatar rectangle large name={selectedUser?.fullName} />
                </div>
              )}
            </>
          )}
        </div>
      </div>
      <Divider className="bg-themeGray2 w-full mb-3 mt-10" />
      <div className={'w-full flex justify-between '}>
        <div className="flex gap-2">
          {localImage && (
            <Tooltip text="Remove" position="top">
              <div
                style={{ borderWidth: '3px' }}
                className={
                  'transition-all hover:bg-red-400 text-red-400 hover:text-white border-red-400 rounded-md p-1.5 cursor-pointer'
                }
                onClick={onRemovePic}
              >
                <FaTrash className="w-6 h-6" />
              </div>
            </Tooltip>
          )}
          {isImageExist && !localImage && (
            <Tooltip text="Remove" position="top">
              <div
                style={{ borderWidth: '3px' }}
                className={
                  'transition-all hover:bg-red-400 text-red-400 hover:text-white border-red-400 rounded-md p-1.5 cursor-pointer'
                }
                onClick={onExistingRemovePic}
              >
                <FaTrash className="w-6 h-6" />
              </div>
            </Tooltip>
          )}
          <Tooltip text="Select from gallery" position="top">
            <div
              style={{ borderWidth: '3px' }}
              className={
                'transition-all hover:bg-blue-500 text-blue-500 hover:text-white border-blue-500 rounded-md p-1.5 cursor-pointer relative'
              }
            >
              <BsImage className="w-6 h-6" />
              <input
                type="file"
                className="cursor-pointer absolute top-0 w-full h-full opacity-0"
                onChange={(e) => onImageChange(e)}
              />
            </div>
          </Tooltip>
        </div>
        {localImage && (
          <div className="mt-2 flex space-x-2">
            <div>
              <Button
                size="md"
                btnColor="bg-blue-500"
                textColor="text-white"
                hoverColor="bg-blue-600"
                label={'Save'}
                onClick={onImageSave}
              />
            </div>
          </div>
        )}
      </div>
    </Modal>
  )
}

export default EditProfilePicDialog
