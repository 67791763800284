/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-restricted-imports */
import { useEffect, useState } from 'react'

import Avatar from 'components/atoms/Avatar'
import Button from 'components/atoms/Button/button'
import { ReviewSegment } from 'components/templates/Review/ReviewSegment'
import { useCompanyTools } from 'hooks/useCompanyTools'
import useIsMobile from 'hooks/useIsMobile'
import { get } from 'lodash'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

interface Props {
  user: any
  selectedEmployee?: any
  fetching?: boolean
}

const ProfileComponents = ({
  setSelectedGoal,
  setViewModal,
  onDataFromReviewSegment,
  handleSelectedEmployee,
  viewSidebar,
  setViewSidebar,
}) => {
  const { companyId, userId } = useParams<any>()
  const { getCompanyUserInfo, getEmployeeUserInfo } = useCompanyTools()
  const isMobile = useIsMobile()

  const [selectedEmployee, setSelectedEmployee]: any = useState({})
  const [companyUserInfo, setCompanyUserInfo]: any = useState()
  const [fetching, setFetching] = useState(true)
  const [company, setCompany]: any = useState()
  const [showCreateReviewModal, setShowCreateReviewModal] = useState(false)
  const user1: any = useSelector((state) => get(state, 'user.user'))

  const location = useLocation()
  const pathname = location.pathname

  const isSelf = company?.employeeId === companyUserInfo?.myProfile?.employeeId
  const isManager = company?.employeeId !== companyUserInfo?.myProfile?.employeeId

  const fetchRole = async () => {
    setFetching(true)
    try {
      const data = await getCompanyUserInfo(userId)
      setCompanyUserInfo(data)
      setFetching(false)
    } catch (e) {
      setFetching(false)
    }
  }

  const getCompanyName = () => {
    user1?.employee?.forEach((company) => {
      if (company.companyId === parseInt(companyId)) {
        setCompany(company)
      }
    })
  }

  const fetchEmployeeUserInfo = async () => {
    const userId = pathname.split('/')[4]
    setFetching(true)
    try {
      const data = await getEmployeeUserInfo(userId)
      setFetching(false)
      setSelectedEmployee(data)
      handleSelectedEmployee(data)
    } catch (e) {}
  }

  useEffect(() => {
    getCompanyName()
  }, [company, getCompanyName])

  useEffect(() => {
    fetchEmployeeUserInfo()
    fetchRole()
  }, [userId])

  const handleUpdateShowCreateReviewModal = () => {
    setShowCreateReviewModal(true)
  }

  const responsiveClass = `sm:max-w-172 lg:max-w-200 xl:max-w-256 ${isMobile ? 'mx-4' : 'ml-12'} max-w-192`

  const zoomStyle = window.innerWidth <= 1400 ? { zoom: '0.9' } : {}

  return (
    <div style={zoomStyle} className="p-0 m-0 font-roboto">
      <div
        className="w-full h-24 fixed top-0"
        style={{ zIndex: 1, background: 'linear-gradient(134deg, rgb(106, 152, 240) 0%, rgb(73, 97, 220) 99%)' }}
      >
        <div
          className={`${responsiveClass} fixed rounded-lg justify-start flex items-center mt-7`}
          style={{ zIndex: 1 }}
        >
          {fetching ? (
            <div className="flex items-center w-full justify-between">
              <div className="h-12 w-12 bg-gray-200 duration-700 rounded-full animate-pulse"></div>
              <div className="h-10 w-28 mx-3 bg-gray-200 duration-700 rounded animate-pulse"></div>
            </div>
          ) : (
            <>
              <Avatar
                className=""
                rectangle
                name={company?.companyName || 'Instinct Design Studio'}
                image={company?.companyThumbnail}
              />
              <h1 className={`${isMobile ? 'text-lg' : 'text-3xl'} text-white font-bold ml-4`}>
                {' '}
                {`Welcome to ${
                  company?.companyName
                  // .split(' ')
                  // .map((name) => capitalize(name))
                  // .join(' ')
                }`}
              </h1>
            </>
          )}
        </div>
        <header
          className="mb-12 bg-white"
          style={{
            marginTop: '97px',
            zIndex: 1,
            maxWidth: '1120px',
            minWidth: '350px',
            width: isMobile ? '90%' : '78%',
          }}
        >
          <div
            style={{
              backdropFilter: 'blur(16px)',
              background: 'rgba(255,255,255, 0.5)',
              boxShadow: '0 2px 6px 4px rgba(212, 212, 212, 0.44)',
              width: isMobile ? '100%' : 'unset',
            }}
            className={`sm:max-w-172 lg:max-w-200 xl:max-w-256 ${
              isMobile ? 'mx-4' : 'ml-12'
            } max-w-192 h-20 transform rounded-lg justify-between px-5 flex items-center translate-y-8`}
          >
            {fetching ? (
              <div className="flex items-center w-full justify-between">
                <div className="flex items-center">
                  <div className="h-12 w-12 bg-gray-200 animate-pulse duration-700 rounded-full"></div>
                  <div className="h-10 w-40 mx-3 bg-gray-200 animate-pulse duration-700 rounded"></div>
                </div>
              </div>
            ) : (
              <>
                <Avatar
                  title={selectedEmployee?.myProfile?.jobTitle}
                  nameClass={isMobile ? 'text-sm font-bold mr-4' : 'text-lg font-bold'}
                  name={selectedEmployee?.myProfile?.fullName}
                  image={selectedEmployee?.myProfile?.profileImageThumbnailUrl}
                  withName
                />
                {/* Tabs here */}
                {isManager && (
                  <Button
                    textColor="text-black"
                    size="md"
                    hoverColor="border-blue-500"
                    hoverTextColor="text-blue-500"
                    hoverBorderColor="text-blue-500"
                    label={isMobile ? 'Create' : 'Create Assessment'}
                    className="font-semibold"
                    secondary
                    onClick={() => handleUpdateShowCreateReviewModal()}
                  />
                )}
              </>
            )}
          </div>
        </header>
      </div>

      <main className={`transform translate-y-64 -mt-2 ${responsiveClass}`}>
        <ReviewSegment
          setSelectedGoal={setSelectedGoal}
          setViewModal={setViewModal}
          companyUserInfo={selectedEmployee}
          selectedEmployee={selectedEmployee?.myProfile}
          companyId={companyId}
          fetching={fetching}
          onDataFromReviewSegment={onDataFromReviewSegment}
          showCreateReviewModal={showCreateReviewModal}
          setShowCreateReviewModal={setShowCreateReviewModal}
          viewSidebar={viewSidebar}
          setViewSidebar={setViewSidebar}
        />
      </main>
    </div>
  )
}

export default ProfileComponents
