import Avatar from 'components/atoms/Avatar'
import Dropdown from 'react-multilevel-dropdown'

export const ParticipantDropdown = ({ header, participants }: any) => {
  return (
    <Dropdown
      title={header}
      position="right"
      menuClassName="w-60 m-0"
      wrapperClassName="moderator-dropdown"
      buttonVariant="tertiary"
    >
      <div className="w-60 overflow-auto max-h-68">
        {participants?.[0] ? (
          participants.map((item) => (
            <>
              <Dropdown.Item key={item?.id}>
                <Avatar
                  className="cursor-pointer ml-2"
                  hw="6"
                  small
                  name={item?.fullName}
                  image={item?.profileImageThumbnailUrl}
                />
                <p className="text-sm font-medium text-gray-900 capitalize ml-2">{item?.fullName}</p>
              </Dropdown.Item>
            </>
          ))
        ) : (
          <p className="px-3 w-52 whitespace-pre-line py-1 text-gray-900 text-xs">No participants</p>
        )}
      </div>
    </Dropdown>
  )
}
