import useIsMobile from 'hooks/useIsMobile'

const StepperTabs = ({ tabs, curTab, setCurTab }) => {
  const isMobile = useIsMobile()
  return (
    <ul className="stepper">
      {tabs.map((tab) => (
        <li
          onClick={() => setCurTab(tab?.name)}
          className={`${isMobile ? 'text-xs' : ''} stepper__item border-white cursor-pointer ${
            curTab === tab?.name
              ? tab.status === 'complete'
                ? 'current_complete'
                : 'current_pending'
              : tab.status === 'complete'
              ? 'complete'
              : 'pending'
          }`}
        >
          {tab?.name}
        </li>
      ))}
    </ul>
  )
}

export default StepperTabs
