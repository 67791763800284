import * as types from 'state/redux/constants'

export const setDrawerToggle = (toggle: any) => async (dispatch: any) => {
  try {
    dispatch({ type: types.SET_DRAWER_TOGGLE, data: toggle })
  } catch (error) {
    console.error(error)
  }
}

export const setErrorMessages = (messages: string[]) => async (dispatch: any) => {
  try {
    dispatch({ type: types.SET_ERROR_MESSAGE, data: [...messages] })
    if (messages.length > 0) {
      setTimeout(() => {
        dispatch({ type: types.SET_ERROR_MESSAGE, data: [] })
      }, 4000)
    }
  } catch (error) {
    console.error(error)
  }
}

export const setSuccessMessage = (message: string) => async (dispatch: any) => {
  try {
    dispatch({ type: types.SET_SUCCESS_MESSAGE, data: message })
    if (message.length > 0) {
      setTimeout(() => {
        dispatch({ type: types.SET_SUCCESS_MESSAGE, data: '' })
      }, 4000)
    }
  } catch (error) {
    console.error(error)
  }
}