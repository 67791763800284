import Avatar from 'components/atoms/Avatar'
import Label from 'components/atoms/Labels/Label'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import moment from 'moment'
import { useEffect, useRef, useState } from 'react'
import DatePicker from 'react-datepicker'
import Dropdown from 'react-multilevel-dropdown'

export const AssignDropdown = ({
  header,
  onAssignTo,
  index,
  obj,
  ownerSearchInput,
  ownerSuggestedList,
  setOwnerSearchInput,
  suggestionFetching,
  disabledDate,
  date,
  setDate,
  type,
  onClick,
  width,
  notFoundMessage,
}: {
  header?: any
  onAssignTo?: any
  index?: any
  obj?: any
  ownerSearchInput?: any
  ownerSuggestedList?: any
  setOwnerSearchInput?: any
  suggestionFetching?: any
  disabledDate?: any
  date?: any
  setDate?: any
  type?: any
  onClick?: any
  width?: any
  notFoundMessage?: any
}) => {
  const calendarRef = useRef()
  const dropdownRef: any = useRef()
  const [selectedGoal, setSelectedGoal] = useState<any>(null)

  const openCalendar = () => {
    if (!disabledDate) {
      // @ts-ignore
      calendarRef?.current?.setOpen(true)
    }
  }

  useEffect(() => {
    if (setDate) obj?.dueDate && setDate(new Date(obj?.dueDate))
    obj?.goal && setSelectedGoal(obj?.goal)
  }, [obj])

  const handleDateUpdate = (d) => {
    setDate(d)
    onAssignTo(obj?.assignTo?.id, d, obj?.goal, obj, index)
  }

  const menuClass = (type) => {
    switch (type) {
      case 'compare':
        return 'w-98 m-0'
      case 'assessment-cycle-select':
        return 'w-80 m-0'
      case 'goal':
        return 'w-98 m-0 -ml-6'
      case 'filter':
        return 'w-98 m-0 -ml-5 ml-5 mt-2'
      case 'assessment-filter':
        return 'w-98 -ml-20 mt-2'
      case 'assign-to':
        return 'w-98 m-0 -ml-160'
      default:
        return 'w-98 m-0'
    }
  }

  const wrapperClass = (type) => {
    switch (type) {
      case 'compare':
        return 'hover:text-themeBlue w-full'
      case 'assessment-cycle-select':
        return 'hover:text-themeBlue w-full'
      case 'goal':
        return 'hover:text-themeBlue w-full'
      case 'filter':
        return 'hover:text-themeBlue w-full'
      case 'assessment-filter':
        return 'hover:text-themeBlue w-full'
      case 'assign-to':
        return ''
      default:
        return ''
    }
  }

  const buttonClass = (type) => {
    switch (type) {
      case 'compare':
        return '-mt-2 ml-0 w-full'
      case 'assessment-cycle-select':
        return '-mt-2 ml-0 w-full'
      case 'goal':
        return '-mt-2 ml-0 w-full'
      case 'filter':
        return '-mt-2 ml-0 w-full'
      case 'assessment-filter':
        return '-mt-2 ml-0 w-full'
      case 'parent-goal-select':
        return '-mt-2 ml-0 w-full w-[576px]'
      case 'assign-to':
        return '-mt-2 ml-4'
      default:
        return '-mt-2 ml-4'
    }
  }

  return (
    <Dropdown
      title={header}
      position="right"
      menuClassName={menuClass(type)}
      wrapperClassName={wrapperClass(type)}
      buttonClassName={buttonClass(type)}
      buttonVariant="tertiary"
      ref={dropdownRef}
    >
      <div className={`overflow-auto max-h-68 ${type === 'assessment-cycle-select' ? 'w-80' : 'w-50'} ${width}`}>
        {type === 'assign-to' && (
          <>
            <p className="px-3 py-1 uppercase text-gray-500 text-xs font-semibold">Assign Action Item</p>
            <div className="px-3 my-2 flex items-center gap-2">
              <p className="text-sm">Due Date: </p>
              <Tooltip
                position="bottom"
                text={date ? `Due: ${moment(new Date(date)).fromNow()}` : disabledDate ? 'No Due Date' : `Add Due Date`}
              >
                <span
                  onClick={() => openCalendar()}
                  style={{
                    textDecoration: 'underline',
                    textDecorationColor: 'rgb(220, 225, 230)',
                    textDecorationStyle: 'dashed',
                    textUnderlineOffset: '6px',
                    position: 'absolute',
                    width: '8rem',
                    top: '-10px',
                  }}
                  className="cursor-pointer font-bold text-sm"
                >
                  {obj?.dueDate ? moment(obj?.dueDate).format('l') : 'Add Due Date'}
                </span>
              </Tooltip>
              <DatePicker
                wrapperClassName="assign-cal-wrapper"
                calendarClassName="mt-8"
                ref={calendarRef}
                showMonthDropdown
                showYearDropdown
                scrollableYearDropdown={true}
                yearDropdownItemNumber={6}
                showTimeSelect={false}
                timeIntervals={15}
                minDate={new Date()}
                selected={obj?.dueDate ? new Date(obj?.dueDate) : date}
                onChange={(d) => handleDateUpdate(d)}
                placeholderText="Select"
                className={`w-full border border-themeGray2 focus:ring-themeBlue focus:border-themeBlue focus:outline-none focus:ring-2 focus:ring-offset-2 transition-all rounded-md  dark:text-white`}
              />
            </div>
          </>
        )}
        <div className="px-3 py-1">
          {type === 'goal' && (
            <div className="mb-4">
              <span className="text-sm font-semibold">Related goal :</span>
              <p className="text-sm w-48 overflow-ellipsis overflow-hidden whitespace-nowrap">{selectedGoal?.name}</p>
            </div>
          )}
          <input
            type="text"
            name="search"
            autoComplete={'off'}
            value={ownerSearchInput}
            id="search"
            onChange={(e: any) => setOwnerSearchInput(e.target.value)}
            className={`my-2 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-themeBlue transition-all focus:border-themeBlue block w-full border border-themeGray2 rounded-lg text-sm text-black`}
            placeholder={'Search..'}
          />
        </div>
        {suggestionFetching ? (
          <>
            <Dropdown.Item className="mx-3 animate-pulse h-6 my-2 bg-gray-200" />
            <Dropdown.Item className="mx-3 animate-pulse h-6 my-2 bg-gray-200" />
            <Dropdown.Item className="mx-3 animate-pulse h-6 my-2 bg-gray-200" />
            <Dropdown.Item className="mx-3 animate-pulse h-6 my-2 bg-gray-200" />
          </>
        ) : ownerSuggestedList?.[0] ? (
          type === 'assign-to' ? (
            ownerSuggestedList.map((item) => (
              <>
                <Dropdown.Item
                  key={item?.id}
                  onClick={() => {
                    onAssignTo(item?.id, date, item?.goal, obj, index)
                    setOwnerSearchInput('')
                    dropdownRef?.current?.toggle()
                  }}
                >
                  <Avatar
                    outline={item?.id === obj?.assignTo?.id}
                    className="cursor-pointer ml-2"
                    hw="6"
                    small
                    name={item?.fullName}
                    image={item?.profileImageThumbnailUrl}
                  />
                  <p className="text-sm font-medium text-gray-900 capitalize ml-2">{item.fullName}</p>
                </Dropdown.Item>
              </>
            ))
          ) : type === 'goal' ? (
            ownerSuggestedList?.map(
              (item) =>
                item?.id && (
                  <Dropdown.Item key={item?.id}>
                    <div
                      role={'button'}
                      aria-label={item?.name}
                      onClick={() => {
                        setSelectedGoal(item)
                        onAssignTo(obj?.assignTo?.id, obj?.dueDate, item, obj, index)
                        onClick()
                        dropdownRef?.current?.toggle()
                      }}
                      className="w-50 whitespace-nowrap overflow-ellipsis overflow-hidden"
                    >
                      {item.name}
                    </div>
                  </Dropdown.Item>
                )
            )
          ) : type === 'filter' || type === 'assessment-filter' ? (
            ownerSuggestedList?.map(
              (item) =>
                item?.id && (
                  <Dropdown.Item key={item?.id}>
                    <div
                      role={'button'}
                      aria-label={item?.name}
                      onClick={() => {
                        item.onClick()
                        dropdownRef?.current?.toggle()
                      }}
                      className="w-50 whitespace-nowrap overflow-ellipsis overflow-hidden"
                    >
                      {item.name}
                    </div>
                  </Dropdown.Item>
                )
            )
          ) : type === 'assessment-cycle-select' ? (
            ownerSuggestedList?.map(
              (item) =>
                item?.id && (
                  <Dropdown.Item key={item?.id}>
                    <div
                      role={'button'}
                      aria-label={item?.name}
                      onClick={() => {
                        item.onClick()
                        dropdownRef?.current?.toggle()
                      }}
                      className="w-80 whitespace-nowrap overflow-ellipsis overflow-hidden"
                    >
                      {item.name}
                    </div>

                    {item?.isCurrent && <Label label={'CURRENT'} labelColor="text-black" bgColor={'bg-themeBlue2'} />}
                  </Dropdown.Item>
                )
            )
          ) : (
            ownerSuggestedList?.map(
              (item) =>
                item?.id && (
                  <Dropdown.Item key={item?.id}>
                    <div
                      role={'button'}
                      aria-label={item?.name}
                      onClick={() => {
                        item.onClick()
                        dropdownRef?.current?.toggle()
                      }}
                      className="w-50 whitespace-nowrap overflow-ellipsis overflow-hidden"
                    >
                      {item.name}
                    </div>
                  </Dropdown.Item>
                )
            )
          )
        ) : (
          <p className="px-3 w-52 whitespace-pre-line py-1 text-gray-900 text-xs">
            {type === 'goal' ? 'No goals found' : 'No employee found'}
          </p>
        )}
      </div>
    </Dropdown>
  )
}
