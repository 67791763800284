/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'

import { get, map, truncate } from 'lodash'
import Table2 from 'components/organisms/Table/Table2'
import Tooltip2 from 'components/atoms/Tooltip/Tooltip2'
import Avatar from 'components/atoms/Avatar'
import { useLocation, useParams } from 'react-router-dom'
import { HttpRequest } from 'helpers/services'
import { ExploreHooks } from 'components/organisms/Goals/ExploreHooks/ExploreHooks'
import moment from 'moment'
import { useSelector } from 'react-redux'
import Rating from 'components/templates/Review/Rating/Rating'
import useIsMobile from 'hooks/useIsMobile'

const ExploreGoalList = ({
  data,
  loading = false,
  isManage = false,
  setRefetch,
  avgRating,
  onAvgRatingChange,
  onGoalsRatingChange,
  isDisabled = false,
  setSelectedGoal,
  setViewModal,
  ratingSys,
  valueRatingSys,
  isSelf,
  isManager,
  employeeSubmitted,
  managerSubmitted,
  assessmentType,
  excludedGoals,
  setExcludedGoals,
  ancestor,
}: {
  data?: any[]
  loading?: boolean
  isManage?: boolean
  onCreate?: () => void
  onSort?: () => void
  setRefetch?: any
  setSelected?: any
  chipClose?: any
  setAllActive?: any
  avgRating?: any
  onAvgRatingChange?: any
  onGoalsRatingChange?: any
  isSelf?: boolean
  goalsSelfAssessment?: string
  isDisabled?: boolean
  setSelectedGoal?: any
  setViewModal?: any
  ratingSys?: any
  valueRatingSys?: any
  isManager?: any
  employeeSubmitted?: any
  managerSubmitted?: any
  assessmentType?: any
  excludedGoals?: any
  setExcludedGoals?: any
  ancestor?: any
}) => {
  const [localCopy, setLocalCopy] = useState(data)
  const [showCreate, setShowCreate] = useState(false)
  const [editGoal, setEditGoal] = useState(false)
  const [endGoalModal, setEndGoalModal] = useState(false)
  const [deleteGoalModal, setDeleteGoalModal] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [update, setUpdate] = useState(false)
  const [timelineList, setTimelineList] = useState([])
  const [initialized, setInitialized] = useState(false)

  const { getLabelGoalList } = ExploreHooks()

  const user = useSelector((state) => get(state, 'user.user'))
  const { companyId }: any = useParams()
  const location = useLocation()
  const isSubGoalPage = location.pathname.includes('subgoal')

  const [breadcrumbList, setBreadcrumbList] = useState([])
  const [timelineRefetch, setTimelineRefetch] = useState(false)

  const fetchGoal = (goalId: any) => {
    setFetching(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/goal/${goalId}`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          const goals = data?.data?.goal
          const subgoals = data?.data?.subgoals
          setSelectedGoal({ ...goals, subgoals })
        }
        setFetching(false)
      })
      .catch((err) => {
        console.error(err)
        setFetching(false)
      })
      .finally(() => {
        setTimeout(() => {
          setFetching(false)
        }, 1000)
      })
  }

  useEffect(() => {
    setLocalCopy(data)
  }, [data])

  useEffect(() => {
    if (!isSubGoalPage) {
      setBreadcrumbList([])
    }
  }, [isSubGoalPage])

  const isMobile = useIsMobile()

  const Breadcrumb = () => {
    return (
      <div className="flex items-center pt-4 m-2 gap-1 -ml-1">
        <div className="w-full flex items-center justify-between">
          <p className={`${isMobile ? 'text-sm' : ''} font-bold`}>Goals</p>
          <div className="flex pointer-events-none cursor-not-allowed">
            {avgRating > 0 && (
              <p className="flex items-center justify-center text-xs py-1 px-2 mr-4 rounded-md bg-gray-100 shadow text-blue-800 font-bold">
                {avgRating}
              </p>
            )}
            <Rating
              defaultValue={avgRating}
              onClick={(value) => onAvgRatingChange(value, 'goals')}
              fillColor="#1F51FF"
              ratingSys={ratingSys}
              valueRatingSys={valueRatingSys}
            />
          </div>
        </div>
      </div>
    )
  }

  const dataList = map(localCopy, (user, index) => {
    const owner = user?.owner?.length && (
      <div className="flex w-full justify-start">
        {user?.owner?.map((item) => (
          <Tooltip2 companyId={companyId} user={item?.owner} Key={item?.owner}>
            <Avatar
              name={item?.owner?.fullName}
              medium
              className="-ml-1 pt-1"
              image={item?.owner?.profileImageThumbnailUrl}
            />
          </Tooltip2>
        ))}
      </div>
    )

    const dueDate = new Date(user?.dueDate)

    const dueDateString = `${dueDate
      .toLocaleString('default', { month: 'numeric' })
      .substring(0, 3)}/${dueDate.getDate()}/${dueDate.getFullYear()}`

    let lastUpdated = ''

    if (user?.goalTimeline?.length !== 0) {
      lastUpdated = moment(user?.goalTimeline?.[0]?.updatedAt).fromNow()
    } else {
      lastUpdated = 'Never'
    }

    const subgoals = (
      <span className="flex items-center gap-2">
        <p className="ml-1">{user?.subgoals?.length}</p>
        {user?.subgoals?.length > 0 && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-subtask"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="6" y1="9" x2="12" y2="9" />
            <line x1="4" y1="5" x2="8" y2="5" />
            <path d="M6 5v11a1 1 0 0 0 1 1h5" />
            <rect x="12" y="7" width="8" height="4" rx="1" />
            <rect x="12" y="15" width="8" height="4" rx="1" />
          </svg>
        )}
      </span>
    )

    const title = (
      <span
        onClick={() => {
          setViewModal(true)
          setUpdate(false)
        }}
      >
        {truncate(user.name, { length: 40 })}
      </span>
    )

    const review = (
      <div className={isDisabled ? 'pointer-events-none cursor-not-allowed' : 'flex cursor-pointer'}>
        <Rating
          defaultValue={user?.rating}
          onClick={(value) => onGoalsRatingChange(value, index)}
          fillColor="#ffd700"
          isSelf={isSelf}
          isManager={isManager}
          employeeSubmitted={employeeSubmitted}
          managerSubmitted={managerSubmitted}
          assessmentType={assessmentType}
          ratingSys={ratingSys}
          valueRatingSys={valueRatingSys}
        />
      </div>
    )

    const returnData = {
      id: user?.id,
      title,
      status: getLabelGoalList(user, () => {
        setViewModal(true)
        setUpdate(true)
      }),
      subgoals,
      assessment: review,
      due: dueDateString,
    }

    return returnData
  })

  const onCreate = () => {
    setShowCreate(true)
  }

  const headers = () => {
    if (ancestor === 'manager-appraisal') {
      return ['', 'Title', 'Assessment', 'Status', 'Due']
    } else {
      return ['Title', 'Assessment', 'Status', 'Due']
    }
  }

  const GoalListConfig = {
    headers: headers(),
    loading,
    dataList,
    onCreate,
    config: {
      dark: false,
      headers: {
        textColor: 'text-black ',
        textSize: 'text-xs',
        bgColor: isManage ? 'border-t' : '',
      },
      dataList: {
        textSize: 'text-sm',
        items: localCopy,
        onItemClick: (goal) => {
          fetchGoal(goal.id)
        },
      },
    },
  }

  return (
    <>
      <Breadcrumb />
      <Table2
        {...GoalListConfig}
        ancestor={ancestor}
        excludedGoals={excludedGoals}
        setExcludedGoals={setExcludedGoals}
        onGoalsRatingChange={onGoalsRatingChange}
      />
    </>
  )
}

export default ExploreGoalList
