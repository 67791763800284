/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpRequest } from 'helpers/services'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { useParams } from 'react-router'
import { useSettings } from 'hooks/useSettings'

export const useCompanyTools = () => {
  const dispatch: any = useDispatch()
  const loggedInUser = useSelector((state) => get(state, 'user.user'))
  const { companyId }: any = useParams()
  const { loading, setLoading } = useSettings()
  const companyHeader = { 'x-appengine-company-id': companyId }

  const getCompanyUserInfo = (companyId) =>
    new Promise((resolve, reject) => {
      HttpRequest('GET', `/company/user/${companyId}/user-profile`, {}, companyHeader)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve(res.data?.data)
          } else {
            dispatch(setErrorMessages(['Error while fetching company user info']))
            reject('Error while fetching company user info')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const getEmployeeUserInfo = (companyId) =>
    new Promise((resolve, reject) => {
      HttpRequest('GET', `/company/user/${companyId}/user-profile`, {}, companyHeader)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve(res.data?.data)
          } else {
            dispatch(setErrorMessages(['Error while fetching company user info']))
            reject('Error while fetching company user info')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const getWorkHistory = (employeeId) =>
    new Promise((resolve, reject) => {
      HttpRequest('GET', `/company/work-history/${employeeId}`, {}, companyHeader)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve(res.data?.data)
          } else {
            dispatch(setErrorMessages(['Error while fetching User Work History']))
            reject('Error while fetching User Work History')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const deleteWorkHistory = (workHistoryId, onSuccess) =>
    new Promise((resolve, reject) => {
      HttpRequest('DELETE', `/company/work-history/${workHistoryId}`, {}, companyHeader)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            onSuccess()
            resolve(res.data?.data)
          } else {
            dispatch(setErrorMessages(['Error while deleting User Work History']))
            reject('Error while deleting User Work History')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const getRooms = () =>
    new Promise((resolve, reject) => {
      HttpRequest('GET', `/meeting-room/listMeetingRooms`, {}, companyHeader)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve(res.data?.data)
          } else {
            dispatch(setErrorMessages(['Error while fetching rooms']))
            reject('Error while fetching rooms')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const updateActiveDirectory = (data) =>
    new Promise((resolve, reject) => {
      HttpRequest('PATCH', `/company/sync-active-directory`, data, companyHeader)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve(res.data?.data)
          } else {
            dispatch(setErrorMessages(['Error while syncing active directory']))
            reject('Error while syncing active directory')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const updateSamlInfo = (data) =>
    new Promise((resolve, reject) => {
      HttpRequest('PATCH', `/company/saml-config`, data, companyHeader)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            dispatch(setSuccessMessage('Successfully updated saml config'))
            resolve(res.data?.data)
          } else {
            dispatch(setErrorMessages(['Error while syncing saml config']))
            reject('Error while syncing saml config')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const getCompany = () =>
    new Promise((resolve, reject) => {
      HttpRequest('GET', `/company/`, {}, companyHeader)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve(res.data?.data)
          } else {
            dispatch(setErrorMessages(['Error while fetching company info']))
            reject('Error while fetching company info')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  return {
    getCompanyUserInfo,
    getEmployeeUserInfo,
    getWorkHistory,
    deleteWorkHistory,
    getRooms,
    updateActiveDirectory,
    updateSamlInfo,
    getCompany,
  }
}
