import useIsMobile from 'hooks/useIsMobile'
import React from 'react'

const BodyWrapper = ({ children }: { children: React.ReactNode }) => {
  const isMobile = useIsMobile()
  return (
    <div className={isMobile ? 'relative min-h-screen overflow-hidden' : 'relative min-h-screen'}>
      <main className="w-full min-h-screen">{children}</main>
    </div>
  )
}

export default BodyWrapper
