/* eslint-disable @typescript-eslint/no-unused-vars */

import PendingRequestsModal from 'components/templates/Review/Modal/PendingRequestsModal'
import { HttpRequest } from 'helpers/services'
import useIsMobile from 'hooks/useIsMobile'
import { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const RequestedFeedbackList = ({
  user,
  companyUserInfo,
  isInfoFetching,
  setFeedbackModalShow,
  companyId,
  refetch,
  setRefetch,
  type,
  setRequestType,
}: {
  user?: any
  companyUserInfo?: any
  isInfoFetching?: boolean
  setFeedbackModalShow?: any
  companyId?: any
  refetch?: boolean
  setRefetch?: any
  type: string
  setRequestType?: any
}) => {
  const location = useLocation()
  const isMobile = useIsMobile()
  const pathname = location.pathname
  const userId = pathname.split('/')[4]
  const [requestFeedbackModalShow, setRequestFeedbackModalShow] = useState(false)
  const [fetching, setFetching] = useState(false)
  const [feedbackList, setFeedbackList] = useState([])
  const [company, setCompany]: any = useState()
  const [selectedCompany, setSelectedCompany]: any = useState()

  const onCloseRequestsFeedbackModal = () => {
    setRequestFeedbackModalShow(false)
  }

  const fetchFeedback = () => {
    setFetching(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-size': 100,
      'x-appengine-for-assessment': true,
    }

    const data = {
      feedbackType: 'GIVEN',
      isTeam: false,
      type: 'REQUEST',
      isPending: false,
    }

    const URL = `/feedback/list/${userId}/requests`

    HttpRequest('POST', URL, data, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const feedback = data?.data?.feedbacks
          if (feedback) {
            setFeedbackList(feedback)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setRefetch(false)
        setFetching(false)
      })
  }

  useEffect(() => {
    if (userId) {
      fetchFeedback()
    }
  }, [requestFeedbackModalShow, userId, user, selectedCompany])

  useEffect(() => {
    if (refetch) {
      fetchFeedback()
    }
  }, [refetch])

  const getCompanyName = () => {
    user?.employee?.forEach((company) => {
      if (company.companyId === parseInt(companyId)) {
        setCompany(company)
      }
    })
  }

  useEffect(() => {
    getCompanyName()
  }, [company, getCompanyName])

  useEffect(() => {
    user.employee.map((company) => {
      if (company.companyId === parseInt(companyId)) {
        setSelectedCompany(company)
      }
    })
  }, [user])

  const isSelf = company?.employeeId === companyUserInfo?.myProfile?.employeeId

  return (
    <>
      <PendingRequestsModal
        fetching={fetching}
        feedbackList={feedbackList}
        setOpen={setRequestFeedbackModalShow}
        open={requestFeedbackModalShow}
        onCloseModal={onCloseRequestsFeedbackModal}
      />
      <div className={`${isMobile ? 'flex-col items-start' : 'text-start'} flex justify-between`}>
        <div className="mx-4 mt-2">
          <p
            onClick={() => setRequestFeedbackModalShow(true)}
            className={`${isMobile ? 'text-xs' : 'text-sm'} text-blue-400 cursor-pointer`}
          >
            Pending Feedback Requests
          </p>
        </div>

        {isInfoFetching ? (
          <div
            className={`w-40 mt-2 mb-2 mx-4 h-6 ${
              isMobile ? 'text-xs' : 'text-sm'
            } rounded-full bg-gray-200 animate-pulse cursor-pointer`}
          />
        ) : (
          <div
            onClick={() => {
              type === 'manager' ? setRequestType('manager') : setRequestType('self')
              setFeedbackModalShow(true)
            }}
            className={`pt-2 pb-2 px-4 ${isMobile ? 'text-xs' : 'text-sm'} text-blue-400 cursor-pointer`}
          >
            Request 360 Feedback
          </div>
        )}
      </div>
    </>
  )
}

export default RequestedFeedbackList
