/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'

import AppraisalsMetricsSidebar from 'components/atoms/AppraisalsMetricsSidebar'
import Avatar from 'components/atoms/Avatar'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import AppraisalsInfoSidebar from 'components/organisms/AppraisalsInfoSidebar'
import AppraisalsSidebar from 'components/organisms/AppraisalsSidebar'
import MeetingGoalDetails from 'components/templates/Meeting/OneOne/Dialogs/MeetingGoalDetails'
import ProfileComponents from 'components/templates/Review/ProfileComponents'
import { DashboardLayout } from 'containers/Layouts/DashboardLayout'
import { useCompanyTools } from 'hooks/useCompanyTools'
import useIsMobile from 'hooks/useIsMobile'
import { useReviewTools } from 'hooks/useReviewTools'
import { get, isEmpty, map, truncate as trim } from 'lodash'
import { FaInfoCircle } from 'react-icons/fa'
import { TbDeviceAnalytics } from 'react-icons/tb'
import { Route, Routes, useLocation, useNavigate, useParams } from 'react-router-dom'
import { useTeams } from 'components/templates/People/ContextApi/TeamContext'

const truncate = (str) => {
  const maxLength = 12
  if (str?.length > maxLength) {
    return str?.slice(0, maxLength) + '...'
  } else {
    return str
  }
}

const initialData = {
  avgRating: {},
  company: {},
  isManager: '',
  isSelf: '',
  selfValues: {},
}

const getLocalCompany = () => {
  let company = localStorage.getItem('company')
  if (company) {
    return JSON.parse(localStorage.getItem('company'))
  } else {
    return {}
  }
}

const Review = () => {
  const { getCompanyUserInfo } = useCompanyTools()
  const [companyUserInfo, setCompanyUserInfo]: any = useState(getLocalCompany())
  const [selectedCompany, setSelectedCompany]: any = useState()

  const [isFetching, setIsFetching] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()
  const pathName = location.pathname

  const isMobile = useIsMobile()

  const user: any = useSelector((state) => get(state, 'user.user'))
  const { companyId, path, subpath }: any = useParams()
  const [company, setCompany]: any = useState()
  const { getSkipLevelSignoff } = useReviewTools()

  const [selectedEmployee, setSelectedEmployee]: any = useState({})
  const [viewModal, setViewModal] = useState(false)
  const [dataFromReviewSegment, setDataFromReviewSegment] = useState(initialData)
  const [viewInfoSidebar, setViewInfoSidebar] = useState(false)
  const [viewMetricsSidebar, setViewMetricsSidebar] = useState(false)
  const [selectedGoal, setSelectedGoal] = useState<any>({})
  const [goalDetailModal, setGoalDetailModal] = useState<any>(false)

  const { fetchTeams, fetchTeamListContext } = useTeams()

  const handleDataFromReviewSegment = (data) => {
    setDataFromReviewSegment(data)
  }

  const handleSelectedEmployee = (data) => {
    setSelectedEmployee(data)
  }

  const fetchCompanyUserInfo = async () => {
    localStorage.getItem('company') === '{}' && setIsFetching(true)
    try {
      const data = await getCompanyUserInfo(company.employeeId)
      setCompanyUserInfo(data)
      setIsFetching(false)
    } catch (e) {
      setIsFetching(false)
    }
  }

  useEffect(() => {
    localStorage.setItem('company', JSON.stringify(companyUserInfo))
  }, [companyUserInfo])

  const [skipData, setSkipData] = useState<any>([])
  const fetchSkipLevelSignoff = () => {
    const onSuccess = (res) => {
      setSkipData(res)
    }
    getSkipLevelSignoff(companyId, company?.employeeId, onSuccess)
  }

  useEffect(() => {
    if (company?.employeeId) {
      fetchSkipLevelSignoff()
    }
  }, [company, companyId])

  useEffect(() => {
    fetchTeamListContext(true)
  }, [company])

  const getCompanyName = () => {
    user?.employee?.forEach((company) => {
      if (company.companyId === parseInt(companyId)) {
        setCompany(company)
      }
    })
  }

  useEffect(() => {
    getCompanyName()
  }, [company, getCompanyName])

  useEffect(() => {
    fetchCompanyUserInfo()
  }, [company])

  const selectedUserClass = 'bg-white shadow'

  useEffect(() => {
    user.employee.map((company) => {
      if (company.companyId === parseInt(companyId)) {
        setSelectedCompany(company)
      }
    })
  }, [user])

  const hideSelfRating = () => {
    setViewModal(false)
  }

  const appraisalsSidebarProps = {
    hideSelfRating,
    selectedEmployee,
    viewModal,
  }

  const sidebarContent = (
    <>
      <div className="font-roboto">
        <div className="pl-8 mb-5">
          <h4 className={'capitalize text-gray-600 font-bold text-sm flex items-center gap-2 cursor-pointer'}>
            <Tooltip position={'bottom'} text={selectedCompany?.companyName}>
              {trim(selectedCompany?.companyName, { length: 10 })} Assessment
            </Tooltip>
          </h4>
        </div>
        <div
          className={`flex items-center px-8 pt-2 pb-2 mb-2 justify-start cursor-pointer ${
            path === 'user' && parseInt(subpath) === company?.employeeId && selectedUserClass
          }`}
          onClick={() => {
            navigate(`/review/${companyId}/user/${company?.employeeId}`)
          }}
        >
          <Avatar small name={user?.fullName} image={user?.profileImageThumbnailUrl} />
          <h3 className="font-semibold text-sm pl-3">My Assessment</h3>
        </div>
        {companyUserInfo?.team && companyUserInfo?.team[0] && (
          <>
            <div className="px-4 mt-5 ">
              <hr className="border-themeGray3" />
            </div>
            <div
              className={`flex items-center px-8 pt-2 pb-2 justify-start cursor-pointer ${
                pathName.includes('direct-reports') && selectedUserClass
              }`}
              onClick={() => {
                navigate(`/people/${companyId}/direct-reports`)
              }}
            >
              <h3 className="font-semibold text-sm">{trim('Direct Reports Assessment', { length: 24 })}</h3>
            </div>
          </>
        )}
        {isFetching ? (
          <div className="space-y-4 pt-4 px-5">
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
          </div>
        ) : (
          <>
            {map(
              companyUserInfo?.team?.filter((item) => item?.status === 'Active'),
              (people, idx) => {
                return (
                  <div
                    className={`flex items-center px-8 py-2 justify-start cursor-pointer ${
                      path === 'user' && parseInt(subpath) === people?.employeeId && selectedUserClass
                    }`}
                    onClick={() => {
                      if (people.employeeId) {
                        navigate(`/review/${companyId}/user/${people?.employeeId}`)
                      } else {
                        alert('User Pending')
                      }
                    }}
                    key={idx}
                  >
                    <Avatar small name={people?.fullName} image={people?.profileImageThumbnailUrl} />
                    <h3 className="font-semibold text-sm ml-3 capitalize">{people?.fullName}</h3>
                  </div>
                )
              }
            )}
          </>
        )}
        {
          <>
            <div className="px-4 mt-5 ">
              <hr className="border-themeGray3" />
            </div>
            <div
              className={`flex items-center px-8 pt-2 pb-2 justify-start cursor-pointer ${
                pathName.includes('skip-level-signoff') && selectedUserClass
              }`}
            >
              <h3 className="font-semibold text-sm">Assesment Signoffs</h3>
            </div>
          </>
        }

        {isFetching && skipData?.length > 0 ? (
          <div className="space-y-4 pt-4 px-5">
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
          </div>
        ) : (
          <>
            {skipData?.map((skipLevelData) => (
              <div
                className={`flex items-center px-8 py-2 justify-start cursor-pointer ${
                  path === 'user' && parseInt(subpath) === skipLevelData?.targetEmployee?.id && selectedUserClass
                }`}
                onClick={() => {
                  if (skipLevelData.targetEmployee?.id) {
                    navigate(`/review/${companyId}/user/${skipLevelData?.targetEmployee?.id}`)
                  } else {
                    alert('User Pending')
                  }
                }}
                key={skipLevelData?.id}
              >
                <Avatar
                  small
                  name={skipLevelData?.targetEmployee?.fullName}
                  image={skipLevelData?.targetEmployee?.profileImageThumbnailUrl}
                />
                <h3 className="font-semibold text-sm ml-3 capitalize">{skipLevelData?.targetEmployee?.fullName}</h3>
              </div>
            ))}
          </>
        )}

        {skipData?.length === 0 && (
          <div className="my-2 font-semibold px-8">
            <h3 className="text-sm pl-3 border-l-2 border-pink-400">No Signoffs</h3>
          </div>
        )}

        <>
          <div className="px-4 mt-5 ">
            <hr className="border-themeGray3" />
          </div>
          <div
            className={`flex items-center px-8 pt-2 pb-2 justify-start cursor-pointer ${
              pathName.includes('manager') && selectedUserClass
            }`}
          >
            <h3 className="font-semibold text-sm">Manager</h3>
          </div>
        </>

        {isFetching ? (
          <div className="space-y-4 pt-4 px-5">
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
          </div>
        ) : companyUserInfo?.managers?.id ? (
          <>
            <div
              className={`flex items-center px-8 py-2 justify-start cursor-pointer ${
                path === 'user' && parseInt(subpath) === companyUserInfo?.managers?.id && selectedUserClass
              }`}
              onClick={() => {
                navigate(`/people/${companyId}/user/${companyUserInfo?.managers?.id}/overview`)
              }}
            >
              <Avatar
                small
                withName
                name={truncate(companyUserInfo?.managers?.fullName)}
                image={companyUserInfo?.managers?.profileImageThumbnailUrl}
                nameClass="font-semibold text-sm"
              />
            </div>
          </>
        ) : (
          <div className="my-2 font-semibold px-8">
            <h3 className="text-sm pl-3 border-l-2 border-pink-400">No Manager</h3>
          </div>
        )}

        {
          <>
            <div className="px-4 mt-5 ">
              <hr className="border-themeGray3" />
            </div>
            <div
              className={`flex items-center px-8 pt-2 pb-2 justify-start cursor-pointer ${
                pathName.includes('department') && selectedUserClass
              }`}
              onClick={() => navigate(`/company/${companyId}/departments`)}
            >
              <h3 className="font-semibold text-sm">Departments</h3>
            </div>
          </>
        }

        {isFetching ? (
          <div className="space-y-4 pt-4 px-5">
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
          </div>
        ) : !isEmpty(companyUserInfo?.myProfile?.department) ? (
          <>
            {companyUserInfo?.myProfile?.department?.map((department) => (
              <div
                className={`flex items-center px-8 py-2 justify-start cursor-pointer ${
                  path === 'department' && parseInt(subpath) === department.id && selectedUserClass
                }`}
                onClick={() => {
                  navigate(`/people/${companyId}/department/${department.id}`)
                }}
              >
                <h3 className={`font-semibold text-sm capitalize`}>
                  <Avatar small withName name={truncate(department?.name)} nameClass="font-semibold text-sm" />
                </h3>
              </div>
            ))}
          </>
        ) : (
          <div className="my-2 font-semibold px-8">
            <h3 className="text-sm pl-3 border-l-2 border-pink-400">No Departments</h3>
          </div>
        )}

        {
          <>
            <div className="px-4 mt-5 ">
              <hr className="border-themeGray3" />
            </div>
            <div
              className={`flex items-center px-8 pt-2 pb-2 justify-start cursor-pointer ${
                pathName.includes('department') && selectedUserClass
              }`}
              onClick={() => {
                navigate(`/company/${companyId}/teams`)
              }}
            >
              <h3 className="font-semibold text-sm">Teams</h3>
            </div>
          </>
        }

        {isFetching ? (
          <div className="space-y-4 pt-4 px-5">
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
            <div className="h-6 w-44 bg-gray-200 animate-pulse duration-700 rounded"></div>
          </div>
        ) : !isEmpty(fetchTeams) ? (
          <>
            {fetchTeams?.map((team) => (
              <div
                className={`flex items-center px-8 py-2  justify-start cursor-pointer 
                  ${path === 'teams' && parseInt(subpath) === team?.id && selectedUserClass}`}
                onClick={() => {
                  navigate(`/people/${companyId}/teams/${team.id}/members`)
                }}
              >
                <h3 className={`font-semibold text-sm capitalize`}>
                  <Avatar small withName name={truncate(team?.name)} nameClass="font-semibold text-sm" />
                </h3>
              </div>
            ))}
          </>
        ) : (
          <div className="my-2 font-semibold px-8">
            <h3 className="text-sm pl-3 border-l-2 border-pink-400">No Teams</h3>
          </div>
        )}
      </div>
    </>
  )

  return (
    <DashboardLayout sidebarContent={sidebarContent}>
      <Routes>
        <Route
          element={
            <ProfileComponents
              setSelectedGoal={setSelectedGoal}
              setViewModal={setGoalDetailModal}
              onDataFromReviewSegment={handleDataFromReviewSegment}
              handleSelectedEmployee={handleSelectedEmployee}
              viewSidebar={viewModal}
              setViewSidebar={setViewModal}
            />
          }
          path="/user/:userId/*"
        />
      </Routes>
      {dataFromReviewSegment?.isManager && (
        <button
          className={`${
            isMobile ? 'flex items-center justify-center' : ''
          } appraisal-tooltip bg-gray-800 absolute z-50 px-4 py-2.5 rounded-t-lg`}
          style={{ top: '270px', right: isMobile ? '-48px' : '-61px' }}
          onClick={() => setViewModal(true)}
        >
          <span className={`${isMobile ? 'text-xs' : ''} text-white`}>Self Assessment</span>
        </button>
      )}
      <button
        className="bg-gray-800 absolute -right-1 top-28 z-50 p-3 rounded-l-lg"
        onClick={() => setViewInfoSidebar(true)}
      >
        <span className="text-white">
          <FaInfoCircle className={isMobile ? 'w-4 h-4' : 'w-5 h-5'} />
        </span>
      </button>
      <button
        className="bg-gray-800 absolute -right-1 top-40 z-10 p-3 rounded-l-lg"
        onClick={() => setViewMetricsSidebar(true)}
      >
        <span className="text-white">
          <TbDeviceAnalytics size={isMobile ? 16 : 22} className=" text-xl" />
        </span>
      </button>
      {dataFromReviewSegment?.isManager && <AppraisalsSidebar {...appraisalsSidebarProps} />}
      <AppraisalsInfoSidebar viewInfoSidebar={viewInfoSidebar} setViewInfoSidebar={setViewInfoSidebar} />
      <AppraisalsMetricsSidebar
        viewMetricsSidebar={viewMetricsSidebar}
        setViewMetricsSidebar={setViewMetricsSidebar}
        companyId={companyId}
        selectedEmployee={selectedEmployee}
      />
      <MeetingGoalDetails
        viewModal={goalDetailModal}
        selectedGoal={selectedGoal}
        setSelectedGoal={setSelectedGoal}
        setViewModal={setGoalDetailModal}
      />
    </DashboardLayout>
  )
}

export default Review
