import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import Button from 'components/atoms/Button/button'

const Counter = ({
  value,
  setValue,
  min,
  max,
}: {
  value: number
  setValue: (newValue: number) => void
  min?: number
  max?: number
}) => {
  return (
    <div className="flex justify-between items-center gap-2">
      <Button
        size="md"
        btnColor="bg-blue-500"
        hoverColor="bg-blue-500"
        className="font-bold text-lg w-20 rounded-tr-none rounded-br-none py-2"
        label={'-'}
        primary
        onClick={() => setValue(Number(value) - 1)}
        disabled={value <= min}
      />
      <NormalFormInput
        value={value}
        type="number"
        onChange={(e) => setValue(e.target.value)}
        className="-mt-1 text-center pr-2"
        ancestor="counter"
      />
      <Button
        size="md"
        btnColor="bg-blue-500"
        hoverColor="bg-blue-500"
        className="font-bold text-lg w-20 rounded-tl-none rounded-bl-none py-2"
        label={'+'}
        primary
        onClick={() => setValue(Number(value) + 1)}
        disabled={value >= max}
      />
    </div>
  )
}

export default Counter
//92000000000350268
