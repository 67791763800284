/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'

import Button from 'components/atoms/Button/button'
import Divider from 'components/atoms/Divider'
import { useTranslation } from 'react-i18next'
import useTabs from 'components/organisms/Tabs/useTabs'
import { HttpRequest } from 'helpers/services'
import { useParams } from 'react-router'
import { MultiSelctor } from 'components/atoms/formcompomnents/MultipleSelector'
import { AiFillLock } from 'react-icons/ai'
import FeedbackEditor from 'components/atoms/Editor/FeedbackEditor'

import { useDebounce } from 'helpers/searchDebouncer'

const RequestFeedbackSegment = ({
  fromEmployees,
  toEmployees,
  setFromEmployees,
  setToEmployees,
  visibility,
  setVisibility,
  setShowAcknowledgement,
  defaultDescription,
  setRefetch,
}: any) => {
  const { t } = useTranslation()

  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [feedbackDescription, setFeedbackDescription] = useState('')
  const [visibilityLabel, setVisibilityLabel] = useState('Privately')
  const [employeeList, setEmployeeList] = useState([])
  const [initialized, setInitialized] = useState(false)
  const [fromEmployee, setFromEmployee] = useState(null)
  const [toEmployee, setToEmployee] = useState(null)
  const [employeeFromSearch, setEmployeeFromSearch] = useState('')
  const [employeeToSearch, setEmployeeToSearch] = useState('')

  const [isEmployeelistLoading, setIsEmployeelistLoading] = useState(false)

  const visibilityTabs = [
    { name: `Private + Manager`, color: 'secondary', icon: <AiFillLock /> },
    { name: `Manager`, color: 'secondary', icon: <AiFillLock /> },
  ]

  const tabs2 = useTabs(visibilityTabs)
  const curVisibilityTab = tabs2.curTab

  const onSaveFeedback = () => {
    setRefetch(true)
    requestFeedback()
  }

  useEffect(() => {
    setVisibility('PRIVATE')
  }, [])

  useEffect(() => {
    if (curVisibilityTab === 'Private + Manager') {
      setVisibilityLabel('Private')
      setVisibility('PRIVATE')
    } else {
      setVisibilityLabel('Manager')
      setVisibility('MANAGER_ONLY')
    }
  }, [tabs2])

  useEffect(() => {
    if (feedbackDescription.length > 0 && visibility && fromEmployees.length > 0 && toEmployees.length > 0) {
      setDisableSaveButton(false)
    }
  }, [feedbackDescription, fromEmployees, toEmployees, visibility])

  const { companyId }: any = useParams()

  const requestFeedback = () => {
    let data = {}
    const from = fromEmployees.map((e) => {
      return { id: e.id }
    })

    const to = toEmployees.map((e) => {
      return { id: e.employeeId || e.id }
    })

    data['from'] = from
    data['to'] = to

    data['requestDesc'] = feedbackDescription
    data['visibility'] = visibility

    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', '/feedback/request', data, commonHeader)
      .then(({ data }: any) => {
        setShowAcknowledgement(true)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const debouncedEmployeeFromSearchInput = useDebounce(employeeFromSearch, 300)
  const debouncedEmployeeToSearchInput = useDebounce(employeeToSearch, 300)

  const fetchEmployeeList = () => {
    setIsEmployeelistLoading(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-size': -1,
      type: 'tasks',
    }
    if (debouncedEmployeeFromSearchInput) {
      commonHeader['search'] = debouncedEmployeeFromSearchInput
    }
    if (debouncedEmployeeToSearchInput) {
      commonHeader['search'] = debouncedEmployeeToSearchInput
    }
    HttpRequest('GET', '/company/employees/list', {}, commonHeader)
      .then(({ data }: any) => {
        setInitialized(true)
        if (data.code === 200) {
          setIsEmployeelistLoading(false)
          const employees = data?.data
          if (employees) {
            setEmployeeList(employees)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setInitialized(true)
        setIsEmployeelistLoading(false)
      })
  }

  useEffect(() => {
    if (!initialized) {
      fetchEmployeeList()
    }
  }, [])

  useEffect(() => {
    if (initialized) {
      fetchEmployeeList()
    }
  }, [debouncedEmployeeFromSearchInput, debouncedEmployeeToSearchInput])

  const onFromEmployeeSelect = (item) => {
    setEmployeeFromSearch('')

    setFromEmployee(item?.name)
    setFromEmployees([...fromEmployees, item])
    const tempEmployees = employeeList.filter((i) => i.id !== item.id)
    setEmployeeList(tempEmployees.sort((a, b) => a.id - b.id))
  }

  const onToEmployeeSelect = (item) => {
    setEmployeeToSearch('')

    setToEmployee(item?.name)
    setToEmployees([...toEmployees, item])
    const tempEmployees = employeeList.filter((i) => i.id !== item.id)
    setEmployeeList(tempEmployees.sort((a, b) => a.id - b.id))
  }

  const onFromEmployeeChipCancel = (item) => {
    setEmployeeFromSearch('')

    const newEmployee = fromEmployees.filter((owner) => owner?.id !== item?.id)
    setFromEmployees(newEmployee)
  }

  const onToEmployeeChipCancel = (item) => {
    setEmployeeToSearch('')

    const newEmployee = toEmployees.filter((owner) => owner?.id !== item?.id)
    setToEmployees(newEmployee)
    setEmployeeList((prev) => [...prev, item])
  }

  useEffect(() => {
    defaultDescription && setFeedbackDescription(defaultDescription)
  }, [])

  return (
    <>
      <div>
        <div className="">
          <MultiSelctor
            noOptionText="No employees to select"
            width=""
            label="Who do you want to ask for feedback?"
            selectedItem={fromEmployee}
            list={employeeList}
            placeholder="Type their names..."
            onSelect={onFromEmployeeSelect}
            spanClass="py-0.5"
            chipList={fromEmployees}
            onChipCancel={onFromEmployeeChipCancel}
            withAvatar
            isSearchSelector
            searchInput={employeeFromSearch}
            setSearchInput={setEmployeeFromSearch}
            loading={isEmployeelistLoading}
          />
        </div>
        <div className="mt-2">
          <MultiSelctor
            noOptionText="No employees to select"
            width=""
            label="Who’s the feedback about?"
            selectedItem={toEmployee}
            list={employeeList}
            placeholder="Type their name..."
            onSelect={onToEmployeeSelect}
            spanClass="py-0.5"
            chipList={toEmployees}
            onChipCancel={onToEmployeeChipCancel}
            withAvatar
            isSearchSelector
            searchInput={employeeToSearch}
            setSearchInput={setEmployeeToSearch}
            loading={isEmployeelistLoading}
          />
        </div>
        <div className="my-5 opacity-80">
          <FeedbackEditor
            placeholder="I want Feedback On..."
            value={feedbackDescription}
            setValue={setFeedbackDescription}
            // eslint-disable-next-line quotes
            label={"What's your Feedback?"}
          />
        </div>
      </div>
      <Divider className={'mt-4 border-b border-gray-200'} />
      <div className="mt-3 w-full flex items-center justify-end">
        <Button
          loadingText="Saving"
          label={`Request ${visibilityLabel} Feedback`}
          onClick={onSaveFeedback}
          disabled={disableSaveButton}
          primary
        />
      </div>
    </>
  )
}

export default RequestFeedbackSegment
