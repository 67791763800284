import React, { useState } from 'react'
import Button from 'components/atoms/Button/button'
import Modal from 'components/atoms/Modal'
import { HttpRequest } from 'helpers/services'

interface Props {
  endGoalModal: boolean
  setEndGoalModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedGoal?: any
  companyId?: number
  onCancelEdit?: () => void
  setRefetch?: any
  redirect?: boolean
}
const EndGoalDialog = ({
  endGoalModal,
  setEndGoalModal,
  selectedGoal,
  companyId,
  onCancelEdit,
  setRefetch,
  redirect,
}: Props) => {
  const [endGoalSelect, setEndGoalSelect] = useState(0)

  const onEndGoal = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest(
      'PATCH',
      `/goal/${selectedGoal.id}/end`,
      {
        isCompleted: endGoalSelect === 1,
      },
      commonHeader
    )
      .then(({ data }: any) => {
        if (data.code === 200) {
          if (redirect) {
            setEndGoalModal(false)
            window.location.reload()
          } else {
            onCancelEdit()
            setRefetch(true)
          }
        }
        setEndGoalModal(false)
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <Modal
      max={{ w: 152 }}
      hwClassName={'max-w-152'}
      open={endGoalModal}
      setOpen={setEndGoalModal}
      header="End this goal..."
      zIndex={6000}
    >
      <div className="flex justify-center flex-col">
        <div className="flex space-x-4 my-2">
          <div
            className={`border ${
              endGoalSelect === 1 ? 'border-themeBlue text-themeBlue' : 'border-themeGray2 text-themeGray'
            } p-4 rounded-lg flex-1 cursor-pointer`}
            onClick={() => setEndGoalSelect(1)}
          >
            <p className=" font-medium">Complete Goal</p>
            <p className="text-sm mt-2">
              You made a satisfactory level <br /> of progress on this goal.
            </p>
          </div>
          <div
            className={`border ${
              endGoalSelect === 2 ? 'border-themeBlue text-themeBlue' : 'border-themeGray2 text-themeGray'
            } p-4 rounded-lg flex-1 cursor-pointer`}
            onClick={() => setEndGoalSelect(2)}
          >
            <p className="font-medium">Mark as incomplete</p>
            <p className="text-sm mt-2">
              A satisfactory level of progress <br /> was not made on this goal
            </p>
          </div>
        </div>
        <div className="flex justify-end items-center pt-4 space-x-2">
          <Button
            textColor="text-black"
            size="md"
            hoverColor="bg-themeRed"
            focusRing="ring-red-500"
            className="font-semibold"
            label={'Cancel'}
            secondary
            onClick={() => setEndGoalModal(false)}
          />
          <Button
            size="md"
            hoverColor="bg-themeBlue"
            className="font-semibold"
            label={'End Goal'}
            disabled={endGoalSelect === 0}
            primary
            onClick={onEndGoal}
          />
        </div>
      </div>
    </Modal>
  )
}

export default EndGoalDialog
