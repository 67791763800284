import classNames from 'classnames'
import React from 'react'
import { compact } from 'lodash'

const Tabs = ({
  tabs,
  curTab,
  setCurTab,
  secondary,
  tabClass,
  textSize,
  page,
}: {
  tabs: { name: string; count?: number | string; disable?: boolean }[]
  curTab: string
  setCurTab: React.Dispatch<React.SetStateAction<string>>
  secondary?: boolean
  tabClass?: string
  textSize?: string
  page?: string
}) => {
  return (
    <div className="select-none">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-xs md:text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
          //   @ts-ignore
        >
          {tabs &&
            tabs.map((tab, idx) => (
              <option key={idx}>{tab && tab.count ? `${tab?.name} (${tab.count})` : `${tab?.name}`}</option>
            ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav
            className={`-mb-px flex border-b ${page === 'appraisals' && 'justify-between'} ${
              page !== 'appraisals' && 'space-x-8'
            }`}
            aria-label="Tabs"
          >
            {compact(tabs).map((tab, idx) => (
              <button
                key={idx}
                disabled={tab.disable}
                onClick={() => setCurTab(tab?.name)}
                className={classNames(
                  tab?.name === curTab
                    ? `${secondary ? 'font-medium text-black' : 'text-themeBlue'} border-themeBlue`
                    : 'border-transparent text-themeGray hover:text-themeGray4 hover:border-gray-200',
                  tabClass,
                  textSize ? textSize : 'text-xl',
                  'whitespace-nowrap transition-all flex py-3 px-1 border-b-2 font-medium outline-none'
                )}
              >
                {tab && tab.count ? `${tab?.name} (${tab.count})` : `${tab?.name}`}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

const Badge = ({ curTab, count }) => {
  return (
    <span
      className={`${
        curTab ? '-top-3 right-0' : '-top-1 -right-3'
      } transition-all flex items-center justify-center font-bold text-sm absolute bg-blue-600 text-white w-6 h-6 rounded-full`}
    >
      {count}
    </span>
  )
}

export const Tabs2 = ({
  tabs,
  curTab,
  setCurTab,
  todoItemsCount,
}: {
  tabs: { name: string }[]
  curTab: string
  setCurTab: React.Dispatch<React.SetStateAction<string>>
  todoItemsCount?: number
}) => {
  return (
    <div className="select-none">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full pl-3 pr-10 py-2 text-xs md:text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
        >
          {tabs && tabs.map((tab) => <option key={tab?.name}>{tab?.name}</option>)}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav className="-mb-px  flex items-center space-x-8" aria-label="Tabs">
            {tabs.map((tab) => (
              <button
                key={tab?.name}
                onClick={() => setCurTab(tab?.name)}
                className={classNames(
                  tab?.name === curTab
                    ? 'bg-blurred shadow px-4 rounded-lg h-10 flex items-center text-blue-500 font-bold'
                    : 'text-gray-900 hover:text-gray-700',
                  'relative whitespace-nowrap transition-all flex py-4 px-1  font-medium text-base'
                )}
              >
                {tab?.name}{' '}
                {Boolean(tab?.name === 'Tasks' && todoItemsCount && !(todoItemsCount === 0)) && (
                  <Badge curTab={tab?.name === curTab} count={todoItemsCount} />
                )}
              </button>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export const Tabs3 = ({
  tabs,
  curTab,
  setCurTab,
}: {
  tabs: { name: string }[]
  curTab: string
  setCurTab: React.Dispatch<React.SetStateAction<string>>
}) => {
  return (
    <div className="select-none">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 text-xs md:text-base rounded-md"
          //   @ts-ignore
        >
          {tabs && tabs.map((tab) => <option key={tab?.name}>{tab?.name}</option>)}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav className="mb-2 flex space-x-3 my-2" aria-label="Tabs">
            {tabs.map((tab) => (
              <div
                key={tab?.name}
                className={
                  tab?.name === curTab
                    ? 'bg-blue-100 hover:bg-blue-200 transition-all rounded-full'
                    : 'bg-gray-100 rounded-full hover:bg-gray-200 transition-all'
                }
              >
                <button
                  key={tab?.name}
                  onClick={() => setCurTab(tab?.name)}
                  className={classNames(
                    tab?.name === curTab ? 'text-themeDarkerBlue' : '',
                    'whitespace-nowrap transition-all flex py-2 px-4 font-medium text-sm'
                  )}
                >
                  {tab?.name}
                </button>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

// Create goal > Explore goals
export const Tabs4 = ({
  tabs,
  curTab,
  setCurTab,
}: {
  tabs: { name: string }[]
  curTab: string
  setCurTab: React.Dispatch<React.SetStateAction<string>>
}) => {
  return (
    <div className="select-none">
      <div className="hidden sm:block">
        <div className="">
          <nav className="-mb-px flex justify-between border-b pt-3 px-0" aria-label="Tabs">
            {tabs.map((tab) => (
              <div key={tab?.name} className={''}>
                <button
                  onClick={() => setCurTab(tab?.name)}
                  className={classNames(
                    tab?.name === curTab
                      ? `border-themeBlue`
                      : 'border-transparent text-themeGray hover:text-themeGray4 hover:border-gray-200',

                    'whitespace-nowrap transition-all flex py-3 px-12 border-b-2 font-medium'
                  )}
                >
                  {tab?.name}
                </button>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Tabs
