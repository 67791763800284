import { NavSidebar } from 'components/organisms/Menu/NavSidebar'
import BodyWrapper from 'containers/BodyWrapper'
import { classNames } from 'lib/utils/classNames'
import React, { useState } from 'react'

export const DashboardLayout = ({
  children,
  sidebarContent,
  onlyMainSidebar,
  bgColor = 'unset',
}: {
  children: React.ReactNode
  sidebarContent?: React.ReactNode
  onlyMainSidebar?: boolean
  bgColor?: string
}) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true)

  return (
    <BodyWrapper>
      <div className="flex h-screen relative font-roboto">
        <NavSidebar
          onlyMainSidebar={onlyMainSidebar}
          customElements={sidebarContent}
          setIsSidebarOpen={setIsSidebarOpen}
          isSidebarOpen={isSidebarOpen}
        />

        <div
          style={{ background: bgColor }}
          className={classNames('flex  flex-col flex-1 overflow-y-scroll overflow-x-hidden transition-all')}
        >
          <main className="content">
            <section className="sm:flex-row flex flex-col flex-1">
              <div className="" style={{ flexGrow: 2, flexBasis: '0%' }}>
                {children}
              </div>
            </section>
          </main>
        </div>
      </div>
    </BodyWrapper>
  )
}
