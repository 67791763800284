import React, { useEffect, useState } from 'react'
import Button from 'components/atoms/Button/button'
import Modal from 'components/atoms/Modal'
import { Selector2, Selector3 } from 'components/atoms/formcompomnents/Selector'
import DatePickerInput from 'components/atoms/formcompomnents/DatePickerinput'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import { useSelector } from 'react-redux'
import { get, isEmpty } from 'lodash'
import { HttpRequest } from 'helpers/services'
import { useParams } from 'react-router-dom'
import { getTimeFormat, validTimezones } from 'helpers/dateconvertors'
import moment from 'moment'
import { durationFormattedList, timeRangeFormattedList } from 'lib/utils'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { ParticipantDropdown } from 'components/templates/Meeting/OneOne/Dialogs/ParticipantDropdown'
import Avatar from 'components/atoms/Avatar'
import MeetLogo from 'assets/logo/meet.png'
import TeamsLogo from 'assets/logo/teams.png'

const CreateReviewModal = ({
  showModal,
  setShowModal,
  time,
  setTime,
  duration,
  setDuration,
  date,
  setDate,
  setTimeFormat,
  location,
  setLocation,
  isInstant,
  setIsInstant,
  onSave,
  header,
  hideInstantToggle,
  reviewCycle,
  setReviewCycle,
  error,
  participants,
  manualLink,
  setManualLink,
  generateLink,
  setGenerateLink,
}: {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  time?: any
  setTime?: any
  duration?: any
  setDuration?: any
  date?: any
  setDate?: any
  timeFormat?: any
  timeZone?: any
  setTimeFormat?: any
  onSave?: any
  location?: any
  isInstant?: any
  setIsInstant?: any
  setLocation?: any
  header?: React.ReactNode | string
  hideInstantToggle?: boolean
  reviewCycle?: any
  setReviewCycle?: any
  error?: string
  participants?: any
  manualLink?: any
  setManualLink?: any
  generateLink?: any
  setGenerateLink?: any
}) => {
  // @ts-ignore
  const { companyId, userId } = useParams()
  const [timeZone, setTimeZone] = useState(null)
  const [formError, setFormError]: any = useState({})
  const user: any = useSelector((state) => get(state, 'user.user'))
  const [isGoogleCalendarIntegrated, setIsGoogleCalendarIntegrated] = useState(false)
  const [isOutlookCalendarIntegrated, setIsOutlookCalendarIntegrated] = useState(false)
  const [googleCalendarUrl, setGoogleCalendarUrl] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [reviewCycleList, setReviewCycleList] = useState([])
  const [link, setLink] = useState(-1)

  const onCancel = () => {
    setShowModal(false)
  }

  const timeRangeList = timeRangeFormattedList()
  const durationList = durationFormattedList()

  const timeZoneList = [
    {
      id: 'a',
      name: moment().tz(validTimezones['EST']).isDST() ? 'EDT' : 'EST',
    },
    {
      id: 'b',
      name: moment().tz(validTimezones['CST']).isDST() ? 'CDT' : 'CST',
    },
    {
      id: 'c',
      name: moment().tz(validTimezones['MST']).isDST() ? 'MDT' : 'MST',
    },
    {
      id: 'd',
      name: moment().tz(validTimezones['PST']).isDST() ? 'PDT' : 'PST',
    },
    {
      id: 'e',
      name: 'IST',
    },
  ]

  const onTimeSelect = (item) => {
    setTime(item?.name)
  }

  const onDurationSelect = (item) => {
    setDuration(item?.name)
  }

  const onTimeZoneSelect = (item) => {
    setTimeZone(item?.name)
  }

  const onReviewCycleSelect = (item) => {
    setReviewCycle(item)
  }

  const validateForm = () => {
    let isValid = true
    let messages = {
      date: '',
      time: '',
      duration: '',
      location: '',
      reviewCycle: '',
    }

    if (date === null) {
      messages.date = 'Please enter a date'
      isValid = false
    }

    if (time === '') {
      messages.time = 'Please select time'
      isValid = false
    }

    if (reviewCycle === '') {
      messages.reviewCycle = 'Please select a assessment cycle'
      isValid = false
    }

    setFormError({
      ...formError,
      ...messages,
    })
    return isValid
  }

  const onSaveMeeting = () => {
    if (validateForm()) {
      onSave()
    }
  }

  const getMeetingTime = (d?) => {
    const date = d ? new Date(d) : new Date()
    return date.toLocaleTimeString([], {
      hour12: true,
      hour: '2-digit',
      minute: '2-digit',
    })
  }

  const fetchReviews = () => {
    const commonHeader = {
      'x-appengine-size': 0,
      'x-appengine-page': 1,
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/review/cycle/list', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          const reviews = data?.data?.reviewCycle
          if (reviews) {
            setReviewCycleList(reviews)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  useEffect(() => {
    if (showModal) {
      fetchReviews()
    }
  }, [showModal])

  const fetchGoogleUrl = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-user-id': userId,
    }

    HttpRequest('GET', `/user/google-calendar-url`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const url = data?.data?.url
          setGoogleCalendarUrl(url)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  useEffect(() => {
    const token = user.googleIDToken
    setIsOutlookCalendarIntegrated(user.isOutlookCalendarSynced)
    setUserEmail(user.emails[0])
    if (token) {
      const userIntegratedEmail = JSON.parse(atob(token?.split('.')[1])).email
      setIsGoogleCalendarIntegrated(userIntegratedEmail ? true : false)
      setUserEmail(userIntegratedEmail)
    }
    setTimeZone(user.timeZone)
  }, [user])

  useEffect(() => {
    fetchGoogleUrl()
  }, [])

  const currentReviewCycle = () => {
    if (!isEmpty(reviewCycleList)) {
      const filteredList = reviewCycleList.filter((item) => item.iscurrent)[0]
      setReviewCycle(filteredList)
    }
  }

  useEffect(() => {
    currentReviewCycle()
  }, [showModal, reviewCycleList])

  useEffect(() => {
    if (showModal) {
      setTime(getMeetingTime(new Date()).toUpperCase())
      setDuration('30 minutes')
      setDate(new Date())
      setTimeFormat(getTimeFormat(new Date()))
      setLocation('')
    }
  }, [showModal])

  useEffect(() => {
    if (isInstant) {
      setTime(getMeetingTime(new Date()).toUpperCase())
      setDate(new Date())
    }
  }, [isInstant])

  return (
    <Modal hwClassName={'max-w-220 p-0'} open={showModal} setOpen={onCancel}>
      <div className="flex">
        <div className="space-y-6 w-8/12 h-full mr-8">
          <div>
            <Selector2
              showSubText
              required
              selectedItem={reviewCycle}
              list={reviewCycleList}
              label="Assessment Cycle"
              placeholder="Select Assessment Cycle"
              onSelect={onReviewCycleSelect}
              buttonBoxClass="mt-1 py-2.5 "
              width="w-full flex-1"
              error={formError.reviewCycle}
            />
          </div>
          <div className="flex space-x-3 w-full">
            <div className="flex-1">
              <DatePickerInput
                error={formError.date}
                required
                date={date}
                minDate={new Date()}
                setDate={setDate}
                label="Discussion Date"
                disabled={isInstant}
              />
            </div>
            <div className="flex space-x-2 flex-1">
              <Selector3
                required
                selectedItem={time}
                list={timeRangeList}
                label="Start Time"
                placeholder="Ex. 10:00"
                onSelect={onTimeSelect}
                buttonBoxClass="py-2.5 "
                width="flex-1"
                error={formError.time}
                disabled={isInstant}
              />
              <Selector3
                required
                selectedItem={duration}
                list={durationList}
                label="Duration"
                placeholder="Ex. 30 minutes"
                onSelect={onDurationSelect}
                buttonBoxClass="py-2.5 "
                width="flex-1"
                error={formError.duration}
                errorClass="text-xs w-40 absolute"
                disabled={isInstant}
              />
              <div className="flex items-center">
                <Selector3
                  required
                  selectedItem={timeZone}
                  list={timeZoneList}
                  label=""
                  placeholder=""
                  onSelect={onTimeZoneSelect}
                  buttonBoxClass="mt-1 py-2.5 "
                  width="w-full pt-9 flex-1"
                  noMinWidth
                  disabled
                />
              </div>
            </div>
          </div>
          <div>
            <NormalFormInput
              placeholder={`Enter Location...`}
              value={location}
              label={'Location'}
              onChange={(e) => setLocation(e.target.value)}
              error={formError.location}
            />
          </div>
        </div>
        <div className="w-0.5 bg-gray-200"></div>
        <div className="w-4/12 h-full pl-4">
          {participants?.length > 0 && (
            <div className="w-max ml-3 pb-1 mt-3">
              <h2 className="font-semibold text-sm -ml-3">Participants</h2>
              <div className="file__close relative cursor-pointer mt-4">
                <ParticipantDropdown
                  participants={participants}
                  header={
                    <div className="flex items-center">
                      {participants?.map((participant) => (
                        <Tooltip margin="0 0 0 3rem" text={participant?.fullName} position="bottom">
                          <Avatar
                            className="-ml-3"
                            nameClass="font-semibold"
                            name={participant?.fullName}
                            image={participant?.profileImageThumbnailUrl}
                          />
                        </Tooltip>
                      ))}
                    </div>
                  }
                />
              </div>
            </div>
          )}

          <div className="mt-3 mb-4">
            <h4 className="mb-3 text-sm text-black font-semibold">Generate Meeting Link</h4>
            <div className="flex items-center space-x-8">
              <label className="inline-flex items-center">
                <input
                  disabled={!isGoogleCalendarIntegrated}
                  type="checkbox"
                  value="activedirectory"
                  checked={link === 1}
                  onChange={() => {
                    setLink((prev) => (prev === 1 ? -1 : 1))
                    setGenerateLink((prev) => (prev === 'googleMeet' ? '' : 'googleMeet'))
                  }}
                  className={`${
                    !isGoogleCalendarIntegrated ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } cursor-pointer text-blue-500 focus:outline-none focus:ring-0 focus:ring-blue-500`}
                />
                <span
                  className={`${
                    !isGoogleCalendarIntegrated ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } ml-2 text-sm`}
                >
                  <img className="w-8 h-6" alt="google meet" src={MeetLogo} />
                </span>
              </label>
              <label className="inline-flex items-center">
                <input
                  disabled={!isOutlookCalendarIntegrated}
                  type="checkbox"
                  value="activedirectory"
                  checked={link === 2}
                  onChange={() => {
                    setLink((prev) => (prev === 2 ? -1 : 2))
                    setGenerateLink((prev) => (prev === 'microsoftTeams' ? '' : 'microsoftTeams'))
                  }}
                  className={`${
                    !isOutlookCalendarIntegrated ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } text-blue-500 focus:outline-none focus:ring-0 focus:ring-blue-500`}
                />
                <span
                  className={`${
                    !isOutlookCalendarIntegrated ? 'opacity-50 cursor-not-allowed' : 'cursor-pointer'
                  } ml-2 text-sm`}
                >
                  <img className="w-9 h-8" alt="microsoft teams" src={TeamsLogo} />
                </span>
              </label>
            </div>

            <div className="pt-2">
              <NormalFormInput
                className="h-10"
                placeholder={`Enter Manual Link...`}
                value={manualLink}
                label={'Custom Meeting Link'}
                onChange={(e) => setManualLink(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="">
        <div className="space-y-4" style={{ zIndex: 1000 }}></div>
      </div>

      {error && <p className="mt-6 text-red-500 text-sm">{error}</p>}

      <div className="pt-5 my-2  flex items-end justify-between w-full border-gray-200">
        {isGoogleCalendarIntegrated || isOutlookCalendarIntegrated ? (
          <p className="text-sm">
            * Calendar synced with <span className="font-semibold">{userEmail}</span>
          </p>
        ) : (
          <p className="text-sm">
            * No Calendar Integrated,{' '}
            <span onClick={() => (window.location.href = googleCalendarUrl)} className="text-blue-500 cursor-pointer">
              Click here
            </span>{' '}
            to sync
          </p>
        )}
        <Button
          size="md"
          hoverColor="bg-themeBlue"
          className="w-20 font-semibold"
          label={'Save'}
          primary
          onClick={onSaveMeeting}
        />
      </div>
    </Modal>
  )
}

export default CreateReviewModal
