import React, { useEffect, useState, Fragment } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import { useLocation } from 'react-router-dom'
import { Transition } from '@headlessui/react'
import { isEmpty } from 'lodash'

import classNames from 'classnames'
import get from 'lodash/get'
import Avatar from 'components/atoms/Avatar'
import { Dropdown3 } from 'components/atoms/Dropdown/Dropdown'
import { useTranslation } from 'react-i18next'
import { BUTTONS } from 'constants/common'
import { logout } from 'state/redux/actions/user.actions'
import useKeyPress from 'hooks/useKeyPress'
import { avatarInitials } from 'lib/utils'
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg'
import { ReactComponent as GoalIcon } from 'assets/icons/goals.svg'
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg'
import { ReactComponent as AdminIcon } from 'assets/icons/admin.svg'
import { ReactComponent as ArrowIcon } from 'assets/icons/arrow.svg'
import { ReactComponent as ReviewIcon } from 'assets/icons/review2.svg'
import { ReactComponent as Logo } from 'assets/logo/logo2-01.svg'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import { setCompany } from 'state/redux/actions/company.actions'
import { GiVideoConference } from 'react-icons/gi'
import { TbDeviceAnalytics } from 'react-icons/tb'
import { FiSettings } from 'react-icons/fi'
import useIsMobile from 'hooks/useIsMobile'
import { RiSurveyLine } from 'react-icons/ri'
import { BsRobot } from 'react-icons/bs'
import { NavLink } from 'react-router-dom'

export const MasterHomeSidebar = ({
  setIsSidebarOpen,
  mainSidebarOpen,
  setMainSidebarOpen,
}: {
  isSidebarOpen: boolean
  customElements?: React.ReactNode
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
  mainSidebarOpen: boolean
  setMainSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const iconClass = 'py-2.5 px-3 hover:text-themeGreen cursor-pointer flex items-center'
  const { companyId }: any = useParams()
  const { t } = useTranslation()
  const location = useLocation()
  const path = location.pathname
  const navigate = useNavigate()
  const isMobile = useIsMobile()

  const [showCompanySelector, setShowCompanySelector] = useState(false)
  const [chatWindow, setChatWindow] = useState(null)
  const [companyList, setCompanyList] = useState([])
  const [selectedCompany, setSelectedCompany] = useState({
    companyId: null,
    companyName: null,
    isAdmin: null,
    employeeId: null,
    isAnalyticsAdmin: null,
  })

  const user: any = useSelector((state) => get(state, 'user.user'))
  const companyInfo: any = useSelector((state) => get(state, 'company.companyInfo'))

  useEffect(() => {
    let updatedCompanyList: any[] = []
    if (user?.employee?.length > 0) {
      user?.employee?.forEach((company, _) => {
        if (companyId === company.companyId) {
          setSelectedCompany(company)
          setShowCompanySelector(false)
        }
        return updatedCompanyList.push(company)
      })
      if (!companyId) {
        setSelectedCompany({
          companyId: null,
          companyName: null,
          isAdmin: null,
          employeeId: null,
          isAnalyticsAdmin: null,
        })
        setShowCompanySelector(true)
      }
      setCompanyList(updatedCompanyList)
    }
  }, [user, companyId])

  useEffect(() => {
    if (companyId) {
      user?.employee?.forEach((company) => {
        if (company.companyId === Number(companyId)) {
          setSelectedCompany(company)
        }
      })
    }
  }, [user, companyId])

  useEffect(() => {
    if (!isEmpty(companyInfo) && !isEmpty(user)) {
      fetchUserDetail()
      navigate(`/admin/${companyInfo?.id}/company/profile`)
    }
  }, [companyInfo, user])

  const fetchUserDetail = () => {
    user?.employee?.forEach((company, _) => {
      if (companyInfo?.id === company.companyId) {
        setSelectedCompany(company)
      }
    })
    dispatch(setCompany({}))
  }

  const dispatch: any = useDispatch()
  const onLogout = () => {
    dispatch(logout())
    navigate('/login')
    localStorage.removeItem('company')
  }

  const profileBtnList = [
    { id: '1', name: t(BUTTONS.MANAGE_SETTINGS), onClick: () => navigate('/settings/profile') },
    { id: '2', name: t(BUTTONS.LOGOUT), onClick: onLogout },
  ]

  // Sidebar collapse/expand with '[' keystroke
  const bracketOpen = useKeyPress('[')

  useEffect(() => {
    if (bracketOpen) {
      setIsSidebarOpen((prev) => !prev)
    }
  }, [bracketOpen])

  const openChatWindow = () => {
    const features = 'width=600,height=800,toolbar=no,location=no,menubar=no,scrollbars=no,resizable=no,status=no'

    const createChatWindow = () => {
      const newChatWindow = window.open(`/chat/${companyId}`, 'AI_Assistant_Window', features)
      if (newChatWindow) {
        setTimeout(() => {
          if (chatWindow) {
            chatWindow.document.title = 'Coplae AI Assistant'
          }
        }, 1000)
        setChatWindow(newChatWindow)
      } else {
        console.error('Popup blocked. Please allow popups for this website.')
      }
    }

    const focusChatWindow = () => {
      if (chatWindow && !chatWindow.closed) {
        chatWindow.focus()
      } else {
        createChatWindow()
      }
    }

    return focusChatWindow()
  }

  const CompanyCard = ({ company, setShowCompanySelector }) => {
    const { companyId }: any = useParams()
    const isSelected = company.companyId === Number(companyId)
    return (
      <>
        {!isSelected && !company.isCompanyHidden && (
          <div
            onClick={() => {
              if (company.isCompanyArchived) {
                alert('Company Archived')
                return
              }
              setSelectedCompany(company)
              setShowCompanySelector(false)
              company.companyId && navigate(`/home/${company.companyId}`)
            }}
            className={`transition-all hover:text-white cursor-pointer ${
              isSelected ? '' : 'ml-0.5'
            } text-white flex px-4 pb-1 items-center`}
          >
            <div
              style={{ minWidth: '40px' }}
              className={classNames(
                'flex items-center justify-center w-10 h-10',
                isSelected && 'bg-white bg-opacity-20 rounded-md'
              )}
            >
              {company.companyThumbnail ? (
                <img
                  alt="company thumbnail"
                  src={company.companyThumbnail}
                  className="flex items-center justify-center w-6 h-6 bg-blue-500 rounded-full text-base"
                />
              ) : (
                <div
                  style={{ backgroundColor: avatarInitials(company.companyName || '').color }}
                  className={classNames(
                    'flex items-center justify-center w-6 h-6 bg-blue-500 rounded-full text-base border-2'
                  )}
                >
                  {company.companyName[0].toUpperCase()}
                </div>
              )}
            </div>
            <p className={classNames('text-white text-sm ml-2 truncate capitalize', isSelected && 'font-bold')}>
              {company.companyName}
            </p>
          </div>
        )}
      </>
    )
  }

  const SelectedCompanyCard = ({ company, showCompanySelector, setShowCompanySelector }) => {
    const { companyId }: any = useParams()
    const isSelected = company.companyId === Number(companyId)
    return (
      <>
        <div
          onClick={() => {
            setShowCompanySelector(!showCompanySelector)
          }}
          className={`transition-all hover:text-white cursor-pointer ${
            isSelected ? '' : 'ml-0.5'
          } text-white flex px-4 pb-1 items-center`}
        >
          <div
            style={{ minWidth: '40px' }}
            className={classNames(
              'flex items-center justify-center w-10 h-10',
              isSelected && 'shadow bg-white bg-opacity-20 rounded-md'
            )}
          >
            {company.companyThumbnail ? (
              <img
                alt="company thumbnail"
                src={company.companyThumbnail}
                className="flex items-center justify-center w-6 h-6 bg-themeGreen rounded-full text-base"
              />
            ) : (
              <div
                style={{ backgroundColor: avatarInitials(company.companyName || '').color }}
                className={classNames('flex items-center justify-center w-6 h-6 rounded-full text-base border-2')}
              >
                {company.companyName[0].toUpperCase()}
              </div>
            )}
          </div>
          <p className={classNames('text-white text-sm ml-2 w-20 truncate capitalize', isSelected && 'font-bold')}>
            {company.companyName}
          </p>
          <ArrowIcon className="ml-2" />
        </div>
      </>
    )
  }

  return (
    <Transition
      as={Fragment}
      appear={true}
      enter="transition-all ease-out duration-1000"
      enterFrom="transform scale-100 opacity-90"
      enterTo="transform opacity-100 scale-100"
      leave="transition ease-in duration-1000"
      leaveFrom="transform opacity-100 scale-100 translate-x-0"
      leaveTo="transform scale-100 opacity-90 -translate-x-10"
      show={true}
    >
      <div className="z-50 flex font-roboto translate-x-25">
        <div
          style={{
            width: isMobile ? 0 : 190,
          }}
          className={`bg-gray-800 flex group relative flex-col justify-between inset-y-0`}
        >
          {!path.includes('home') && (
            <div className="group-hover:opacity-100 opacity-0 absolute mt-4 left-44 z-50">
              <div
                className={`w-6 h-6 flex justify-center items-center text-xs rounded-full cursor-pointer bg-dark border-2 text-white font-bold`}
                onClick={() => setMainSidebarOpen(!mainSidebarOpen)}
              >
                {!mainSidebarOpen ? <MdArrowBackIosNew /> : <MdArrowForwardIos />}
              </div>
            </div>
          )}
          <div className="overflow-y-scroll flex flex-col flex-1 justify-between scrollbar-none no-scrollbar">
            <div className={'w-full cursor-pointer'}>
              <NavLink className="px-6 py-5 block" to="/home/?show=home">
                <Logo />
              </NavLink>

              {selectedCompany?.companyId && (
                <SelectedCompanyCard
                  company={selectedCompany}
                  showCompanySelector={showCompanySelector}
                  setShowCompanySelector={setShowCompanySelector}
                />
              )}
              {showCompanySelector && (
                <div className={'w-full max-h-84 overflow-y-scroll scrollbar-none no-scrollbar'}>
                  {companyList.map((company) => (
                    <CompanyCard key={company.id} company={company} setShowCompanySelector={setShowCompanySelector} />
                  ))}
                </div>
              )}

              {companyId && (
                <div
                  className={`border-b mt-5 border-t border-opacity-50 py-3.5 mx-4 space-y-2 self-stretch border-indigo-200 justify-self-stretch`}
                  style={{ borderBottomWidth: '0.5px' }}
                >
                  <NavLink
                    tabIndex={2}
                    aria-label="People"
                    to={`/people/${companyId}/user/${selectedCompany?.employeeId}?page=profile`}
                    className={`${iconClass} text-white flex items-center`}
                  >
                    <PeopleIcon className="text-xl w-5" />
                    <p className={'ml-4 text-sm'}>People</p>
                  </NavLink>

                  <NavLink
                    tabIndex={2}
                    aria-label="Meeting"
                    to={`/meeting/${companyId}/user/${selectedCompany?.employeeId}?page=profile`}
                    className={`${iconClass} text-white flex items-center`}
                  >
                    <GiVideoConference className="text-white text-xl w-5" />
                    <p className={'ml-4 text-sm'}>Meeting</p>
                  </NavLink>

                  <NavLink
                    tabIndex={3}
                    to={`/goals/explore/${selectedCompany.companyId}`}
                    className={`${iconClass} text-white flex items-center`}
                  >
                    <GoalIcon className="text-xl w-5" />
                    <p className={'ml-4 text-sm'}>Goals</p>
                  </NavLink>

                  <NavLink
                    tabIndex={4}
                    to={`/review/${selectedCompany.companyId}/user/${selectedCompany?.employeeId}`}
                    className={`${iconClass} text-white flex items-center`}
                  >
                    <ReviewIcon className="text-xl w-5" />
                    <p className={'ml-4 text-sm'}>Assessment</p>
                  </NavLink>

                  <NavLink tabIndex={5} to={`/company/${companyId}`} className={`${iconClass} text-white flex`}>
                    <CompanyIcon className=" text-xl w-5" />
                    <p className={'ml-4 text-sm'}>Company</p>
                  </NavLink>

                  <NavLink tabIndex={5} to={`/active-surveys/${companyId}`} className={`${iconClass} text-white flex`}>
                    <RiSurveyLine className=" text-xl w-5" />
                    <p className={'ml-4 text-sm'}>Surveys</p>
                  </NavLink>

                  <div tabIndex={6} onClick={openChatWindow} className={`${iconClass} text-white flex`}>
                    <BsRobot className="text-xl w-5" />
                    <p className={'ml-4 text-sm'}>AI Assistant</p>
                  </div>

                  {selectedCompany?.isAnalyticsAdmin && companyId && (
                    <NavLink
                      tabIndex={5}
                      to={`/analytics/${companyId}/overview`}
                      className={`${iconClass} text-white flex`}
                    >
                      <TbDeviceAnalytics className=" text-xl w-5" />
                      <p className={'ml-4 text-sm'}>Analytics</p>
                    </NavLink>
                  )}

                  {selectedCompany?.isAdmin && companyId && (
                    <NavLink
                      tabIndex={6}
                      to={`/admin/${selectedCompany.companyId}/employees`}
                      className={`${iconClass} text-white flex`}
                    >
                      <AdminIcon className=" text-xl w-5" />
                      <p className={'ml-4 text-sm'}>Admin</p>
                    </NavLink>
                  )}
                  <NavLink
                    tabIndex={7}
                    to={`/company/${companyId}/employee-settings`}
                    className={`${iconClass} text-white flex`}
                  >
                    <FiSettings className=" text-xl w-5" />
                    <p className={'ml-4 text-sm'}>Settings</p>
                  </NavLink>
                </div>
              )}
            </div>
            <div className={'w-full justify-self-end'}>
              <div tabIndex={7} className={`${iconClass} text-white flex ml-4`}>
                <HelpIcon className=" text-xl w-5" />
                <a href="https://coplae.zendesk.com/hc/en-us">
                  <p className={'ml-4 text-sm'}>Help Center</p>
                </a>
              </div>
            </div>
          </div>
          <div
            className={`${
              isMobile ? 'hidden' : 'visible'
            } cursor-pointer py-4 items-center px-6 bg-gray-400 bg-opacity-5`}
          >
            <Dropdown3 label="Account" items={profileBtnList}>
              <Avatar
                hw="6"
                medium
                className="justify-center "
                name={user?.fullName || 'John Doe'}
                image={user?.profileImageThumbnailUrl}
              />
            </Dropdown3>
          </div>
        </div>
      </div>
    </Transition>
  )
}
