import { AssessmentBox } from 'components/templates/Review/ReviewSegment'
import moment from 'moment'
import { useState } from 'react'
import Rating from 'components/templates/Review/Rating/Rating'
import AppraisalAcknowledgementConfirmationModal from 'components/organisms/AppraisalAcknowledgementConfirmationModal'
import useIsMobile from 'hooks/useIsMobile'

const AppraisalAcknowledgement = ({
  avgRating,
  isSelf,
  managerSubmitted,
  employeeSubmitted,
  saveReview,
  setManagerSubmitted,
  setEmployeeSubmitted,
  setCheckBoxEditable,
  reviews,
  ratingSys,
}) => {
  const isMobile = useIsMobile()
  const [showConfirmationModal, setShowConfirmationModal] = useState(false)

  const acknowledgeCheck = (e) => {
    setManagerSubmitted(e.target.checked)
    saveReview({ employee: employeeSubmitted, manager: e.target.checked })
    setShowConfirmationModal(false)
  }

  const ratingText = (val) => {
    if (ratingSys) {
      switch (val) {
        case 1:
          return ratingSys[4]?.title
        case 1.5:
          return ratingSys[3]?.title
        case 2:
          return ratingSys[3]?.title
        case 2.5:
          return ratingSys[2]?.title
        case 3:
          return ratingSys[2]?.title
        case 3.5:
          return ratingSys[1]?.title
        case 4:
          return ratingSys[1]?.title
        case 4.5:
          return ratingSys[0]?.title
        case 5:
          return ratingSys[0]?.title
      }
    }
  }

  return !isSelf ||
  (isSelf &&
    ((reviews?.skipLevelSigner && reviews?.isSkipLevelSignDone) || !reviews?.skipLevelSigner) &&
    managerSubmitted) ? (
    <>
      <div
        style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
        className={`h-auto rounded-lg bg-white mb-4`}
      >
        <div className="flex flex-col items-center gap-4 pointer-events-none py-5 px-3">
          <Rating
            defaultValue={avgRating.overallRating}
            size={37}
            fillSize={40}
            onClick={() => {}}
            fillColor="#1F51FF"
          />
          {avgRating.overallRating !== 0 && !isNaN(avgRating.overallRating) && (
            <h1 className="font-semibold text-lg">
              {avgRating.overallRating} - {ratingText(avgRating.overallRating)}
            </h1>
          )}
          {reviews?.overallManagerAssessment ? (
            <div className="w-full">
              <AssessmentBox description={reviews?.overallManagerAssessment} field="" />
            </div>
          ) : (
            <div className="h-16 w-full border-2 border-gray-300 rounded-lg"></div>
          )}
        </div>
      </div>
      <div
        style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
        className={`h-auto rounded-lg bg-white px-2 mb-4`}
      >
        <div className="w-full font-roboto items-center pt-4 px-4">
          <div className="w-full font-roboto items-center"></div>
          <div className="w-full rounded-lg bg-white pb-4">
            <div className="flex justify-between items-center w-full">
              <h1 className={`${isMobile ? 'text-sm' : ''} font-bold`}>Areas of Improvement</h1>
            </div>
            <div className="w-full">
              {reviews?.improvementAreas ? (
                <div className="mt-3">
                  <AssessmentBox type="" description={reviews?.improvementAreas} field="" />
                </div>
              ) : (
                <div className="h-16 w-full border-2 border-gray-300 rounded-lg mt-3"></div>
              )}
            </div>
          </div>

          <div className="w-full rounded-lg bg-white pb-4">
            <div className="flex justify-between items-center w-full">
              <h1 className={`${isMobile ? 'text-sm' : ''} font-bold`}>Employee Career Aspirations</h1>
            </div>
            <div className="w-full">
              <div className="mt-3">
                {reviews?.employeeCareerAspirations ? (
                  <div className="mt-3">
                    <AssessmentBox type="" description={reviews?.employeeCareerAspirations} field="" />
                  </div>
                ) : (
                  <div className="h-16 w-full border-2 border-gray-300 rounded-lg mt-1"></div>
                )}
              </div>
            </div>
          </div>

          <div className="w-full rounded-lg bg-white pb-4">
            <div className="flex justify-between items-center w-full">
              <h1 className={`${isMobile ? 'text-sm' : ''} font-bold`}>Employee Comments</h1>
            </div>
            <div className="w-full">
              <div className="mt-3">
                {reviews?.employeeComments ? (
                  <div className="mt-3">
                    <AssessmentBox type="" description={reviews?.employeeComments} field="" />
                  </div>
                ) : (
                  <div className="h-16 w-full border-2 border-gray-300 rounded-lg mt-1"></div>
                )}
              </div>
            </div>
          </div>
        </div>

        {showConfirmationModal && (
          <AppraisalAcknowledgementConfirmationModal
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
            acknowledgeCheck={acknowledgeCheck}
          />
        )}
      </div>
      <div
        style={{ boxShadow: '0 4px 15px 0 rgba(171, 171, 171, 0.42)' }}
        className={`h-auto rounded-lg bg-white px-2`}
      >
        <div className="w-full font-roboto items-center pt-4 px-4">
          <div className="flex flex-col items-start justify-center space-y-4 mt-6">
            <h1 className={`${isMobile ? 'text-sm' : 'text-md'} font-bold`}>
              Submit <span className="text-red-500 text-sm -mt-2">*</span>
            </h1>
            <div className="flex flex-col space-y-4 pb-5">
              <p className={`${isMobile ? 'text-xs' : 'text-sm'} border-l-2 border-red-400 pl-3`}>
                Upon checking this button, you confirm that you have finished your assessment and acknowledge its
                completion.
              </p>
              <div className="ml-4 space-y-3">
                <div className="flex items-center justify-start">
                  <input
                    style={{
                      opacity: isSelf ? '0.6' : '1',
                      cursor: isSelf ? 'not-allowed' : 'pointer',
                    }}
                    onChange={() => setShowConfirmationModal(true)}
                    className="disabled:opacity-50 form-check-input appearance-none h-4 w-4 border border-gray-500 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id={'managerSubmitted'}
                    checked={managerSubmitted}
                    value={'managerSubmitted'}
                    disabled={isSelf || managerSubmitted}
                  ></input>
                  <p className={isMobile ? 'text-xs' : 'text-sm'}>
                    {reviews?.manager?.fullName}{' '}
                    <span className={`${isMobile ? 'text-xs' : ''} italic font-bold`}>
                      ({moment(reviews?.employeeSubmittedTime).format('l')})
                    </span>
                  </p>
                </div>
                <div className="flex items-center justify-start" style={{ opacity: !employeeSubmitted ? '0.6' : '1' }}>
                  <input
                    style={{
                      cursor: !employeeSubmitted ? 'not-allowed' : 'pointer',
                    }}
                    onChange={(e) => {
                      setEmployeeSubmitted(e.target.checked)
                      saveReview({ employee: e.target.checked })
                      setCheckBoxEditable(true)
                    }}
                    className="disabled:opacity-50 form-check-input appearance-none h-4 w-4 border border-gray-500 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
                    type="checkbox"
                    id={'employeeSubmitted'}
                    checked={employeeSubmitted}
                    value={'employeeSubmitted'}
                    disabled={!employeeSubmitted}
                  ></input>
                  <p className={isMobile ? 'text-xs' : 'text-sm'}>
                    {reviews?.targetEmployee?.fullName}{' '}
                    <span className={`${isMobile ? 'text-xs' : ''} italic font-bold`}>
                      ({moment(reviews?.managerSubmittedTime).format('l')})
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showConfirmationModal && (
          <AppraisalAcknowledgementConfirmationModal
            showConfirmationModal={showConfirmationModal}
            setShowConfirmationModal={setShowConfirmationModal}
            acknowledgeCheck={acknowledgeCheck}
          />
        )}
      </div>
    </>
  ) : (
    <>
      <div className="w-full flex justify-center font-bold">Hidden until signoff(s) are done.</div>
    </>
  )
}

export default AppraisalAcknowledgement
