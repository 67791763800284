import React, { useState } from 'react'
import Button from 'components/atoms/Button/button'
import Modal from 'components/atoms/Modal'
import { useOneOnOneTools } from 'hooks/useOneOnOneTools'
import { useLocation } from 'react-router-dom'

interface Props {
  deleteModal: boolean
  setDeleteModal: React.Dispatch<React.SetStateAction<boolean>>
  itemId: any
  attachmentId: any
  fileName: any
  companyId?: any
  deleteAttachments?: any
  setRefetch?: any
  isTalkingPoint?: boolean
}
const DeleteAttachmentDialog = ({
  companyId,
  deleteAttachments,
  setRefetch,
  deleteModal,
  setDeleteModal,
  itemId,
  attachmentId,
  fileName,
  isTalkingPoint = false,
}: Props) => {
  const { deleteAttachment, deletePointAttachment, deleteGoalsAttachment } = useOneOnOneTools()
  const [loading, setLoading] = useState(false)
  const location = useLocation()
  const isGoals = location.pathname.includes('goals')

  const onDelete = () => {
    setLoading(true)
    const onSuccess = (response) => {
      setLoading(false)
      deleteAttachments(response, itemId)
      setDeleteModal(false)
      setRefetch(true)
    }
    const onError = (error) => {
      setLoading(false)
    }
    isGoals
      ? deleteGoalsAttachment(itemId, attachmentId, companyId, onSuccess, onError)
      : isTalkingPoint
      ? deletePointAttachment(itemId, attachmentId, companyId, onSuccess, onError)
      : deleteAttachment(itemId, attachmentId, companyId, onSuccess, onError)
  }

  return (
    <Modal
      max={{ w: 124 }}
      hwClassName={'max-w-124'}
      open={deleteModal}
      setOpen={setDeleteModal}
      header="Delete this attachment"
      zIndex={6000}
    >
      <div className="flex justify-center flex-col">
        <>
          <p className="text-sm">
            Are you sure you want to delete this attachment <span className="font-bold">{fileName}</span>?
          </p>
          <p className="text-sm text-red-500">This action can’t be undone.</p>
        </>
        <div className="flex justify-end items-center pt-4 space-x-2">
          <Button size="md" secondary label={'Cancel'} onClick={() => setDeleteModal(false)} />
          <Button
            size="md"
            btnColor="bg-themeRed"
            textColor="text-white"
            hoverColor="bg-themeRed"
            focusRing="ring-themeRed"
            label={'Delete attachment'}
            onClick={onDelete}
            loading={loading}
            loadingText=""
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteAttachmentDialog
