/* eslint-disable @typescript-eslint/no-unused-vars */
import Avatar from 'components/atoms/Avatar'
import Label from 'components/atoms/Labels/Label'
import { useState } from 'react'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import moment from 'moment'
import { RiAttachment2, RiHashtag, RiPercentLine } from 'react-icons/ri'
import { AiOutlineCheck } from 'react-icons/ai'

interface Props {
  selectedGoal: any
  setFileViewModal: any
}
const GoalDetail = ({ selectedGoal, setFileViewModal }: Props) => {
  const [showFullDescription, setShowFullDescription] = useState(false)

  const toggleDescription = () => {
    setShowFullDescription(!showFullDescription)
  }

  const getDescription = (description: string) => {
    if (description?.split(' ')?.length > 50) {
      if (showFullDescription) {
        return (
          <span>
            {description}{' '}
            <span onClick={toggleDescription} className="text-themeDarkerBlue cursor-pointer">
              ...show less
            </span>
          </span>
        )
      } else {
        const truncatedDescription = description?.split(' ')?.slice(0, 50)?.join(' ')
        return (
          <span>
            {truncatedDescription}{' '}
            <span onClick={toggleDescription} className="text-themeDarkerBlue cursor-pointer">
              ...see more
            </span>
          </span>
        )
      }
    } else {
      return description
    }
  }

  return (
    <>
      <div className="space-y-1">
        <p className="text-sm text-themeGray">DESCRIPTION</p>
        <p className="text-sm text-themeGray">{getDescription(selectedGoal.description)}</p>
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">ATTACHMENTS</p>
        <p
          onClick={() => setFileViewModal(true)}
          className="flex items-center gap-2 cursor-pointer hover:text-blue-500 text-sm"
        >
          {selectedGoal?.attachments?.length ? (
            <>
              <RiAttachment2 size={18} />
              {selectedGoal?.attachments?.length}
            </>
          ) : (
            ''
          )}
        </p>
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">OWNERS</p>
        {selectedGoal?.owner?.map((item) => (
          <Avatar name={item?.owner?.fullName} medium image={item?.owner?.profileImageThumbnailUrl} withName />
        ))}
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">RELATED TASKS</p>
        {selectedGoal?.actionItems && (
          <ul className="space-y-3">
            {selectedGoal?.actionItems?.map((task) => (
              <li className={`pl-3 border-l-2 border-blue-500 text-sm ${task?.isChecked && 'line-through'}`}>
                {task?.item}
              </li>
            ))}
          </ul>
        )}
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">PROGRESS TYPE</p>
        {selectedGoal?.progressType === 'PERCENT' ? (
          <div className="flex items-center text-sm gap-1">
            <RiPercentLine />
            <p>Percentage</p>
          </div>
        ) : selectedGoal?.progressType === 'NUMBER' ? (
          <div className="flex items-center text-sm gap-1">
            <RiHashtag />
            <p>Number</p>
          </div>
        ) : (
          <div className="flex items-center text-sm gap-1">
            <AiOutlineCheck />
            <p>Complete / Incomplete</p>
          </div>
        )}
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">DUE</p>
        <p className="text-sm ">
          {' '}
          {`${new Date(selectedGoal?.dueDate).toLocaleString('default', { month: 'long' }).substring(0, 3)} ${new Date(
            selectedGoal?.dueDate
          ).getDate()}, 
                      ${new Date(selectedGoal?.dueDate).getFullYear()}`}
        </p>
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">ASSESSMENT CYCLE</p>
        <p className="text-sm ">{selectedGoal?.reviewCycle?.name}</p>
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">TYPE</p>
        <p className="text-sm font-medium capitalize">
          {selectedGoal && selectedGoal?.type}
          {selectedGoal && selectedGoal?.type === 'DEPARTMENT' ? ` (${selectedGoal?.department?.name})` : ''}
          {selectedGoal && selectedGoal?.type === 'TEAM' ? ` (${selectedGoal?.team?.name})` : ''}
        </p>
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">PARENT GOAL</p>
        <p className="text-sm font-bold">{selectedGoal?.parent?.name}</p>
      </div>

      {selectedGoal?.subgoals?.[0] && (
        <div className="space-y-2">
          <p className="text-sm text-themeGray">SUBGOALS</p>
          {selectedGoal?.subgoals?.map((goal, index) => (
            <p key={index + goal.name} className="text-sm font-bold">
              {goal.name}
            </p>
          ))}
        </div>
      )}

      <div className="space-y-2">
        <p className="text-sm text-themeGray">SUPPORTERS</p>
        <div className="flex">
          {selectedGoal?.supporters?.map((item) => (
            <Tooltip text={item?.supporter?.fullName} position="top">
              <Avatar
                name={item?.supporter?.fullName}
                className="-ml-1"
                medium
                image={item?.supporter?.profileImageThumbnailUrl}
              />
            </Tooltip>
          ))}
        </div>
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">PRIORITY</p>
        {selectedGoal?.priority && (
          <Label
            className="w-min uppercase"
            label={selectedGoal?.priority}
            labelColor="text-black font-semibold"
            bgColor="bg-green-secondary"
          />
        )}
      </div>

      <div className="space-y-2">
        <p className="text-sm text-themeGray">CREATED</p>
        <p className="text-sm ">
          {`${new Date(selectedGoal?.createdAt)
            .toLocaleString('default', { month: 'long' })
            .substring(0, 3)} ${new Date(selectedGoal?.createdAt).getDate()}, 
                      ${new Date(selectedGoal?.createdAt).getFullYear()}`}
        </p>
      </div>

      <div className="space-y-2 ">
        <p className="text-sm text-themeGray">LAST UPDATED</p>
        <p className="text-sm">{moment(selectedGoal?.updatedAt).fromNow()}</p>
      </div>
    </>
  )
}

export default GoalDetail
