import classNames from 'classnames'
import Avatar from 'components/atoms/Avatar'
import get from 'lodash/get'
import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import Tooltip4 from 'components/atoms/Tooltip/Tooltip4'
import { useNavigate, useLocation } from 'react-router-dom'
import { Dropdown2 } from 'components/atoms/Dropdown/Dropdown'
import { useTranslation } from 'react-i18next'
import { BUTTONS } from 'constants/common'
import { logout } from 'state/redux/actions/user.actions'
import { ReactComponent as PeopleIcon } from 'assets/icons/people.svg'
import { ReactComponent as GoalsIcon } from 'assets/icons/goals.svg'
import { ReactComponent as HelpIcon } from 'assets/icons/help.svg'
import { ReactComponent as CompanyIcon } from 'assets/icons/company.svg'
import { ReactComponent as AdminIcon } from 'assets/icons/admin.svg'
import { ReactComponent as ReviewIcon } from 'assets/icons/review2.svg'
import { ReactComponent as Logo } from 'assets/logo/c-logo.svg'
import { TbDeviceAnalytics } from 'react-icons/tb'
import useKeyPress from 'hooks/useKeyPress'
import { useParams } from 'react-router'
import { avatarInitials } from 'lib/utils'
import { MdArrowBackIosNew, MdArrowForwardIos } from 'react-icons/md'
import { GiVideoConference } from 'react-icons/gi'
import { FiSettings } from 'react-icons/fi'
import { RiSurveyLine } from 'react-icons/ri'
import { BsRobot } from 'react-icons/bs'

const CollapsedSideBar = ({
  setIsSidebarOpen,
  mainSidebarOpen,
  setMainSidebarOpen,
}: {
  isSidebarOpen: boolean
  customElements?: React.ReactNode
  setIsSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
  mainSidebarOpen: boolean
  setMainSidebarOpen: React.Dispatch<React.SetStateAction<boolean>>
}) => {
  const iconClass =
    'rounded-2xl py-2.5 px-3 transition-all relative fixed-nav__btn  border-transparent  cursor-pointer '
  const iconContainerClass = 'space-y-5 w-full cursor-pointer'
  const activeClass = 'border border-gray-500 shadow bg-light-white active text-white'

  const { t } = useTranslation()

  const { companyId }: any = useParams()

  const navigate = useNavigate()
  const location = useLocation()
  const path = location.pathname

  const user: any = useSelector((state) => get(state, 'user.user'))
  const personalInfo: any = useSelector((state) => get(state, 'user.personalInfo'))
  const [selectedCompany, setSelectedCompany]: any = useState()
  const [isDropdownVisible, setDropdownVisible] = useState(false)

  const [chatWindow, setChatWindow] = useState(null)

  useEffect(() => {
    user?.employee?.map((company) => {
      if (company.companyId === Number(companyId)) {
        setSelectedCompany(company)
      }
    })
  }, [user])

  const dispatch: any = useDispatch()
  const onLogout = () => {
    dispatch(logout())
    navigate('/login')
    localStorage.removeItem('company')
  }

  const profileBtnList = [
    { id: '1', name: t(BUTTONS.MANAGE_SETTINGS), onClick: () => navigate('/settings/profile') },
    { id: '2', name: t(BUTTONS.LOGOUT), onClick: onLogout },
  ]

  // Sidebar collapse/expand with '[' keystroke
  const bracketOpen = useKeyPress('[')

  useEffect(() => {
    if (bracketOpen) {
      setIsSidebarOpen((prev) => !prev)
    }
  }, [bracketOpen])

  const companyName = 'Apple'

  const openChatWindow = () => {
    const features = 'width=600,height=800,toolbar=no,location=no,menubar=no,scrollbars=no,resizable=no,status=no'

    const createChatWindow = () => {
      const newChatWindow = window.open(`/chat/${companyId}`, 'AI_Assistant_Window', features)
      if (newChatWindow) {
        setTimeout(() => {
          if (chatWindow) {
            chatWindow.document.title = 'Coplae AI Assistant'
          }
        }, 1000)
        setChatWindow(newChatWindow)
      } else {
        console.error('Popup blocked. Please allow popups for this website.')
      }
    }

    const focusChatWindow = () => {
      if (chatWindow && !chatWindow.closed) {
        chatWindow.focus()
      } else {
        createChatWindow()
      }
    }

    return focusChatWindow()
  }

  return (
    <div className="z-50 flex font-roboto translate-x-25">
      <div style={{ width: 80 }} className={`bg-gray-800 relative flex flex-col justify-between group inset-y-0`}>
        <div className="group-hover:opacity-100 opacity-0 absolute mt-4 left-16 z-50">
          <div
            className={`w-6 h-6 flex justify-center items-center text-xs rounded-full cursor-pointer bg-dark border-2 text-white font-bold`}
            onClick={() => setMainSidebarOpen(!mainSidebarOpen)}
          >
            {!mainSidebarOpen ? <MdArrowBackIosNew /> : <MdArrowForwardIos />}
          </div>
        </div>
        <div className="overflow-y-scroll overflow-x-hidden flex flex-col flex-1 justify-between scrollbar-none no-scrollbar">
          <div className={iconContainerClass}>
            <div className="flex justify-center pt-5 cursor-pointer" onClick={() => navigate('/home/?show=home')}>
              <Logo />
            </div>

            {companyId && (
              <div className="py-1.5" onClick={() => navigate(`/home/${companyId}`)}>
                <Tooltip4 text={selectedCompany?.companyName}>
                  {selectedCompany?.companyThumbnail ? (
                    <img
                      alt="company logo"
                      src={selectedCompany?.companyThumbnail}
                      className="flex items-center justify-center mx-auto w-7.5 h-7.5 bg-white text-white rounded-full text-xl
                       cursor-pointer"
                    />
                  ) : (
                    <div
                      style={{ backgroundColor: avatarInitials(selectedCompany?.companyName || '').color }}
                      className={classNames(
                        'flex items-center justify-center mx-auto w-7.5 h-7.5 bg-white text-white rounded-full text-xl border-2 cursor-pointer'
                      )}
                    >
                      {selectedCompany?.companyName[0].toUpperCase()}
                    </div>
                  )}
                </Tooltip4>
              </div>
            )}

            {companyId && (
              <div
                className={`border-b mt-5 border-t border-opacity-50 py-3 mx-4 space-y-2 self-stretch border-indigo-200 justify-self-stretch`}
                style={{ borderBottomWidth: '0.5px' }}
              >
                <div className="relative group">
                  <Tooltip4 text="People">
                    <div
                      tabIndex={2}
                      aria-label="People"
                      onClick={() => navigate(`/people/${companyId}/user/${selectedCompany?.employeeId}?page=profile`)}
                      className={`${iconClass} ${path.includes('people') ? activeClass : 'text-white'}`}
                    >
                      <PeopleIcon color="black" className=" text-xl" />
                    </div>
                  </Tooltip4>
                </div>
                <Tooltip4 text="Meeting">
                  <div
                    tabIndex={3}
                    aria-label="Meeting"
                    onClick={() => navigate(`/meeting/${companyId}/user/${selectedCompany?.employeeId}?page=profile`)}
                    className={`ml-1 ${iconClass} ${
                      path.includes('meeting') && !path.includes('admin') && !path.includes('analytics')
                        ? activeClass
                        : 'text-white'
                    }`}
                  >
                    <GiVideoConference size={18} className=" text-xl" />
                  </div>
                </Tooltip4>
                <Tooltip4 text="Goals">
                  <div
                    tabIndex={4}
                    onClick={() => navigate(`/goals/explore/${companyId}`)}
                    className={`${iconClass} ${path.includes('explore') ? activeClass : 'text-white'}`}
                  >
                    <GoalsIcon className=" text-xl" />
                  </div>
                </Tooltip4>
                <Tooltip4 text="Assessment">
                  <div
                    tabIndex={4}
                    onClick={() => navigate(`/review/${companyId}/user/${selectedCompany?.employeeId}`)}
                    className={`${iconClass} ${
                      path.includes('review') && !path.includes('admin') ? activeClass : 'text-white'
                    }`}
                  >
                    <ReviewIcon className=" text-xl" />
                  </div>
                </Tooltip4>
                <Tooltip4 text="Company">
                  <div
                    tabIndex={5}
                    onClick={() => navigate(`/company/${companyId}`)}
                    className={`${iconClass} ${
                      path.includes('company') && !path.includes('admin') && !path.includes('employee-settings')
                        ? activeClass
                        : 'text-white'
                    }`}
                  >
                    <CompanyIcon className=" text-xl" />
                  </div>
                </Tooltip4>

                <Tooltip4 text="Surveys">
                  <div
                    tabIndex={5}
                    onClick={() => navigate(`/active-surveys/${companyId}`)}
                    className={`${iconClass} ${path.includes('active-surveys') ? activeClass : 'text-white'}`}
                  >
                    <RiSurveyLine size={22} className=" text-xl" />
                  </div>
                </Tooltip4>

                <Tooltip4 text="AI Assistant">
                  <div tabIndex={5} onClick={openChatWindow} className={`${iconClass} text-white`}>
                    <BsRobot size={22} className=" text-xl" />
                  </div>
                </Tooltip4>

                {selectedCompany?.isAnalyticsAdmin && (
                  <Tooltip4 text="Analytics">
                    <div
                      tabIndex={5}
                      onClick={() => navigate(`/analytics/${companyId}/overview`)}
                      className={`${iconClass} ${path.includes('analytics') ? activeClass : 'text-white'}`}
                    >
                      <TbDeviceAnalytics size={22} className=" text-xl" />
                    </div>
                  </Tooltip4>
                )}

                {selectedCompany?.isAdmin && (
                  <Tooltip4 text="Admin">
                    <div
                      tabIndex={6}
                      aria-label="Admin"
                      title="Admin"
                      onClick={() => navigate(`/admin/${companyId}/employees`)}
                      className={`${iconClass} ${path.includes('admin') ? activeClass : 'text-white'}`}
                    >
                      <AdminIcon className=" text-xl" />
                    </div>
                  </Tooltip4>
                )}
                <Tooltip4 text="Employee Settings">
                  <div
                    tabIndex={5}
                    onClick={() => navigate(`/company/${companyId}/employee-settings`)}
                    className={`${iconClass} ${path.includes('employee-settings') ? activeClass : 'text-white'}`}
                  >
                    <FiSettings className=" text-xl" />
                  </div>
                </Tooltip4>
              </div>
            )}
          </div>

          <div className={'w-full  justify-center flex items-center'}>
            <Tooltip4 text="Help">
              <div tabIndex={6} aria-label="Help" title="Help" className={`${iconClass} text-white`}>
                <a href="https://coplae.zendesk.com/hc/en-us">
                  <HelpIcon className=" text-xl" />
                </a>
              </div>
            </Tooltip4>
          </div>
        </div>

        <div className="cursor-pointer py-4 items-center px-6 bg-gray-400 bg-opacity-5">
          <Tooltip4 text="Your profile" flag={isDropdownVisible}>
            <Dropdown2 items={profileBtnList} setDropdownVisible={setDropdownVisible}>
              <Avatar
                hw="6"
                medium
                className=" justify-center "
                name={user?.fullName || 'John Doe'}
                image={personalInfo?.profileImageUrl}
              />
            </Dropdown2>
          </Tooltip4>
        </div>
      </div>
    </div>
  )
}

export default CollapsedSideBar
