import classNames from 'classnames'

const Tabs = ({
  tabs,
  curTab,
  setCurTab,
  theme,
  onChange,
}: {
  tabs: { name: string; color?: string; icon?: any; disable?: boolean }[]
  curTab: string
  theme?: 'small' | 'large'
  onChange?: (tab: string) => void
  setCurTab: React.Dispatch<React.SetStateAction<string>>
}) => {
  const length = tabs.length
  return (
    <div className="select-none">
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          className="block w-full text-xs md:text-base rounded-md"
          //   @ts-ignore
        >
          {tabs && tabs.map((tab) => <option key={tab?.name}>{tab?.name}</option>)}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="">
          <nav className="-mb-px flex items-center justify-center" aria-label="Tabs">
            {tabs.map((tab, id) => (
              <div
                key={tab?.name}
                className={classNames(
                  tab?.name === curTab && theme === 'small' && 'border-blue-400 bg-blue-100 ',
                  theme === 'large' &&
                    (tab?.name === curTab
                      ? 'py-3 border border-blue-600 bg-blue-500'
                      : 'py-3 cursor-pointer flex-1 flex items-center justify-center border border-gray-400 hover:border-blue-500'),
                  theme === 'small' && 'hover:border-gray-600 border border-gray-400',
                  'py-1.5 px-3 cursor-pointer flex-1 flex items-center justify-center',
                  id === 0 && 'rounded-l-md',
                  length - 1 === id && 'rounded-r-md'
                )}
                onClick={() => {
                  if (!tab.disable) {
                    if (onChange) {
                      onChange(tab?.name)
                    }
                    setCurTab(tab?.name)
                  }
                }}
              >
                <p
                  className={classNames(
                    tab?.name === curTab && theme === 'small' ? 'text-blue-500' : 'text-gray-400',
                    theme === 'large' && 'text-sm text-gray-400 hover:text-blue-500',
                    theme === 'large' && tab?.name === curTab && 'text-blue-500',
                    'text-xs flex justify-center  items-center'
                  )}
                >
                  <span className="pr-1">{tab.icon && tab.icon}</span>
                </p>
              </div>
            ))}
          </nav>
        </div>
      </div>
    </div>
  )
}

export default Tabs
