/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal from 'components/atoms/Modal'
import { RequestFeedbackCard } from 'components/templates/Review/Card/RequestFeedbackCard'
import { useState } from 'react'

const PendingRequestsModal = ({
  open,
  setOpen,
  onCloseModal,
  feedbackList,
  fetching,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal?: any
  feedbackList?: any
  fetching?: boolean
}) => {
  const [currentFeedbackCard, setCurrentFeedbackCard] = useState(-1)

  return (
    <Modal
      max={{ w: 150, h: 'full' }}
      hwClassName={'max-w-152 max-h-200'}
      header={'Pending Requests'}
      open={open}
      fixedOverflow
      setOpen={setOpen}
      onClose={onCloseModal}
    >
      <div className="requests-list-container" style={{ overflowY: 'scroll', height: '70vh' }}>
        <div className="bg-gray-100 text-gray-800 rounded py-4 px-2 mt-4 mb-4 space-y-3">
          {fetching ? (
            <RequestFeedbackCard loading />
          ) : feedbackList.length > 0 ? (
            feedbackList.map((f, idx) => (
              <RequestFeedbackCard
                feedback={f}
                idx={idx}
                setCurrentCard={setCurrentFeedbackCard}
                currentCard={currentFeedbackCard}
                key={f.id + idx}
              />
            ))
          ) : (
            <div className="text-xs sm:text-sm uppercase font-bold text-center">No Pending Requests</div>
          )}
        </div>
      </div>
    </Modal>
  )
}

export default PendingRequestsModal
