import { XIcon } from '@heroicons/react/outline'
import FeedbackEditor from 'components/atoms/Editor/FeedbackEditor'
import { HttpRequest } from 'helpers/services'
import { useState } from 'react'
import { useDispatch } from 'react-redux'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'

const ReminderModal = ({ setShowReminderModal, companyId, selectedEmployee, reviewId }) => {
  const dispatch: any = useDispatch()

  const [emailContent, setEmailContent] = useState('')
  const [sending, setSending] = useState(false)

  const sendReminder = () => {
    setSending(true)

    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    const data = {
      content: emailContent,
    }

    HttpRequest('POST', `/review/${reviewId}/reminder`, data, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setSending(false)
          dispatch(setSuccessMessage(`Reminder sent to ${selectedEmployee?.fullName} successfully!`))
          setShowReminderModal(false)
        }
      })
      .catch((err) => {
        setSending(false)
        dispatch(setErrorMessages([`Failed to send reminder to ${selectedEmployee?.fullName}`]))
      })
  }

  return (
    <div
      className="min-h-32 w-120 bg-white rounded-lg z-50 absolute top-1 right-0"
      style={{ boxShadow: '2px 2px 12px rgba(0, 0, 0, 0.2)' }}
    >
      <div className="flex flex-col p-4">
        <div className="flex justify-end h-1/5 w-full mb-2">
          <div className="dark:bg-gray-800 rounded-md text-gray-400 hover:text-blue-500 focus:outline-none cursor-pointer">
            <XIcon className="h-6 w-6" aria-hidden="true" onClick={() => setShowReminderModal(false)} />
          </div>
        </div>
        <div className="w-full">
          <FeedbackEditor
            placeholder="Draft reminder email here..."
            value={emailContent}
            setValue={setEmailContent}
            label={''}
          />
        </div>
        <div className="h-1/5 w-full flex justify-end mt-1">
          <button
            className={`px-3 py-2 text-sm bg-blue-500 hover:bg-blue-600 text-white font-semibold transition-all flex items-center gap-x-1 focus:outline-none justify-center rounded-lg mt-3 mb-1 ${
              sending ? 'cursor-not-allowed opacity-40' : 'cursor-pointer'
            }`}
            onClick={sendReminder}
            disabled={sending}
          >
            Send Reminder
          </button>
        </div>
      </div>
    </div>
  )
}

export default ReminderModal
