import Modal from 'components/atoms/Modal'
import moment from 'moment'
import { useEffect, useState } from 'react'

const RecurringModal = ({
  showModal,
  setShowModal,
  days,
  weeks,
  occurrences,
  setDays,
  setWeeks,
  setOccurrences,
}: {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  days: any[]
  weeks: string
  occurrences: string
  setDays: React.Dispatch<React.SetStateAction<any>>
  setWeeks: React.Dispatch<React.SetStateAction<string>>
  setOccurrences: React.Dispatch<React.SetStateAction<string>>
}) => {
  const [error, setError] = useState({ occurrences: '', weeks: '' })

  const onCancel = () => {
    setShowModal(false)
  }

  const toggleDays = (id: number) => {
    const newDays = days.map((item) => {
      if (item.id === id) {
        return { ...item, selected: !item.selected }
      }
      return item
    })
    setDays(newDays)
  }

  useEffect(() => {
    if (showModal) {
      setDays((prev) =>
        prev.map((day) => {
          if (day.id === moment().day()) {
            return { ...day, selected: true }
          }
          return day
        })
      )
    }
  }, [showModal])

  return (
    <>
      <Modal
        fixedOverflow
        max={{ w: 120 }}
        hwClassName={'max-w-120'}
        header={'Edit Recurrence'}
        open={showModal}
        setOpen={onCancel}
        zIndex={5002}
      >
        <div className="pt-4">
          <div className="relative flex gap-2 justify-start items-center">
            <p className="text-sm">Repeat every</p>
            <div className="w-14 bg-gray-200 rounded-md py-0.5 px-1">
              <input
                type={'number'}
                onChange={(e) => {
                  setError((prev) => ({ ...prev, weeks: '' }))
                  setWeeks(e.target.value)
                  if (Number(e.target.value) > 52) {
                    setError((prev) => ({ ...prev, weeks: 'Max 52 weeks' }))
                    return
                  }

                  if (Number(e.target.value) < 1) {
                    setError((prev) => ({ ...prev, weeks: 'Min 1 week' }))
                    return
                  }

                  if (e.target.value.includes('.')) {
                    setError((prev) => ({ ...prev, weeks: 'Should be a integer' }))
                    return
                  }
                }}
                value={weeks}
                className="h-7 text-center w-full bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0 focus:border-blue-500 text-sm text-gray-800"
              />
            </div>
            <span className="text-sm text-gray-800 bg-gray-200 rounded-md py-1 px-3">weeks</span>
            {error.weeks && <p className="absolute -bottom-6 mt-1 text-xs text-red-500 ml-20">{error.weeks}</p>}
          </div>

          <div className="mt-6 flex flex-col gap-2 justify-center items-start">
            <p className="text-sm mb-2">Repeat on</p>
            <div className="flex items-center justify-center gap-2">
              {days.map((item) => (
                <span
                  onClick={() => toggleDays(item.id)}
                  className={`${
                    item.selected
                      ? 'hover:bg-blue-600 bg-blue-500 text-white'
                      : 'opacity-80 text-gray-800 bg-gray-200 hover:bg-gray-300'
                  } transition-all cursor-pointer text-sm flex items-center justify-center w-8 h-8 rounded-full`}
                >
                  {item.label}
                </span>
              ))}
            </div>
          </div>
          <div className="pb-4 mt-4 flex flex-col gap-2 justify-center items-start">
            <p className="text-sm mt-2">Ends</p>
            <div className="relative flex justify-start items-center">
              <input checked type="radio" name="end" id="end1" className="mr-2" />
              <p className="text-sm mr-2">After</p>
              <div className="w-14 bg-gray-200 rounded-tl-md rounded-bl-md py-0.5 px-1">
                <input
                  type={'number'}
                  onChange={(e) => {
                    setError((prev) => ({ ...prev, occurrences: '' }))
                    setOccurrences(e.target.value)
                    if (Number(e.target.value) > 52) {
                      setError((prev) => ({ ...prev, occurrences: 'Max 362 occurrences' }))
                      return
                    }

                    if (Number(e.target.value) < 1) {
                      setError((prev) => ({ ...prev, occurrences: 'Min 1 occurrences' }))
                      return
                    }

                    if (e.target.value.includes('.')) {
                      setError((prev) => ({ ...prev, occurrences: 'Should be a integer' }))
                      return
                    }
                  }}
                  value={occurrences}
                  className="h-1 text-center w-full bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0 focus:border-blue-500 text-sm text-gray-800"
                />
              </div>
              <span className="text-sm text-gray-800 bg-gray-200 rounded-tr-md rounded-br-md py-1 pr-4">
                occurrences
              </span>
              {error.occurrences && (
                <p className="absolute -bottom-6 mt-1 text-xs text-red-500 ml-12">{error.occurrences}</p>
              )}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default RecurringModal
