export const metrics = {
  meetingCount: 'meetings',
  todoCount: 'tasks',
  reviewCount: 'assessments',
  feedbackCount: 'feedback',
  goalCount: 'goals',
  managerMeetingCount: 'manger',
  directReportMeetingCount: 'direct report',
  skipLevelMeetingCount: 'skip level',
  departmentMeetingCount: 'department',
  teamMeetingCount: 'team',
  totalMeetingCount: 'total',
  individualGoalCount: 'individual',
  departmentGoalCount: 'department',
  teamGoalCount: 'team',
  companyGoalCount: 'company',
  givenFeedbackCount: 'given',
  receivedFeedbackCount: 'received',
  requestGivenFeedbackCount: 'given request',
  requestReceivedFeedbackCount: 'received request',
  avgOverallRating: 'overall',
  avgFeedbackRating: 'feedback',
  avgValuesRating: 'values',
  avgGoalsRating: 'goals',
  totalReviewsCompleted: 'total assessments',
  totalAssignedTodoCount: 'total assigned',
  completedTodoCount: 'completed',
  incompleteTodoCount: 'incomplete',
  assignedByCount: 'assigned by',
}

const MetricCard = ({ metric }) => {
  return (
    <div
      className="bg-white flex flex-col w-full rounded-lg justify-center items-center"
      style={{ boxShadow: '2px 2px 12px rgba(0, 0, 0, 0.2)' }}
    >
      <div className="font-semibold text-base py-2">{metrics[metric.metric]}</div>
      <div className="font-bold text-2xl pb-2">{metric.value}</div>
    </div>
  )
}

export default MetricCard
