import { useEffect, useState } from 'react'
import Avatar from 'components/atoms/Avatar'
import Dropdown from 'components/atoms/Dropdown/Dropdown'
import { AiOutlineSetting } from 'react-icons/ai'
import { HttpRequest } from 'helpers/services'
import Label from 'components/atoms/Labels/Label'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import Button from 'components/atoms/Button/button'
import { ReactComponent as OffTrackIcon } from 'assets/icons/GoalExplore/GoalStatus/offTrack.svg'
import { ReactComponent as ProcessingIcon } from 'assets/icons/GoalExplore/GoalStatus/processing.svg'
import { ReactComponent as OnTrackIcon } from 'assets/icons/GoalExplore/GoalStatus/onTrack.svg'
import { useDispatch } from 'react-redux'
import { setSuccessMessage } from 'state/redux/actions/settings.actions'

interface Props {
  timelineList: any
  user: any
  goalId: any
  companyId: any
  setRefetch: any
  selectedGoal: any
  hideDropdown?: boolean
}
const TimelinePostList = ({
  timelineList,
  user,
  goalId,
  companyId,
  setRefetch,
  selectedGoal,
  hideDropdown = false,
}: Props) => {
  const [amountType, setAmountType] = useState('INCREMENT')
  const [formError, setFormError]: any = useState({})
  const [list, setList] = useState(timelineList)
  const dispatch: any = useDispatch()

  useEffect(() => {
    setList(timelineList)
  }, [timelineList])

  const onClickLike = (item: any) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PUT', `/goal/${goalId}/time-line/${item.id}/like`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          setRefetch(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const onEdit = (item: any) => {
    const temp = list.map((i) => {
      if (i?.id === item?.id) {
        const tempObj = {
          ...i,
          isEdit: true,
        }
        return tempObj
      } else {
        return i
      }
    })
    setList(temp)
  }

  const onClickComment = (item: any) => {
    const temp = list.map((i) => {
      if (i?.id === item?.id) {
        const tempObj = {
          ...i,
          isComment: i.isComment ? false : true,
        }
        return tempObj
      } else {
        return i
      }
    })
    setList(temp)
  }

  const onCancelEdit = (item: any) => {
    setList(timelineList)
  }

  const onChangeMessage = (item, e) => {
    const updatedValue = list.map((i) => {
      if (i?.id === item?.id) {
        const tempObj = {
          ...i,
          message: e,
        }
        return tempObj
      } else {
        return i
      }
    })
    setList(updatedValue)
  }

  const onChangeComment = (item, e) => {
    const updatedValue = list.map((i) => {
      if (i?.id === item?.id) {
        const tempObj = {
          ...i,
          commentText: e,
        }
        return tempObj
      } else {
        return i
      }
    })
    setList(updatedValue)
  }

  const onChangeProgressType = (item, e) => {
    const updatedValue = list.map((i) => {
      if (i?.id === item?.id) {
        const tempObj = {
          ...i,
          goalProgressType: e,
        }
        return tempObj
      } else {
        return i
      }
    })
    setList(updatedValue)
  }

  const onChangeProgressValue = (item, e) => {
    const updatedValue = list.map((i) => {
      if (i?.id === item?.id) {
        const tempObj = {
          ...i,
          progressValue: e,
        }
        return tempObj
      } else {
        return i
      }
    })
    setList(updatedValue)
  }

  const validateForm = (item) => {
    let isValid = true
    let msg = {
      message: '',
    }

    const updatedValue = list.map((i) => {
      if (i?.id === item?.id && item.message === '') {
        const tempObj = {
          ...i,
          errorMessage: 'Please enter a message',
        }
        isValid = false
        return tempObj
      } else {
        return i
      }
    })
    setList(updatedValue)

    setFormError({
      ...formError,
      ...msg,
    })
    return isValid
  }

  const onModifyUpdate = (item) => {
    if (validateForm(item)) {
      const commonHeader = {
        'x-appengine-company-id': companyId,
      }

      const data = {
        message: item?.message,
        amountType: 'INCREMENT',
        status: item?.goalProgressType,
      }

      if (item?.progressValue) {
        data['progressValue'] = selectedGoal?.progressType === 'BINARY' ? 0 : item?.progressValue
      }

      HttpRequest('PATCH', `/goal/${goalId}/time-line/${item.id}`, data, commonHeader)
        .then(({ data }: any) => {
          if (data.code === 200) {
            setRefetch(true)
            setList(timelineList)
            dispatch(setSuccessMessage('Modified update'))
          }
        })
        .catch((err) => {
          console.error(err)
        })
    }
  }

  const onAddComment = (item) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PUT', `/goal/${goalId}/time-line/${item.id}/comment`, { comment: item.commentText }, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          setRefetch(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const onCommentDelete = (c) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('DELETE', `/goal/time-line/comment/${c?.id}`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          setRefetch(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const onDelete = (item: any) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('DELETE', `/goal/goaltimeline/${item.id}`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          setRefetch(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  return (
    <>
      {list.map((item) => (
        <>
          {!item?.isEdit ? (
            <div className="">
              <div className="flex justify-between">
                <Avatar name={item?.createdBy?.fullName} medium withName nameClass="font-semibold" />
                <p className="text-sm text-themeGray">
                  {`${new Date(item?.createdAt)
                    .toLocaleString('default', { month: 'long' })
                    .substring(0, 3)} ${new Date(item?.createdAt).getDate()}, ${new Date(
                    item?.createdAt
                  ).getFullYear()}`}
                </p>
              </div>
              <div
                className={`border-l-4 p-4 my-4 ${item?.goalProgressType === 'PROCESSING' && 'border-lightYellow'} ${
                  item?.goalProgressType === 'OFF_TRACK' && 'border-themeRed2'
                } ${item?.goalProgressType === 'ON_TRACK' && 'border-themeGreen3'} `}
              >
                <div className="text-sm text-themeGray flex items-center justify-between">
                  <p className="mr-2">{item.message}</p>
                  {!hideDropdown && (
                    <div>
                      <Dropdown
                        secondary
                        Icon={AiOutlineSetting}
                        iconClass="text-black"
                        items={[
                          {
                            id: '0',
                            name: 'Edit',
                            onClick: () => {
                              onEdit(item)
                            },
                          },
                          {
                            id: '1',
                            name: 'Delete',
                            onClick: () => {
                              onDelete(item)
                            },
                            className: 'text-themeRed',
                          },
                        ]}
                      />
                    </div>
                  )}
                </div>
              </div>
              {item.isComment && (
                <div className="bg-gray-100 rounded-lg p-5 my-2 border border-themeGray5">
                  {item?.comment.length > 0 &&
                    item?.comment
                      .sort((a, b) => a.id - b.id)
                      .map((c, index) => (
                        <div className="py-2">
                          <div className="flex justify-between items-center">
                            <Avatar name={c?.createdBy?.fullName} withName small nameClass="font-semibold" />
                            <p className="text-sm">
                              {`${new Date(c?.createdAt)
                                .toLocaleString('default', { month: 'long' })
                                .substring(0, 3)} ${new Date(c?.createdAt).getDate()}, 
                              ${new Date(c?.createdAt).getFullYear()}`}
                            </p>
                          </div>
                          <div className="flex justify-between items-center">
                            <p className="text-sm pl-10">{c.message}</p>
                            <Dropdown
                              secondary
                              size="sm"
                              Icon={AiOutlineSetting}
                              iconClass="text-black"
                              items={[
                                {
                                  id: '1',
                                  name: 'Delete',
                                  onClick: () => {
                                    onCommentDelete(c)
                                  },
                                  className: 'text-themeRed',
                                },
                              ]}
                            />
                          </div>
                        </div>
                      ))}

                  <div className="flex space-x-4">
                    <Avatar name={user?.fullName} small nameClass="font-semibold" />
                    <div className="flex-1 w-full">
                      <NormalFormInput
                        value={item?.commentText}
                        onChange={(e) => onChangeComment(item, e.target.value)}
                        placeholder="Add comment"
                      />
                    </div>
                  </div>
                  <div className="flex justify-end pt-4">
                    <Button
                      size="sm"
                      disabled={item?.commentText === ''}
                      hoverColor="bg-themeBlue"
                      label={'Comment'}
                      primary
                      onClick={() => onAddComment(item)}
                    />
                  </div>
                </div>
              )}

              <div className="text-themeGray text-sm font-bold flex items-center space-x-2 px-5">
                <div
                  className="hover:text-black cursor-pointer flex items-center gap-x-2"
                  onClick={() => onClickLike(item)}
                >
                  Like{' '}
                  {item?.like?.length > 0 && (
                    <Label
                      className="w-min uppercase"
                      label={item?.like?.length}
                      labelColor="text-black font-semibold"
                      bgColor="bg-green-secondary"
                    />
                  )}
                </div>
                <span className="w-1 h-1 bg-themeGray2 rounded-full"></span>
                <div
                  className="hover:text-black cursor-pointer flex items-center gap-x-2"
                  onClick={() => onClickComment(item)}
                >
                  Comment
                  {item?.comment?.length > 0 && (
                    <Label
                      className="w-min uppercase"
                      label={item?.comment?.length}
                      labelColor="text-black font-semibold"
                      bgColor="bg-green-secondary"
                    />
                  )}
                </div>
              </div>
            </div>
          ) : (
            <div>
              <div className="border border-themeGray2 rounded-lg p-5 my-5 space-y-4">
                <NormalFormInput
                  id="progressStatus"
                  name="progressStatus"
                  value={item?.message}
                  onChange={(e) => onChangeMessage(item, e.target.value)}
                  textarea
                  placeholder="What progress have you made?"
                  label="What’s new?"
                  error={item?.errorMessage}
                />
                {selectedGoal?.progressType !== 'BINARY' && (
                  <>
                    <p className="text-sm">What’s the new amount?</p>

                    <div className="flex items-center space-x-2">
                      <div className="flex">
                        <div
                          className={`p-2 w-24 cursor-pointer hover:border-themeBlue border rounded-l-lg ${
                            amountType === 'INCREMENT' && 'border-themeBlue bg-themeBlue2 '
                          }`}
                          onClick={() => setAmountType('INCREMENT')}
                        >
                          <p className="text-sm px-3 flex justify-center items-center text-black">
                            <span className="">Increment</span>
                          </p>
                        </div>
                        <div
                          className={`p-2 w-24 cursor-pointer border hover:border-themeBlue rounded-r-lg ${
                            amountType === 'TOTAL' && 'border-themeBlue bg-themeBlue2 '
                          }`}
                          onClick={() => setAmountType('TOTAL')}
                        >
                          <p className="text-sm px-3 flex justify-center  items-center text-black">
                            <span className="">Total</span>
                          </p>
                        </div>
                      </div>
                      <div>
                        {amountType === 'INCREMENT' ? (
                          <NormalFormInput
                            value={item?.progressValue}
                            placeholder="+"
                            type="number"
                            onChange={(e) => onChangeProgressValue(item, e.target.value)}
                          />
                        ) : (
                          <div className="flex items-center space-x-2">
                            <NormalFormInput
                              value={item?.progressValue}
                              placeholder="New total"
                              type="number"
                              onChange={(e) => onChangeProgressValue(item, e.target.value)}
                            />
                          </div>
                        )}
                      </div>
                    </div>
                  </>
                )}

                <p className="text-sm">What’s the status?</p>
                <div className="flex">
                  <div
                    className={`p-2 cursor-pointer hover:border-black flex-1 border border-themeGray2 rounded-l-lg ${
                      item?.goalProgressType === 'OFF_TRACK' && 'border-darkYellow bg-lightYellow '
                    }`}
                    onClick={() => onChangeProgressType(item, 'OFF_TRACK')}
                  >
                    <p className="text-sm flex justify-center items-center text-themeGray">
                      <OffTrackIcon /> <span className="ml-2"> Off track</span>
                    </p>
                  </div>
                  <div
                    className={`p-2 cursor-pointer flex-1 border hover:border-black ${
                      item?.goalProgressType === 'PROCESSING' && 'border-darkYellow bg-lightYellow '
                    }`}
                    onClick={() => onChangeProgressType(item, 'PROCESSING')}
                  >
                    <p className="text-sm flex justify-center  items-center text-themeGray">
                      <ProcessingIcon /> <span className="ml-2">Processing</span>
                    </p>
                  </div>
                  <div
                    className={`p-2 cursor-pointer hover:border-black flex-1 border border-themeGray2 rounded-r-lg  ${
                      item?.goalProgressType === 'ON_TRACK' && 'border-darkYellow bg-lightYellow '
                    }`}
                    onClick={() => onChangeProgressType(item, 'ON_TRACK')}
                  >
                    <p className="text-sm flex justify-center items-center text-themeGray">
                      <OnTrackIcon />
                      <span className="ml-2">On track</span>
                    </p>
                  </div>
                </div>
                <div className="flex w-full py-4 space-x-8">
                  <Button
                    size="md"
                    hoverColor="bg-themeRed"
                    focusRing="ring-themeRed"
                    label={'Cancel'}
                    secondary
                    className="flex-1"
                    onClick={() => onCancelEdit(item)}
                  />
                  <Button
                    size="md"
                    hoverColor="bg-themeBlue"
                    label={'Modify Update'}
                    primary
                    className="flex-1"
                    onClick={() => {
                      onModifyUpdate(item)
                    }}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ))}
    </>
  )
}

export default TimelinePostList
