import { AiOutlineHome } from 'react-icons/ai'
import { CgMenuGridO } from 'react-icons/cg'
import { BsBuildings } from 'react-icons/bs'
import { HiOutlineUserCircle } from 'react-icons/hi'
import Profile from 'components/organisms/Menu/Mobile/Profile'
import { useState } from 'react'
import MMenu from 'components/organisms/Menu/Mobile/MMenu'
import { IoMdClose } from 'react-icons/io'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { FiSettings } from 'react-icons/fi'
import SubMenu from 'components/organisms/Menu/Mobile/SubMenu'

const MobileNav = ({ subnav }) => {
  const navigate = useNavigate()
  const { companyId }: any = useParams()
  const location = useLocation()
  const path = location.pathname
  const isHome = location.pathname.includes('/home')
  const isSettings = location.pathname.includes('/settings/profile')
  const [showProfile, setShowProfile] = useState(false)
  const [showMMenu, setShowMMenu] = useState(false)
  const [showSubMenu, setShowSubMenu] = useState(false)

  const mobileNavItemClass =
    'text-gray-600 flex flex-col justify-center items-center transition-all p-4 cursor-pointer hover:bg-gray-200'

  const mobileNavItemActiveClass =
    'text-gray-600 flex flex-col justify-center items-center transition-all p-4 cursor-pointer bg-gray-200'
  const mobileNavItemStyle = { fontSize: '10px' }
  const iconSize = 30

  const toggleNav = (opt: string) => {
    switch (opt) {
      case 'profile':
        setShowProfile((prev) => !prev)
        setShowMMenu(false)
        setShowSubMenu(false)
        break
      case 'menu':
        setShowMMenu((prev) => !prev)
        setShowProfile(false)
        setShowSubMenu(false)
        break
      case 'submenu':
        setShowSubMenu((prev) => !prev)
        setShowMMenu(false)
        setShowProfile(false)
        break
      case 'home':
        navigate('/home')
        setShowMMenu(false)
        setShowProfile(false)
        setShowSubMenu(false)
        break
      case 'settings':
        navigate('/settings/profile')
        setShowMMenu(false)
        setShowProfile(false)
        setShowSubMenu(false)
        break
      case 'companysettings':
        navigate(`/company/${companyId}/employee-settings`)
        setShowMMenu(false)
        setShowProfile(false)
        setShowSubMenu(false)
        break
      default:
        break
    }
  }

  const menu = () => {
    if (
      path.includes('people') ||
      path.includes('meeting') ||
      path.includes('goals') ||
      path.includes('review') ||
      path.includes('admin') ||
      path.includes('/settings/')
    ) {
      return 'submenu'
    } else if (companyId) {
      return 'companysettings'
    } else {
      return 'settings'
    }
  }

  return (
    <>
      <Profile setState={setShowProfile} show={showProfile} />
      <MMenu setState={setShowMMenu} show={showMMenu} />
      <SubMenu subnav={subnav} show={showSubMenu} />
      <div
        style={{ boxShadow: '0px 0px 8px rgba(0,0,0,0.1)', zIndex: 5499 }}
        className="w-full grid grid-cols-4 items-center justify-between bg-white fixed bottom-0"
      >
        <span
          onClick={() => toggleNav('home')}
          style={mobileNavItemStyle}
          className={isHome ? mobileNavItemActiveClass : mobileNavItemClass}
        >
          <AiOutlineHome size={iconSize} />
        </span>
        <span
          onClick={() => toggleNav(menu())}
          style={mobileNavItemStyle}
          className={isSettings ? mobileNavItemActiveClass : mobileNavItemClass}
        >
          {showSubMenu ? (
            <IoMdClose size={iconSize} />
          ) : menu() === 'submenu' ? (
            <CgMenuGridO size={iconSize} />
          ) : (
            <FiSettings size={iconSize} />
          )}
        </span>
        <span
          onClick={() => toggleNav('menu')}
          style={mobileNavItemStyle}
          className={showMMenu ? mobileNavItemActiveClass : mobileNavItemClass}
        >
          {!showMMenu ? <BsBuildings size={iconSize} /> : <IoMdClose size={iconSize} />}
        </span>
        <span
          onClick={() => toggleNav('profile')}
          style={mobileNavItemStyle}
          className={showProfile ? mobileNavItemActiveClass : mobileNavItemClass}
        >
          {!showProfile ? <HiOutlineUserCircle size={iconSize} /> : <IoMdClose size={iconSize} />}
        </span>
      </div>
    </>
  )
}

export default MobileNav
