import TooltipPortal from 'components/atoms/Tooltip/TooltipPortal'
import React, { useState, useRef, useEffect } from 'react'

const Tooltip = ({ text, children, flag = false }) => {
  const [visible, setVisible] = useState(false)
  const [tooltipStyle, setTooltipStyle] = useState({})
  const tooltipRef = useRef(null)
  const targetRef = useRef(null)

  useEffect(() => {
    if (visible && tooltipRef.current && targetRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect()
      const tooltipRect = tooltipRef.current.getBoundingClientRect()

      setTooltipStyle({
        top: targetRect.top + window.scrollY + targetRect.height / 2 - tooltipRect.height / 2,
        left: targetRect.left + window.scrollX + targetRect.width + 10,
      })
    }
  }, [visible])

  return (
    <div
      ref={targetRef}
      className="relative"
      onMouseEnter={() => !flag && setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      {children}
      {visible && (
        <TooltipPortal>
          <div
            ref={tooltipRef}
            style={{
              ...tooltipStyle,
              position: 'absolute',
              zIndex: 9999,
              transition: 'background 350ms ease-in-out',
              transform: '150ms ease',
            }}
            className="py-[7px] px-[18px] min-w-[50px] max-w-[300px] w-max rounded-[6px] text-[14px] text-white text-center whitespace-pre-wrap shadow-custom bg-tooltip-bg bg-tooltip-gradient transform tooltip-transform"
          >
            {text}
          </div>
        </TooltipPortal>
      )}
    </div>
  )
}

export default Tooltip
