import { nanoid } from 'nanoid'

function validateEmail(email: string) {
  const re =
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  return re.test(email)
}

const wait = (timeout: number) => {
  return new Promise((resolve) => setTimeout(resolve, timeout))
}

var colorArray = [
  '#FF6633',
  '#FFB399',
  '#FF33FF',
  '#FFFF99',
  '#00B3E6',
  '#E6B333',
  '#3366E6',
  '#999966',
  '#99FF99',
  '#B34D4D',
  '#80B300',
  '#809900',
  '#E6B3B3',
  '#6680B3',
  '#66991A',
  '#FF99E6',
  '#CCFF1A',
  '#FF1A66',
  '#E6331A',
  '#33FFCC',
  '#66994D',
  '#B366CC',
  '#4D8000',
  '#B33300',
  '#CC80CC',
  '#66664D',
  '#991AFF',
  '#E666FF',
  '#4DB3FF',
  '#1AB399',
  '#E666B3',
  '#33991A',
  '#CC9999',
  '#B3B31A',
  '#00E680',
  '#4D8066',
  '#809980',
  '#E6FF80',
  '#1AFF33',
  '#999933',
  '#FF3380',
  '#CCCC00',
  '#66E64D',
  '#4D80CC',
  '#9900B3',
  '#E64D66',
  '#4DB380',
  '#FF4D4D',
  '#99E6E6',
  '#6666FF',
]

const avatarInitials = (name: string) => {
  const splitted = name.split(' ')
  let initials = ''
  if (splitted.length === 1) {
    initials = splitted[0].charAt(0).toUpperCase()
  } else {
    initials = splitted[0].charAt(0).toUpperCase() + splitted[1].charAt(0).toUpperCase()
  }

  const charIndex = initials.charCodeAt(0) - 65
  const colorIndex = charIndex % 19
  const color = colorArray[colorIndex]
  return { color, initials }
}

const getName = (user) => (user?.fullName ? user?.fullName : user?.email?.substring(0, user?.email?.lastIndexOf('@')))

export { validateEmail, wait, avatarInitials, getName }

export const timeRangeFormattedList = () => {
  let items = []
  for (var hour = 0; hour < 24; hour++) {
    items.push([hour, 0])
    items.push([hour, 15])
    items.push([hour, 30])
    items.push([hour, 45])
  }

  const date = new Date()
  const formatter = new Intl.DateTimeFormat('en-US', {
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  })

  const range = items.map((time) => {
    const [hour, minute] = time
    date.setHours(hour)
    date.setMinutes(minute)

    return formatter.format(date)
  })

  const updatedRange = []
  range.forEach((item) =>
    updatedRange.push({
      id: nanoid(6),
      name: item,
    })
  )

  return updatedRange
}

export function durationFormattedList() {
  const durationArray = []
  for (let i = 15; i <= 360; i += 15) {
    if (i < 60) {
      durationArray.push(i + ' minutes')
    } else {
      durationArray.push(Math.floor(i / 60) + ' hour ' + (i % 60 === 0 ? '' : (i % 60) + ' minutes'))
    }
  }
  const updatedList = []
  durationArray.forEach((item) =>
    updatedList.push({
      id: nanoid(6),
      name: item,
    })
  )
  return updatedList
}

export function formatDurationToString(duration: string) {
  let minutes = parseInt(duration)
  let hours = Math.floor(minutes / 60)
  let remainingMinutes = minutes % 60
  return `${hours === 0 ? '' : hours + ' hour'} ${remainingMinutes === 0 ? '' : remainingMinutes + ' minutes'}`
}

export function formatDurationToStringForOverview(duration) {
  let minutes = parseInt(duration)
  let hours = Math.floor(minutes / 60)
  let remainingMinutes = minutes % 60

  let hoursString = hours > 0 ? `${hours} h ` : ''
  let minutesString = remainingMinutes > 0 ? `${remainingMinutes} m` : ''

  return `${hoursString}${minutesString}`.trim()
}

export function formatDurationToMinutes(duration: string) {
  let durationArr = duration.split(' ')
  let totalMinutes
  let hours = parseInt(durationArr[0])
  let minutes = duration.includes('hour') ? parseInt(durationArr[2]) : parseInt(durationArr[0])
  if (duration.includes('hour') && duration.includes('minutes')) {
    totalMinutes = hours * 60 + minutes
    return totalMinutes
  } else {
    if (duration.includes('hour')) {
      totalMinutes = hours * 60
      return totalMinutes
    } else {
      totalMinutes = minutes
      return totalMinutes
    }
  }
}

export function getFileType(fileExtension: string) {
  switch (fileExtension) {
    case 'pdf':
      return 'pdf'
    case 'doc':
    case 'docx':
      return 'doc'
    case 'xls':
    case 'xlsx':
      return 'xls'
    case 'csv':
      return 'csv'
    case 'ppt':
    case 'pptx':
      return 'ppt'
    case 'zip':
    case 'rar':
      return 'zip'
    case 'txt':
      return 'txt'
    case 'png':
    case 'jpg':
    case 'jpeg':
    case 'gif':
    case 'tiff':
    case 'webp':
      return 'img'
    case 'webm':
    case 'mp4':
    case 'ogg':
    case 'mpeg':
    case 'mov':
    case 'avi':
    case 'm4v':
    case 'flv':
      return 'video'
    default:
      return 'file'
  }
}

export function getFileExtension(fileName: string) {
  return fileName.split('.').pop()
}
