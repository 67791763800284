/* eslint-disable @typescript-eslint/no-unused-vars */
import { useSelector, useDispatch } from 'react-redux'
import * as types from 'state/redux/constants'

export const useSettings = () => {
  const dispatch: any = useDispatch()
  const loading = useSelector((state: any) => state.settings.loading)
  const setLoading = (loading) => {
    dispatch({ type: types.SET_LOADING, data: loading })
  }

  return { loading, setLoading }
}
