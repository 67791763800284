import Button from 'components/atoms/Button/button'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

const modules = {
  toolbar: {
    container: [
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      ['blockquote', 'code-block'],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ color: [] }, { background: [] }], // dropdown with defaults from theme
      [{ align: [] }],
    ],
  },
  clipboard: { matchVisual: false },
}

const formats = [
  'header',
  'bold',
  'italic',
  'underline',
  'strike',
  'blockquote',
  'size',
  'color',
  'list',
  'bullet',
  'indent',
  'link',
  'align',
]

const FeedbackEditor = ({
  label,
  required,
  value,
  setValue,
  bgColor,
  placeholder,
  onBlur,
  readonly = false,
  jobDescription,
  callbackFn,
  jobTitle,
}: {
  label?: string
  required?: boolean
  value?: string
  placeholder?: string
  bgColor?: string
  setValue: (value: any) => void
  onBlur?: () => void
  readonly?: boolean
  jobDescription?: string
  callbackFn?: () => any
  jobTitle?: boolean
}) => {
  return (
    <>
      {label && (
        <div className="flex py-3 items-center justify-between">
          <p className="block dark:text-white text-xs sm:text-sm font-semibold">
            {label} {required && <span className="text-red-500 dark:text-red-400 font-medium text-base">*</span>}
          </p>

          {jobDescription && (
            <Button
              size="sm"
              className="block dark:text-white py-2 text-xs sm:text-sm font-semibold"
              label={jobDescription}
              primary
              onClick={callbackFn}
              disabled={jobTitle}
            />
          )}
        </div>
      )}
      <div
        style={{
          ...(bgColor && { background: bgColor }),
          cursor: readonly ? 'not-allowed' : 'default',
        }}
        className={`relative min-h-28 block border w-full border-gray-400 text-xs sm:text-sm p-0 focus:border-blue-500 focus:outline-none rounded-md`}
      >
        <ReactQuill
          readOnly={readonly}
          placeholder={placeholder ? placeholder : ''}
          theme="snow"
          value={value}
          modules={modules}
          formats={formats}
          onChange={setValue}
          onBlur={onBlur}
        />
      </div>
    </>
  )
}

export default FeedbackEditor
