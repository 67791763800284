import { XIcon } from '@heroicons/react/solid'
import Button from 'components/atoms/Button/button'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import EditGoal from 'components/organisms/Goals/EditGoal/EditGoal'
import GoalDetail from 'components/organisms/Goals/EditGoal/GoalDetail'
import { ExploreHooks } from 'components/organisms/Goals/ExploreHooks/ExploreHooks'
import TimelinePostList from 'components/organisms/Goals/GoalDetails/TimelinePostList'
import { HttpRequest } from 'helpers/services'
import { get } from 'lodash'
import { useEffect, useState } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import { BsCheck2Square } from 'react-icons/bs'
import { FaRegEdit } from 'react-icons/fa'
import { GiVideoConference } from 'react-icons/gi'
import { RiDeleteBin7Line } from 'react-icons/ri'
import { useSelector } from 'react-redux'
import { Link, useParams } from 'react-router-dom'
import { ReactComponent as OffTrackIcon } from 'assets/icons/GoalExplore/GoalStatus/offTrack.svg'
import { ReactComponent as ProcessingIcon } from 'assets/icons/GoalExplore/GoalStatus/processing.svg'
import { ReactComponent as OnTrackIcon } from 'assets/icons/GoalExplore/GoalStatus/onTrack.svg'
import DeleteDialog from 'components/organisms/Goals/EditGoal/DeleteDialog'
import EndGoalDialog from 'components/organisms/Goals/EditGoal/EndGoalDialog'
import CreateMeetingWithGoalModal from 'components/organisms/Goals/CreateGoalPopup/CreateMeetingWithGoal'
import useIsMobile from 'hooks/useIsMobile'

const MeetingGoalDetails = ({ viewModal, setViewModal, setSelectedGoal, selectedGoal }) => {
  const { companyId }: any = useParams()
  const isMobile = useIsMobile()
  const { getRange, getLabelGoalDetail } = ExploreHooks()
  const [selectedCompany, setSelectedCompany]: any = useState(null)
  const user: any = useSelector((state) => get(state, 'user.user'))
  const [editGoal, setEditGoal] = useState(false)
  const [update, setUpdate] = useState(false)
  const [showMeetingModal, setShowMeetingModal] = useState(false)
  const [endGoalModal, setEndGoalModal] = useState(false)
  const [deleteGoalModal, setDeleteGoalModal] = useState(false)
  const [timelineList, setTimelineList] = useState([])
  const [timelineRefetch, setTimelineRefetch] = useState(false)
  const [status, setStatus] = useState('')
  const [newProgress, setNewProgress] = useState('')
  const [amountType, setAmountType] = useState('INCREMENT')
  const [progressValue, setProgressValue] = useState(null)
  const [formError, setFormError]: any = useState({})
  const [isPosting, setIsPosting] = useState(false)

  useEffect(() => {
    user.employee.map((company) => {
      if (company.companyId === parseInt(companyId)) {
        setSelectedCompany(company)
      }
    })
  }, [user])

  const onCancelEdit = () => {
    setViewModal(false)
    setSelectedGoal({})
  }

  const onReactivateGoal = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/goal/${selectedGoal.id}/re-active`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          onCancelEdit()
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const onPublishGoal = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/goal/${selectedGoal.id}/publish`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          onCancelEdit()
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const fetchTimelines = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/goal/${selectedGoal?.id}/time-line`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          const goal = data?.data?.goal
          let timeline = data?.data?.timeline
          if (timeline.length > 0) {
            timeline = timeline.map((item) => ({
              ...item,
              isEdit: false,
              isComment: false,
              commentText: '',
            }))
          }
          setSelectedGoal(goal)
          setTimelineList(timeline)
        }
        setTimelineRefetch(false)
      })
      .catch((err) => {
        console.error(err)
        setTimelineRefetch(false)
      })
      .finally(() => {
        setTimeout(() => {
          setTimelineRefetch(false)
        }, 1000)
      })
  }

  const validateForm = () => {
    let isValid = true
    let messages = {
      newProgress: '',
      status: '',
    }

    if (newProgress.length === 0) {
      messages.newProgress = 'Please enter a comment'
      isValid = false
    }

    if (status.length === 0) {
      messages.status = 'Please select status'
      isValid = false
    }

    setFormError({
      ...formError,
      ...messages,
    })
    return isValid
  }

  const onPostUpdate = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    const data = {
      message: newProgress,
      amountType: amountType,
      status: status,
    }

    if (progressValue) {
      data['progressValue'] = selectedGoal?.progressType === 'BINARY' ? 0 : progressValue
    }
    if (validateForm()) {
      setIsPosting(true)
      HttpRequest('POST', `/goal/${selectedGoal?.id}/time-line/status`, data, commonHeader)
        .then(({ data }: any) => {
          setIsPosting(false)
          if (data.code === 200) {
            setUpdate(false)
          }
          setNewProgress('')
          setAmountType('INCREMENT')
          setStatus('')
          setProgressValue(null)
        })
        .catch((err) => {
          setIsPosting(false)
          console.error(err)
        })
    }
  }

  useEffect(() => {
    if (selectedGoal?.id) {
      if (update) {
        fetchTimelines()
      }
    }
  }, [update])

  useEffect(() => {
    if (timelineRefetch) {
      fetchTimelines()
    }
  }, [timelineRefetch])

  return (
    <>
      <CreateMeetingWithGoalModal showModal={showMeetingModal} setShowModal={setShowMeetingModal} goal={selectedGoal} />
      <EndGoalDialog
        endGoalModal={endGoalModal}
        setEndGoalModal={setEndGoalModal}
        selectedGoal={selectedGoal}
        companyId={companyId}
        setRefetch={() => {}}
        onCancelEdit={onCancelEdit}
      />
      <DeleteDialog
        selectedItem={selectedGoal}
        deleteGoalModal={deleteGoalModal}
        companyId={companyId}
        setRefetch={() => {}}
        onCancel={onCancelEdit}
        setDeleteGoalModal={setDeleteGoalModal}
      />
      {!editGoal && (
        <div
          className={`fixed flex flex-col top-0 right-0 h-screen bg-white overflow-hidden transform ${
            viewModal ? 'translate-x-0 opacity-100 visible' : 'translate-x-10 opacity-0 invisible'
          } duration-150 transition-all`}
          style={{
            boxShadow: '-4px 2px 6px 0 rgba(212, 212, 212, 0.44)',
            width: isMobile ? '100%' : '425px',
            zIndex: 5000,
            zoom: isMobile ? '0.8' : 'unset',
            height: isMobile ? 'calc(100vh + 200px)' : '',
          }}
        >
          <div className="overflow-y-scroll flex space-y-6 flex-col flex-1 p-8">
            <div className="flex justify-between items-center">
              <Link
                className={`${'cursor-pointer hover:text-themeBlue'} font-semibold flex items-center`}
                to={`/goals/explore/${companyId}/detail/${selectedGoal.id}`}
                target={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId ? '_self' : '_blank'}
              >
                {selectedGoal?.name} <BiLinkExternal className="text-sm mx-1" />
              </Link>
              <XIcon className="h-5 w-5 text-themeGray cursor-pointer" aria-hidden="true" onClick={onCancelEdit} />
            </div>
            {selectedGoal?.isPublished ? (
              <>
                {getLabelGoalDetail(selectedGoal)}
                {selectedGoal?.progressType !== 'BINARY' && (
                  <div>
                    <div className="h-3 my-4 relative bg-themeGray2 rounded-full">{getRange(selectedGoal, true)}</div>
                    <div className="text-sm text-themeGray flex justify-between">
                      <div className="flex gap-x-2">
                        <p>Start: {selectedGoal?.progressStartValue}</p>
                        <p>Current: {selectedGoal?.progressValue ? selectedGoal?.progressValue : 0}</p>
                      </div>
                      <p>Goal: {selectedGoal?.progressTargetValue}</p>
                    </div>
                  </div>
                )}
              </>
            ) : (
              <>
                <div className="bg-gray-100 py-2 flex justify-center rounded-lg">
                  <p className="text-themeGray flex items-center text-xs gap-x-1 uppercase font-medium">
                    <BsCheck2Square />
                    DRAFT
                  </p>
                </div>
              </>
            )}
            {!update && (
              <>
                <div>
                  {selectedGoal?.isPublished ? (
                    <div className="flex space-x-2 border-b-2 border-themeGray2 pb-5">
                      {!selectedGoal?.hasEnded ? (
                        <>
                          <Button
                            textColor="text-black"
                            hoverColor="bg-blue-500"
                            hoverTextColor="text-white"
                            label="Update"
                            className="flex-1 hover:bg-blue-500 hover:text-white"
                            secondary
                            onClick={() => setUpdate(true)}
                          />
                          <Button
                            disabled={selectedGoal?.owner?.[0]?.owner?.id === selectedCompany?.employeeId}
                            hoverColor="bg-blue-500"
                            hoverTextColor="text-white"
                            iconClass="hover:text-white"
                            className="hover:bg-blue-500 hover:text-white"
                            secondary
                            onlyIcon
                            Icon={GiVideoConference}
                            onClick={() => setShowMeetingModal(true)}
                          />

                          <Button
                            hoverColor="bg-blue-500"
                            hoverTextColor="text-white"
                            iconClass="hover:text-white"
                            className="hover:bg-blue-500 hover:text-white"
                            secondary
                            onlyIcon
                            Icon={FaRegEdit}
                            onClick={() => setEditGoal(true)}
                          />
                          <Button
                            hoverColor="bg-blue-500"
                            className="hover:bg-blue-500 hover:text-white"
                            hoverTextColor="text-white"
                            iconClass="hover:text-white"
                            secondary
                            onlyIcon
                            Icon={BsCheck2Square}
                            onClick={() => setEndGoalModal(true)}
                          />
                          <Button
                            secondary
                            onlyIcon
                            Icon={RiDeleteBin7Line}
                            iconClass="w-4 h-4"
                            hoverColor="bg-red-500"
                            className="hover:bg-red-500 hover:text-white"
                            onClick={() => setDeleteGoalModal(true)}
                          />
                        </>
                      ) : (
                        <Button
                          secondary
                          label="Reactivate"
                          textColor="text-black"
                          className="flex-1"
                          onClick={onReactivateGoal}
                        />
                      )}
                    </div>
                  ) : (
                    <div className="flex space-x-2 border-b-2 border-themeGray2 pb-5">
                      <Button
                        secondary
                        className="flex-1"
                        hoverColor="bg-blue-500"
                        hoverTextColor="text-white"
                        label="Publish"
                        iconClass=""
                        onClick={onPublishGoal}
                        disabled={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId}
                      />
                      <Button
                        secondary
                        className="flex-1"
                        hoverColor="bg-blue-500"
                        hoverTextColor="text-white"
                        onlyIcon
                        Icon={FaRegEdit}
                        iconClass=""
                        onClick={() => setEditGoal(true)}
                        disabled={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId}
                      />
                      <Button
                        secondary
                        onlyIcon
                        className="flex-1"
                        Icon={RiDeleteBin7Line}
                        iconClass=""
                        hoverColor="bg-red-500"
                        hoverTextColor="text-white"
                        onClick={() => setDeleteGoalModal(true)}
                        disabled={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId}
                      />
                    </div>
                  )}
                </div>
                <GoalDetail setFileViewModal={setViewModal} selectedGoal={selectedGoal} />
              </>
            )}

            {update && (
              <>
                <div className="border-t-2 border-themeGray2 py-2 space-y-4">
                  <NormalFormInput
                    id="progressStatus"
                    name="progressStatus"
                    value={newProgress}
                    onChange={(e) => setNewProgress(e.target.value)}
                    textarea
                    placeholder="What progress have you made?"
                    label="What’s new?"
                    error={formError.newProgress}
                  />
                  {selectedGoal?.progressType !== 'BINARY' && (
                    <>
                      <p className="text-sm font-semibold">What’s the new amount?</p>

                      <div className="flex items-center text-sm space-x-2">
                        <div className="flex">
                          <div
                            className={`p-2 w-20 cursor-pointer hover:border-themeBlue border rounded-l-lg ${
                              amountType === 'INCREMENT' && 'border-themeBlue bg-themeBlue2 '
                            }`}
                            onClick={() => setAmountType('INCREMENT')}
                          >
                            <p className="text-sm px-3 flex justify-center items-center text-black">
                              <span className="">Increment</span>
                            </p>
                          </div>
                          <div
                            className={`p-2 w-20 cursor-pointer border hover:border-themeBlue rounded-r-lg ${
                              amountType === 'TOTAL' && 'border-themeBlue bg-themeBlue2 '
                            }`}
                            onClick={() => setAmountType('TOTAL')}
                          >
                            <p className="text-sm px-3 flex justify-center  items-center text-black">
                              <span className="">Total</span>
                            </p>
                          </div>
                        </div>
                        <div>
                          {amountType === 'INCREMENT' ? (
                            <NormalFormInput
                              value={progressValue}
                              placeholder="+"
                              type="number"
                              onChange={(e) => setProgressValue(e.target.value)}
                            />
                          ) : (
                            <div className="flex items-center flex-nowrap space-x-2">
                              <NormalFormInput
                                value={progressValue}
                                placeholder="New total"
                                type="number"
                                className=""
                                onChange={(e) => setProgressValue(e.target.value)}
                              />
                            </div>
                          )}
                        </div>
                      </div>
                    </>
                  )}

                  <p className="text-sm font-semibold">What’s the status?</p>
                  <div className="flex">
                    <div
                      className={`p-2 cursor-pointer hover:border-black flex-1 border border-themeGray2 rounded-l-lg ${
                        status === 'OFF_TRACK' && 'border-darkYellow bg-lightYellow '
                      }`}
                      onClick={() => setStatus('OFF_TRACK')}
                    >
                      <p className="text-sm flex justify-center items-center text-themeGray">
                        <OffTrackIcon /> <span className="ml-2"> Off track</span>
                      </p>
                    </div>
                    <div
                      className={`p-2 cursor-pointer flex-1 border hover:border-black ${
                        status === 'PROCESSING' && 'border-darkYellow bg-lightYellow '
                      }`}
                      onClick={() => setStatus('PROCESSING')}
                    >
                      <p className="text-sm flex justify-center  items-center text-themeGray">
                        <ProcessingIcon /> <span className="ml-2">Processing</span>
                      </p>
                    </div>
                    <div
                      className={`p-2 cursor-pointer hover:border-black flex-1 border border-themeGray2 rounded-r-lg  ${
                        status === 'ON_TRACK' && 'border-darkYellow bg-lightYellow '
                      }`}
                      onClick={() => setStatus('ON_TRACK')}
                    >
                      <p className="text-sm flex justify-center items-center text-themeGray">
                        <OnTrackIcon />
                        <span className="ml-2">On track</span>
                      </p>
                    </div>
                  </div>
                  <p className="transition-all duration-200 text-sm text-red-600">{formError.status}</p>

                  <div className="py-6">
                    <p className="text-sm font-semibold pb-4">Timeline</p>
                    {timelineList.length > 0 ? (
                      <TimelinePostList
                        hideDropdown
                        setRefetch={setTimelineRefetch}
                        goalId={selectedGoal?.id}
                        companyId={companyId}
                        timelineList={timelineList}
                        user={user}
                        selectedGoal={selectedGoal}
                      />
                    ) : (
                      <div></div>
                    )}
                  </div>
                </div>
              </>
            )}
          </div>
          {update && (
            <div className="space-y-4 p-5 border-t border-themeGray2">
              <div className="flex space-x-2">
                <Button
                  size="md"
                  hoverColor="bg-themeBlue"
                  label={'Post Update'}
                  primary
                  onClick={onPostUpdate}
                  loading={isPosting}
                  loadingText="Posting"
                />
                <Button
                  textColor="text-black"
                  size="md"
                  hoverColor="bg-themeRed"
                  label={'Cancel'}
                  secondary
                  onClick={() => setUpdate(false)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* <------- Edit goal --------> */}
      {editGoal && (
        <div
          className={`fixed flex flex-col top-0 right-0 h-screen bg-white`}
          style={{
            boxShadow: '-4px 2px 6px 0 rgba(212, 212, 212, 0.44)',
            width: isMobile ? '100%' : '425px',
            zIndex: 5000,
            zoom: isMobile ? '0.8' : 'unset',
            height: isMobile ? 'calc(100vh + 200px)' : '',
          }}
        >
          <EditGoal
            editGoal={editGoal}
            setViewModal={setViewModal}
            setEditGoal={setEditGoal}
            selectedGoal={selectedGoal}
            setRefetch={() => {}}
            setSelectedGoal={setSelectedGoal}
          />
        </div>
      )}
    </>
  )
}

export default MeetingGoalDetails
