import { XIcon } from '@heroicons/react/solid'
import Rating from 'components/templates/Review/Rating/Rating'
import { AssessmentBox, ValuesLoader } from 'components/templates/Review/ReviewSegment'
import { roundToHalf } from 'helpers/formatters'
import useIsMobile from 'hooks/useIsMobile'
import { useReviewTools } from 'hooks/useReviewTools'
import { get, isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'

export const initial_avg_rating = {
  overall_self: 0,
  values_self: 0,
  feedback_self: 0,
  avgGoal_self: 0,
}

const AppraisalsSidebar = (props) => {
  const { hideSelfRating, selectedEmployee, viewModal } = props
  const isMobile = useIsMobile()
  const location = useLocation()
  const pathname = location.pathname
  const userId = pathname.split('/')[4]
  const [isFetching, setIsFetching] = useState(false)
  const [company, setCompany]: any = useState()
  const [reviews, setReviews] = useState<any>({})
  const [selfValues, setSelfValues] = useState([])
  const [feedbackSelfAssessment, setFeedbackSelfAssessment] = useState<any>('')
  const [goalsSelfAssessment, setGoalsSelfAssessment] = useState<any>('')
  const [avgRating, setAvgRating] = useState(initial_avg_rating)
  const [accomplishments, setAccomplishments] = useState('')
  const [careerAspirations, setCareerAspirations] = useState('')
  const [activeIndices, setActiveIndices] = useState([])

  const { companyId } = useParams<any>()
  const user1: any = useSelector((state) => get(state, 'user.user'))
  const { getReviews, getReview } = useReviewTools()

  const getCompanyName = () => {
    user1?.employee?.forEach((company) => {
      if (company.companyId === parseInt(companyId)) {
        setCompany(company)
      }
    })
  }

  const fetchReviews = (id?: string | number) => {
    setIsFetching(true)
    const onSuccess = (response) => {
      if (!isEmpty(response?.past) || !isEmpty(response?.future) || !isEmpty(response?.todays)) {
        const onReviewSuccess = (response) => {
          setReviews(response)
          setIsFetching(false)
        }
        const currReviewId = response.future[0]
          ? response.future[0].id
          : response.todays[0]
          ? response.todays[0].id
          : response.past[0].id
        getReview(id ? id : currReviewId, companyId, onReviewSuccess)
      } else {
        setIsFetching(false)
      }
    }
    getReviews(userId, companyId, onSuccess)
  }

  useEffect(() => {
    getCompanyName()
  }, [company, getCompanyName])

  useEffect(() => {
    fetchReviews()
  }, [selectedEmployee])

  useEffect(() => {
    setSelfValues(reviews?.selfCompanyValues)
    setFeedbackSelfAssessment(reviews?.feedbackSelfAssessment ? reviews?.feedbackSelfAssessment : '')
    setGoalsSelfAssessment(reviews?.goalsSelfAssessment ? reviews?.goalsSelfAssessment : '')
    setAccomplishments(reviews?.accomplishments)
    setCareerAspirations(reviews?.employeeCareerAspirations)
    const updatedSelfGoals = reviews?.selfGoals?.map((item) => {
      return {
        ...item.goal,
        rating: item.rating,
      }
    })
    const avgGoal_self = updatedSelfGoals?.reduce((acc, cur) => acc + cur.rating, 0) / updatedSelfGoals?.length
    const avgValue_self =
      reviews?.selfCompanyValues?.reduce((acc, cur) => acc + cur.rating, 0) / reviews?.selfCompanyValues?.length

    setAvgRating({
      values_self: isNaN(avgValue_self) ? 0 : roundToHalf(avgValue_self),
      avgGoal_self: isNaN(avgGoal_self) ? 0 : roundToHalf(avgGoal_self),
      feedback_self: reviews?.feedbackSelfRating ? roundToHalf(reviews?.feedbackSelfRating) : 0,
      overall_self: isNaN(reviews?.overallSelfRating) ? 0 : roundToHalf(reviews?.overallSelfRating),
    })
  }, [reviews])

  const toggleAccordion = (index) => {
    if (activeIndices.includes(index)) {
      setActiveIndices(activeIndices.filter((i) => i !== index))
    } else {
      setActiveIndices([...activeIndices, index])
    }
  }

  const expandAll = () => {
    const allIndices = Array.from({ length: selfValues.length }, (_, index) => index)
    setActiveIndices(allIndices)
  }

  const collapseAll = () => {
    setActiveIndices([])
  }

  const truncateString = (str, length) => {
    if (str.length > length) {
      return str.slice(0, length) + '...'
    }
    return str
  }

  return (
    <div
      className={`fixed flex flex-col top-0 right-0 h-screen bg-white overflow-hidden transform ${
        viewModal ? 'translate-x-0 opacity-100 visible' : 'translate-x-10 opacity-0 invisible'
      } duration-150 transition-all`}
      style={{
        boxShadow: '-4px 2px 6px 0 rgba(212, 212, 212, 0.44)',
        width: isMobile ? '100%' : '425px',
        zIndex: 5000,
        zoom: isMobile ? '0.8' : 'unset',
        height: isMobile ? 'calc(100vh + 200px)' : '',
      }}
    >
      {isFetching ? (
        <div className="p-8 space-y-5">
          <div className="h-8 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <div className="border-b-2 border-themeGray2"></div>
          <p className="text-sm text-themeGray">Company Values</p>
          <div className="h-80 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <p className="text-sm text-themeGray">360 Feedback</p>
          <div className="h-20 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <p className="text-sm text-themeGray">Goals</p>
          <div className="h-20 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <p className="text-sm text-themeGray">Accomplishments</p>
          <div className="h-20 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <p className="text-sm text-themeGray">Career Aspirations</p>
          <div className="h-20 bg-gray-200 animate-pulse duration-700 rounded"></div>
          <p className="text-sm text-themeGray">Overall Rating</p>
          <div className="h-20 bg-gray-200 animate-pulse duration-700 rounded"></div>
        </div>
      ) : (
        <div className="overflow-y-scroll flex space-y-6 flex-col flex-1 p-6 -mr-4">
          <div className="flex items-center">
            <XIcon className="h-5 w-5 text-themeGray cursor-pointer" aria-hidden="true" onClick={hideSelfRating} />
            <span className="ml-5 text-lg">
              {selectedEmployee?.myProfile?.fullName ? selectedEmployee?.myProfile?.fullName : 'Employee Name'}
            </span>
          </div>
          <div className="">
            <div className="text-sm flex flex-col">
              <div className="mt-3 border-2 border-gray-300 rounded-md p-2">
                <div className="flex items-center justify-between pt-1 gap-3 mb-3">
                  <h1 className="text-md font-bold text-gray-900 mb">Company Values</h1>
                  <div className="flex pointer-events-none cursor-not-allowed">
                    <Rating defaultValue={avgRating?.values_self} onClick={() => {}} fillColor="#1F51FF" />
                  </div>
                </div>
                {!isFetching ? (
                  selfValues?.length > 0 ? (
                    selfValues.map((item, index) => (
                      <div key={index} className="flex flex-col">
                        <div className="flex justify-between">
                          <button
                            className="flex items-center w-full gap-2 outline-none "
                            onClick={() => toggleAccordion(index)}
                          >
                            <p className="text-left border-l-2 border-themeGreen pl-2 truncate my-2 mr-2 text-gray-900 text-sm font-semibold max-w-48 overflow-hidden overflow-ellipsis">
                              {truncateString(item?.companyValue?.value, 16)}
                            </p>
                            <span
                              className={`mr-5 transform transition-transform ${
                                activeIndices.includes(index) ? 'rotate-180' : 'rotate-0'
                              }`}
                            >
                              <svg
                                width="13"
                                height="9"
                                viewBox="0 0 15 9"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M1.11133 1.07812L7.11133 7.07812L13.1113 1.07812"
                                  stroke="black"
                                  stroke-width="2"
                                  stroke-linecap="round"
                                  stroke-linejoin="round"
                                />
                              </svg>
                            </span>
                          </button>
                          <div className={`pointer-events-none cursor-not-allowed flex items-center`}>
                            <Rating defaultValue={item?.rating} onClick={() => {}} fillColor="#ffd700" />
                          </div>
                        </div>
                        <div>
                          {activeIndices.includes(index) && (
                            <div className="mr-2 border-l-2 border-themeGreen">
                              <div className="px-2 pt-2">
                                <div>
                                  <div className="mb-2">
                                    <p className="text-sm">{item?.companyValue?.description}</p>
                                  </div>
                                  <textarea
                                    name="value-assessment"
                                    id="value-assessment"
                                    rows={4}
                                    placeholder="Describe in brief how you lived by this value...."
                                    value={item.assessment}
                                    onChange={() => {}}
                                    readOnly
                                    className="w-full resize-none rounded-md text-sm focus:outline-none focus:border-gray"
                                  ></textarea>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="flex justify-between border-l-2 border-themeGreen">
                      <p className="m-2 text-dark text-sm font-bold">No Company Values.</p>
                      <p className="m-2 text-black text-sm font-bold">-</p>
                    </div>
                  )
                ) : (
                  <ValuesLoader />
                )}
                <div className="mt-3 flex justify-between">
                  <div className="flex gap-4 items-end">
                    <button onClick={() => expandAll()} className="no-underline text-blue-500 outline-none text-sm">
                      Expand all
                    </button>
                    <button onClick={() => collapseAll()} className="no-underline text-blue-500 outline-none text-sm">
                      Collapse all
                    </button>
                  </div>
                </div>
              </div>
              <div className="border-2 border-gray-300 rounded-md p-2 mt-3">
                <div className="flex flex-col items-center justify-between p-1">
                  <h1 className="w-full text-md font-bold text-gray-900 text-left mb-1">360 Feedback</h1>
                  {!isFetching && (
                    <div className="w-full">
                      {feedbackSelfAssessment ? (
                        <AssessmentBox type="self" description={feedbackSelfAssessment} field="Feedbacks" />
                      ) : (
                        <div>
                          <div className="h-16 w-full border-2 border-gray-300 rounded-md mt-1"></div>
                        </div>
                      )}
                    </div>
                  )}
                  <div className="pointer-events-none cursor-not-allowed"></div>
                </div>
              </div>
              <div className="border-2 border-gray-300 rounded-md p-2 mt-3">
                <div className="flex flex-col p-1">
                  <div className="flex items-center justify-between mb-1">
                    <h1 className="text-md font-bold text-gray-900">Goals</h1>
                    <div className="pointer-events-none cursor-not-allowed -mr-3">
                      <Rating defaultValue={avgRating?.avgGoal_self} onClick={() => {}} fillColor="#1F51FF" />
                    </div>
                  </div>
                  {!isFetching && (
                    <div className="w-full">
                      {goalsSelfAssessment ? (
                        <AssessmentBox type="self" description={goalsSelfAssessment} field="Feedbacks" />
                      ) : (
                        <div>
                          <div className="h-16 w-full border-2 border-gray-300 rounded-md mt-1"></div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="border-2 border-gray-300 rounded-md p-2 my-3">
                <div className="flex flex-col p-1">
                  <h1 className="text-md font-bold text-gray-900 mb-1">Accomplishments</h1>
                  {!isFetching && (
                    <div className="w-full">
                      {accomplishments ? (
                        <AssessmentBox type="self" description={accomplishments} field="Feedbacks" />
                      ) : (
                        <div>
                          <div className="h-16 w-full border-2 border-gray-300 rounded-md mt-1"></div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="border-2 border-gray-300 rounded-md p-2 my-3">
                <div className="flex flex-col p-1">
                  <h1 className="text-md font-bold text-gray-900 mb-1">Career Aspirations</h1>
                  {!isFetching && (
                    <div className="w-full">
                      {careerAspirations ? (
                        <AssessmentBox type="self" description={careerAspirations} field="Feedbacks" />
                      ) : (
                        <div>
                          <div className="h-16 w-full border-2 border-gray-300 rounded-md mt-1"></div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
              <div className="border-2 border-gray-300 rounded-md p-2 flex justify-between items-center w-full">
                <h1 className="font-bold text-gray-900">Overall Rating</h1>
                <div className="pointer-events-none cursor-not-allowed">
                  <Rating defaultValue={avgRating?.overall_self} onClick={() => {}} fillColor="#1F51FF" />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  )
}

export default AppraisalsSidebar
