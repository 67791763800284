/* eslint-disable @typescript-eslint/no-unused-vars */
import { createStore, applyMiddleware } from 'redux'
import { thunk } from 'redux-thunk'
import rootReducer from 'state/redux/reducers/root.reducers'

const configureStore = () => {
  const store = createStore(rootReducer, applyMiddleware(thunk))
  return { store }
}

export default configureStore
