import { FeedbackDateFormat } from 'helpers/dateconvertors'
import parse from 'html-react-parser'
import { useState } from 'react'

const SharedNotesAccordion = ({ sharedNotes }) => {
  const [activeIndex, setActiveIndex] = useState(null)

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index)
  }

  return (
    <div className="bg-gray-100 text-gray-800 rounded py-2 px-2 m-4 space-y-2">
      {sharedNotes.map((note, index) => {
        return (
          <div
            key={index}
            className="bg-white px-4 py-3 rounded-lg cursor-pointer"
            onClick={() => toggleAccordion(index)}
          >
            <div className="flex justify-between">
              <div className="flex items-center gap-4">
                <h1 className="text-sm font-semibold">{note?.title ? note?.title : 'Meeting title'}</h1>
                <span
                  className={`mr-5 transform transition-transform ${activeIndex === index ? 'rotate-180' : 'rotate-0'}`}
                >
                  <svg width="13" height="9" viewBox="0 0 15 9" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path
                      d="M1.11133 1.07812L7.11133 7.07812L13.1113 1.07812"
                      stroke="black"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </span>
              </div>
              <h1 className="text-sm font-semibold">{FeedbackDateFormat(note?.meetingTime)}</h1>
            </div>
            {activeIndex === index && (
              <p className="text-sm mt-4 border border-gray-400 p-2 rounded-lg">{parse(note?.sharedNote?.note)}</p>
            )}
          </div>
        )
      })}
    </div>
  )
}

export default SharedNotesAccordion
