import React, { useState } from 'react'
import styled from 'styled-components'
import { useTable, useExpanded, useBlockLayout, useResizeColumns } from 'react-table'
import { IoIosArrowUp } from 'react-icons/io'
import moment from 'moment'

const Styles = styled.div`
  padding-top: 1.5rem;

  .table {
    .th {
      border-right: 1px solid rgba(229, 231, 235);
      border-bottom: 1px solid rgba(229, 231, 235);
      font-size: 12px;
      font-weight: 700;
    }

    .td {
      font-size: 14px;
      display: flex !important;
      justify-content: flex-start !important;
      align-items: flex-end !important;
    }

    .th,
    .td {
      margin: 0;
      padding: 0.5rem;

      ${
        '' /* In this example we use an absolutely position resizer,
     so this is required. */
      }
      position: relative;

      :last-child {
        border-right: 0;
      }

      .resizer {
        display: inline-block;
        background: transparent;
        width: 10px;
        height: 100%;
        position: absolute;
        right: 0;
        top: 0;
        transform: translateX(50%);
        z-index: 1;
        transition: background 0.2s ease-in-out;
        ${'' /* prevents from scrolling while dragging on touch devices */}
        touch-action:none;

        &.isResizing {
          //   background: rgb(59, 130, 246);
        }
      }
    }
  }
`

function Table({ columns: userColumns, data }) {
  const [selectedRow, setSelectedRow] = useState(null)

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: userColumns,
      data,
      getSubRows: (row) => row.children,
    },
    useBlockLayout,
    useResizeColumns,
    useExpanded,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [...columns])
    }
  )

  return (
    <>
      <div>
        <div {...getTableProps()} className="table">
          <div>
            {headerGroups.map((headerGroup) => (
              <div {...headerGroup.getHeaderGroupProps()} className="tr">
                {headerGroup.headers.map((column) => (
                  <div {...column.getHeaderProps()} className="th">
                    {column.render('Header')}
                    {/* Use column.getResizerProps to hook up the events correctly */}
                    <div {...column.getResizerProps()} className={`resizer ${column.isResizing ? 'isResizing' : ''}`} />
                  </div>
                ))}
              </div>
            ))}
          </div>

          <div {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <div
                  onClick={() => {
                    setSelectedRow(row.original.id)
                  }}
                  {...row.getRowProps()}
                  className={`${row.original.id === selectedRow ? 'bg-blue-500 text-white' : ''}   cursor-pointer tr`}
                >
                  {row.cells.map((cell) => {
                    const isSelected = row.original.id === selectedRow
                    const isFilteredFalse = row.original.filtered === false

                    const textColorClass = isFilteredFalse ? (isSelected ? 'text-white' : 'text-purple-700') : ''

                    return (
                      <div {...cell.getCellProps()} className={`td ${textColorClass}`}>
                        {cell.render('Cell')}
                      </div>
                    )
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </div>
    </>
  )
}

function GoalsTree({ data, status, owner, progress, subGoalsCount, setViewModal, fetchGoal, setUpdate }) {
  const columns = React.useMemo(
    () => [
      {
        Header: 'TITLE',
        accessor: 'name',
        Cell: ({ cell: { value }, row }) => {
          return (
            <div
              {...row.getToggleRowExpandedProps({
                style: {
                  paddingLeft: `${row.depth * 1.4}rem`,
                },
              })}
              className="cursor-pointer h-full flex items-center gap-2"
            >
              {row.canExpand ? (
                <span>
                  <IoIosArrowUp
                    className={`transition-all ${row.isExpanded ? 'transform rotate-180' : 'transform rotate-90'}`}
                  />
                </span>
              ) : null}
              <span
                onClick={() => {
                  fetchGoal(row.original.id)
                  setViewModal(true)
                  setUpdate(false)
                }}
              >
                {value}
              </span>
            </div>
          )
        },
        width: Math.round(window.innerWidth * 0.35),
        minWidth: Math.round(window.innerWidth * 0.25),
        maxWidth: Math.round(window.innerWidth * 0.4),
      },
      {
        Header: () => <span className="w-full flex justify-center">PROGRESS</span>,
        accessor: 'progress',
        Cell: ({ row }) => {
          return (
            <div
              onClick={() => {
                fetchGoal(row.original.id)
              }}
              className="w-full flex justify-center self-center"
            >
              {progress(row.original)}
            </div>
          )
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
      {
        Header: () => <span className="w-full flex justify-center">STATUS</span>,
        accessor: 'status',
        Cell: ({ row }) => {
          return (
            <div
              onClick={() => {
                fetchGoal(row.original.id)
              }}
              className="w-full flex justify-center"
            >
              {status(row.original)}
            </div>
          )
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
      {
        Header: () => <span className="w-full flex justify-center">SUBGOALS</span>,
        accessor: 'children',
        Cell: ({ row }) => {
          return <>{subGoalsCount(row.original)}</>
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
      {
        Header: () => <span className="w-full flex justify-center">OWNER</span>,
        accessor: 'owner',
        Cell: ({ row }) => {
          return <>{owner(row.original)}</>
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
      {
        Header: () => <span className="w-full flex justify-center">DUE</span>,
        accessor: 'dueDate',
        Cell: ({ cell: { value } }) => {
          return (
            <div className="w-full h-full flex items-center justify-center">
              <span>{moment(value).format('MM/DD/YYYY')}</span>
            </div>
          )
        },
        width: Math.round(window.innerWidth * 0.08),
        minWidth: Math.round(window.innerWidth * 0.08),
        maxWidth: Math.round(window.innerWidth * 0.08),
      },
    ],
    []
  )

  return (
    <Styles>
      <Table columns={columns} data={data} />
    </Styles>
  )
}

export default GoalsTree
