import 'react-datepicker/dist/react-datepicker.css'
import 'assets/styles/index.css'
import 'assets/styles/index.scss'
import Error from 'components/atoms/notifications/alerts/Error'
import SuccessBlock from 'components/atoms/notifications/alerts/Success'
import LayoutRouter from 'containers/Router'
import { getAccessToken } from 'helpers/cookies'
import { HttpRequest } from 'helpers/services'
import get from 'lodash/get'
import isEmpty from 'lodash/isEmpty'
import { Fragment, useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'
import { logout, setUser } from 'state/redux/actions/user.actions'
import { getErrorMessages, getSuccessMessages } from 'state/redux/selectors/settings.selector'
import { useSettings } from 'hooks/useSettings'
import { TeamContextProvider } from 'components/templates/People/ContextApi/TeamContext'
import { MeetingContextProvider } from 'components/templates/Meeting/ContextApi/MeetingContext'
import { CreateOneOnOneContextProvider } from 'components/templates/Meeting/ContextApi/CreateOneOnOneContext'

const App = () => {
  const dispatch: any = useDispatch()
  const { setLoading } = useSettings()
  const [initialized, setInitialized] = useState(false)
  const user = useSelector((state) => get(state, 'user.user'))
  const accessToken = getAccessToken()
  const errorMessage = useSelector((state) => getErrorMessages(state)) || []
  const successMessage = useSelector((state) => getSuccessMessages(state)) || ''
  const fetchUserDetail = useCallback(() => {
    setLoading(true)
    HttpRequest('GET', '/company/user/info')
      .then((res: any) => {
        const data = res.data
        if (data.code === 200 && data.message === 'User information') {
          dispatch(setUser(data?.data))
          setInitialized(true)
        } else {
          dispatch(setUser({}))
        }
      })

      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setLoading(false)
        setInitialized(true)
      })
  }, [])

  useEffect(() => {
    if (isEmpty(accessToken)) {
      setLoading(false)
      try {
        dispatch(logout())
      } catch (err) {
        console.error(err)
      } finally {
        setInitialized(true)
      }
    } else {
      fetchUserDetail()
    }
  }, [accessToken])

  useEffect(() => {
    document.title = 'People Success Platform | Performance and Engagement Software | Coplae'
  }, [])

  const closeErrorBlock = () => {
    dispatch(setErrorMessages([]))
  }
  const closeSuccessMessage = () => {
    dispatch(setSuccessMessage(''))
  }

  return (
    <Fragment>
      <div style={{ zIndex: 5003 }} className="fixed bottom-4 right-6 w-2/6 z-10">
        <div className="mb-4">
          <SuccessBlock message={successMessage || ''} closeSuccessMessage={closeSuccessMessage} />
        </div>
        <Error errors={errorMessage || []} closeErrorBlock={closeErrorBlock} />
      </div>
      {initialized && (
        <TeamContextProvider>
          <MeetingContextProvider>
            <CreateOneOnOneContextProvider>
              <LayoutRouter isUser={!isEmpty(user)} loading={false} />
            </CreateOneOnOneContextProvider>
          </MeetingContextProvider>
        </TeamContextProvider>
      )}
    </Fragment>
  )
}

export default App
