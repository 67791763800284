import * as types from 'state/redux/constants'

const initialState = {
  companyInfo: {},
}

const companyReducer = (state = initialState, action: { type: any; msg: any; err: any; data: any }) => {
  switch (action.type) {
    case types.SET_COMPANY_INFO:
      return { ...state, companyInfo: action.data }

    default:
      return state
  }
}

export default companyReducer
