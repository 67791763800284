import useIsMobile from 'hooks/useIsMobile'
import { IButtonProps } from 'interfaces/atoms/button.types'
import { isEmpty } from 'lodash'
import { useEffect, useState } from 'react'
import Dropdown from 'react-multilevel-dropdown'
interface IDropdown extends IButtonProps {
  items: {
    name: string
    className?: string
    id: string
    onClick?: () => void
    children?: any
    checkBox?: boolean
    count?: number
    active?: boolean
    search?: boolean
    title?: boolean
    description?: string
    searchInput?: string
    onChangeSearch?: (e: any) => void
  }[]
  showDots?: boolean
  right?: boolean
  border?: boolean
  noIcon?: boolean
  secondaryWithBorder?: boolean
  withoutTitle?: boolean
  onCheckboxChange?: any
  chipList?: any
  isAnyoneDisabled?: boolean
  width?: string
  showSearchInput?: boolean // New prop to control the visibility of the search input
}

export default function Dropdown3({
  label,
  items,
  Icon,
  onCheckboxChange,
  withoutTitle,
  chipList,
  isAnyoneDisabled = false,
  width = 'w-30',
  showSearchInput = true, // New prop to control the visibility of the search input
}: IDropdown) {
  const [searchInput, setSearchInput] = useState('')
  const [filteredItems, setFilteredItems]: any = useState(items)

  const onChange = (c, parentItem, e) => {
    const arr = []

    if (filteredItems?.children) {
      filteredItems?.children.map((item: any) => {
        if (item?.isActive === true) {
          arr.push(item)
        }
      })
    }
    if (arr) {
      onCheckboxChange({ ...arr[0], isActive: false }, parentItem, false)
    }

    c = {
      ...c,
      isActive: e,
    }
    onCheckboxChange(c, parentItem, e)
  }

  const title = withoutTitle ? null : (
    <p className="truncate text-xs font-semibold text-gray-500 bg-white relative">
      {!isEmpty(chipList) ? chipList[0].text : label}{' '}
    </p>
  )

  const isMobile = useIsMobile()

  useEffect(() => {
    if (searchInput === '') {
      setFilteredItems(items)
    } else {
      const newFilteredItems = items.map((item) => {
        if (item.children) {
          const filteredChildren = item.children.filter((child) =>
            child.name.toLowerCase().includes(searchInput.toLowerCase())
          )
          return { ...item, children: filteredChildren }
        }
        return item
      })
      setFilteredItems(newFilteredItems)
    }
  }, [searchInput, items])

  return (
    <div className="">
      <Dropdown
        title={title}
        position="right"
        menuClassName={isMobile ? 'w-40 m-0' : 'w-60 m-0'}
        wrapperClassName={`employee_dropdown3 h-10 mt-0.5 ${
          isMobile ? 'w-full h-10' : width
        } text-gray-700 hover:text-blue-500 hover:border-blue-500 focus:border-blue-500 border border-gray-400 rounded-lg`}
        buttonClassName={isMobile ? 'h-auto' : 'w-full'}
        buttonVariant="tertiary"
      >
        {filteredItems?.map((item) => (
          <div onClick={item?.onClick} key={item.id} className={'noFlex'}>
            {item?.description && item?.description.length > 0 && (
              <p className="px-3 w-52 whitespace-pre-line py-1 text-gray-900 text-xs">{item?.description}</p>
            )}
            {item?.search && showSearchInput && (
              <div className="my-2 px-3 py-1">
                <input
                  type="text"
                  name="search"
                  autoComplete={'off'}
                  value={searchInput}
                  id="search"
                  onChange={(e: any) => setSearchInput(e.target.value)}
                  className={`focus:outline-none transition-all focus:border-blue-500 block w-full border border-gray-400 rounded-lg text-sm text-gray-700`}
                  placeholder={'Search..'}
                />
              </div>
            )}
            {item?.children?.map((i, idx) => (
              <>
                <Dropdown.Item
                  key={i.id}
                  onClick={() => {
                    setSearchInput('')
                    if (!(idx === 0 && isAnyoneDisabled)) {
                      onChange(i, item, !i?.isActive)
                      item?.onChangeSearch && item?.onChangeSearch('')
                    }
                    const mousedownEvent = new MouseEvent('mousedown')
                    document.dispatchEvent(mousedownEvent)
                  }}
                  className={i?.isActive ? 'bg-themeBlue text-white' : ''}
                >
                  <label className={`flex cursor-pointer w-full`}>
                    <div className={`ml-2 ${idx === 0 && isAnyoneDisabled ? 'text-gray-500 cursor-not-allowed' : ''}`}>
                      <p className="">{i.name}</p>
                    </div>
                  </label>
                </Dropdown.Item>
                {i.break && <div className="h-0.5 w-full my-2 bg-themeGray2"></div>}
              </>
            ))}
          </div>
        ))}
      </Dropdown>
    </div>
  )
}
