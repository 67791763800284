import { AiOutlineCloseCircle, AiOutlineExclamationCircle } from 'react-icons/ai'
import { CgClose } from 'react-icons/cg'
import Avatar from 'components/atoms/Avatar'
import Tooltip from 'components/atoms/Tooltip/Tooltip'

const SearchBar2 = ({
  onChange,
  value,
  onCancel,
  onFocus,
  onBlur,
  withChip,
  onChipCancel,
  chipItem,
  placeholder,
  label,
  sublabel,
  required,
  inputClass,
  tooltip = '',
  Icon = false,
  error,
  disabled,
  noBadge = false,
  noAvatar,
  ancestor,
  withClearButton = false,
  onClear,
  multi = true,
}: {
  value?: string
  onCancel?: () => void
  onChipCancel?: (i: any) => void
  onChange?: (e: any) => void
  onFocus?: (e: any) => void
  onBlur?: (e: any) => void
  placeholder?: string
  Icon?: boolean
  label?: string
  sublabel?: string
  required?: string
  inputClass?: string
  withChip?: boolean
  chipItem?: any
  tooltip?: string
  error?: string
  disabled?: boolean
  noBadge?: boolean
  noAvatar?: boolean
  ancestor?: string
  withClearButton?: boolean
  onClear?: () => void
  multi?: boolean
}) => {
  return (
    <>
      {label && (
        <div className="my-2">
          <div className="flex items-center gap-x-1">
            <label className="block dark:text-white text-xs sm:text-sm font-semibold">
              {label}{' '}
              {required && <span className="text-red-500 dark:text-red-400 font-medium text-sm sm:text-base">*</span>}
            </label>
            {tooltip.length > 0 && (
              <Tooltip text={tooltip} position="top">
                <AiOutlineExclamationCircle className="text-themeGray mx-1" />
              </Tooltip>
            )}
          </div>
          {sublabel && <span className="text-xs sm:text-sm text-gray-600 italic">{sublabel}</span>}
        </div>
      )}
      <label
        className={`${inputClass} ${
          disabled && 'cursor-not-allowed bg-gray-100'
        } flex flex-wrap gap-2 relative items-center focus:outline-none transition-all  px-2 py-2.5 focus:border-blue-500 w-full text-xs sm:text-sm border border-gray-400 rounded-lg`}
      >
        {noBadge ? (
          <>
            {chipItem?.map?.((item, id) => (
              <span key={item?.id} className=" rounded-full flex items-center align-center w-max">
                <span className="text-black">
                  <p className="text-xs sm:text-sm ml-3">{item?.name}</p>
                </span>
              </span>
            ))}
          </>
        ) : (
          <>
            {chipItem?.map?.((item, id) => (
              <span
                key={item?.id}
                className="px-2 py-1 rounded-full text-gray-700 bg-blue-100 border border-blue-300 text-sm flex items-center align-center w-max"
              >
                <span className="flex items-center gap-2 text-black mr-2">
                  {!noAvatar && (
                    <Avatar
                      name={item?.fullName}
                      image={item?.profileImageThumbnailUrl}
                      nameClass="text-xs sm:text-sm"
                      small
                    />
                  )}
                  <p className="text-xs text-gray-700 font-semibold">
                    {ancestor === 'department' ? item?.name : item?.fullName}
                  </p>
                </span>

                {!disabled && (
                  <CgClose
                    className="transition-all hover:text-blue-800 h-5 w-5 ml-1 font-extrabold cursor-pointer"
                    onClick={() => onChipCancel(item)}
                  />
                )}
              </span>
            ))}
          </>
        )}
        <input
          onFocus={onFocus}
          onBlur={onBlur}
          disabled={disabled}
          autoComplete="off"
          value={value ? value : ''}
          placeholder={(chipItem && chipItem.length) === 0 && placeholder}
          id="search"
          onChange={(e: any) => onChange(e.target.value)}
          className={`${
            (chipItem && chipItem.length) > 0 ? 'w-20' : ''
          } appearance-none bg-transparent border-none text-gray-700 leading-tight focus:outline-none ${
            !multi && chipItem?.length > 0 ? 'hidden' : ''
          }`}
        />
        {withClearButton && (chipItem && chipItem.length) > 0 && (
          <div className="pr-3 pl-5 absolute right-0">
            <AiOutlineCloseCircle size={20} className="transition-all cursor-pointer text-gray-700" onClick={onClear} />
          </div>
        )}
      </label>
      {error && <p className="mt-2 transition-all duration-200 text-xs sm:text-sm text-red-600">{error}</p>}
    </>
  )
}

export default SearchBar2
