import { HttpRequest } from 'helpers/services'
import { useDispatch } from 'react-redux'
import { setErrorMessages } from 'state/redux/actions/settings.actions'

export const useReviewTools = () => {
  const dispatch: any = useDispatch()

  const getReviews = (employeeId, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-employee-id': employeeId,
    }
    HttpRequest('GET', `/review`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        const errMgs = err?.response?.data?.message
        dispatch(setErrorMessages([errMgs]))
      })
  }

  const postReviews = (data, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/review`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        const errMgs = err?.response?.data?.message
        onError(errMgs)
      })
  }

  const getReview = (id, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/review/${id}`, {}, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        const errMgs = err?.response?.data?.message
        dispatch(setErrorMessages([errMgs]))
      })
  }

  const patchReviewDate = (reviewId, data, companyId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/review/${reviewId}`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        const errMgs = err?.response?.data?.message
        dispatch(setErrorMessages([errMgs]))
      })
  }

  const postReview = (reviewId, data, companyId, onSuccess, onError) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('POST', `/review/${reviewId}`, data, commonHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuccess(fetchedData)
        }
      })
      .catch((err) => {
        const errMgs = err?.response?.data?.message
        onError(errMgs)
        dispatch(setErrorMessages([errMgs]))
      })
  }

  const getSkipLevelSignoff = (companyId, employeeId, onSuccess) => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-employee-id': `${employeeId}`,
    }
    HttpRequest('GET', '/review/skip-level-signoffs', {}, commonHeader)
      .then((res: any) => {
        const fetchedData = res?.data?.data
        onSuccess(fetchedData)
      })
      .catch((err) => {
        const errMgs = err?.response?.data?.message
        dispatch(setErrorMessages([errMgs]))
      })
  }

  return {
    getReviews,
    getReview,
    postReviews,
    patchReviewDate,
    postReview,
    getSkipLevelSignoff,
  }
}
