export const formatNumber = (value: string) => {
  return value && value.replace(/^(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export const roundToHalf = (num: number) => {
  return Math.round(num * 2) / 2
}

export function countNestedObjects(obj) {
  let count = 1 // initialize count to 1 for the current object
  if (obj.children && obj.children.length > 0) {
    // if the object has children, iterate through each child and add their count to the total count
    obj.children.forEach((child) => {
      count += countNestedObjects(child)
    })
  }
  return count
}

