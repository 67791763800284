/* eslint-disable @typescript-eslint/no-unused-vars */
import { useEffect, useState } from 'react'

import { get, map } from 'lodash'
import Avatar from 'components/atoms/Avatar'
import { XIcon } from '@heroicons/react/solid'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import Button from 'components/atoms/Button/button'
import { FaListUl, FaRegEdit } from 'react-icons/fa'
import { BsCheck2Square } from 'react-icons/bs'
import { RiAttachment2, RiDeleteBin7Line } from 'react-icons/ri'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import DeleteDialog from 'components/organisms/Goals/EditGoal/DeleteDialog'
import EndGoalDialog from 'components/organisms/Goals/EditGoal/EndGoalDialog'
import EditGoal from 'components/organisms/Goals/EditGoal/EditGoal'
import { HttpRequest } from 'helpers/services'
import { ExploreHooks } from 'components/organisms/Goals/ExploreHooks/ExploreHooks'
import { ReactComponent as OffTrackIcon } from 'assets/icons/GoalExplore/GoalStatus/offTrack.svg'
import { ReactComponent as ProcessingIcon } from 'assets/icons/GoalExplore/GoalStatus/processing.svg'
import { ReactComponent as OnTrackIcon } from 'assets/icons/GoalExplore/GoalStatus/onTrack.svg'
import GoalDetail from 'components/organisms/Goals/EditGoal/GoalDetail'
import moment from 'moment'
import { BiLinkExternal } from 'react-icons/bi'
import { MdKeyboardArrowRight } from 'react-icons/md'
import TimelinePostList from 'components/organisms/Goals/GoalDetails/TimelinePostList'
import { useSelector } from 'react-redux'
import SubGoalTable from 'components/organisms/Goals/SubGoalTable'
import Tabs from 'components/organisms/Goals/Tabs/Tabs'
import { CgListTree } from 'react-icons/cg'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import FileViewerModal from 'components/organisms/Meeting/OneOne/FileViewer'
import { useOneOnOneTools } from 'hooks/useOneOnOneTools'
import GoalsTree from 'pages/Goal/GoalsTree'
import { countNestedObjects } from 'helpers/formatters'
import { GiVideoConference } from 'react-icons/gi'
import CreateMeetingWithGoalModal from 'components/organisms/Goals/CreateGoalPopup/CreateMeetingWithGoal'
import { IoDuplicateOutline } from 'react-icons/io5'
import Slider from 'react-rangeslider'
import Counter from 'components/atoms/Counter'

const ExploreGoalList = ({
  data,
  loading = false,
  isManage = false,
  setRefetch,
  setSelected,
  chipClose,
  setAllActive,
  ownerNameList,
  statusList,
  setFilter,
  setHeaderLabel,
  setListType,
  setSearchInput,
  selectedCompany,
  setOwnerNameList,
}: {
  data?: any[]
  loading?: boolean
  isManage?: boolean
  onCreate?: () => void
  onSort?: () => void
  setRefetch?: any
  setSelected?: any
  chipClose?: any
  setAllActive?: any
  ownerNameList?: any
  statusList?: any
  setHeaderLabel?: any
  setListType?: any
  setFilter?: any
  setSearchInput?: any
  selectedCompany?: any
  setOwnerNameList?: any
}) => {
  const { listGoalsAttachment } = useOneOnOneTools()
  const [localCopy, setLocalCopy] = useState(data)
  const [showCreate, setShowCreate] = useState(false)
  const [viewModal, setViewModal] = useState(false)
  const [editGoal, setEditGoal] = useState(false)
  const [endGoalModal, setEndGoalModal] = useState(false)
  const [deleteGoalModal, setDeleteGoalModal] = useState(false)
  const [fetching, setFetching] = useState(true)
  const [selectedGoal, setSelectedGoal]: any = useState({})
  const [update, setUpdate] = useState(false)
  const [timelineList, setTimelineList] = useState([])
  const [initialized, setInitialized] = useState(false)
  const [dupGoalLoading, setDupGoalLoading] = useState(false)

  const { getRange, getLabelGoalDetail, getLabelGoalList, getGoalListTitle, getProgress } = ExploreHooks()

  const user: any = useSelector((state) => get(state, 'user.user'))
  const { companyId }: any = useParams()
  const navigate = useNavigate()
  const location = useLocation()
  const isSubGoalPage = location.pathname.includes('subgoal')

  const [breadcrumbList, setBreadcrumbList] = useState([])

  const [status, setStatus] = useState('')
  const [newProgress, setNewProgress] = useState('')
  const [progressValue, setProgressValue] = useState(null)
  const [formError, setFormError]: any = useState({})
  const [isPosting, setIsPosting] = useState(false)
  const [timelineRefetch, setTimelineRefetch] = useState(false)
  const [fileViewModal, setFileViewModal] = useState(false)
  const [showMeetingModal, setShowMeetingModal] = useState(false)
  const [duplicateGoal, setDuplicateGoal]: any = useState()
  const [sliderValue, setSliderValue] = useState<number>()

  const tabs = [
    { name: 'cascading', icon: <CgListTree size={14} /> },
    { name: `default`, icon: <FaListUl size={14} /> },
  ]

  const [curTab, setCurTab] = useState('cascading')
  const helpers = tabs.map((tab) => tab?.name === curTab)

  const fetchTimelines = () => {
    setFetching(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/goal/${selectedGoal?.id}/time-line`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          const goal = data?.data?.goal
          let timeline = data?.data?.timeline
          if (timeline.length > 0) {
            timeline = timeline.map((item) => ({
              ...item,
              isEdit: false,
              isComment: false,
              commentText: '',
            }))
          }
          // setSelectedGoal(goal)
          setTimelineList(timeline)
          setProgressValue(goal?.progressValue ? goal?.progressValue : goal?.progressStartValue)
        }
        setFetching(false)
        setInitialized(true)
        setTimelineRefetch(false)
      })
      .catch((err) => {
        console.error(err)
        setFetching(false)
        setTimelineRefetch(false)
      })
      .finally(() => {
        setTimeout(() => {
          setFetching(false)
          setInitialized(true)
          setTimelineRefetch(false)
        }, 1000)
      })
  }

  useEffect(() => {
    if (selectedGoal?.id) {
      if (update) {
        fetchTimelines()
      }
    }
  }, [update])

  useEffect(() => {
    if (!initialized && selectedGoal?.id) {
      fetchTimelines()
    }
    if (timelineRefetch) {
      fetchTimelines()
    }
  }, [timelineRefetch, selectedGoal])

  const fetchGoal = (goalId: any) => {
    setFetching(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/goal/${goalId}`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          const goals = data?.data?.goal
          const subgoals = data?.data?.subgoals
          setSelectedGoal({ ...goals, subgoals })
        }
        setFetching(false)
      })
      .catch((err) => {
        console.error(err)
        setFetching(false)
      })
      .finally(() => {
        setTimeout(() => {
          setFetching(false)
        }, 1000)
      })
  }

  useEffect(() => {
    setLocalCopy(data)
  }, [data])

  useEffect(() => {
    if (!isSubGoalPage) {
      setBreadcrumbList([])
    }
  }, [isSubGoalPage])

  const Breadcrumb = () => {
    return (
      <div className="flex items-center mt-4 m-2 gap-1 -ml-1">
        <div
          onClick={() => {
            setBreadcrumbList([])
            navigate(`/goals/explore/${companyId}`)
          }}
          className="flex items-center"
        >
          <p className="text-gray-700 py-1 px-2 rounded-md hover:bg-gray-100 hover:shadow cursor-pointer transition-all">
            Goals
          </p>
          <MdKeyboardArrowRight style={{ color: 'text-gray-700' }} size={22} />
        </div>
        {map(breadcrumbList, (item: any, index: number) => (
          <div
            key={item.id}
            onClick={() => {
              const updatedList = [...breadcrumbList]
              updatedList.splice(index + 1, 9e9)
              setBreadcrumbList(updatedList)
              navigate(`/goals/explore/${companyId}/subgoal/${item.id}`)
            }}
            className="flex items-center"
          >
            {!(index === breadcrumbList.length - 1) ? (
              <>
                <p
                  className={`text-gray-700 py-1 px-2 rounded-md hover:bg-gray-100 hover:shadow cursor-pointer transition-all`}
                >
                  {item.name}
                </p>
                <MdKeyboardArrowRight style={{ color: 'text-gray-700' }} size={22} />
              </>
            ) : (
              <>
                <p
                  className={`font-bold text-gray-700 py-1 px-2 rounded-md hover:bg-gray-100 hover:shadow cursor-pointer transition-all`}
                >
                  {item.name}
                </p>
              </>
            )}
          </div>
        ))}
      </div>
    )
  }

  const dataList = map(localCopy, (user) => {
    const owner = user.owner.length && (
      <div className="flex w-full justify-center ">
        {user?.owner?.map((item) => (
          <Tooltip text={user?.owner?.[0]?.owner?.fullName} position="top">
            <Avatar
              name={item?.owner?.fullName}
              medium
              className="-ml-1 pt-1"
              image={item?.owner?.profileImageThumbnailUrl}
            />
          </Tooltip>
        ))}
      </div>
    )

    const dueDate = new Date(user?.dueDate)

    const dueDateString = `${dueDate
      .toLocaleString('default', { month: 'numeric' })
      .substring(0, 3)}/${dueDate.getDate()}/${dueDate.getFullYear()}`

    let lastUpdated = ''

    if (user?.goalTimeline.length !== 0) {
      lastUpdated = moment(user?.goalTimeline[0]?.updatedAt).fromNow()
    } else {
      lastUpdated = 'Never'
    }

    const subgoals = (
      <span
        onClick={() => {
          if (user?.children?.length > 0) {
            const value = ownerNameList.forEach((item) => {
              if (item.id === 'anyone') {
                item.isActive = true
              } else {
                item.isActive = false
              }
            })
            setOwnerNameList(value)
            statusList.forEach((item) => {
              if (item.id === 'd1') {
                item.isActive = true
              } else {
                item.isActive = false
              }
            })
            setFilter({
              id: 'a',
              name: 'Any',
            })
            setSearchInput('')
            setSelected('all')
            chipClose({ title: 'all' })
            setAllActive(false)
            setBreadcrumbList((prev) => [...prev, { id: user?.id, name: user?.name }])
            navigate(`/goals/explore/${companyId}/subgoal/${user.id}`)
          }
        }}
        className="flex items-center gap-2 text-center justify-center"
      >
        <p className="ml-1">{user?.children?.length}</p>
        {user?.children?.length > 0 && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-subtask"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="6" y1="9" x2="12" y2="9" />
            <line x1="4" y1="5" x2="8" y2="5" />
            <path d="M6 5v11a1 1 0 0 0 1 1h5" />
            <rect x="12" y="7" width="8" height="4" rx="1" />
            <rect x="12" y="15" width="8" height="4" rx="1" />
          </svg>
        )}
      </span>
    )

    const title = (
      <span
        onClick={() => {
          setViewModal(true)
          setUpdate(false)
        }}
      >
        {getGoalListTitle(user, 70)}
      </span>
    )

    const StatusList = ({ user }) => (
      <div className="cursor-pointer text-black w-full flex justify-center items-center">
        {getLabelGoalList(user, () => {
          setViewModal(true)
          setUpdate(true)
        })}
      </div>
    )

    const ProgressList = ({ user }) => (
      <div className="cursor-pointer text-black w-full flex justify-center items-center m-auto">
        {getProgress(user)}
      </div>
    )

    const returnData = {
      id: user?.id,
      title,
      status: <StatusList user={user} />,
      subgoals,
      progress: <ProgressList user={user} />,
      owner: owner,
      due: dueDateString,
      updated: lastUpdated,
    }

    return returnData
  })

  const SubGoals = ({ user }) => {
    return (
      <span
        onClick={() => {
          if (user?.children?.length > 0) {
            const value = ownerNameList.map((item) => {
              if (item.id === 'anyone') {
                item.isActive = true
              } else {
                item.isActive = false
              }
            })
            setOwnerNameList(value)
            statusList.forEach((item) => {
              if (item.id === 'd1') {
                item.isActive = true
              } else {
                item.isActive = false
              }
            })
            setFilter({
              id: 'a',
              name: 'Any',
            })
            setSearchInput('')
            setHeaderLabel({ name: 'Explore goals', title: '' })
            setSelected('all')
            chipClose({ title: 'all' })
            setAllActive(false)
            setBreadcrumbList((prev) => [...prev, { id: user?.id, name: user?.name }])
            navigate(`/goals/explore/${companyId}/subgoal/${user.id}`)
          }
        }}
        className="cursor-pointer w-full h-full justify-center flex items-center gap-2"
      >
        <p className="ml-1">{countNestedObjects(user) - 1}</p>
        {user?.children?.length > 0 && (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="icon icon-tabler icon-tabler-subtask"
            width="22"
            height="22"
            viewBox="0 0 24 24"
            stroke-width="1.5"
            stroke="#2c3e50"
            fill="none"
            stroke-linecap="round"
            stroke-linejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="6" y1="9" x2="12" y2="9" />
            <line x1="4" y1="5" x2="8" y2="5" />
            <path d="M6 5v11a1 1 0 0 0 1 1h5" />
            <rect x="12" y="7" width="8" height="4" rx="1" />
            <rect x="12" y="15" width="8" height="4" rx="1" />
          </svg>
        )}
      </span>
    )
  }

  const Attachment = ({ user }) => {
    return (
      <Tooltip
        text={user?.attachments?.length > 0 ? `${user?.attachments?.length} Attachments` : 'Attachments'}
        position="top"
      >
        <span
          onClick={() => {
            setSelectedGoal(user)
            setFileViewModal(true)
          }}
          className="cursor-pointer hover:text-blue-500 flex items-center gap-1"
        >
          <RiAttachment2 size={18} />
          {user?.attachments?.length > 0 ? <p className="ml-1">{user?.attachments?.length}</p> : ''}
        </span>
      </Tooltip>
    )
  }

  const Title = ({ user, isChild }) => (
    <span
      onClick={() => {
        setViewModal(true)
        setUpdate(false)
      }}
    >
      {getGoalListTitle(user, isChild ? 66 : 70)}
    </span>
  )

  const Progress = ({ user }) => (
    <div className="cursor-pointer w-full flex justify-center items-center m-auto">{getProgress(user)}</div>
  )

  const Status = ({ user }) => (
    <div className="cursor-pointer text-black w-full flex justify-center items-center">
      {getLabelGoalList(user, () => {
        setViewModal(true)
        setUpdate(true)
      })}
    </div>
  )

  const Owner = ({ user }) => {
    return user?.owner?.length ? (
      <div className="flex w-full justify-center">
        <Tooltip text={user?.owner?.[0]?.owner?.fullName} position="top">
          {user?.owner?.map((item) => (
            <Avatar
              name={item?.owner?.fullName}
              medium
              className="-ml-1 pt-1"
              image={item?.owner?.profileImageThumbnailUrl}
            />
          ))}
        </Tooltip>
      </div>
    ) : (
      <div>-</div>
    )
  }

  const DueDate = ({ user }) => {
    const dueDate = moment(user?.dueDate).format('MM/DD/YYYY')

    return <span>{dueDate}</span>
  }

  const onCreate = () => {
    setShowCreate(true)
  }

  const GoalListConfig = {
    headers: ['Title', 'Progress', 'Status', 'SubGoals', 'Owner', 'Due'],
    loading,
    curTab,
    dataList,
    onCreate,
    viewModal,
    config: {
      dark: false,
      headers: {
        textColor: 'text-black ',
        textSize: 'text-xs',
        bgColor: isManage ? 'border-t' : '',
      },
      dataList: {
        textSize: 'text-sm',
        items: localCopy,
        selectedItem: selectedGoal,
        onItemClick: (goal) => {
          fetchGoal(goal.id)
        },
      },
    },
  }

  const onCancelEdit = () => {
    setViewModal(false)
    setSelectedGoal({})
    setUpdate(false)
  }

  const onPublishGoal = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/goal/${selectedGoal.id}/publish`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          onCancelEdit()
          setRefetch(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const onReactivateGoal = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('PATCH', `/goal/${selectedGoal.id}/re-active`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          onCancelEdit()
          setRefetch(true)
        }
      })
      .catch((err) => {
        console.error(err)
      })
  }

  const validateForm = () => {
    let isValid = true
    let messages = {
      newProgress: '',
      status: '',
    }

    if (newProgress.length === 0) {
      messages.newProgress = 'Please enter a comment'
      isValid = false
    }

    if (status.length === 0) {
      messages.status = 'Please select status'
      isValid = false
    }

    setFormError({
      ...formError,
      ...messages,
    })
    return isValid
  }

  const onPostUpdate = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    const data = {
      message: newProgress,
      amountType: 'TOTAL',
      status: status,
    }

    if (progressValue || sliderValue) {
      data['progressValue'] =
        selectedGoal?.progressType === 'BINARY'
          ? 0
          : selectedGoal?.progressType === 'PERCENT'
          ? sliderValue
          : progressValue
    }
    if (validateForm()) {
      setIsPosting(true)
      HttpRequest('POST', `/goal/${selectedGoal?.id}/time-line/status`, data, commonHeader)
        .then(({ data }: any) => {
          setIsPosting(false)
          if (data.code === 200) {
            setUpdate(false)
            setTimeout(() => fetchGoal(selectedGoal?.id), 1000)
            setRefetch(true)
          }
          setNewProgress('')
          setStatus('')
          setProgressValue(null)
        })
        .catch((err) => {
          setIsPosting(false)
          console.error(err)
        })
        .finally(() => {
          setUpdate(true)
        })
    }
  }

  const loadingList = [
    { id: '1', fullName: '', title: '', permissions: '' },
    { id: '2', fullName: '', title: '', permissions: '' },
  ]

  const TreeLoading = () => (
    <tbody className="">
      {map(
        loadingList,
        (item, idx) =>
          loading && (
            <tr
              role="button"
              onClick={() => {}}
              className={'bg-transparent hover:bg-gray-100 cursor-pointer  border-b border-gray-200'}
              key={idx}
            >
              {map([0, 1, 2, 3], (header, _idx) => {
                return (
                  <td
                    key={item.id + '-' + header}
                    style={{ width: '40%' }}
                    className={'px-4 py-4 whitespace-nowrap text-base'}
                  >
                    <div className="h-4 bg-gray-200 animate-pulse duration-700 rounded"></div>
                  </td>
                )
              })}
            </tr>
          )
      )}
    </tbody>
  )

  const handleViewChange = (tab: string) => {
    setListType(tab)
  }

  const getAttachments = (setLoading, setDocs, setRefetch) => {
    setLoading(true)
    const onSuccess = (response) => {
      const updatedDocs = response?.attachmentsWithUrl.map((doc: any) => {
        return { ...doc, uri: doc.url }
      })
      setDocs(updatedDocs)
      setLoading(false)
      setRefetch(false)
    }

    const onError = (error) => {
      setLoading(false)
    }

    listGoalsAttachment(selectedGoal?.id, companyId, onSuccess, onError)
  }

  const updateAttachments = () => {
    setRefetch(true)
  }

  const deleteAttachments = () => {
    setRefetch(true)
  }

  const getEmployeeId = () => {
    return user.employee.filter((emp) => emp.companyId === parseInt(companyId))[0]?.employeeId
  }

  const onDuplicateGoal = () => {
    setDupGoalLoading(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', `/goal/${selectedGoal.id}/duplicate`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          setDuplicateGoal(data?.data)
          setDupGoalLoading(false)
        }
      })
      .catch((err) => {
        setDupGoalLoading(false)
        console.error(err)
      })
  }

  useEffect(() => {
    if (duplicateGoal) {
      navigate(`/goals/explore/${companyId}/edit/${duplicateGoal.id}`)
    }
  }, [duplicateGoal])

  useEffect(() => {
    if (selectedGoal) {
      setSliderValue(selectedGoal?.progressValue)
    }
  }, [selectedGoal])

  return (
    <>
      <FileViewerModal
        getAttachments={getAttachments}
        deleteAttachments={deleteAttachments}
        header={selectedGoal?.name}
        showModal={fileViewModal}
        setShowModal={setFileViewModal}
        item={selectedGoal}
        updateAttachments={updateAttachments}
      />
      <CreateMeetingWithGoalModal showModal={showMeetingModal} setShowModal={setShowMeetingModal} goal={selectedGoal} />
      <div className="flex justify-between items-center">
        <Breadcrumb />
        <Tabs onChange={handleViewChange} theme="small" tabs={tabs} curTab={curTab} setCurTab={setCurTab} />
      </div>
      {curTab === 'default' && <SubGoalTable {...GoalListConfig} />}
      {curTab === 'cascading' && <TreeLoading />}

      {!loading && curTab === 'cascading' && (
        <GoalsTree
          owner={(node: any) => <Owner user={node} />}
          status={(node: any) => <Status user={node} />}
          subGoalsCount={(node: any) => <SubGoals user={node} />}
          progress={(node: any) => <Progress user={node} />}
          data={localCopy}
          setViewModal={setViewModal}
          setUpdate={setUpdate}
          fetchGoal={fetchGoal}
        />
      )}

      {/* <------- End Goal dialog --------> */}
      <EndGoalDialog
        endGoalModal={endGoalModal}
        setEndGoalModal={setEndGoalModal}
        selectedGoal={selectedGoal}
        companyId={companyId}
        setRefetch={setRefetch}
        onCancelEdit={onCancelEdit}
      />

      {/* <------- Delete Goal dialog --------> */}
      <DeleteDialog
        selectedItem={selectedGoal}
        deleteGoalModal={deleteGoalModal}
        companyId={companyId}
        setRefetch={setRefetch}
        onCancel={onCancelEdit}
        setDeleteGoalModal={setDeleteGoalModal}
      />

      {/* <------- View goal --------> */}
      {!editGoal && (
        <div
          className={`fixed flex flex-col top-0 right-0 h-screen bg-white overflow-hidden transform ${
            viewModal ? 'translate-x-0 opacity-100 visible' : 'translate-x-10 opacity-0 invisible'
          } duration-150 transition-all`}
          style={{ boxShadow: '-4px 2px 6px 0 rgba(212, 212, 212, 0.44)', width: '425px', zIndex: 5000 }}
        >
          {fetching ? (
            <div className="p-8 space-y-5">
              <div className="h-16 bg-gray-200 animate-pulse duration-700 rounded"></div>
              <div className="border-b-2 border-themeGray2"></div>
              <p className="text-sm text-themeGray">DESCRIPTION</p>
              <div className="h-12 bg-gray-200 animate-pulse duration-700 rounded"></div>
              <p className="text-sm text-themeGray">OWNERS</p>
              <div className="h-12 bg-gray-200 animate-pulse duration-700 rounded"></div>
              <p className="text-sm text-themeGray">CYCLE</p>
              <div className="h-12 bg-gray-200 animate-pulse duration-700 rounded"></div>
              <p className="text-sm text-themeGray">LAST UPDATED</p>
              <div className="h-12 bg-gray-200 animate-pulse duration-700 rounded"></div>
              <p className="text-sm text-themeGray">DUE</p>
              <div className="h-12 bg-gray-200 animate-pulse duration-700 rounded"></div>
            </div>
          ) : (
            <div className="overflow-y-scroll flex space-y-6 flex-col flex-1 p-8">
              <div className="flex justify-between items-center">
                <Link
                  className={`${'cursor-pointer hover:text-themeBlue'} font-semibold flex items-center`}
                  to={`/goals/explore/${companyId}/detail/${selectedGoal.id}`}
                  target={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId ? '_self' : '_blank'}
                >
                  {selectedGoal?.name} <BiLinkExternal className="text-sm mx-1" />
                </Link>
                <XIcon className="h-5 w-5 text-themeGray cursor-pointer" aria-hidden="true" onClick={onCancelEdit} />
              </div>
              {selectedGoal?.isPublished ? (
                <>
                  {getLabelGoalDetail(selectedGoal)}
                  {selectedGoal?.progressType !== 'BINARY' && (
                    <div>
                      {!update && (
                        <div className="h-3 my-4 relative bg-themeGray2 rounded-full">
                          {getRange(selectedGoal, true)}
                        </div>
                      )}
                      {selectedGoal?.progressType === 'PERCENT' && update && (
                        <div className="tasks_slider">
                          <Slider
                            min={selectedGoal?.progressStartValue || 0}
                            max={selectedGoal?.progressTargetValue || 100}
                            value={sliderValue}
                            onChange={(value) => setSliderValue(value)}
                          />
                        </div>
                      )}
                      <div className="text-sm text-themeGray flex justify-between">
                        <div className="flex gap-x-2">
                          <p>Start: {selectedGoal?.progressStartValue}</p>
                          <p>Current: {selectedGoal?.progressValue ? selectedGoal?.progressValue : 0}</p>
                        </div>
                        <p>Target: {selectedGoal?.progressTargetValue}</p>
                      </div>
                    </div>
                  )}
                </>
              ) : (
                <>
                  <div className="bg-gray-100 py-2 flex justify-center rounded-lg">
                    <p className="text-themeGray flex items-center text-xs gap-x-1 uppercase font-medium">
                      <BsCheck2Square />
                      DRAFT
                    </p>
                  </div>
                </>
              )}
              {!update && (
                <>
                  <div>
                    {selectedGoal?.isPublished ? (
                      <div className="flex space-x-2 border-b-2 border-themeGray2 pb-5">
                        {!selectedGoal?.hasEnded ? (
                          <>
                            <Button
                              textColor="text-black"
                              hoverColor="bg-blue-500"
                              hoverTextColor="text-white"
                              label="Update"
                              className="h-8 flex-1 hover:bg-blue-500 hover:text-white"
                              secondary
                              onClick={() => setUpdate(true)}
                            />
                            <Tooltip text="Create Meeting with this goal" position="top">
                              <Button
                                disabled={selectedGoal?.owner?.[0]?.owner?.id === selectedCompany?.employeeId}
                                hoverColor="bg-blue-500"
                                hoverTextColor="text-white"
                                iconClass="hover:text-white w-4 h-4"
                                className="hover:bg-blue-500 hover:text-white"
                                secondary
                                onlyIcon
                                Icon={GiVideoConference}
                                onClick={() => setShowMeetingModal(true)}
                              />
                            </Tooltip>
                            <Tooltip text="Edit Goal" position="top">
                              <Button
                                hoverColor="bg-blue-500"
                                hoverTextColor="text-white"
                                iconClass="hover:text-white w-4 h-4"
                                className="hover:bg-blue-500 hover:text-white"
                                secondary
                                onlyIcon
                                Icon={FaRegEdit}
                                onClick={() => setEditGoal(true)}
                              />
                            </Tooltip>
                            <Tooltip text="End Goal" position="top">
                              <Button
                                hoverColor="bg-blue-500"
                                className="hover:bg-blue-500 hover:text-white"
                                hoverTextColor="text-white"
                                iconClass="hover:text-white w-4 h-4"
                                secondary
                                onlyIcon
                                Icon={BsCheck2Square}
                                onClick={() => setEndGoalModal(true)}
                              />
                            </Tooltip>
                            <Tooltip text="Copy Goal" position="top">
                              <Button
                                loading={dupGoalLoading}
                                hoverColor="bg-blue-500"
                                className="hover:bg-blue-500 hover:text-white"
                                hoverTextColor="text-white"
                                iconClass="w-4 h-4 hover:text-white"
                                secondary
                                onlyIcon
                                Icon={IoDuplicateOutline}
                                onClick={() => onDuplicateGoal()}
                              />
                            </Tooltip>
                            <Tooltip text="Delete Goal" position="left">
                              <Button
                                secondary
                                onlyIcon
                                Icon={RiDeleteBin7Line}
                                iconClass="w-4 h-4"
                                hoverColor="bg-red-500"
                                className="hover:bg-red-500 hover:text-white"
                                onClick={() => setDeleteGoalModal(true)}
                              />
                            </Tooltip>
                          </>
                        ) : (
                          <Button
                            secondary
                            label="Reactivate"
                            textColor="text-black"
                            className="flex-1"
                            onClick={onReactivateGoal}
                          />
                        )}
                      </div>
                    ) : (
                      <div className="flex space-x-2 border-b-2 border-themeGray2 pb-5">
                        <Button
                          secondary
                          className="flex-1"
                          hoverColor="bg-blue-500"
                          hoverTextColor="text-white"
                          label="Publish"
                          iconClass=""
                          onClick={onPublishGoal}
                          disabled={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId}
                        />
                        <Button
                          secondary
                          className="flex-1"
                          hoverColor="bg-blue-500"
                          hoverTextColor="text-white"
                          onlyIcon
                          Icon={FaRegEdit}
                          iconClass=""
                          onClick={() => setEditGoal(true)}
                          disabled={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId}
                        />
                        <Button
                          secondary
                          className="flex-1"
                          hoverColor="bg-blue-500"
                          hoverTextColor="text-white"
                          onlyIcon
                          Icon={IoDuplicateOutline}
                          iconClass=""
                          onClick={() => onDuplicateGoal()}
                          disabled={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId}
                        />
                        <Tooltip text="Delete Goal" position="top">
                          <Button
                            secondary
                            onlyIcon
                            className="flex-1"
                            Icon={RiDeleteBin7Line}
                            iconClass=""
                            hoverColor="bg-red-500"
                            hoverTextColor="text-white"
                            onClick={() => setDeleteGoalModal(true)}
                            disabled={selectedGoal?.owner?.[0]?.owner?.id !== selectedCompany?.employeeId}
                          />
                        </Tooltip>
                      </div>
                    )}
                  </div>
                  <GoalDetail setFileViewModal={setFileViewModal} selectedGoal={selectedGoal} />
                </>
              )}

              {update && (
                <>
                  {selectedGoal?.progressType !== 'BINARY' &&
                    selectedGoal?.progressType !== 'PERCENT' &&
                    !selectedGoal?.rollupChildCounts &&
                    selectedGoal?.parent !== null && (
                      <>
                        <p className="text-sm font-semibold">What’s the new amount?</p>
                        <div className="flex items-center text-sm space-x-2">
                          <div className="flex items-center flex-nowrap space-x-2">
                            <Counter value={progressValue} setValue={setProgressValue} min={0} />
                          </div>
                        </div>
                      </>
                    )}
                  <div className="border-t-2 border-themeGray2 py-2 space-y-4">
                    <NormalFormInput
                      id="progressStatus"
                      name="progressStatus"
                      value={newProgress}
                      onChange={(e) => setNewProgress(e.target.value)}
                      textarea
                      placeholder="What progress have you made?"
                      label="What’s new?"
                      error={formError.newProgress}
                    />

                    <p className="text-sm font-semibold">What’s the status?</p>
                    <div className="flex">
                      <div
                        className={`p-2 cursor-pointer hover:border-black flex-1 border border-themeGray2 rounded-l-lg ${
                          status === 'OFF_TRACK' && 'border-darkYellow bg-lightYellow '
                        }`}
                        onClick={() => setStatus('OFF_TRACK')}
                      >
                        <p className="text-sm flex justify-center items-center text-themeGray">
                          <OffTrackIcon /> <span className="ml-2"> Off track</span>
                        </p>
                      </div>
                      <div
                        className={`p-2 cursor-pointer flex-1 border hover:border-black ${
                          status === 'PROCESSING' && 'border-darkYellow bg-lightYellow '
                        }`}
                        onClick={() => setStatus('PROCESSING')}
                      >
                        <p className="text-sm flex justify-center  items-center text-themeGray">
                          <ProcessingIcon /> <span className="ml-2">Processing</span>
                        </p>
                      </div>
                      <div
                        className={`p-2 cursor-pointer hover:border-black flex-1 border border-themeGray2 rounded-r-lg  ${
                          status === 'ON_TRACK' && 'border-darkYellow bg-lightYellow '
                        }`}
                        onClick={() => setStatus('ON_TRACK')}
                      >
                        <p className="text-sm flex justify-center items-center text-themeGray">
                          <OnTrackIcon />
                          <span className="ml-2">On track</span>
                        </p>
                      </div>
                    </div>
                    <p className="transition-all duration-200 text-sm text-red-600">{formError.status}</p>

                    <div className="py-6">
                      <p className="text-sm font-semibold pb-4">Timeline</p>
                      {timelineList.length > 0 ? (
                        <TimelinePostList
                          hideDropdown
                          setRefetch={setTimelineRefetch}
                          goalId={selectedGoal?.id}
                          companyId={companyId}
                          timelineList={timelineList}
                          user={user}
                          selectedGoal={selectedGoal}
                        />
                      ) : (
                        <div></div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          )}
          {update && (
            <div className="space-y-4 p-5 border-t border-themeGray2">
              <div className="flex space-x-2">
                <Button
                  size="md"
                  hoverColor="bg-themeBlue"
                  label={'Post Update'}
                  primary
                  onClick={onPostUpdate}
                  loading={isPosting}
                  loadingText="Posting"
                />
                <Button
                  textColor="text-black"
                  size="md"
                  hoverColor="bg-themeRed"
                  label={'Cancel'}
                  secondary
                  onClick={() => setUpdate(false)}
                />
              </div>
            </div>
          )}
        </div>
      )}

      {/* <------- Edit goal --------> */}
      {editGoal && (
        <div
          className={`fixed flex flex-col top-0 right-0 h-screen bg-white`}
          style={{ boxShadow: '-4px 2px 6px 0 rgba(212, 212, 212, 0.44)', width: '425px', zIndex: 5000 }}
        >
          <EditGoal
            editGoal={editGoal}
            setViewModal={setViewModal}
            setEditGoal={setEditGoal}
            selectedGoal={selectedGoal}
            setRefetch={setRefetch}
            setSelectedGoal={setSelectedGoal}
          />
        </div>
      )}
    </>
  )
}

export default ExploreGoalList
