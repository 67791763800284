/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useState } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ChevronDownIcon } from '@heroicons/react/solid'
import { classNames } from 'lib/utils/classNames'
import { IButtonProps } from 'interfaces/atoms/button.types'
import { map } from 'lodash'
import { HiDotsHorizontal } from 'react-icons/hi'
import useIsMobile from 'hooks/useIsMobile'

interface IDropdown extends IButtonProps {
  items: {
    name: string
    className?: string
    id: string
    onClick?: () => void
  }[]
  showDots?: boolean
  right?: boolean
  border?: boolean
  noIcon?: boolean
  secondaryWithBorder?: boolean
  Icon?: any
  center?: any
}

export default function Dropdown({
  label,
  textColor = 'text-gray-700',
  size = 'md',
  primary = false,
  btnColor = 'bg-blue-500',
  secondary = false,
  secondaryWithBorder = false,
  items,
  right = false,
  showDots = false,
  hoverColor = 'bg-blue-600',
  border = false,
  iconBefore = false,
  noIcon = false,
  Icon,
  center,
}: IDropdown) {
  const genSize = () => {
    switch (size) {
      case 'sm':
        return 'px-2.5 py-1.5 text-xs'
      case 'md':
        return 'px-3 py-2 text-sm'
      case 'lg':
        return 'px-4 py-3 text-sm'
      case 'xl':
        return 'p-4 text-base'

      default:
        return 'px-3 py-2 text-sm'
    }
  }

  const renderByType = () => {
    switch (true) {
      case primary:
        return `${btnColor} hover:bg-blue-600 text-white `
      case secondary:
        return `bg-transparent  group hover:text-blue-500`
      case secondaryWithBorder:
        return `bg-transparent group hover:${hoverColor} ${textColor} hover:text-white hover:border-transparent border-themeGray2  border `
      default:
        return `${btnColor} hover:${hoverColor} text-white `
    }
  }

  const isMobile = useIsMobile()

  return (
    <Menu
      onClick={(e) => e.stopPropagation()}
      as="div"
      className={`relative ${border && 'border rounded-md'} inline-block text-left`}
    >
      <div>
        <Menu.Button
          className={classNames(
            genSize(),
            renderByType(),
            ' transition-all flex items-center gap-x-1 focus:outline-none justify-center rounded-lg'
          )}
        >
          {iconBefore && !noIcon && Icon && <Icon aria-hidden="true" className="h-5 w-5" />}
          {label}{' '}
          {!iconBefore && !noIcon && (
            <>
              {Icon ? (
                <Icon aria-hidden="true" size="15px" />
              ) : showDots ? (
                <HiDotsHorizontal className="h-5 w-5" aria-hidden="true" />
              ) : (
                <ChevronDownIcon className="-mr-1 ml-2 h-5 w-5" aria-hidden="true" />
              )}
            </>
          )}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{ zIndex: 999, right: center && '-90px' }}
          className={`${
            !right && 'right-0'
          } origin-top-right absolute mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {map(
              items,
              (item) =>
                item?.id && (
                  <Menu.Item key={item?.id}>
                    {({ active }) => (
                      <div
                        role={'button'}
                        aria-label={item?.name}
                        onClick={item.onClick}
                        className={classNames(
                          item?.className,
                          active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                          'block px-4 py-2 cursor-pointer',
                          isMobile ? 'text-xs' : 'text-sm'
                        )}
                      >
                        {item.name}
                      </div>
                    )}
                  </Menu.Item>
                )
            )}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export const Dropdown2 = ({
  children,
  items,
  setDropdownVisible,
}: {
  children: React.ReactNode
  setDropdownVisible?: (b: boolean) => void
  items: {
    name: string
    className?: string
    id: string
    onClick?: () => void
  }[]
}) => {
  const [open, setOpen] = useState(false)

  const handleOpen = (isOpen) => {
    setOpen(isOpen)
    setDropdownVisible(isOpen)
  }
  return (
    <Menu
      onClick={(e) => e.stopPropagation()}
      as="div"
      className="relative inline-block text-left  items-center justify-center"
    >
      <div>
        <Menu.Button
          onClick={() => handleOpen(!open)}
          className="bg-gray-100 rounded-full flex items-center justify-center text-gray-400 hover:text-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-themeGreen"
        >
          <span className="sr-only">Open options</span>
          {children}
        </Menu.Button>
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{ zIndex: 999, left: 70 }}
          className="origin-top-right absolute -bottom-1 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
        >
          <div className="py-1">
            {map(items, (item) => (
              <Menu.Item key={item.id}>
                {({ active }) => (
                  <div
                    role={'button'}
                    aria-label={item.name}
                    onClick={item.onClick}
                    className={classNames(
                      item?.className,
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 py-2 text-sm cursor-pointer'
                    )}
                  >
                    {item.name}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}

export const Dropdown3 = ({
  children,
  label,
  items,
}: {
  children: React.ReactNode
  label?: string
  items: {
    name: string
    className?: string
    id: string
    onClick?: () => void
  }[]
}) => {
  return (
    <Menu onClick={(e) => e.stopPropagation()} as="div" className=" inline-block text-left items-center justify-center">
      <div>
        {label && (
          <Menu.Button className="rounded-full flex text-sm items-center hover:text-themeGreen justify-center text-white">
            {children} <span className="ml-3">{label}</span>
          </Menu.Button>
        )}
      </div>

      <Menu.Items
        style={{ zIndex: 9999, left: 160 }}
        className="origin-top-right absolute z-999 bg-themeGreenle bottom-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div className="py-1">
          {map(items, (item) => (
            <Menu.Item key={item.id}>
              {({ active }) => (
                <div
                  role={'button'}
                  aria-label={item.name}
                  onClick={item.onClick}
                  className={classNames(
                    item?.className,
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  {item.name}
                </div>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  )
}

export const Dropdown4 = ({
  children,
  label,
  items,
}: {
  children: React.ReactNode
  label?: string
  items: {
    name: string
    className?: string
    id: string
    onClick?: () => void
  }[]
}) => {
  return (
    <Menu onClick={(e) => e.stopPropagation()} as="div" className=" inline-block text-left items-center justify-center">
      <div>
        <Menu.Button className="rounded-full flex items-center hover:text-themeGreen justify-center text-white">
          {children} <span className="ml-4">{label}</span>
        </Menu.Button>
      </div>

      <Menu.Items
        style={{ zIndex: 999, left: 160 }}
        className="origin-top-right absolute z-999 bg-themeGreenle top-25 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
      >
        <div className="py-1">
          {map(items, (item) => (
            <Menu.Item key={item.id}>
              {({ active }) => (
                <div
                  role={'button'}
                  aria-label={item.name}
                  onClick={item.onClick}
                  className={classNames(
                    item?.className,
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'block px-4 py-2 text-sm cursor-pointer'
                  )}
                >
                  {item.name}
                </div>
              )}
            </Menu.Item>
          ))}
        </div>
      </Menu.Items>
    </Menu>
  )
}
