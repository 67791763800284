import React, { useState } from 'react'
import Button from 'components/atoms/Button/button'
import Modal from 'components/atoms/Modal'
import Avatar from 'components/atoms/Avatar'
import { BiMinus } from 'react-icons/bi'
import { HttpRequest } from 'helpers/services'
import { useNavigate } from 'react-router-dom'

interface Props {
  deleteGoalModal: boolean
  setDeleteGoalModal: React.Dispatch<React.SetStateAction<boolean>>
  selectedItem: any
  companyId?: any
  onCancel?: any
  setRefetch?: React.Dispatch<React.SetStateAction<boolean>>
  redirect?: boolean
}
const DeleteDialog = ({
  deleteGoalModal,
  setDeleteGoalModal,
  selectedItem,
  companyId,
  setRefetch,
  onCancel,
  redirect,
}: Props) => {
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)

  const onDelete = () => {
    setLoading(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('DELETE', `/goal/${selectedItem?.id}`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.code === 200) {
          if (redirect) {
            navigate(`/goals/explore/${companyId}`)
          }
          setLoading(false)
          setRefetch(true)
          setDeleteGoalModal(false)
          onCancel()
        }
      })
      .catch((err) => {
        setLoading(false)
        console.error(err)
      })
  }

  return (
    <Modal
      max={{ w: 124 }}
      hwClassName={'max-w-124'}
      open={deleteGoalModal}
      setOpen={setDeleteGoalModal}
      header="Delete this goal"
      zIndex={6000}
    >
      <div className="flex justify-center flex-col">
        <>
          <p className="text-sm">
            Are you sure you want to delete this goal {selectedItem?.subgoals?.length > 0 ? 'and its subgoals' : ''}?
            This action can’t be undone.
          </p>
          <div className="flex space-x-1 my-4">
            <div className="bg-themeGray5 flex justify-center items-center w-14 border-t border-l border-b border-themeGray2 rounded-l-lg">
              <BiMinus className="text-themeGray" />
            </div>

            <div className="bg-themeGray5 p-5 space-y-4 flex-1 border-t border-r border-b border-themeGray2 rounded-r-lg">
              <div>
                <p className="text-black font-semibold">{selectedItem?.name}</p>
                <p className="text-themeGray text-sm">Not updated</p>
              </div>
              <div className="flex justify-between items-center">
                <div className="flex">
                  {selectedItem?.owner?.length > 0 &&
                    selectedItem?.owner?.map((item) => (
                      <Avatar
                        name={item?.owner?.fullName}
                        className="-ml-1 "
                        medium
                        image={item.profileImageThumbnailUrl}
                      />
                    ))}
                </div>
                <p className="text-sm text-themeGray">
                  Due{' '}
                  {`${new Date(selectedItem?.dueDate)
                    .toLocaleString('default', { month: 'long' })
                    .substring(0, 3)} ${new Date(selectedItem?.dueDate).getDate()}, 
                      ${new Date(selectedItem?.dueDate).getFullYear()}`}
                </p>
              </div>
            </div>
          </div>
        </>
        {selectedItem?.subgoals?.length > 0 && (
          <div className="mb-2">
            <p className="text-red-500 text-xs">
              * This goal has <span className="font-bold">{selectedItem?.subgoals?.length}</span>{' '}
              {selectedItem?.subgoals?.length > 1 ? 'subgoals' : 'subgoal'}
            </p>
          </div>
        )}
        <div className="flex justify-end items-center pt-4 space-x-2">
          <Button size="md" secondary label={'Cancel'} onClick={() => setDeleteGoalModal(false)} />
          <Button
            size="md"
            btnColor="bg-themeRed"
            textColor="text-white"
            hoverColor="bg-themeRed"
            focusRing="ring-themeRed"
            label={selectedItem?.subgoals?.length > 0 ? 'Delete goal and Subgoals' : 'Delete goal'}
            onClick={onDelete}
            loading={loading}
            loadingText=""
          />
        </div>
      </div>
    </Modal>
  )
}

export default DeleteDialog
