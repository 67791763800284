import { Transition } from '@headlessui/react'

const Error = ({ error }: { error?: string }) => {
  return (
    <Transition
      show={Boolean(error)}
      className="text-red-500 text-sm my-4"
      enter="transition transform ease-out duration-150"
      enterFrom="transform opacity-0 translate-y-1"
      enterTo="transform opacity-100 translate-y-0"
      leave="transition transform ease-in duration-75"
      leaveFrom="transform opacity-100 translate-y-0"
      leaveTo="transform opacity-0 translate-y-1"
    >
      {error}
    </Transition>
  )
}

export default Error
