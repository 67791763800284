/* This example requires Tailwind CSS v2.0+ */
import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, SelectorIcon } from '@heroicons/react/solid'
import { classNames } from 'lib/utils/classNames'
import { isEmpty } from 'lodash'
import Avatar from 'components/atoms/Avatar'
import { CgClose } from 'react-icons/cg'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { AiOutlineExclamationCircle } from 'react-icons/ai'
import useIsMobile from 'hooks/useIsMobile'
import SmallLoader from 'components/atoms/SmallLoader'

interface IselectorType {
  list: any[]
  placeholder?: string
  label?: string
  inLabel?: string
  required?: boolean
  selectedItem?: any
  onSelect?: (item: any) => void
  onChipCancel?: (item: any) => void
  border?: boolean
  chipList?: any[]
  error?: string
  keyName?: string
  disableFocus?: boolean
  width?: string
  spanClass?: string
  buttonBoxClass?: string
  suggestion?: string
  noOptionText?: string
  withAvatar?: boolean
  tooltip?: string
  optional?: boolean
  showAllTimePlaceholder?: boolean
  zIndex?: number
  disabled?: boolean
  isSearchSelector?: boolean
  searchInput?: string
  setSearchInput?: (value: string) => void
  loading?: boolean
}

export const MultiSelctor = ({
  list,
  placeholder = '',
  label = '',
  inLabel = '',
  required = false,
  selectedItem = '',
  onSelect = (item) => {},
  onChipCancel = (item) => {},
  border = true,
  chipList = [],
  error = '',
  keyName = 'name',
  disableFocus = false,
  width = '',
  spanClass = '',
  buttonBoxClass = '',
  suggestion = '',
  noOptionText = '',
  withAvatar = false,
  tooltip = '',
  optional = false,
  showAllTimePlaceholder = false,
  zIndex = 1000,
  disabled = false,
  isSearchSelector = false,
  searchInput,
  setSearchInput,
  loading = false,
}: IselectorType) => {
  const isMobile = useIsMobile()
  const [chipLists, setChipLists] = useState([])
  const [filteredList, setFilteredList] = useState([])

  const fetchFilteredList = () => {
    const l = list.filter((item) => !chipList.includes(item))
    setFilteredList(l)
  }

  useEffect(() => {
    setChipLists(chipList)
    fetchFilteredList()
  }, [chipList, list])

  return (
    <div className={`${!isEmpty(width) ? width : 'w-full'}`}>
      <Listbox value={selectedItem} onChange={onSelect}>
        {({ open }) => (
          <div>
            {label && (
              <div className="flex items-center">
                <Listbox.Label
                  className={`block dark:text-white my-2 ${isMobile ? 'text-xs' : 'text-sm'} font-semibold`}
                >
                  {label} {required && <span className="text-red-500 font-medium text-base">*</span>}{' '}
                  {optional && <span className="text-themeGray font-medium">(optional)</span>}
                </Listbox.Label>
                {tooltip.length > 0 && (
                  <Tooltip text={tooltip}>
                    <AiOutlineExclamationCircle className="text-themeGray mx-1" />
                  </Tooltip>
                )}
              </div>
            )}
            <div className="relative">
              <Listbox.Button
                className={`${
                  disabled ? 'bg-gray-100 pointer-events-none' : ''
                } ${buttonBoxClass} bg-white dark:bg-gray-800 relative w-full ${
                  border ? 'border' : ''
                } dark:border-gray-700 border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none ${
                  disableFocus ? '' : 'focus:ring-blue-500 focus:border-blue-500 focus:outline-none transition-al'
                } text-xs sm:text-sm`}
              >
                <span
                  className={`${spanClass} block truncate  text-themeGray dark:text-white ${
                    !selectedItem ? 'text-themeGray' : ''
                  }`}
                >
                  {chipLists.length > 0 ? (
                    <div className="flex flex-wrap gap-2 items-center">
                      {chipLists.map((item, index) => (
                        <span
                          key={index}
                          className="px-1.5 py-1 rounded-full text-themeGray bg-gray-200 text-xs sm:text-sm flex items-center align-center w-max"
                        >
                          {withAvatar ? (
                            <span className="text-black mr-2">
                              <Avatar
                                name={item?.fullName}
                                image={item?.profileImageThumbnailUrl}
                                nameClass={isMobile ? 'text-xs font-semibold' : 'font-semibold'}
                                small
                                withName
                              />
                            </span>
                          ) : (
                            <span className="mx-2">{item?.name}</span>
                          )}
                          <CgClose
                            className="h-5 w-5 ml-1 font-extrabold cursor-pointer"
                            onClick={() => onChipCancel(item)}
                          />
                        </span>
                      ))}
                      {showAllTimePlaceholder && placeholder}
                    </div>
                  ) : (
                    placeholder
                  )}
                </span>
                <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                  <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
                </span>
              </Listbox.Button>
              {suggestion && <p className="text-xs mt-2 text-gray-400">{suggestion}</p>}
              {error && (
                <p className={`mt-2 transition-all duration-200 ${isMobile ? 'text-xs' : 'text-sm'} text-red-600`}>
                  {error}
                </p>
              )}

              <Transition
                show={open}
                as={Fragment}
                leave="transition ease-in duration-100"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <Listbox.Options
                  style={{ zIndex: 1000 }}
                  static
                  className="pl-0 scroll-dark-md min-w-48 absolute mt-1 w-full bg-white dark:bg-gray-700 shadow-lg max-h-60 rounded-md py-1 ring-1 ring-black ring-opacity-5 overflow-auto focus:outline-none text-xs sm:text-sm"
                >
                  {/* Search Input */}
                  {isSearchSelector && (
                    <div className="px-3 py-2">
                      <input
                        type="text"
                        placeholder="Search..."
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                        className="w-full p-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm"
                      />
                    </div>
                  )}

                  {loading ? (
                    <SmallLoader />
                  ) : (
                    filteredList.map((item, idx) => (
                      <Listbox.Option
                        disabled={item.hasOwnProperty('disabled')}
                        key={idx}
                        className={({ active }) =>
                          classNames(
                            active ? 'bg-themeBlue2' : 'text-gray-900',
                            item.name === selectedItem ? ' bg-themeBlue2' : 'text-black',
                            'cursor-default relative py-2 pl-3 pr-9'
                          )
                        }
                        value={item}
                      >
                        {({ selected, active }) => (
                          <>
                            <span
                              className={classNames(
                                selected ? 'font-semibold ' : 'font-normal',
                                'block truncate dark:text-white text-left'
                              )}
                              key={idx}
                            >
                              {withAvatar ? (
                                <Avatar
                                  name={item?.fullName}
                                  image={item?.profileImageThumbnailUrl}
                                  nameClass={isMobile ? 'text-xs' : ''}
                                  withName
                                  title={item?.jobTitle}
                                  medium
                                />
                              ) : (
                                <>{item?.name}</>
                              )}
                            </span>

                            {selected ? (
                              <CheckIcon
                                className={classNames(
                                  active ? 'text-white' : 'text-indigo-600',
                                  'absolute inset-y-0 right-0 flex items-center pr-4 h-5 w-5'
                                )}
                                aria-hidden="true"
                              />
                            ) : null}
                          </>
                        )}
                      </Listbox.Option>
                    ))
                  )}
                  {!loading && list.length === 0 && (
                    <span className={classNames('block truncate text-themeGray dark:text-white p-2 text-left')}>
                      {noOptionText}
                    </span>
                  )}
                </Listbox.Options>
              </Transition>
            </div>
          </div>
        )}
      </Listbox>
    </div>
  )
}
