import ReactDOM from 'react-dom/client'
import { Suspense } from 'react'
import { ErrorBoundary } from 'react-error-boundary'
import { Provider } from 'react-redux'
import App from 'App'
import UserContextProvider from 'context/UserContext'
import ErrorPage from 'pages/Common/Error'
import configureStore from 'state'
import 'i18n'
import { Oval as Loader } from 'react-loader-spinner'

// @ts-ignore
function ErrorFallback() {
  return <ErrorPage />
}

const { store } = configureStore()

const Spinner = () => (
  <div className="py-4 flex justify-center items-center h-screen w-screen gap-6">
    <Loader color={'rgb(59, 130, 246)'} secondaryColor="#7d7d7d" height={100} width={100} />
  </div>
)

const MainApp = () => (
  <Suspense fallback={<Spinner />}>
    <Provider store={store}>
      <UserContextProvider>
        <ErrorBoundary FallbackComponent={ErrorFallback}>
          <App />
        </ErrorBoundary>
      </UserContextProvider>
    </Provider>
  </Suspense>
)

// Use ReactDOM.render for React versions prior to 18
// For React 18 and later, use ReactDOM.createRoot
const rootContainer = document.getElementById('root')
if (rootContainer) {
  const reactRoot = ReactDOM.createRoot(rootContainer)
  reactRoot.render(<MainApp />)
}
