/* eslint-disable @typescript-eslint/no-unused-vars */
import Modal from 'components/atoms/Modal'
import { useTranslation } from 'react-i18next'
import { useEffect, useState } from 'react'
import Acknowledgement from 'components/templates/People/FeedbackModal/Acknowledgement'
import RequestFeedbackSegment from 'components/templates/Review/RequestFeedbackSegment'

const RequestFeedbackModal = ({
  open,
  setOpen,
  onCloseModal,
  manager,
  employee,
  setRefetch,
  requestType,
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  onCloseModal?: any
  manager?: string
  employee?: any
  setRefetch?: any
  requestType: string
}) => {
  const { t } = useTranslation()

  const [showAcknowledgement, setShowAcknowledgement] = useState(true)
  const [visibility, setVisibility] = useState('PUBLIC')
  const [employees, setEmployees] = useState([])
  const [toEmployees, setToEmployees] = useState([])
  const [fromEmployees, setFromEmployees] = useState([])

  const [curTab, setCurTab] = useState('')

  useEffect(() => {
    if (!open) {
      setShowAcknowledgement(false)
      setEmployees([])
      setToEmployees([])
      setFromEmployees([])
    } else {
      setToEmployees([{ ...employee?.myProfile, id: employee?.myProfile?.userId }])
    }
  }, [open])

  useEffect(() => {
    setShowAcknowledgement(false)
    setEmployees([])
    setToEmployees([])
    setFromEmployees([])
  }, [curTab])

  const defaultDescription =
    requestType === 'manager'
      ? `Feedback requested for <strong>${employee?.myProfile?.fullName}</strong> performance review,
    <br><br>I'd like for you to give me feedback about <strong>${employee?.myProfile?.fullName}</strong> for their upcoming performance review. Please make your feedback as specific as possible, supported by examples of things you thought <strong>${employee?.myProfile?.fullName}</strong> did well and things <strong>${employee?.myProfile?.fullName}</strong> should work to improve on.
    <br><br>You can choose to either give this feedback privately only to me as the manager, or to both me and <strong>${employee?.myProfile?.fullName}</strong>. Thank you in advance for your help with this important review process.
    <br><br>
    <strong>${manager}</strong>`
      : `I would appreciate your feedback on my upcoming Performance Assessment.<br><br>Please ensure your feedback is as specific as possible, supported by examples of areas where I have demonstrated proficiency, as well as aspects in which there is potential for improvement.<br><br>Thank you in advance for your help.<br><br><strong>${employee?.myProfile?.fullName}<strong>`

  return (
    <Modal
      max={{ w: 150, h: 'full' }}
      hwClassName={'max-w-152 max-h-200'}
      header={'Request 360 Feedback'}
      open={open}
      fixedOverflow
      setOpen={setOpen}
      onClose={onCloseModal}
    >
      {!showAcknowledgement && (
        <RequestFeedbackSegment
          setRefetch={setRefetch}
          defaultDescription={defaultDescription}
          visibility={visibility}
          setVisibility={setVisibility}
          setShowAcknowledgement={setShowAcknowledgement}
          fromEmployees={fromEmployees}
          toEmployees={toEmployees}
          setFromEmployees={setFromEmployees}
          setToEmployees={setToEmployees}
        />
      )}
      {showAcknowledgement && (
        <Acknowledgement
          currTab={curTab}
          fromEmployees={fromEmployees}
          toEmployees={toEmployees}
          employees={employees}
          setEmployees={setEmployees}
          setFromEmployees={setFromEmployees}
          setToEmployees={setToEmployees}
          visibility={visibility}
          setShowAcknowledgement={setShowAcknowledgement}
        />
      )}
    </Modal>
  )
}

export default RequestFeedbackModal
