/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpRequest } from 'helpers/services'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'
import { MESSAGES } from 'constants/common'
import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { setCookie } from 'nookies'
import get from 'lodash/get'
import { setUser } from 'state/redux/actions/user.actions'
import { setCompany } from 'state/redux/actions/company.actions'

export const useAuthTools = () => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()

  const addDomain = (data, onDomainError) =>
    HttpRequest('POST', '/company/info', data)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          navigate('/login', { state: { domainID: res?.data?.data?.id } })
        } else {
          dispatch(setErrorMessages([MESSAGES.ERROR_MESSAGE.COMPANY_DOMAIN_ERROR]))
        }
      })
      .catch((err) => {
        onDomainError(err)
      })

  const signUserIn = (data, headersData, onError) => {
    HttpRequest('POST', '/company/user/auth/login', data, headersData)
      .then((res: any) => {
        if (res.data.code === 200) {
          const config = {
            path: '/',
            maxAge: 43200,
            sameSite: true,
          }

          setCookie(null, 'refreshToken', get(res, 'data.refreshToken', res.data.data.refreshToken), config)
          setCookie(null, 'accessToken', get(res, 'data.accessToken', res.data.data.accessToken), config)
          dispatch(setUser(res.data.data.user))

          navigate('/home')
        } else {
          onError()
          dispatch(setErrorMessages(['Error while login, please try again later']))
        }
      })
      .catch((err) => {
        onError()
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const forgotPassword = (data, headersData, onStopLoading?) => {
    HttpRequest('POST', '/company/user/forgot-password', data, headersData)
      .then((res: any) => {
        if (res) {
          dispatch(setSuccessMessage(MESSAGES.SUCCESS_MESSAGES.CHECK_EMAIL))
          onStopLoading()
        } else {
          dispatch(setErrorMessages(['Error while processing request']))
          onStopLoading()
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
        onStopLoading()
      })
  }

  const registerUser = (data, headersData, onAlreadyRegistered, onError) => {
    HttpRequest('POST', '/user/register', data, headersData)
      .then((res: any) => {
        const config = {
          path: '/',
          maxAge: 43200,
          sameSite: true,
        }
        setCookie(null, 'refreshToken', get(res, 'data.refreshToken', res.data.data.refreshToken), config)
        setCookie(null, 'accessToken', get(res, 'data.accessToken', res.data.data.accessToken), config)
        dispatch(setUser(res.data.data.user))
        navigate('/home')
        // dispatch(setSuccessMessage('Your details saved successfully, Login with same password'))
      })
      .catch((err) => {
        if (err.response.data.messageCode === 'EMAIL_ALREADY_USED') {
          onAlreadyRegistered()
        } else {
          onError()
          dispatch(setErrorMessages([MESSAGES.ERROR_MESSAGE.CREATE_ACCOUNT_ERROR]))
        }
      })
  }
  const registerIndividualUser = (data, headersData, onAlreadyRegistered, onError) => {
    HttpRequest('POST', '/user/register', data, headersData)
      .then((res: any) => {
        const config = {
          path: '/',
          maxAge: 43200,
          sameSite: true,
        }
        setCookie(null, 'refreshToken', get(res, 'data.refreshToken', res.data.data.refreshToken), config)
        setCookie(null, 'accessToken', get(res, 'data.accessToken', res.data.data.accessToken), config)
        dispatch(setUser(res.data.data.user))
        navigate('/home')
        // dispatch(setSuccessMessage('Your details saved successfully, Login with same password'))
      })
      .catch((err) => {
        if (err.response.data.messageCode === 'EMAIL_ALREADY_USED') {
          onAlreadyRegistered()
        } else {
          onError()
          dispatch(setErrorMessages([MESSAGES.ERROR_MESSAGE.CREATE_ACCOUNT_ERROR]))
        }
      })
  }
  const setPassword = (data, headersData) => {
    HttpRequest('POST', '/company/user/set-password', data, headersData)
      .then((res: any) => {
        dispatch(setSuccessMessage('Password changed'))
        navigate('/login')
      })
      .catch(() => {
        dispatch(setErrorMessages([MESSAGES.ERROR_MESSAGE.RESET_PASSWORD_ERROR]))
      })
  }

  const tokenValidation = (data, onStateSave, onError) => {
    HttpRequest('POST', '/company/user/auth/check-token', data)
      .then((res: any) => {
          onStateSave(res.data?.data)
      })
      .catch((err) => {
        onError(err?.response?.data?.message)
      })
  }

  const emailVerification = (data, onStateSave, onError) => {
    HttpRequest('POST', '/user/email/verify', data)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          onStateSave(res.data?.data)
        } else {
          onError('Something went wrong')
        }
      })
      .catch((err) => {
        onError(err?.response?.data?.message)
      })
  }
  const changePassword = (data, headersData) => {
    HttpRequest('POST', '/user/change-password', data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Password changed'))
        } else {
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages(['Error while changing password']))
      })
  }
  const passwordTokenValidation = (data, onSuccess, onError) => {
    HttpRequest('POST', '/company/user/verify-token', data)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          onSuccess(res.data?.data)
        } else {
          dispatch(setErrorMessages([MESSAGES.ERROR_MESSAGE.INVALID_TOKEN]))
        }
      })
      .catch((err) => {
        onError(err?.response?.data?.message)
        if (err?.response?.data?.message !== 'Invalid user and token') {
          dispatch(setErrorMessages([err?.response?.data?.message]))
        }
      })
  }
  const createIndividualCompany = (data) => {
    HttpRequest('POST', '/user/company', data)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          window.location.reload()
        } else {
          dispatch(setErrorMessages(['Error while adding new company']))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
  }

  const createIndividualCompanyPromise = (data) =>
    new Promise((resolve, reject) => {
      HttpRequest('POST', '/user/company', data)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve('success')
            const companyDetails = res?.data?.data
            dispatch(setCompany(companyDetails))
          } else {
            dispatch(setErrorMessages(['Error while adding new company']))
            reject('Error while adding new company')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const fetchGoogleCodePromise = () =>
    new Promise((resolve, reject) => {
      HttpRequest('GET', '/user/login-google-url')
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve(res?.data)
          } else {
            dispatch(setErrorMessages(['Error while adding new company']))
            reject('Error while adding new company')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  const fetchMicrosoftCodePromise = () =>
    new Promise((resolve, reject) => {
      HttpRequest('GET', '/user/login-outlook-url')
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve(res?.data)
          } else {
            dispatch(setErrorMessages(['Error while adding new company']))
            reject('Error while adding new company')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  return {
    addDomain,
    signUserIn,
    forgotPassword,
    registerUser,
    setPassword,
    tokenValidation,
    emailVerification,
    changePassword,
    passwordTokenValidation,
    registerIndividualUser,
    createIndividualCompany,
    createIndividualCompanyPromise,
    fetchGoogleCodePromise,
    fetchMicrosoftCodePromise,
  }
}
