import 'components/atoms/Tooltip/_tooltip2.scss'
import Avatar from 'components/atoms/Avatar'
import { useNavigate, useParams } from 'react-router'

const Tooltip = ({ children, user }: any) => {
  const navigate = useNavigate()
  const { companyId }: any = useParams()

  return (
    <div className="relative hover-trigger">
      <div
        style={{ boxShadow: '0 2px 6px 0px rgba(212, 212, 212, 0.44)' }}
        className="absolute bottom-8 mb-1 -left-28 bg-white min-w-64 hover-target"
      >
        <Avatar
          title={user.jobTitle}
          nameClass="font-bold"
          className="p-3"
          name={user.fullName}
          withName
          image={user.profileImageThumbnailUrl}
        />
        <hr className="mt-2 w-full" />
        <button
          onClick={() => {
            navigate(`/people/${companyId}/user/${user.id}`)
          }}
          className="text-sm w-full my-2 justify-center hover:text-themeBlue"
        >
          View Profile
        </button>
      </div>
      {children}
    </div>
  )
}

export default Tooltip
