import * as types from 'state/redux/constants'
import { destroyCookie } from 'nookies'

export const setUser = (user: any) => async (dispatch: any) => {
  try {
    dispatch({ type: types.SET_USER_DATA, data: user })
  } catch (error) {
    console.error(error)
  }
}

export const setPersonalInfo = (personalInfo: any) => async (dispatch: any) => {
  try {
    dispatch({ type: types.SET_PERSONAL_INFO, data: personalInfo })
  } catch (error) {
    console.error(error)
  }
}
export const logout = () => async (dispatch: any) => {
  try {
    dispatch({ type: types.SET_USER_DATA, data: {} })
    destroyCookie(null, 'accessToken')
    destroyCookie(null, 'refreshToken')
  } catch (error) {
    console.error(error)
  }
}
