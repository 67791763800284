import React, { useEffect, useState } from 'react'

import Button from 'components/atoms/Button/button'
import Modal from 'components/atoms/Modal'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import { HttpRequest } from 'helpers/services'
import { useDispatch } from 'react-redux'
import { setErrorMessages } from 'state/redux/actions/settings.actions'

const UpdateEmailDialog = ({
  open,
  fetchUserInfo,
  setOpen,
  status,
  existingEmail,
  companyId,
}: {
  open: boolean
  fetchUserInfo: any
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  existingEmail: string
  status: string
  companyId: number
}) => {
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState('')
  const dispatch: any = useDispatch()

  const validateEmail = () => {
    if (email === '') {
      setEmailError('Email is required')
      return false
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email)) {
      setEmailError('Invalid email address')
      return false
    } else {
      return true
    }
  }

  const onSubmit = async () => {
    if (validateEmail()) {
      try {
        const data = {
          email: existingEmail,
          newEmail: email,
        }
        await new Promise((resolve, reject) => {
          const commonHeader = {
            'x-appengine-company-id': companyId,
          }
          HttpRequest('POST', '/company/employees/email', data, commonHeader)
            .then((res: any) => {
              if (res?.data?.code === 200) {
                resolve('success')
              } else {
                dispatch(setErrorMessages(['Error while changing email address']))
                reject('Error while changing email address')
              }
              setOpen(false)
              setEmail('')
              fetchUserInfo()
            })
            .catch((err) => {
              dispatch(setErrorMessages([err?.response?.data?.message]))
              reject(err?.response?.data.message)
              setOpen(false)
            })
        })
      } catch (err) {
        console.error('Error while updating email!')
      }
    }
  }

  const onKeyPress = (e) => {
    if (e.key === 'Enter') {
      onSubmit()
      fetchUserInfo()
    }
  }

  useEffect(() => {
    setEmail(existingEmail)
  }, [existingEmail])

  return (
    <Modal
      max={{ w: 200, h: 132 }}
      hwClassName={'max-w-200 max-h-132'}
      header={'Change email'}
      open={open}
      zIndex={5000}
      setOpen={setOpen}
    >
      <NormalFormInput
        label="Enter email address below"
        id="emailAddress"
        name="emailAddress"
        type="email"
        placeholder="Please enter your email address........."
        value={email}
        onKeyPress={onKeyPress}
        onChange={(e) => {
          setEmail(e.target.value)
          setEmailError('')
        }}
        fullWidth
        error={emailError}
      />
      <div className="mt-8 w-full flex items-center justify-end">
        <Button
          loadingText="Saving"
          label={status === 'Pending' || status === 'Loaded' ? 'Save Email' : 'Request Confirmation Link'}
          onClick={onSubmit}
          disabled={!email}
          primary
        />
      </div>
    </Modal>
  )
}

export default UpdateEmailDialog
