import classnames from 'classnames'
import { IButtonProps } from 'interfaces/atoms/button.types'

const Button = ({
  label,
  onClick,
  textColor = 'text-gray-700',
  size = 'md',
  primary = false,
  btnColor = 'bg-blue-500',
  secondary = false,
  iconBefore = false,
  iconClass = 'text-white h-6 w-6',
  Icon,
  onlyIcon,
  fullWidth = false,
  loading = false,
  disabled = false,
  loadingText = 'Processing',
  hoverColor = 'bg-blue-500',
  hoverTextColor = 'text-white',
  className,
  borderColor = 'border-gray-400',
  id = '',
  hoverBorderColor = 'transparent',
}: IButtonProps) => {
  const genSize = () => {
    switch (size) {
      case 'sm':
        return 'px-2.5 py-1.5 text-xs'
      case 'md':
        return 'px-4 py-2 text-sm'
      case 'lg':
        return 'px-4 py-3 text-sm'
      case 'xl':
        return 'px-4 py-2 text-base'
      default:
        return 'px-3 py-2 text-sm'
    }
  }

  const renderByType = () => {
    switch (true) {
      case primary:
        return `${btnColor} hover:bg-blue-600 text-white`
      case secondary:
        return `${
          loading && `${hoverColor} text-white`
        } bg-transparent group hover:${hoverColor} ${textColor} hover:${hoverTextColor} hover:border-${hoverBorderColor} ${borderColor}  border `
      default:
        return `${btnColor} hover:${hoverColor}  hover:${hoverTextColor} ${textColor ? textColor : 'text-white'} `
    }
  }

  return (
    <button
      id={id}
      onClick={disabled || loading ? () => {} : onClick}
      aria-label={label}
      className={classnames(
        genSize(),
        disabled || loading ? 'opacity-50' : '',
        disabled && 'cursor-not-allowed ',
        renderByType(),
        fullWidth && 'w-full',
        `${className} transition-all flex items-center gap-x-1 focus:outline-none justify-center rounded-lg`
      )}
    >
      {loading && (
        <svg
          className="animate-spin -ml-1 mr-3 h-5 w-5 text-white"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      )}
      {!loading && iconBefore && Icon && (
        <Icon className={`${iconClass} hover:${hoverTextColor} hover:border-${hoverBorderColor}`} />
      )}
      {label &&
        (loading ? (
          loadingText
        ) : onlyIcon ? (
          <Icon className={`${iconClass} hover:${hoverTextColor} hover:border-${hoverBorderColor}`} />
        ) : (
          label
        ))}
      {/* @ts-ignore */}
      {!loading && !iconBefore && Icon && (
        <Icon className={` ${iconClass} hover:${hoverTextColor} hover:border-${hoverBorderColor}`} />
      )}
    </button>
  )
}

export default Button
