import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { isInteger, round } from 'lodash'
import { useEffect, useState } from 'react'
import { AiFillStar } from 'react-icons/ai'
import { BsStarHalf } from 'react-icons/bs'

const colors = {
  orange: '#FFBA5A',
  grey: '#BEBEBE',
}

const Rating = ({
  fillColor,
  onClick,
  defaultValue,
  size = 22,
  fillSize = 24,
  marginRight = 10,
  tooltip = false,
  type = undefined,
  isSelf = undefined,
  isManager = undefined,
  employeeSubmitted = undefined,
  managerSubmitted = undefined,
  assessmentType = undefined,
  ratingSys = undefined,
  valueRatingSys = undefined,
}) => {
  const [currentValue, setCurrentValue] = useState(0)
  const [hoverValue, setHoverValue] = useState(undefined)

  const stars = Array(5).fill(0)

  const handleClick = (value) => {
    if (
      (assessmentType === 'self' && isSelf && !employeeSubmitted) ||
      (assessmentType === 'manager' && isManager && !managerSubmitted)
    ) {
      setCurrentValue(value)
      onClick(value)
    }
  }

  const handleMouseOver = (newHoverValue) => {
    setHoverValue(newHoverValue)
  }

  const handleMouseLeave = () => {
    setHoverValue(undefined)
  }

  useEffect(() => {
    setCurrentValue(defaultValue)
  }, [defaultValue])

  const tooltipText = (hoverValue) => {
    if (ratingSys) {
      switch (hoverValue) {
        case 1:
          return ratingSys[4]?.title
        case 2:
          return ratingSys[3]?.title
        case 3:
          return ratingSys[2]?.title
        case 4:
          return ratingSys[1]?.title
        case 5:
          return ratingSys[0]?.title
      }
    }
  }

  const valuesTooltipText = (hoverValue) => {
    if (valueRatingSys) {
      switch (hoverValue) {
        case 1:
          return valueRatingSys[4]?.title
        case 2:
          return valueRatingSys[3]?.title
        case 3:
          return valueRatingSys[2]?.title
        case 4:
          return valueRatingSys[1]?.title
        case 5:
          return valueRatingSys[0]?.title
      }
    }
  }

  return (
    <div style={styles.stars as React.CSSProperties}>
      {stars.map((_, index) => {
        if (!isInteger(currentValue) && index === round(currentValue - 1)) {
          return (
            <Tooltip text={type === 'values' ? valuesTooltipText(hoverValue) : tooltipText(hoverValue)} position="top">
              <BsStarHalf
                key={index}
                size={size}
                onClick={() => handleClick(index + 1)}
                onMouseOver={() => handleMouseOver(index + 1)}
                onMouseLeave={handleMouseLeave}
                color={(hoverValue || currentValue) > index ? fillColor : colors.grey}
                style={{
                  marginRight,
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )
        } else {
          return (
            <Tooltip text={type === 'values' ? valuesTooltipText(hoverValue) : tooltipText(hoverValue)} position="top">
              <AiFillStar
                key={index}
                size={fillSize}
                onClick={() => handleClick(index + 1)}
                onMouseOver={() => handleMouseOver(index + 1)}
                onMouseLeave={handleMouseLeave}
                color={(hoverValue || currentValue) > index ? fillColor : colors.grey}
                style={{
                  marginRight,
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          )
        }
      })}
    </div>
  )
}

const styles = {
  stars: {
    display: 'flex',
    flexDirection: 'row',
  },
}

export default Rating
