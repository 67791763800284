/* eslint-disable @typescript-eslint/no-unused-vars */
import { useContext, useState, useEffect, createContext } from 'react'
import { HttpRequest } from 'helpers/services'

const difTeam = {
  fetchMeetings: { oneOnOne: [], department: [], teams: [] },
  fetchMeetingContext: (companyId: number) => {},
  refetchData: () => {},
}
const MeetingContext = createContext(difTeam)

export function MeetingContextProvider({ children }) {
  const [fetchMeetings, setFetchMeetings] = useState([])
  const [isFetching, setIsFetching] = useState(false)
  const [refetch, setRefetch] = useState(false)

  const isGoalPage = window.location.pathname.includes('goals')
  const companyId = parseInt(window.location.pathname.split('/')[isGoalPage ? 3 : 2])

  const fetchMeetingContext = (companyId: number) => {
    setIsFetching(true)
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }
    HttpRequest('GET', '/meeting-room/listMeetingRooms', {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const meetings = data?.data?.rooms
          setFetchMeetings(meetings)
        }
        setIsFetching(false)
        setRefetch(false)
      })
      .catch((err) => {
        setIsFetching(false)
        setRefetch(false)
        console.error(err)
      })
  }

  const refetchData = () => {
    setRefetch(true)
  }

  useEffect(() => {
    if (refetch) {
      fetchMeetingContext(companyId)
    }
  }, [refetch])

  return (
    <MeetingContext.Provider
      value={{
        // @ts-ignore
        fetchMeetings,
        fetchMeetingContext,
        refetchData,
      }}
    >
      {children}
    </MeetingContext.Provider>
  )
}

export function useMeetings() {
  const context = useContext(MeetingContext)
  if (context === undefined) {
    throw new Error('Context must be used within a Provider')
  }
  return context
}
