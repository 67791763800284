import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/outline'
import { noop } from 'lodash'
import classNames from 'classnames'
import useIsMobile from 'hooks/useIsMobile'

const Modal = ({
  open = false,
  setOpen,
  children,
  header,
  headerColor,
  onClose,
  disableBackdropClose = true,
  hideCloseBtn = false,
  max,
  hwClassName,
  fixedOverflow,
  bodyPaddingZero = false,
  zIndex = 5500,
  width,
  rounded = 'md',
}: {
  open: boolean
  setOpen: React.Dispatch<React.SetStateAction<boolean>>
  children: React.ReactNode
  header?: string | React.ReactNode
  headerColor?: string | React.ReactNode
  disableBackdropClose?: boolean
  hideCloseBtn?: boolean
  onClose?: () => void
  max?: { w?: number; h?: number | 'full' }
  hwClassName?
  fixedOverflow?: boolean
  bodyPaddingZero?: boolean
  zIndex?: number
  width?: string
  rounded?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | '3xl' | 'none'
}) => {
  const genWidth = () => {
    switch (max?.w) {
      case max?.w:
        return `max-w-${max?.w}`
      default:
        return `max-w-256`
    }
  }

  const genHeight = () => {
    switch (max?.h) {
      case max?.h:
        return `max-h-${max?.h}`
      default:
        return `max-h-132`
    }
  }
  const isMobile = useIsMobile()
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        style={{ zIndex: zIndex }}
        security=""
        as="div"
        static
        className={`fixed inset-0 ${fixedOverflow ? 'overflow-y-scroll' : 'overflow-y-auto'}`}
        open={open}
        onClose={!disableBackdropClose ? (onClose ? onClose : setOpen) : noop}
      >
        <div
          className={`pb-20 font-roboto flex items-end justify-center min-h-screen pt-4 px-4 text-center sm:block sm:p-0`}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay
              style={{ backdropFilter: 'blur(2px)' }}
              className="fixed inset-0 bg-black bg-opacity-40 transition-opacity"
            />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out transformduration-300"
            enterFrom="opacity-0 translate-y-4 sm:scale-50"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in transformduration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:scale-50"
          >
            <div
              className={classNames(
                genWidth(),
                genHeight(),
                `inline-block align-bottom  bg-white dark:bg-gray-800 ${
                  `rounded-` + rounded
                } px-4 pt-5  text-left overflow shadow-xl transform transition-all w-full h-full sm:my-8 sm:align-middle   ${
                  bodyPaddingZero ? 'sm:p-0' : 'sm:p-6'
                } pb-4 ${hwClassName}`
              )}
              style={{ width: width }}
            >
              {!hideCloseBtn && (
                <div className="absolute top-0 right-0 pt-6 pr-6">
                  <button
                    type="button"
                    className="bg-white dark:bg-gray-800 rounded-md text-gray-400 hover:text-blue-500 focus:outline-none "
                    onClick={() => (onClose ? onClose() : setOpen(false))}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              <div className={``}>
                {header && (
                  <div className={`modal-header border-b border-gray-200 pb-2 ${bodyPaddingZero && 'p-6'}`}>
                    <h3 className={`dark:text-white ${isMobile ? 'text-sm' : 'text-xl'} font-semibold ${headerColor}`}>
                      {header}
                    </h3>
                  </div>
                )}
                <div className={`${!bodyPaddingZero && header && 'pt-4'}`}>{children}</div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  )
}
export default Modal
