/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpRequest } from 'helpers/services'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { useParams } from 'react-router-dom'
import useGetParams from 'hooks/useGetParams'
import { useSettings } from 'hooks/useSettings'

export const useAdminTools = () => {
  const navigate = useNavigate()
  const dispatch: any = useDispatch()
  const loggedInUser = useSelector((state) => get(state, 'user.user'))
  const [params]: any = useGetParams()
  const { companyId, employeeId }: any = useParams()
  const companyHeader = { 'x-appengine-company-id': companyId }
  const { loading, setLoading } = useSettings()
  const getCompanyId = () => {
    if (companyId) {
      return companyId
    } else {
      navigate('/')
    }
  }
  const saveEmployeeDetails = (employeeID, data, headersData, onSuceess) => {
    setLoading(true)
    HttpRequest('PUT', `/company/employees/${employeeID}`, data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Details updated!'))
          onSuceess()
        } else {
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
      .finally(() => setLoading(false))
  }

  const getEmployeeChart = (headersData, onSuceess) => {
    HttpRequest('GET', `/company/employees/organization`, {}, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          onSuceess()
        } else {
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }

  const deactivateEmployee = (employeeID, headersData, onSuccess) => {
    const data = { status: 'DeActive' }
    setLoading(true)

    HttpRequest('PATCH', `/company/employees/${employeeID}`, data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Account deactivated!'))
          onSuccess(res?.data?.data)
        } else {
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(setErrorMessages([err.response.data.message]))
      })
      .finally(() => setLoading(false))
  }

  const deleteEmployee = (employeeID, headersData) => {
    setLoading(true)

    HttpRequest('PATCH', `/company/employees/remove/${employeeID}`, {}, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Account deleted!'))
        } else {
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(setErrorMessages([err.response.data.message]))
      })
      .finally(() => setLoading(false))
  }

  const activateEmployee = (employeeID, headersData, onSuccess) => {
    const data = { status: 'Active' }
    setLoading(true)
    HttpRequest('PATCH', `/company/employees/${employeeID}`, data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Account reactivated!'))
          onSuccess(res?.data?.data)
        } else {
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(setErrorMessages([err.response.data.message]))
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyInfo = (headersData, onSuceess) => {
    setLoading(true)
    HttpRequest('GET', '/company/profile', {}, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuceess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
      .finally(() => setLoading(false))
  }

  const saveCompanyInfo = (data, headersData, onSuceess) => {
    setLoading(true)
    HttpRequest('PUT', '/company/profile', data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Comapany Settings updated!'))
          onSuceess()
        } else {
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
      .finally(() => setLoading(false))
  }

  const fetchCompanyValues = (receivedHeader, onSuceess) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('GET', '/company/values', {}, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuceess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }
  const createCompanyValues = (data, receivedHeader, onSuceess) => {
    const newData = {
      values: [
        {
          value: '12345',
          description: '12345',
        },
      ],
    }
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('POST', '/company/values', data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          dispatch(setSuccessMessage('Company value set successfully!'))
          onSuceess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }

  const deleteCompanyValue = (id, receivedHeader, onSuceess) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('DELETE', `/company/values/${id}`, {}, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          onSuceess()
          dispatch(setSuccessMessage('Company value removed!'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }
  const changeAdminStatus = (id, isAdmin, isAnalyticsAdmin, header, onSuceess) => {
    const headersData = { ...header }
    const data = { employeeId: id, isAdmin, isAnalyticsAdmin }
    HttpRequest('POST', '/company/employees/make-admin', data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          onSuceess(res?.data)
          dispatch(setSuccessMessage('Permissions changed!!'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages(['Error while changing permissions!']))
      })
  }

  const addDepartmentHead = (data, receivedHeader, onSuceess) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('POST', '/company/department/heads', data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          dispatch(setSuccessMessage('Department head added successfully!'))
          onSuceess()
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }

  const setDepartmentHeads = (toBeAdded, toBeRemoved, departmentId, receivedHeader, onSuccess) => {
    const headersData = { ...receivedHeader, ...companyHeader }

    const toBeAddedPromises = toBeAdded.map((member) => {
      return HttpRequest(
        'POST',
        '/company/department/heads',
        { departmentId: departmentId, memberId: member.id },
        headersData
      )
    })

    const toBeRemovedPromises = toBeRemoved.map((member) => {
      return HttpRequest(
        'POST',
        '/company/department/heads/remove',
        { departmentId: departmentId, memberId: member.id },
        headersData
      )
    })

    const allPromises = [...toBeAddedPromises, ...toBeRemovedPromises]
    if (allPromises.length > 0) {
      Promise.all(allPromises)
        .then((valArray) => {
          onSuccess()
          dispatch(setSuccessMessage('Department head set!'))
        })
        .catch((err) => {
          dispatch(setErrorMessages([err.response.data.message]))
        })
    }
  }

  const setTeamHeads = (toBeAdded, toBeRemoved, teamId, data, onSuccess) => {
    const headersData = { ...companyHeader }

    const toBeAddedPromises = toBeAdded.map((member) => {
      return HttpRequest('PATCH', `/team/${teamId}`, { ...data, teamLeadId: member.id }, headersData)
    })

    const toBeRemovedPromises = toBeRemoved.map((member) => {
      return HttpRequest('PATCH', `/team/${teamId}`, { ...data }, headersData)
    })

    const allPromises = [...toBeAddedPromises, ...toBeRemovedPromises]
    if (allPromises.length > 0) {
      Promise.all(allPromises)
        .then((valArray) => {
          onSuccess()
          dispatch(setSuccessMessage('Team heads set!'))
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
        })
    }
  }

  const fetchDepartmentMembers = (_, receivedHeader, onSuceess, memberId) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('GET', `/company/department/${memberId}/members`, {}, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuceess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }

  const addDepartmentMember = (data, receivedHeader, onSuceess) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('POST', '/company/department/add-member', data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          dispatch(setSuccessMessage('Department member added successfully!'))
          onSuceess()
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }
  const removeDepartmentMember = (data, receivedHeader, onSuceess) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('DELETE', `/company/department/member/${data.memberId}`, {}, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          dispatch(setSuccessMessage('Department member removed!'))
          onSuceess()
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }

  const fetchCustomAttributes = (onSuceess) => {
    const headersData = { ...companyHeader }
    HttpRequest('GET', '/company/custom-attributes?sort=ASC&sort_by=Assign&is_deleted=false', {}, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuceess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }

  const fetchTeamMembers = (data, receivedHeader, onSuceess, teamId) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('GET', `/team/get-member/${teamId}`, data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedData = res?.data?.data
          onSuceess(fetchedData)
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response?.data.message]))
      })
  }

  const addTeamMember = (data, receivedHeader, onSuceess) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('POST', '/team/add-member', data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Team member added successfully!'))
          onSuceess()
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }
  const removeTeamMember = (data, receivedHeader, onSuceess) => {
    const headersData = { ...receivedHeader, ...companyHeader }
    HttpRequest('PATCH', `/team/remove-member`, data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Team member removed!'))
          onSuceess()
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }

  const resendInvite = (inviteEmail) => {
    HttpRequest('POST', '/company/user/re-invite', { inviteEmail }, companyHeader)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Invite sent successfully!'))
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages([err.response.data.message]))
      })
  }

  return {
    getEmployeeChart,
    getCompanyId,
    saveEmployeeDetails,
    deactivateEmployee,
    deleteEmployee,
    activateEmployee,
    fetchCompanyInfo,
    saveCompanyInfo,
    fetchCompanyValues,
    createCompanyValues,
    deleteCompanyValue,
    changeAdminStatus,
    addDepartmentHead,
    fetchCustomAttributes,
    setDepartmentHeads,
    setTeamHeads,
    fetchDepartmentMembers,
    addDepartmentMember,
    removeDepartmentMember,
    fetchTeamMembers,
    addTeamMember,
    removeTeamMember,
    resendInvite,
  }
}
