import Avatar from 'components/atoms/Avatar'
import Button from 'components/atoms/Button/button'
import useIsMobile from 'hooks/useIsMobile'
import parse from 'html-react-parser'
import moment from 'moment'
import { IoIosArrowDown, IoMdArrowDropright } from 'react-icons/io'

type FeedbackCardProps = {
  idx?: number
  currentCard?: number
  setCurrentCard?: React.Dispatch<React.SetStateAction<number>>
  feedback?: any
  loading?: boolean
}

export const RequestFeedbackCard = ({ setCurrentCard, currentCard, idx, feedback, loading }: FeedbackCardProps) => {
  const isMobile = useIsMobile()
  const handleCardClick = () => {
    if (currentCard === idx) {
      setCurrentCard(-2)
    } else {
      setCurrentCard(idx)
    }
  }

  const FeedbackDateFormat = (date) => {
    const now = moment(new Date())
    const newDate = moment(date)
    let returnData = null

    if (now.diff(newDate, 'days') > 7) {
      returnData = newDate.format('DD MMM YYYY')
    } else {
      returnData = newDate.fromNow()
    }

    return returnData
  }

  return (
    <div style={{ boxShadow: '0 4px 10px 0 rgba(171, 171, 171, 0.2)' }} className="bg-white py-3 px-3 rounded-md">
      {loading ? (
        <div className="flex justify-between">
          <div className="w-40 h-6 animate-pulse bg-gray-200 rounded-lg" />
          <div className="w-40 h-6 animate-pulse bg-gray-200 rounded-lg" />
        </div>
      ) : (
        <>
          <div onClick={handleCardClick} className="flex justify-between rounded cursor-pointer">
            <div className={`flex ${isMobile ? 'text-xs' : 'text-sm gap-4'}`}>
              <div>
                <span className={`${isMobile ? 'text-xs' : ''} font-bold`}>From: </span>
                <span className={isMobile ? 'text-xs' : ''}>{feedback?.from?.fullName}</span>
              </div>
              <div>
                <IoIosArrowDown
                  style={{ transform: currentCard === idx ? 'rotate(180deg)' : '', transition: 'all 0.3s ease-in-out' }}
                  className="text-gray-600"
                  size={20}
                />
              </div>
            </div>
            <Button
              size={isMobile ? 'sm' : 'md'}
              textColor="text-themeGray"
              hoverTextColor="text-blue-500"
              hoverBorderColor="text-blue-500"
              hoverColor="border-blue-500"
              focusRing="ring-black"
              label={'Resend Mail'}
              secondary
              onClick={() => {}}
            />
          </div>
          <div
            style={{
              transition: 'max-height 0.4s linear',
              maxHeight: currentCard === idx ? '500px' : '0',
              overflow: 'hidden',
            }}
            className={`${currentCard === idx ? 'my-4' : ''}`}
          >
            <div className="flex gap-2 items-center">
              <Avatar
                nameClass={isMobile ? 'text-xs' : 'text-sm'}
                medium
                name={feedback?.from?.fullName}
                image={feedback?.from?.profileImageUrl}
                withName
              />
              <span className="text-gray-600 font-bold text-xl">
                <IoMdArrowDropright size={24} />
              </span>
              {feedback?.to?.map((to) => (
                <Avatar
                  nameClass={isMobile ? 'text-xs' : 'text-sm'}
                  medium
                  name={to?.fullName}
                  image={to?.profileImageUrl}
                  withName
                />
              ))}
            </div>
            <div className="mt-3 mx-1">
              <span className={`font-bold ${isMobile ? 'text-xs' : 'text-sm'}`}>Feedback:</span>
              <p className={`mt-2 ${isMobile ? 'text-xs' : 'text-sm'}`}>
                {parse(feedback?.requestDesc ? feedback?.requestDesc : '')}
              </p>
            </div>
            <div className="flex items-center justify-between mt-3 mx-1 pb-2">
              <p className={`${isMobile ? 'text-xs' : 'text-sm'} italic text-gray-700`}>
                {FeedbackDateFormat(feedback?.createdAt)}
              </p>
            </div>
          </div>
        </>
      )}
    </div>
  )
}
