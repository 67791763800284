/* eslint-disable @typescript-eslint/no-unused-vars */
import { HttpRequest } from 'helpers/services'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'
import { useDispatch, useSelector } from 'react-redux'
import get from 'lodash/get'
import { useParams } from 'react-router'
import { useSettings } from 'hooks/useSettings'

export const useUserTools = () => {
  const dispatch: any = useDispatch()
  const loggedInUser = useSelector((state) => get(state, 'user.user'))
  const { companyId }: any = useParams()
  const { loading, setLoading } = useSettings()
  const companyHeader = { 'x-appengine-company-id': companyId }
  const saveUserDetails = (data, headersData, onSuceess) => {
    HttpRequest('PATCH', '/user', data, {})
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Details updated!'))
          onSuceess()
        } else {
        }
      })
      .catch((err) => {
        console.error(JSON.stringify(err))
        dispatch(setErrorMessages(['Error while updating details', err.message]))
      })
  }

  const saveCompanyDescription = (data, headersData, onSuceess) => {
    setLoading(true)
    HttpRequest('PATCH', '/company/employees/short-desc', data, headersData)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          dispatch(setSuccessMessage('Details updated!'))
          onSuceess()
        } else {
        }
      })
      .catch((err) => {
        dispatch(setErrorMessages(['Error while updating details', err?.response?.data?.message]))
      })
      .finally(() => setLoading(false))
  }

  const addEmailAddress = (data, onSuccess) =>
    new Promise((resolve, reject) => {
      HttpRequest('POST', '/user/email', data)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve('success')
          } else {
            dispatch(setErrorMessages(['Error while adding email address']))
            reject('Error while adding email address')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })
  const updateEmailAddress = (data, onSuccess) =>
    new Promise((resolve, reject) => {
      HttpRequest('PATCH', '/user/email', data)
        .then((res: any) => {
          if (res?.data?.code === 200) {
            resolve('success')
          } else {
            dispatch(setErrorMessages(['Error while changing email address']))
            reject('Error while changing email address')
          }
        })
        .catch((err) => {
          dispatch(setErrorMessages([err?.response?.data?.message]))
          reject(err?.response?.data.message)
        })
    })

  return {
    saveUserDetails,
    saveCompanyDescription,
    addEmailAddress,
    updateEmailAddress,
  }
}
