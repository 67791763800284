import { FiSettings } from 'react-icons/fi'
import { BsBuildings, BsPeople, BsPersonGear } from 'react-icons/bs'
import { GiVideoConference } from 'react-icons/gi'
import { TbDeviceAnalytics, TbTargetArrow } from 'react-icons/tb'
import { RiSurveyLine, RiUserStarLine } from 'react-icons/ri'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { useCallback, useEffect, useState } from 'react'
import { HttpRequest } from 'helpers/services'
import { setUser } from 'state/redux/actions/user.actions'
import { setCompany } from 'state/redux/actions/company.actions'
import { get, isEmpty } from 'lodash'
import { useDispatch, useSelector } from 'react-redux'
import classNames from 'classnames'
import { avatarInitials } from 'lib/utils'

const MMenu = ({ show, setState }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const pathname = location.pathname
  const { companyId }: any = useParams()
  const dispatch: any = useDispatch()
  const user: any = useSelector((state) => get(state, 'user.user'))
  const companyInfo: any = useSelector((state) => get(state, 'company.companyInfo'))
  const [companyList, setCompanyList] = useState([])
  const [selectedCompany, setSelectedCompany] = useState({
    companyId: null,
    companyName: null,
    isAdmin: null,
    employeeId: null,
    isAnalyticsAdmin: null,
  })

  useEffect(() => {
    let updatedCompanyList: any[] = []
    if (user?.employee?.length > 0) {
      user?.employee?.forEach((company, _) => {
        if (companyId === company.companyId) {
          setSelectedCompany(company)
        }
        return updatedCompanyList.push(company)
      })
      if (!companyId) {
        setSelectedCompany({
          companyId: null,
          companyName: null,
          isAdmin: null,
          employeeId: null,
          isAnalyticsAdmin: null,
        })
      }
      setCompanyList(updatedCompanyList)
    }
  }, [user, companyId, pathname])

  useEffect(() => {
    if (!isEmpty(companyInfo) && !isEmpty(user)) {
      fetchUserDetail()
      navigate(`/admin/${companyInfo?.id}/company/profile`)
    }
  }, [companyInfo, user])

  const fetchUserDetail = () => {
    user?.employee?.forEach((company, _) => {
      if (companyInfo?.id === company.companyId) {
        setSelectedCompany(company)
      }
    })
    dispatch(setCompany({}))
  }

  const iconSize = 22
  const menu = [
    {
      id: 0,
      name: 'People',
      icon: <BsPeople size={iconSize} />,
      link: `/people/${companyId}/user/${selectedCompany?.employeeId}?page=profile`,
      show: true,
    },
    {
      id: 1,
      name: 'Meeting',
      icon: <GiVideoConference size={iconSize} />,
      link: `/meeting/${companyId}/user/${selectedCompany?.employeeId}?page=profile`,
      show: true,
    },
    {
      id: 2,
      name: 'Goals',
      icon: <TbTargetArrow size={iconSize} />,
      link: `/goals/explore/${selectedCompany.companyId}`,
      show: true,
    },
    {
      id: 3,
      name: 'Assessment',
      icon: <RiUserStarLine size={iconSize} />,
      link: `/review/${selectedCompany.companyId}/user/${selectedCompany?.employeeId}`,
      show: true,
    },
    {
      id: 4,
      name: 'Company',
      icon: <BsBuildings size={iconSize} />,
      link: `/company/${companyId}`,
      show: true,
    },
    {
      id: 5,
      name: 'Survey',
      icon: <RiSurveyLine size={iconSize} />,
      link: `/active-surveys/${companyId}`,
      show: true,
    },
    {
      id: 6,
      name: 'Analytics',
      icon: <TbDeviceAnalytics size={iconSize} />,
      link: `/analytics/${companyId}/overview`,
      show: selectedCompany?.isAnalyticsAdmin && companyId,
    },
    {
      id: 7,
      name: 'Admin',
      icon: <BsPersonGear size={24} />,
      link: `/admin/${selectedCompany.companyId}/employees`,
      show: selectedCompany?.isAdmin && companyId,
    },
    {
      id: 8,
      name: 'Settings',
      icon: <FiSettings size={iconSize} />,
      link: `/company/${companyId}/employee-settings`,
      show: true,
    },
  ]

  const handleNavClick = (link) => {
    navigate(link)
    setState(false)
  }

  return (
    <div
      style={{ zIndex: 5498, height: 'calc(100vh - 1px)', overflowY: 'scroll' }}
      className={`${
        show ? 'translate-x-0' : 'translate-x-full'
      } transition-all transform bg-gray-100 absolute top-0 w-full`}
    >
      {!companyId && (
        <div className="pt-4 pb-12 px-8">
          <p className="text-md font-bold text-gray-700">Select company</p>
          {companyList.map((company) => (
            <div
              onClick={() => handleNavClick(`/home/${company.companyId}`)}
              key={company.id}
              className={`${
                show ? 'visible' : 'hidden'
              } cursor-pointer text-md text-gray-700 flex items-center gap-5 py-4 border-b-2 border-gray-300`}
            >
              {company.companyThumbnail ? (
                <img
                  alt="company thumbnail"
                  src={company.companyThumbnail}
                  className="flex items-center justify-center w-8 h-8 bg-white rounded-full text-base"
                />
              ) : (
                <div
                  style={{ backgroundColor: avatarInitials(company.companyName || '').color }}
                  className={classNames(
                    'flex items-center justify-center w-8 h-8 bg-blue-500 text-white rounded-full text-base'
                  )}
                >
                  {company.companyName[0].toUpperCase()}
                </div>
              )}
              <span>{company.companyName}</span>
            </div>
          ))}
        </div>
      )}

      {companyId && (
        <div className="px-5 pt-5">
          <select
            onChange={(e) => handleNavClick(`/home/${e.target.value}`)}
            id="tabs"
            name="tabs"
            className="block w-full pl-3 pr-10 py-2 text-xs md:text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 rounded-md"
            //   @ts-ignore
          >
            {companyList &&
              companyList.map((tab, idx) => (
                <option value={tab.companyId} key={idx} selected={tab.companyId === Number(companyId)}>
                  {tab.companyName}
                </option>
              ))}
          </select>
        </div>
      )}

      {companyId && (
        <div className="pt-2 pb-12 px-8">
          {menu.map((m) => (
            <div
              onClick={() => handleNavClick(m.link)}
              key={m.id}
              className={`${
                show ? 'visible' : 'hidden'
              } cursor-pointer text-md text-gray-700 flex items-center gap-5 py-4 border-b-2 border-gray-300`}
            >
              {m.icon}
              <span>{m.name}</span>
            </div>
          ))}
        </div>
      )}
    </div>
  )
}

export default MMenu
