/* This example requires Tailwind CSS v2.0+ */
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { SelectorIcon } from '@heroicons/react/solid'
import { classNames } from 'lib/utils/classNames'
import { IButtonProps } from 'interfaces/atoms/button.types'
import { isEmpty, map } from 'lodash'
import { IoCaretForwardOutline } from 'react-icons/io5'
import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { AiOutlineExclamationCircle } from 'react-icons/ai'

interface IDropdown extends IButtonProps {
  items: {
    name: string
    className?: string
    id: string
    onClick?: () => void
    children?: any
    icon?: any
  }[]
  right?: boolean
  border?: boolean
  placeholder?: string
  type: any
  setType?: any
  tooltip?: string
  error?: string
  required?: boolean
  className?: string
  ancestor?: string
}

export default function NestedDropdown({
  label,
  items,
  right = false,
  border = false,
  placeholder = '',
  type = {
    item: {},
    subItem: {},
  },
  setType,
  tooltip = '',
  error = '',
  required,
  className = '',
  ancestor,
}: IDropdown) {
  let title = ''
  if (type) {
    if (!isEmpty(type?.subItem)) {
      title = `${type?.item?.name} (${type?.subItem?.name})`
    } else {
      title = type?.item?.name
    }
  }
  return (
    <Menu
      onClick={(e) => {
        e.stopPropagation()
      }}
      as="div"
      className={`${className} relative ${border && 'border rounded-md'} w-full inline-block text-left`}
    >
      <div className="flex items-center">
        {label && (
          <label className="block dark:text-white my-2 text-sm font-semibold">
            {label} {required && <span className="text-red-500 dark:text-red-400 font-medium text-base">*</span>}
          </label>
        )}
        {tooltip.length > 0 && (
          <Tooltip text={tooltip}>
            <AiOutlineExclamationCircle className="text-themeGray mx-1" />
          </Tooltip>
        )}
      </div>
      <div className="">
        <Menu.Button
          className={`relative block w-full pr-10 sm:text-sm p-2 ${
            ancestor !== 'survey' && 'py-2.5'
          } border border-gray-400 focus:border-blue-500 focus:outline-none transition-all rounded-md  dark:text-white`}
        >
          <span className={` block text-left truncate text-gray-500 dark:text-white`}>
            {title ? title : placeholder}
          </span>
          <span className="absolute -top-5 right-0 pt-8 flex items-center pr-2 pointer-events-none">
            <SelectorIcon className="h-5 w-5 text-gray-400" aria-hidden="true" />
          </span>
        </Menu.Button>
        {error && <p className="mt-2 transition-all duration-200 text-sm text-red-600">{error}</p>}
      </div>

      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items
          style={{ zIndex: 1010 }}
          className={`${
            !right && 'right-0'
          } origin-top-right absolute left-0  mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
        >
          <div className="py-1">
            {map(items, (item) => (
              <Menu.Item key={item.id}>
                {({ active }) => (
                  <div
                    role={'button'}
                    aria-label={item.name}
                    onClick={() => setType({ item: item, subItem: {} })}
                    className={classNames(
                      item?.className,
                      active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                      'block px-4 w-full py-2 text-sm cursor-pointer'
                    )}
                  >
                    {item.children ? (
                      <Menu
                        onClick={(e) => e.stopPropagation()}
                        as="div"
                        className={`relative ${border && 'border rounded-md'} inline-block text-left`}
                      >
                        <div className="">
                          <Menu.Button className={'w-full flex items-center'}>
                            {item.name} <IoCaretForwardOutline className="h-5 w-5" aria-hidden="true" />
                          </Menu.Button>
                        </div>

                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items
                            style={{ zIndex: 999 }}
                            className={`${
                              !right && 'right-0'
                            } origin-top-right absolute left-24 top-0 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none`}
                          >
                            <div className="py-1">
                              {map(item.children, (subItem) => (
                                <Menu.Item key={subItem.id}>
                                  {({ active }) => (
                                    <div
                                      role={'button'}
                                      aria-label={subItem.name}
                                      onClick={() => {
                                        setType({ item: item, subItem: subItem })
                                      }}
                                      className={classNames(
                                        subItem?.className,
                                        active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                        'block px-4 py-2 text-sm cursor-pointer'
                                      )}
                                    >
                                      {subItem.icon} {subItem.name}
                                    </div>
                                  )}
                                </Menu.Item>
                              ))}
                            </div>
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    ) : (
                      <>
                        {' '}
                        {item.icon} {item.name}
                      </>
                    )}
                  </div>
                )}
              </Menu.Item>
            ))}
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
