import Modal from 'components/atoms/Modal'
import { useCallback, useEffect, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { isEmpty } from 'lodash'
import ProgressBarGif from 'assets/icons/progress-bar.gif'
import { BsFillFileEarmarkImageFill } from 'react-icons/bs'
import { useOneOnOneTools } from 'hooks/useOneOnOneTools'
import { useLocation, useParams } from 'react-router-dom'
import UploadIcon from 'assets/icons/upload.png'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import { AiOutlineCloseCircle } from 'react-icons/ai'

const AddAttachmentModal = ({
  showModal,
  setShowModal,
  item,
  header,
  setRefetch,
  updateAttachments,
  isTalkingPoint = false,
}: {
  showModal: boolean
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>
  setRefetch: React.Dispatch<React.SetStateAction<boolean>>
  updateAttachments: (attachment: any, id: number) => void
  item: any
  header: string
  isTalkingPoint?: boolean
}) => {
  const { companyId }: any = useParams()
  const location = useLocation()
  const isGoals = location.pathname.includes('goals')
  const { uploadAttachment, uploadPointAttachment, uploadGoalsAttachment } = useOneOnOneTools()
  const [error, setError] = useState('Error uploading file. Please try again')
  const [loading, setLoading] = useState(false)
  const [fileUrl, setFileUrl] = useState(null)
  const [addUrl, setAddUrl] = useState(false)
  const [file, setFile] = useState<File>(null)

  useEffect(() => {
    if (error) {
      const timer = setTimeout(() => {
        setError('')
      }, 3000)
      return () => {
        clearTimeout(timer)
      }
    }
  }, [error])

  const onFileSuccess = (response) => {
    setRefetch(true)
    updateAttachments(response.attachment, item?.id)
    setLoading(false)
    setShowModal(false)
  }

  const onFileError = (error) => {
    setError(error.message)
    setLoading(false)
  }

  const onDrop = useCallback((files: File[]) => {
    if (isEmpty(files)) setError('Error uploading file. Please try again')
    setFile(files[0])
    setLoading(true)
    const formData = new FormData()
    formData.append('file', files[0])
    formData.append('type', 'file')
    isGoals
      ? uploadGoalsAttachment(item?.id, formData, companyId, onFileSuccess, onFileError)
      : isTalkingPoint
      ? uploadPointAttachment(item?.id, formData, companyId, onFileSuccess, onFileError)
      : uploadAttachment(item?.id, formData, companyId, onFileSuccess, onFileError)
  }, [])

  const onFileUrlChange = (e) => {
    setFileUrl(e.target.value)
    if (e.target.value.length > 5 && e.target.value.includes('http')) {
      setTimeout(() => {
        setLoading(true)
        const formData = new FormData()
        formData.append('file', e.target.value)
        formData.append('type', 'url')
        uploadAttachment(item?.id, formData, companyId, onFileSuccess, onFileError)
      }, 1000)
    } else {
      setError('Please enter a valid url')
    }
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
  })

  const onCancel = () => {
    setShowModal(false)
  }

  const dropText = isDragActive ? 'Drop the files here ...' : 'Drag & Drop your file here'

  useEffect(() => {
    if (showModal) {
      setFileUrl('')
      setError('')
      setLoading(false)
      setAddUrl(false)
    }
  }, [showModal])

  return (
    <>
      <Modal
        fixedOverflow
        max={{ w: 152 }}
        hwClassName={'max-w-152'}
        header={'Attachments'}
        open={showModal}
        setOpen={onCancel}
      >
        <div className="mb-4 mt-2">
          <div className="rounded-lg bg-gray-100 px-4 py-2 w-full text-sm border border-dashed border-gray-400">
            {header}
          </div>
        </div>

        <div className="pt-4">
          <div className="justify-center transition-all flex gap-4 items-center">
            <div className="flex flex-col justify-center items-center">
              <p className="text-sm font-semibold">Add a new attachment</p>
              <p className="mt-1 text-xs text-gray-600">10 Mb max.</p>
            </div>
          </div>
        </div>

        {loading ? (
          <div
            style={{ width: '80%', height: 'max-content' }}
            className={`pt-4 bg-gray-100 border-gray-400 border border-dashed
           flex flex-col items-center justify-center rounded-lg my-4 mx-auto`}
          >
            <p className="flex gap-2 -mb-4">
              <BsFillFileEarmarkImageFill className="text-blue-500" size={20} />{' '}
              {file.name ? file.name : 'Uploading...'}
            </p>
            <img className="w-11/12" src={ProgressBarGif} alt="progress-bar" />
          </div>
        ) : (
          <>
            <div
              style={{ width: '80%', height: '250px' }}
              className={`${
                isDragActive ? 'bg-blue-100 border-blue-500' : 'bg-gray-100 border-gray-400'
              } hover:bg-blue-100 cursor-pointer border border-dashed
          overflow-hidden transition-all flex flex-col items-center justify-center rounded-lg my-4 mx-auto`}
              {...getRootProps()}
            >
              <input {...getInputProps()} />
              <img style={{ width: '200px', height: '160px' }} src={UploadIcon} alt="attach" />
              <p className="font-semibold mb-1 mt-3">{dropText}</p>
              <p className="text-xs text-gray-600">or, click to browse</p>
              <p className={`${error ? 'opacity-1' : 'opacity-0'} text-sm text-red-500 my-2 pb-4`}>{error}</p>
            </div>
            {!addUrl ? (
              <div className="font-semibold text-md text-center">
                Or paste{' '}
                <span onClick={() => setAddUrl(true)} className="cursor-pointer text-blue-500 underline">
                  URL
                </span>
              </div>
            ) : (
              <div style={{ width: '80%' }} className="flex mx-auto items-center justify-center gap-2">
                <NormalFormInput
                  label=""
                  fullWidth
                  className="text-gray-700"
                  placeholder="Paste url here..."
                  onChange={(e) => onFileUrlChange(e)}
                  value={fileUrl}
                />
                <AiOutlineCloseCircle
                  size={20}
                  className="cursor-pointer ml-2 text-gray-700"
                  onClick={() => {
                    setFileUrl('')
                    setAddUrl(false)
                  }}
                />
              </div>
            )}
          </>
        )}
      </Modal>
    </>
  )
}

export default AddAttachmentModal
