import Modal from 'components/atoms/Modal'
import { FaInfoCircle } from 'react-icons/fa'

const AppraisalAcknowledgementConfirmationModal = ({
  showConfirmationModal,
  setShowConfirmationModal,
  acknowledgeCheck,
}) => {
  return (
    <div className="mx-5">
      <Modal
        width="50%"
        hwClassName={'p-0'}
        header={
          <div className="flex items-center content-center gap-2">
            <div>
              <FaInfoCircle />
            </div>
            <div>Confirmation</div>
          </div>
        }
        open={showConfirmationModal}
        setOpen={setShowConfirmationModal}
        zIndex={5001}
      >
        <div>
          <p className="font-sm text-justify">
            You are about to acknowledge this assessment, affirming its content and values. By checking this checkbox,
            you are confirming your decision to submit the assessment, knowing that it will become final and
            unalterable. Please note that once checked, this action cannot be undone.
          </p>
          <br />
          <div className="flex gap-2 items-center">
            <input
              onChange={(e) => acknowledgeCheck(e)}
              className="disabled:opacity-50 form-check-input appearance-none h-4 w-4 border border-gray-500 rounded-sm bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="checkbox"
            ></input>
            <div className="font-bold text-justify">
              Are you certain you want to proceed and acknowledge this assessment?
            </div>
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default AppraisalAcknowledgementConfirmationModal
