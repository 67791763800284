import { ReactComponent as EditIcon } from 'assets/icons/Edit.svg'
import Avatar from 'components/atoms/Avatar'
import Button from 'components/atoms/Button/button'
import DatePickerInput from 'components/atoms/formcompomnents/DatePickerinput'
import NormalFormInput from 'components/atoms/formcompomnents/NormalFormInput'
import { Selector3 } from 'components/atoms/formcompomnents/Selector'
import CalendarModal from 'components/organisms/Meeting/OneOne/CalendarModal'
import EditProfilePicDialog from 'components/organisms/Settings/EditProfilePicDialog'
import UpdateEmailDialog from 'components/templates/Admin/Employee/EmployeeDialogs/UpdateEmailDialog'
import WorkHistoryList from 'components/templates/Admin/Employee/WorkHistoryList'
import { BUTTONS } from 'constants/common'
import { DashboardLayout } from 'containers/Layouts/DashboardLayout'
import { validTimezones } from 'helpers/dateconvertors'
import { HttpRequest } from 'helpers/services'
import { useAdminTools } from 'hooks/useAdminTools'
import { useCompanyTools } from 'hooks/useCompanyTools'
import { useSettings } from 'hooks/useSettings'
import { useUserTools } from 'hooks/useUserTools'
import { get } from 'lodash'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { BsCalendarRange } from 'react-icons/bs'
import { Oval as Loader } from 'react-loader-spinner'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { setErrorMessages, setSuccessMessage } from 'state/redux/actions/settings.actions'

let prevUserDetails = {}

export const ethnicityList = [
  {
    id: 'a',
    name: 'Hispanic or Latino',
  },
  {
    id: 'b',
    name: 'Not Hispanic or Latino',
  },
]

export const raceList = [
  {
    id: 'a',
    name: 'Asian',
  },
  {
    id: 'b',
    name: 'Black or African American',
  },
  {
    id: 'c',
    name: 'Native Hawaiian or Pacific Islander',
  },
  {
    id: 'd',
    name: 'American Indian or Alaskan Native',
  },
  {
    id: 'e',
    name: 'White',
  },
  {
    id: 'f',
    name: 'Two or more races',
  },
]
export const genderList = [
  {
    id: 'a',
    name: 'Male',
  },
  {
    id: 'b',
    name: 'Female',
  },
  {
    id: 'c',
    name: 'Non binary',
  },
]
const CompanySettings = () => {
  const { t } = useTranslation()
  const dispatch: any = useDispatch()
  const { saveUserDetails } = useUserTools()
  const [timeZone, setTimeZone] = useState('')
  const [gender, setGender] = useState('')
  const [ethnicity, setEthnicity] = useState(null)
  const [race, setRace] = useState(null)
  const [save, setSave] = useState(true)
  const [disableSaveButton, setDisableSaveButton] = useState(true)
  const [formErrors, setFormErrors]: any = useState({})
  const { saveEmployeeDetails } = useAdminTools()
  const [startDate, setStartDate] = useState<any>(null)
  const [endDate, setEndDate] = useState<any>(null)
  const [birthDate, setBirthDate] = useState<any>(null)
  const { loading, setLoading } = useSettings()
  const [updateEmailPopup, setUpdateEmailPopup] = useState(false)
  const [editProfilePic, setEditProfilePic] = useState(false)
  const [image, setImage] = useState(null)
  const [isWorking, setIsWorking] = useState(null)
  const [workHistoryList, setWorkHistoryList] = useState([])
  const [employeeId, setEmployeeId] = useState('')
  const [isGoogleCalendarIntegrated, setIsGoogleCalendarIntegrated] = useState(false)
  const [googleCalendarUrl, setGoogleCalendarUrl] = useState(null)
  const [userEmail, setUserEmail] = useState(null)
  const [isOutlookCalendarIntegrated, setIsOutlookCalendarIntegrated] = useState(false)
  const [outlookEmail, setOutlookEmail] = useState(null)
  const [outlookCalendarUrl, setOutlookCalendarUrl] = useState(null)
  const [showCalendarModal, setShowCalendarModal] = useState(false)
  const [age, setAge] = useState<string>()
  const [init, setInit] = useState(true)
  const [refetch, setRefetch] = useState(false)
  const [selectedUser, setSelectedUser] = useState({
    email: null,
    fullName: null,
    displayName: null,
    profileImageUrl: '',
    profileImageThumbnailUrl: '',
    phoneNumber: '',
    jobTitle: '',
    address: '',
    managerId: '',
    departmentId: '',
    timeZone: '',
    hrisFullName: '',
    status: '',
    ethnicity: null,
    race: null,
  })

  const timeZoneList = [
    {
      id: 'a',
      name: moment().tz(validTimezones['EST']).isDST() ? 'EDT' : 'EST',
    },
    {
      id: 'b',
      name: moment().tz(validTimezones['CST']).isDST() ? 'CDT' : 'CST',
    },
    {
      id: 'c',
      name: moment().tz(validTimezones['MST']).isDST() ? 'MDT' : 'MST',
    },
    {
      id: 'd',
      name: moment().tz(validTimezones['PST']).isDST() ? 'PDT' : 'PST',
    },
    {
      id: 'e',
      name: 'IST',
    },
  ]

  const { companyId }: any = useParams()
  const user: any = useSelector((state) => get(state, 'user.user'))
  const { getWorkHistory } = useCompanyTools()

  useEffect(() => {
    user.employee.map((company) => {
      if (company.companyId === parseInt(companyId)) {
        setEmployeeId(company?.employeeId)
      }
    })
  }, [user])
  const validateForm = () => {
    let isValid = true
    let messages = {
      email: '',
      fullName: '',
      displayName: '',
      profileImageUrl: '',
      profileImageThumbnailUrl: '',
      phoneNumber: '',
      jobTitle: '',
      managerId: '',
      departmentId: '',
      timeZone: '',
      hrisFullName: '',
      ethnicity: null,
      race: null,
    }
    if (selectedUser.fullName !== null && selectedUser.fullName.length === 0) {
      messages.fullName = `Name can't be blank`
      isValid = false
    }
    if (selectedUser.displayName !== null && selectedUser.displayName.length === 0) {
      messages.displayName = `Preferred name can't be blank`
      isValid = false
    }
    if (selectedUser.email !== null && !selectedUser.email.includes('@')) {
      messages.email = 'Email is invalid'
      isValid = false
    }

    const phoneNumberPattern = selectedUser?.phoneNumber?.replace(/-/g, '')

    if (selectedUser?.phoneNumber && !/^\d+$/.test(phoneNumberPattern)) {
      messages.phoneNumber = `Phone number can only contain numbers and hyphens`
      isValid = false
    } else {
      if (selectedUser.phoneNumber && phoneNumberPattern?.length < 10) {
        messages.phoneNumber = `Phone number can't be less then 10 digits`
        isValid = false
      } else if (selectedUser.phoneNumber && phoneNumberPattern?.length > 10) {
        messages.phoneNumber = `Phone number can't be more then 10 digits`
        isValid = false
      }
    }

    setFormErrors({
      ...formErrors,
      ...messages,
    })
    return isValid
  }
  useEffect(() => {
    if (selectedUser) {
      setDisableSaveButton(!validateForm())
    }
    if (JSON.stringify(prevUserDetails) === JSON.stringify(selectedUser)) {
      setSave(false)
    } else {
      setSave(true)
    }
  }, [selectedUser])

  useEffect(() => {
    if (isWorking) {
      setEndDate(null)
    }
  }, [isWorking])

  const calculateAge = (birthdate) => {
    if (!birthdate) return
    const today = new Date()
    const dob = new Date(birthdate)
    let age = today.getFullYear() - dob.getFullYear()
    const monthDiff = today.getMonth() - dob.getMonth()
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < dob.getDate())) {
      age--
    }
    setAge(age.toString())

    if (init) {
      setSave(true)
    }
  }

  const onChangeBirthDate = (date) => {
    setBirthDate(date)
  }

  useEffect(() => {
    calculateAge(birthDate)
  }, [birthDate])

  const onChangeAge = (e) => {
    const inputAge = parseInt(e.target.value, 10)
    if (!isNaN(inputAge)) {
      setAge(e.target.value)
      setBirthDate(null)
      setSave(true)
    } else {
      setAge(null)
    }
  }

  const getWorkHistoryDetails = async () => {
    const data: any = await getWorkHistory(employeeId)
    setWorkHistoryList(data)
  }

  const fetchUserInfo = () => {
    setLoading(true)
    HttpRequest('GET', `/company/employees/${employeeId}`, {}, { 'x-appengine-company-id': companyId })
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const fetchedUser = res?.data?.data
          setSelectedUser({
            ...selectedUser,
            ...fetchedUser,
          })
          setGender(fetchedUser?.gender)
          setRace(fetchedUser?.race)
          setEthnicity(fetchedUser?.ethnicity)
          setStartDate(fetchedUser?.startDate === null ? null : new Date(fetchedUser?.startDate))
          setBirthDate(fetchedUser?.birthDate === null ? null : new Date(fetchedUser?.birthDate))
          setEndDate(fetchedUser?.endDate === null || fetchedUser?.isWorking ? null : new Date(fetchedUser?.endDate))
          setIsWorking(fetchedUser?.isWorking)
          setAge(fetchedUser?.age)
          setLoading(false)

          prevUserDetails = { ...selectedUser, ...fetchedUser }
          setInit(false)

          setSave(false)
        }
      })
      .catch((err) => {
        console.error(err)
        setLoading(false)
      })
  }

  useEffect(() => {
    if (employeeId) {
      getWorkHistoryDetails()
    }
  }, [employeeId])

  useEffect(() => {
    if (employeeId) {
      fetchUserInfo()
    }
  }, [employeeId])

  const format = 'YYYY-MM-DD'

  const saveEmployeeInfo = () => {
    let headersData = { 'x-appengine-company-id': companyId }
    const data = {
      fullName: selectedUser.fullName || '',
      displayName: selectedUser.displayName || '',
      profileImageUrl: image ? image : selectedUser.profileImageUrl || '',
      profileImageThumbnailUrl: image ? image : selectedUser.profileImageThumbnailUrl || '',
      phoneNumber: selectedUser.phoneNumber || '',
      jobTitle: selectedUser.jobTitle || '',
      address: selectedUser.address || '',
      startDate: startDate === null ? null : moment(startDate).format(format),
      timeZone: timeZone || '',
      birthDate: birthDate === null ? null : moment(birthDate).format(format),
      endDate: endDate === null ? null : moment(endDate).format(format),
      isWorking: isWorking ? true : false,
      gender: gender || '',
      hrisFullName: '',
      ethnicity: ethnicity || null,
      race: race || null,
      age: age || '',
    }

    const onSaveSuccess = () => {
      prevUserDetails = selectedUser
      setSave(false)
      if (image) {
        fetchUserInfo()
      }
    }
    saveEmployeeDetails(employeeId, data, headersData, onSaveSuccess)
  }

  useEffect(() => {
    if (image) {
      saveEmployeeInfo()
    }
  }, [image, user])

  const onGenderSelect = (item) => {
    setGender(item?.name)
    setSave(true)
  }
  const onEthnicitySelect = (item) => {
    setEthnicity(item?.name)
    setSave(true)
  }
  const onRaceSelect = (item) => {
    setRace(item?.name)
    setSave(true)
  }

  const onCancel = () => {
    fetchUserInfo()
  }

  const fetchGoogleUrl = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-user-id': employeeId,
    }

    HttpRequest('GET', `/user/google-calendar-url`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const url = data?.data?.url
          setGoogleCalendarUrl(url)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  const fetchOutlookUrl = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
      'x-appengine-user-id': employeeId,
    }

    HttpRequest('GET', `/user/outlook-calendar-url`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          const url = data?.data?.url
          setOutlookCalendarUrl(url)
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {})
  }

  const disconnectGoogleCalendar = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('GET', `/user/disconnect-google-calendar`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setUserEmail(null)
          setIsGoogleCalendarIntegrated(false)
          setShowCalendarModal(false)
          setRefetch(true)
          dispatch(setSuccessMessage('Google calendar disconnected successfully'))
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
      .finally(() => {})
  }

  const disconnectOutlookCalendar = () => {
    const commonHeader = {
      'x-appengine-company-id': companyId,
    }

    HttpRequest('GET', `/user/disconnect-outlook-calendar`, {}, commonHeader)
      .then(({ data }: any) => {
        if (data.success) {
          setOutlookEmail(null)
          setIsOutlookCalendarIntegrated(false)
          setShowCalendarModal(false)
          setRefetch(true)
          dispatch(setSuccessMessage('Outlook calendar disconnected successfully'))
        }
      })
      .catch((err) => {
        console.error(err)
        dispatch(setErrorMessages([err?.response?.data?.message]))
      })
      .finally(() => {})
  }

  useEffect(() => {
    if (employeeId) {
      fetchGoogleUrl()
      fetchOutlookUrl()
    }
  }, [employeeId])

  const fetchEmailSyncInfo = () => {
    HttpRequest('GET', `/user`)
      .then((res: any) => {
        if (res?.data?.code === 200) {
          const personalInfo = res?.data?.data
          setIsGoogleCalendarIntegrated(personalInfo?.isGoogleCalendarSynced)
          setIsOutlookCalendarIntegrated(personalInfo?.isOutlookCalendarSynced)
          if (personalInfo?.isGoogleCalendarSynced) {
            setUserEmail(personalInfo?.googleCalendarEmail)
          }
          if (personalInfo?.isOutlookCalendarSynced) {
            setOutlookEmail(personalInfo?.outlookEmail)
          }
        }
      })
      .catch((err) => {
        console.error(err)
      })
      .finally(() => {
        setRefetch(false)
      })
  }
  useEffect(() => {
    fetchEmailSyncInfo()
  }, [refetch])

  const timeZoneLabels = {
    EDT: 'EST',
    EST: 'EST',
    MDT: 'MST',
    MST: 'MST',
    PDT: 'PST',
    PST: 'PST',
    CST: 'CST',
    CDT: 'CST',
    IST: 'IST',
  }
  const onTimeZoneSelect = (item) => {
    let headersData = { 'x-appengine-company-id': companyId }
    const tz = timeZoneLabels[item.name]
    const data = {
      timeZone: tz,
    }
    const onSaveSuccess = () => {
      setTimeZone(item?.name)
    }
    saveUserDetails(data, headersData, onSaveSuccess)
  }

  useEffect(() => {
    if (user?.timeZone) {
      setTimeZone(user.timeZone)
    } else {
      setTimeZone('No Timezone')
    }
  }, [user])

  return (
    <DashboardLayout onlyMainSidebar>
      <UpdateEmailDialog
        open={updateEmailPopup}
        fetchUserInfo={fetchUserInfo}
        companyId={companyId}
        setOpen={setUpdateEmailPopup}
        status={selectedUser?.status}
        existingEmail={selectedUser.email}
      />
      <EditProfilePicDialog
        open={editProfilePic}
        setOpen={setEditProfilePic}
        setImage={setImage}
        image={image}
        selectedUser={selectedUser}
        existingImageURL={selectedUser.profileImageThumbnailUrl}
        saveInfo={() => {}}
      />
      <CalendarModal
        userEmail={userEmail}
        outlookEmail={outlookEmail}
        googleCalendarUrl={googleCalendarUrl}
        outlookCalendarUrl={outlookCalendarUrl}
        disconnectGoogleCalendar={disconnectGoogleCalendar}
        disconnectOutlookCalendar={disconnectOutlookCalendar}
        isOutlookCalendarIntegrated={isOutlookCalendarIntegrated}
        isGoogleCalendarIntegrated={isGoogleCalendarIntegrated}
        showModal={showCalendarModal}
        setShowModal={setShowCalendarModal}
      />
      {loading ? (
        <div className="flex justify-center items-center h-96">
          <Loader color={'white'} secondaryColor="#7d7d7d" height={50} width={50} />
        </div>
      ) : (
        <div className="mx-32 m-16 relative font-roboto">
          <h1 className="text-3xl font-bold">{selectedUser.fullName}</h1>
          <div className="flex space-x-4 my-5 w-full">
            <div className="relative mr-4 my-auto">
              <Avatar large name={selectedUser.fullName} image={selectedUser.profileImageThumbnailUrl} />
              <EditIcon onClick={() => setEditProfilePic(true)} className="absolute left-32 top-28 cursor-pointer" />
            </div>
            <div className="flex flex-col w-full">
              <div className="flex w-full space-x-4">
                <NormalFormInput
                  label="Full name"
                  id="name"
                  name="name"
                  type="text"
                  value={selectedUser.fullName}
                  onChange={(e) => setSelectedUser({ ...selectedUser, fullName: e.target.value })}
                  gridClass="my-2 mb-6 w-1/2"
                  fullWidth
                  error={formErrors.fullName}
                />
                <NormalFormInput
                  label="Display name"
                  id="displayName"
                  name="displayName"
                  type="text"
                  value={selectedUser.displayName}
                  onChange={(e) => setSelectedUser({ ...selectedUser, displayName: e.target.value })}
                  gridClass="my-2 mb-6 w-1/2"
                  fullWidth
                  error={formErrors.displayName}
                />
              </div>
              <div className="flex space-x-4 w-full">
                <div className="w-1/2">
                  <NormalFormInput
                    label="Business Email address"
                    id="email"
                    name="email"
                    type="email"
                    value={selectedUser.email}
                    onChange={(e) => setSelectedUser({ ...selectedUser, email: e.target.value })}
                    fullWidth
                    error={formErrors.email}
                    disabled
                  />
                </div>
                <div className="flex items-center justify-between w-1/2">
                  <NormalFormInput
                    label="Phone number"
                    id="phoneNumber"
                    name="phoneNumber"
                    type="string"
                    className="placeholder-themeGray w-50%"
                    placeholder="XXX-XXX-XXXX"
                    value={selectedUser.phoneNumber}
                    onChange={(e) => setSelectedUser({ ...selectedUser, phoneNumber: e.target.value })}
                    error={formErrors.phoneNumber}
                  />
                  <Button
                    size="md"
                    iconBefore
                    iconClass="group-hover:text-white h-4 w-4 mx-1"
                    Icon={BsCalendarRange}
                    hoverColor="bg-blue-600"
                    btnColor="bg-blue-500"
                    className="mt-9 h-10 w-50% font-semibold"
                    label="Sync Calendar"
                    primary
                    onClick={() => setShowCalendarModal(true)}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="mb-5 pt-4">
            <div className="ml-2 w-full flex justify-between">
              <p className="block dark:text-white text-sm font-semibold">Work History</p>
            </div>
            <WorkHistoryList data={workHistoryList} hideEdit />
          </div>
          <div className="space-y-6">
            <div className="flex justify-between w-full items-center space-x-4">
              <div className="flex w-1/4 space-x-3">
                <DatePickerInput
                  className="h-[38px] w-11/12 text-sm"
                  onChangeFunction={onChangeBirthDate}
                  setDate={(date) => {
                    setInit(true)
                    setBirthDate(date)
                    calculateAge(date)
                  }}
                  date={birthDate}
                  maxDate={new Date()}
                  label="Birth Date"
                  dropdownMode="select"
                  // disabled={!!age && !birthDate} // Disable if age is entered
                />
                <NormalFormInput
                  id="age"
                  name="age"
                  type="number"
                  min={0}
                  step={1}
                  label="Age"
                  placeholder="Age"
                  value={age}
                  onChange={onChangeAge}
                  className="h-[38px] px-3 w-full"
                  paddingRight="pr-1"
                  gridClass="w-full"
                  widthClass="w-11/12"
                />
              </div>
              <div className="flex w-3/4 space-x-3">
                <Selector3
                  selectedItem={gender}
                  list={genderList}
                  label="Gender"
                  placeholder="Select"
                  onSelect={onGenderSelect}
                  width="w-1/3"
                />
                <Selector3
                  selectedItem={race}
                  list={raceList}
                  label="Race"
                  placeholder="Select"
                  onSelect={onRaceSelect}
                />
                <Selector3
                  selectedItem={ethnicity}
                  list={ethnicityList}
                  label="Ethnicity"
                  placeholder="Select"
                  onSelect={onEthnicitySelect}
                />
              </div>
            </div>
            <div className="flex justify-between space-x-5"></div>
            <div className="flex items-center justify-between space-x-4">
              <Selector3
                selectedItem={timeZone}
                list={timeZoneList}
                label="Timezone"
                placeholder=""
                onSelect={onTimeZoneSelect}
                buttonBoxClass="h-[38px]"
                width="w-1/4"
                noMinWidth
              />
              <NormalFormInput
                label="Address"
                id="address"
                name="address"
                type="text"
                placeholder="Address"
                value={selectedUser.address}
                onChange={(e) => setSelectedUser({ ...selectedUser, address: e.target.value })}
                fullWidth
              />
            </div>
          </div>
          <div
            className={`w-3/5 fixed bottom-0 bg-white h-20 transform ${
              save ? 'translate-y-0 opacity-100 visible' : 'translate-y-10 opacity-0 invisible'
            } duration-200 transition-all`}
            style={{ boxShadow: '0 2px 6px 4px rgba(212, 212, 212, 0.44)' }}
          >
            <div className="flex justify-between m-5">
              <p className="my-auto">You have unsaved changes</p>
              <div className="flex justify-end space-x-4">
                <Button
                  textColor="text-black"
                  size="md"
                  hoverColor="bg-themeRed"
                  focusRing="ring-red-500"
                  label={t(BUTTONS.CANCEL)}
                  secondary
                  onClick={onCancel}
                />
                <Button
                  onClick={saveEmployeeInfo}
                  size="md"
                  hoverColor="bg-themeBlue"
                  label={t(BUTTONS.SAVE)}
                  primary
                  disabled={disableSaveButton}
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </DashboardLayout>
  )
}

export default CompanySettings
