import Tooltip from 'components/atoms/Tooltip/Tooltip'
import { isEmpty } from 'lodash'
import DatePicker from 'react-datepicker'
import { AiOutlineExclamationCircle } from 'react-icons/ai'

interface IDatePicker {
  date?: Date
  setDate?: any
  minDate?: any
  maxDate?: any
  label?: string
  required?: boolean
  optional?: boolean
  disabled?: boolean
  tooltip?: string
  error?: string
  errorAbsolute?: boolean
  className?: string
  showTimeSelectOnly?: boolean
  showTimeSelect?: boolean
  timeIntervals?: number
  onChangeFunction?: any
  inputMarginClass?: string
  zIndex?: string | number
  placeholderText?: string
  onKeyDown?: (e: any) => void
  unsetWidth?: boolean
  dropdownMode?: any
}

const DatePickerInput = ({
  date,
  setDate,
  minDate,
  maxDate,
  label,
  required,
  optional,
  disabled,
  tooltip = '',
  error = '',
  errorAbsolute = false,
  className = '',
  showTimeSelectOnly = false,
  showTimeSelect = false,
  timeIntervals = 15,
  onChangeFunction,
  inputMarginClass = 'mt-1',
  zIndex = 1000,
  placeholderText = 'Select',
  onKeyDown = () => {},
  unsetWidth,
  dropdownMode,
  ...props
}: IDatePicker) => {
  return (
    <div className={unsetWidth ? '' : 'w-full'} style={{ zIndex }}>
      <div className={`flex items-center  ${isEmpty(label) ? 'my-0' : 'my-2'}`}>
        <label className="block dark:text-white text-sm font-semibold">
          {label} {required && <span className="text-red-500 dark:text-red-400 font-medium text-base">*</span>}
        </label>
        {optional && (
          <span className="text-sm text-themeGray" id="email-optional">
            (Optional)
          </span>
        )}
        {tooltip.length > 0 && (
          <Tooltip text={tooltip}>
            <AiOutlineExclamationCircle className="text-themeGray mx-1" />
          </Tooltip>
        )}
      </div>
      <div className={inputMarginClass}>
        {showTimeSelectOnly ? (
          <DatePicker
            placeholderText={placeholderText}
            showTimeSelect
            showTimeSelectOnly
            dateFormat="HH:mm"
            timeIntervals={timeIntervals}
            onChange={(date) => setDate(date)}
            disabled={disabled}
            onKeyDown={onKeyDown}
            className={`${
              className ? className : 'w-full'
            } border border-gray-400 focus:border-blue-500 cursor-pointer focus:outline-none transition-all rounded-md  dark:text-white ${
              disabled && 'cursor-not-allowed bg-gray-100 opacity-50'
            }`}
          />
        ) : (
          <DatePicker
            showMonthDropdown
            showYearDropdown
            scrollableYearDropdown={true}
            yearDropdownItemNumber={10}
            showTimeSelect={showTimeSelect}
            timeIntervals={timeIntervals}
            minDate={minDate}
            maxDate={maxDate}
            selected={date}
            dropdownMode={dropdownMode}
            onChange={(date) => {
              if (onChangeFunction) {
                onChangeFunction(date)
              }
              setDate(date)
            }}
            placeholderText={placeholderText}
            disabled={disabled}
            onKeyDown={onKeyDown}
            className={`${
              className ? className : 'w-full'
            } border border-gray-400 focus:border-blue-500 cursor-pointer focus:outline-none transition-all rounded-md  dark:text-white ${
              disabled && 'cursor-not-allowed bg-gray-100 opacity-50'
            }`}
          />
        )}
      </div>
      {error && (
        <p className={`${errorAbsolute ? 'absolute' : ''} mt-2 transition-all duration-200 text-sm text-red-600`}>
          {error}
        </p>
      )}
    </div>
  )
}

export default DatePickerInput
